import {
  EDIT_HOME_MAP,
  CLEAR_EDITED_HOME_MAP
} from '../actions/homeMapUserState.js';

function homeMapUserState(state = {}, action) {
  switch (action.type) {
    case EDIT_HOME_MAP:
      return Object.assign({},state,action.data)
    case CLEAR_EDITED_HOME_MAP:
      return {}
    default:
      return state
  }
}

export default homeMapUserState;
