import React from 'react';

function WebGLError() {
  let style = {
    "width": "100%",
    "height": "100%",
    "background":"linear-gradient(45deg, #0075a6 0%, #4affe5 100%)",
    "position": "absolute",
    "fontFamily": 'Sans-Serif',
    "fontSize": "20px",
    "textAlign": "center",
    "color": "#FFFFFF",
    "display":"flex",
    "alignItems":"center",
    "justifyContent":"center",
    "flexDirection":"column"
  }

  let noNetwork = {
    "fontSize":"20px",
    "background":"#FF0000",
    "padding":"5px"
  }

  let extraInfoStyle = {
    "fontSize": "15px",
    "paddingTop": "10px"
  }
  //<div style={noNetwork}>network connection may have been lost</div>

  return (
    <div style={style}>
      <div>Sorry, Your browser or hardware does not seem to support <a className="NoLinkDecoration" href="https://get.webgl.org/">WebGL</a>.</div>
      <div style={extraInfoStyle}>You can try updating your browser to <a className="NoLinkDecoration" href="https://www.google.com/chrome/">Chrome</a> or <a className="NoLinkDecoration" href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>. You can also try updating your graphics card drivers.</div>
      <div style={extraInfoStyle}>If you are already running Chrome or Firefox then it could be that your hardware is unsupported or WebGL has been disabled</div>
      <div style={extraInfoStyle}>
        <a className="NoLinkDecoration" href="/">Return to Inventum3D Map Page</a>
      </div>
    </div>
  )
}

export default WebGLError;
