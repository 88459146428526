import { store } from './store.js';


// INvestigate this to preserve 16:9 ratio
// https://discourse.threejs.org/t/keeping-an-object-scaled-based-on-the-bounds-of-the-canvas-really-battling-to-explain-this-one/17574/10

class ResolutionManager {
	constructor() {
		this.resolutionLocked = false;
		this.aspectLocked = false;

		this.targetAspect = {
			width: window.innerWidth,
			height: window.innerHeight
		};

		this.resolution = {
			width: window.innerWidth,
			height: window.innerHeight
		};

		this.listeners = {};

		this.update = this.update.bind(this);
		this.forceUpdate = this.forceUpdate.bind(this);
		this.updateListeners = this.updateListeners.bind(this);
		this.getResolution = this.getResolution.bind(this);
		this.addListener = this.addListener.bind(this);
		this.removeListener = this.removeListener.bind(this);

		this.public = {
			forceUpdate: this.forceUpdate,
			getResolution: this.getResolution,
			addListener: this.addListener,
			removeListener: this.removeListener
		}
	}

	forceUpdate() {
		this.update();
	}

	setTargetAspectRatio (width, height) {
		if (isNaN(width) || isNaN(height)) return;
		this.targetAspect.width = parseInt(width);
		this.targetAspect.height = parseInt(height);
		this.aspectLocked = true;
		this.update();
	}

	clearTargetAspectRatio () {
		this.aspectLocked = false;
	}

	lockResolution (width, height) {
		if (isNaN(width) || isNaN(height)) return;
		this.resolution = {
			width: parseInt(width),
			height: parseInt(height)
		};
		this.resolutionLocked = true;
		this.update();
	}

	unlockResolution () {
		this.resolutionLocked = false;
		this.update();
	}

	update () {
		if (this.resolutionLocked) {
			// Don't need to do anything.
			// Check if values are not nonsense
		} else if (this.aspectLocked) {
			this.resolution = calcResFromAspect(this.targetAspect.width, this.targetAspect.height);
		} else {
			this.resolution = {
				width: window.innerWidth,
				height: window.innerHeight
			}
		}

		store.camera.aspect = this.resolution.width / this.resolution.height;

		// Force center 16:9
		/*const degreesToRadians = (degrees) => {
				return degrees * (Math.PI / 180);
		};

		const radiansToDegrees = (radians) => {
			return radians * (180 / Math.PI);
		};

		if (store.camera.aspect > (16 / 9)) {
			store.camera.fov = store.sceneFOV;
		} else {
			const cameraHeight = Math.tan(degreesToRadians(store.sceneFOV / 2));
			const ratio = store.camera.aspect / (16 / 9);
			const newCameraHeight = cameraHeight / ratio;
			store.camera.fov = radiansToDegrees(Math.atan(newCameraHeight)) * 2;
		}*/

		store.camera.updateProjectionMatrix();

		store.cameraHUD.left = -this.resolution.width / 2;
		store.cameraHUD.right = this.resolution.width / 2;
		store.cameraHUD.top = this.resolution.height / 2;
		store.cameraHUD.bottom = -this.resolution.height / 2;
		store.cameraHUD.updateProjectionMatrix();
		store.webglRenderer.setSize(this.resolution.width, this.resolution.height);
		store.requestRender();

		// remove this and replace with a subscriber
		if (store.broadcastMode) {
			console.log('Broadcasting Set Resolution!');
			store.sync.private.broadcast({ action: 'SET_RESOLUTION', payload: { width: this.resolution.width, height: this.resolution.height } });
		}

		this.updateListeners();
	}

	getResolution () {
		return {
			width: this.resolution.width,
			height: this.resolution.height
		}
	}

	reset () {
		this.resolutionLocked = false;
		this.aspectLocked = false;

		this.targetAspect = {
			width: window.innerWidth,
			height: window.innerHeight
		};

		this.resolution = {
			width: window.innerWidth,
			height: window.innerHeight
		};

		this.listeners = {};
	}

	updateListeners () {
		Object.keys(this.listeners).map(key => {
			if (!this.listeners.hasOwnProperty(key)) return;
			const cb = this.listeners[key];
			if (typeof(cb) === 'function') {
				cb({ width: this.resolution.width, height: this.resolution.height });
			}
		})
	}

	addListener (cb) {
		if (typeof(cb) !== 'function') {
			console.log('Error: Function was not provided');
		}

		let id = 1;
		while (this.listeners.hasOwnProperty(id)) {
			id++;
		}

		this.listeners[id] = cb;
		return id;
	}

	removeListener (id) {
		delete this.listeners[id];
	}

}

function calcResFromAspect (targetWidth, targetHeight) {
	// Takes a Target Width and Target Height
	// Returns an equivalent aspect ratio constrained by the browser window.

	let resolution = {width: window.innerWidth, height: window.innerHeight};

  const heightConstrained = { width: window.innerWidth, height: (targetHeight / targetWidth) * window.innerWidth };
  const widthConstrained = { width: (targetWidth / targetHeight) * window.innerHeight, height: window.innerHeight };

  if (heightConstrained.height < window.innerHeight) {
    resolution = heightConstrained;
  } else if (widthConstrained.width < window.innerWidth) {
    resolution = widthConstrained;
  }

	resolution.width = Math.floor(resolution.width);
  resolution.height = Math.floor(resolution.height);

  if (resolution.width === 0) {
    resolution.width = 1;
  }

	if (resolution.height === 0) {
    resolution.height = 1;
  }

	// console.log(`New Resolution is:${resolution.width}x${resolution.height}`);
  return resolution;
}


const resolutionManager = new ResolutionManager();
window.testRes = resolutionManager;

export { resolutionManager }

// Rename resolution to ResolutionManager and export it as default
