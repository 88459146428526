import React from 'react';

function LoadingIcon(props) {
  return (
    <div className="sk-folding-cube">
      <div className="sk-cube1 sk-cube"></div>
      <div className="sk-cube2 sk-cube"></div>
      <div className="sk-cube4 sk-cube"></div>
      <div className="sk-cube3 sk-cube"></div>
    </div>
  )
}

let LoadingBox = (props) => {
  return (
    <div className="LoadingIndicator">
    <LoadingIcon />
    Loading World
    </div>
  )
}

let TourPromptBox = (props) => {
  return (
    <div className="TourPromptBox">
      AutoStart Tour
      <label className="editSwitch">
        <input type="checkbox" checked="true" />
        <div></div>
      </label>
    </div>
  )
}

class TourCountdownBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds:5,
      intervalID:null,
      visible:false
    }
    this.handleCancel = this.handleCancel.bind(this);
    this.handleStart = this.handleStart.bind(this);

  }

  //NOTE: React Update
  componentDidMount() {
    setTimeout(() => {
      //Tick used to be a separate method but it caused a problem where we had
      //to store the setInterval ID in the state which got cleared while the tick
      //method was still running. Now the function is inline and the intID is stored with magical ~closure~
      let tick = () => {
        if (this.state.seconds == 0 ) {
          clearInterval(intID);
          this.props.closeSidebar();
          this.props.hideSplash();
          Inventum.animations.playTourAnimation();
          return;
        }
        this.setState({seconds:--this.state.seconds});
      }

      let intID = setInterval(tick,1000);
      this.setState({intervalID:intID,visible:true});
    },2000)
  }


  handleCancel() {
    clearInterval(this.state.intervalID);
    this.setState({intervalID:null},()=> {
      this.props.hideSplash();
    })
  }

  componentWillUnmount() {
    if (this.state.intervalID !== null) {
      clearInterval(this.state.intervalID);
    }
  }

  handleStart() {
    this.props.closeSidebar();
    clearInterval(this.state.intervalID);
    Inventum.animations.playTourAnimation();
    this.props.hideSplash();
  }


  render() {
    return (
      <div className={"TourCountdownBox" + (this.state.visible ? " visible" : "")}>
        <div>
        Starting Project Tour in {this.state.seconds}
        </div>
        <div className="CountdownControls">
          <div className="CountdownButton start" onClick={this.handleStart}>Start Now</div>
          <div className="CountdownButton cancel" onClick={this.handleCancel}>Cancel</div>
        </div>
      </div>
    )
  }
}

function LoadingSimple(props) {
  let handleKey = (e) => {
    if (e.key === "Escape") {
      console.log(e.key);
      props.hideSplash();
    }else {
      console.log(e.key);
    }
  }
  //      {props.hasLoaded ? null : <TourPromptBox />}
  return (
    <div className="LoadingContainer">
      {props.hasLoaded ? null : <div className="LoadingSimpleBox" onKeyUp={handleKey} tabIndex="0"> <LoadingBox /> </div> }
      {props.hasLoaded ? <TourCountdownBox closeSidebar={props.closeSidebar} hideSplash={props.hideSplash} /> : null}
    </div>
  )
}

export default LoadingSimple
