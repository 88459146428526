export const LOAD_GEOJSON = 'LOAD_GEOJSON';
export const CLEAR_GEOJSON = 'CLEAR_GEOJSON'
export const TOGGLE_MARKERS = 'TOGGLE_MARKERS'
export const TOGGLE_TENEMENTS_LAYER = 'TOGGLE_TENEMENTS_LAYER'
export const HIDE_MARKERS = 'HIDE_MARKERS'
export const SHOW_MARKERS = 'SHOW_MARKERS'
export const HIDE_TENEMENTS_LAYER = 'HIDE_TENEMENTS_LAYER'
export const SET_NEXT_BOUNDS = 'SET_NEXT_BOUNDS'
export const CLEAR_NEXT_BOUNDS = 'CLEAR_NEXT_BOUNDS'

export function loadGeojson(data) {
  return {type:LOAD_GEOJSON, data}
}

export function clearGeojson() {
  return {type:CLEAR_GEOJSON}
}

export function toggleMarkers() {
  return {type:TOGGLE_MARKERS}
}

export function hideMarkers() {
  return {type:HIDE_MARKERS}
}

export function showMarkers() {
  return {type:SHOW_MARKERS}
}

export function toggleTenementsLayer() {
  return {type:TOGGLE_TENEMENTS_LAYER}
}

export function hideTenementsLayer() {
  return {type:HIDE_TENEMENTS_LAYER}
}

export function setNextBounds(data) {
  return {type:SET_NEXT_BOUNDS, data}
}

export function clearNextBounds() {
  return {type:CLEAR_NEXT_BOUNDS}
}
