import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import { store } from '../core/store.js';
import { models } from '../models/models.js';
import { notifications } from '../ui/notifications.js';
import { TweenManager } from '../core/TweenManager.js';
var views = { public: {} };
var initialCameraPosition = {};
var initialTargetPosition = {};
var viewsByID = [];

var helperCube = {};
var inProgressViewID = null;
var activeCameraTween = null;

function findNewID () {
  let validIDFound = false;
  let id = 1;
  while (!validIDFound) {
    if (viewsByID[id] !== undefined) {
      id += 1;
    } else {
      validIDFound = true;
    }
  }
  return id;
}

function animateCamera (toPosition, duration, fromPosition) {
  // toPosition = {x, y, z, phi, theta, distance}
  if (!toPosition || activeCameraTween !== null) return Promise.resolve();
  const controls = store.controls;

  let currentPosition = {
    x: controls.target.x,
    y: controls.target.y,
    z: controls.target.z,
    radius: controls.target.distanceTo(controls.object.position),
    phi: controls.getPolarAngle(),
    theta: controls.getAzimuthalAngle()
  };

  const requiredKeys = ['x', 'y', 'z', 'phi', 'theta', 'radius'];

  requiredKeys.map((key) => {
    if (!toPosition.hasOwnProperty(key)) {
      toPosition[key] = currentPosition[key];
    }
  });

  if (fromPosition) {
    currentPosition = Object.assign({}, fromPosition);
  }

  if (!duration) {
    duration = 3000;
  }

  controls.enabled = false;

	if (Math.abs(toPosition.theta - currentPosition.theta) > Math.PI) {
		// Fix flipping issue
		// https://stackoverflow.com/questions/39786475/threejs-oribital-camera-short-rotation
		if (toPosition.theta > 0) {
			toPosition.theta = toPosition.theta - 2 * Math.PI;
		}else {
			toPosition.theta = toPosition.theta + 2 * Math.PI;
		}
	}

  const vFrom = new THREE.Vector3(currentPosition.x, currentPosition.y, currentPosition.z);
  const vTo = new THREE.Vector3(toPosition.x, toPosition.y, toPosition.z);

  if (vFrom.distanceTo(vTo) < 0.1 &&
      Math.abs(currentPosition.phi - toPosition.phi) < 0.1 &&
      Math.abs(currentPosition.theta - toPosition.theta) < 0.1 &&
      Math.abs(currentPosition.radius - toPosition.radius) < 0.1 &&
			duration != 1) { // Duration of 1 is Inventum Sync which we always want to move even if it's a small movement
				if (!store.followerMode) {
					controls.enabled = true;
				}
		    return Promise.resolve();
  }
  return new Promise((resolve, reject) => {
    const tween = new TWEEN.Tween(currentPosition)
      .to(toPosition, duration)
      .easing(TWEEN.Easing.Cubic.InOut)
      .onUpdate(() => {
        if (currentPosition.phi < 0.01) {
          currentPosition.phi = 0.01;
        }

        controls.target.set(currentPosition.x, currentPosition.y, currentPosition.z);
        const s = new THREE.Spherical(currentPosition.radius, currentPosition.phi, currentPosition.theta);
        const c = new THREE.Vector3().setFromSpherical(s);
        c.add(new THREE.Vector3(currentPosition.x, currentPosition.y, currentPosition.z));
        controls.object.position.copy(c);
        store.requestRender();
      })
      .onComplete(() => {
				if (!store.followerMode) {
					controls.enabled = true;
				}
        controls.dispatchEvent({ type: 'end' });
        activeCameraTween = null;
        resolve();
      })
      .start();
    store.requestRender();
    activeCameraTween = tween;
  });
}

views.storeInitialPosition = function storeInitialPosition (cameraPosition, targetPosition) {
  initialCameraPosition = cameraPosition;
  initialTargetPosition = targetPosition;
};

views.public.setInitialPosition = function setInitialPosition () {
  initialCameraPosition = {
    x: Math.round(store.camera.position.x * 1e2) / 1e2,
    y: Math.round(store.camera.position.y * 1e2) / 1e2,
    z: Math.round(store.camera.position.z * 1e2) / 1e2
  };

  initialTargetPosition = {
    x: Math.round(store.controls.target.x * 1e2) / 1e2,
    y: Math.round(store.controls.target.y * 1e2) / 1e2,
    z: Math.round(store.controls.target.z * 1e2) / 1e2
  };
  notifications.add({ content: 'Updated Initial Camera Position' });
};

views.loadView = function loadView (cameraView) {
  // BUG CLEANUP. Deletes views from the menu if they are Untitled and
  if (cameraView.visibleMenu && cameraView.name === 'Untitled View') {
    cameraView.visibleMenu = false;
  }

  if (!cameraView.hasOwnProperty('id')) {
    const id = findNewID();
    console.warn('Camera View should have an ID. Using ID: ' + id);
    cameraView.id = id;
    viewsByID[id] = cameraView;
    return id;
  }

  if (viewsByID[cameraView.id] !== undefined) {
    console.warn('Provided ID is already in use. Ignoring camera view.');
    return;
  }
  viewsByID[cameraView.id] = cameraView;
};

views.broadcastCamera = function broadcastCamera () {
  if (!store.broadcastMode) return;
  if (inProgressViewID) {
    store.sync.private.broadcast({ action: 'CAMERA_SHOW_VIEW', payload: { viewID: inProgressViewID.id, options: inProgressViewID.options } });
  } else {
    const sUpdate = {
      x: store.controls.target.x,
      y: store.controls.target.y,
      z: store.controls.target.z,
      phi: store.controls.getPolarAngle(),
      theta: store.controls.getAzimuthalAngle(),
      radius: store.controls.target.distanceTo(store.controls.object.position)
    };
    store.sync.private.broadcast({ action: 'CAMERA_MOVE', payload: sUpdate });
  }
};

views.public.showView = function showView (viewID, callback, options) {
  const view = viewsByID[viewID];
  if (!view || inProgressViewID) {
    return;
  }

  inProgressViewID = { id: viewID, options: options };
  views.broadcastCamera(); //Check this.

  let duration = view.duration || 3000;

  if (options) {
    if (options.hasOwnProperty('durationOveride')) {
      duration = options.durationOveride;
    }
  }

  const viewPos = { x: view.x, y: view.y, z: view.z, phi: view.phi, theta: view.theta, radius: view.distance };

  const anim = animateCamera(viewPos, duration);

  anim.then(() => {
    inProgressViewID = null;
    if (typeof (callback) === 'function') {
      callback();
    }
  });
};

views.animateCamera = animateCamera;

views.public.getCameraViews = function getCameraViews () {
  const viewsList = [];
  viewsByID.map((view) => {
    if (view !== undefined) {
      if (view.visibleMenu) {
        viewsList.push({ id: view.id, name: view.name });
      }
    }
  });
  return viewsList;
};

views.public.setFOV = function setFOV (value) {
  if (isNaN(value)) return;
  store.camera.fov = value;
  store.camera.updateProjectionMatrix();
  store.requestRender();
};

views.public.getFOV = function getFOV () {
  return store.camera.fov;
};

views.public.getSceneFOV = function getSceneFOV () {
	return store.sceneFOV;
};

views.public.setSceneFOV = function setSceneFOV (value) {
	if (isNaN(value)) return;
	store.camera.fov = value;
	store.camera.updateProjectionMatrix();
	store.sceneFOV = value;
	store.requestRender();
};

views.public.resetFOV = function resetFOV () {
	store.camera.fov = store.sceneFOV;
	store.camera.updateProjectionMatrix();
	store.requestRender();
};

views.public.getControlsInvertedStatus = function getControlsInvertedStatus () {
  return store.controls.rotateSpeed < 0;
};

views.public.invertControls = function invertControls () {
  store.controls.rotateSpeed = store.controls.rotateSpeed * -1;
  store.controls.panSpeed = store.controls.panSpeed * -1;
};

views.getViewInfo = function getViewInfo (id) {
  // Returns a copy of the view (not modifiable)
  if (viewsByID[id]) {
    return JSON.parse(JSON.stringify(viewsByID[id]));
  }
};

views.public.cancelMovement = function cancelMovement () {
  if (activeCameraTween) {
    activeCameraTween.stop();
    activeCameraTween = null;
    inProgressViewID = null;
    store.controls.enabled = true;
  }
};

views.public.getCurrentView = function getCurrentView () {
  // TODO
  const tempID = findNewID();
  const newView = {
    id: tempID,
    name: 'New View',
    position: {
      x: (store.controls.target.x.toFixed(2)) / 1,
      y: (store.controls.target.y.toFixed(2)) / 1,
      z: (store.controls.target.z.toFixed(2)) / 1
    },
    phi: (controls.getPolarAngle().toFixed(2)) / 1,
    theta: (controls.getAzimuthalAngle().toFixed(2)) / 1,
    distance: (store.controls.target.distanceTo(store.controls.object.position).toFixed(2)) / 1,
    visibleMenu: false
  };

  // HACK loadView should accept JSON objects in the format that sceneJSON takes. It current doesn't so this is a converter
  // SEE NOTES AT TOP OF FILE
  newView.x = newView.position.x;
  newView.y = newView.position.y;
  newView.z = newView.position.z;
  return newView;
};

views.public.getCameraYAngle = function getCameraYAngle () {
  return store.controls.getAzimuthalAngle() * (180 / Math.PI) + 360;
};

views.public.showTopNorth = function showTopNorth () {
  if (store.followerMode) {
    return;
  }
  animateCamera({ phi: 0, theta: 0 }, 1000);
};

views.public.showStartView = function showStartView () {
  store.camera.position.copy(initialCameraPosition);
  store.controls.target.copy(initialTargetPosition);
  store.requestRender();
};

views.public.createView = function createView (viewName) {
  const newView = views.public.getCurrentView();
  if (viewName !== undefined) {
    newView.name = viewName;
  }
  views.loadView(newView);
  return newView.id;
};

views.clone = function clone (targetID) {
  const toClone = viewsByID[targetID];
  if (!toClone) {
    console.log('Unable to clone View. Invalid ID');
    return;
  }
  const id = findNewID();
  viewsByID[id] = JSON.parse(JSON.stringify(toClone)); // Safe to clone as it doesn't have any THREE.js Properties
  viewsByID[id].id = id;
  return id;
};

views.setFlightDuration = function setFlightDuration (id) {
  if (!viewsByID[id]) {
    return;
  }
  const camera = viewsByID[id];

  let time = prompt('Flight Time (Seconds):', camera.duration / 1000);
  time = parseFloat(time) * 1000;
  if (isNaN(time)) {
    notifications.add({ content: 'Camera Flight Time not Updated!' });
    return;
  }
  notifications.add({ content: 'Updated Camera Flight Time' });
  camera.duration = time;
};

views.public.zoomToPoint = (vec3, isBroadcastCommand) => {
  if (store.followerMode && !isBroadcastCommand) {
    return;
  }

  if (store.broadcastMode) {
    store.sync.private.broadcast({ action: 'CAMERA_ZOOM_TO_POINT', payload: vec3 });
  }
  animateCamera({ x: vec3.x, y: vec3.y, z: vec3.z, radius: 10 }, 1500);
};

views.public.zoomToObject = function zoomToObject (uuid) {
  if (store.followerMode) {
    return;
  }

  const frame = models.getBoundingFrame(uuid);
  const radius = (frame.distance * 0.9) * store.globalscale;

  const newPos = { x: frame.center.x, y: frame.center.y, z: frame.center.z, radius: radius };
  const anim = animateCamera(newPos, 1000);

  if (store.broadcastMode) {
    const sUpdate = {
      x: frame.center.x,
      y: frame.center.y,
      z: frame.center.z,
      phi: store.controls.getPolarAngle(),
      theta: store.controls.getAzimuthalAngle(),
      radius: radius,
      duration: 1000
    };

    store.sync.private.broadcast({ action: 'CAMERA_MOVE', payload: sUpdate });
  }
};

views.public.getScenestates = function getScenestates () {
  const usedScenestates = [];
  viewsByID.map((view, index) => {
    if (typeof view.scenestate !== 'undefined') {
      usedScenestates[view.scenestate] = true;
    }
  });
  return usedScenestates;
};

function findUnused () {
  // FIXME Same Problem referncing global inventum object
  const activeAnimationCameras = window.Inventum.animations.getCameras();
  const unusedCameras = [];
  let cameraUsed = false;
  viewsByID.map((view, index) => {
    // If view isn't used in the menu then check if it's being used in an animation
    if (activeAnimationCameras[index]) {
      cameraUsed = true;
    }

    // Temp fix to delete old unintentional cameras;
    if (view.visibleMenu && view.name !== 'Untitled View') {
      cameraUsed = true;
    }

    if (!cameraUsed) {
      unusedCameras[index] = view;
    }
    cameraUsed = false;
  });
  return unusedCameras;
}

views.generateJSON = function generateJSON () {
  const unusedViews = findUnused();
  const usedViews = [];
  viewsByID.map((view, index) => {
    if (unusedViews[index] === undefined) {
      const tView = Object.assign({}, view);
      tView.position = {};
      tView.position.x = tView.x;
      tView.position.y = tView.y;
      tView.position.z = tView.z;
      delete tView.x;
      delete tView.y;
      delete tView.z;
      usedViews.push(tView);
    }
  });
  return { views: usedViews, start: { position: initialCameraPosition, target: initialTargetPosition } };
};

views.public.setOrthographic = function setOrthographic () {
  store.camera = store.orthographicCamera;
  store.controls.replaceCamera(store.orthographicCamera);
  store.requestRender();
};

views.public.setPerspective = function setPerspective () {
  store.camera = store.perspectiveCamera;
  store.controls.replaceCamera(store.perspectiveCamera);
  store.requestRender();
};

views.modifyScale = function modifyScale (newScale) {
  // View is not a THREE Vec3 so we have to manually scale it
  const oldScale = store.globalscale;
  viewsByID.map((view) => {
    if (view !== undefined) {
      // Create a temp vec3 to do the vector math
      const tVec = new THREE.Vector3(view.x, view.y, view.z);
      tVec.sub(store.inventumTransform);
      tVec.divideScalar(oldScale);
      tVec.multiplyScalar(newScale);
      tVec.add(store.inventumTransform);
      view.x = tVec.x;
      view.y = tVec.y;
      view.z = tVec.z;

      view.distance /= oldScale;
      view.distance *= newScale;
    }
  });

  // Create a temp vec3 to do the vector math
  const tCamPosVec = new THREE.Vector3(initialCameraPosition.x, initialCameraPosition.y, initialCameraPosition.z);
  tCamPosVec.sub(store.inventumTransform);
  tCamPosVec.divideScalar(oldScale);
  tCamPosVec.multiplyScalar(newScale);
  tCamPosVec.add(store.inventumTransform);

  initialCameraPosition.x = tCamPosVec.x;
  initialCameraPosition.y = tCamPosVec.y;
  initialCameraPosition.z = tCamPosVec.z;

  // Create a temp vec3 to do the vector math
  const tCamTargetVec = new THREE.Vector3(initialTargetPosition.x, initialTargetPosition.y, initialTargetPosition.z);
  tCamTargetVec.sub(store.inventumTransform);
  tCamTargetVec.divideScalar(oldScale);
  tCamTargetVec.multiplyScalar(newScale);
  tCamTargetVec.add(store.inventumTransform);

  initialTargetPosition.x = tCamTargetVec.x;
  initialTargetPosition.y = tCamTargetVec.y;
  initialTargetPosition.z = tCamTargetVec.z;
};

views.modifyTransform = function modifyTransform (deltaTransform) {
  // deltaTransform is the difference between the oldTransform the the newTransform. Supplied by the settings.js file
  // We have to use a delta because the old transform is "baked in" to the numbers
  viewsByID.map((view) => {
    if (view !== undefined) {
      view.x += deltaTransform.x;
      view.y += deltaTransform.y;
      view.z += deltaTransform.z;
    }
  });

  initialCameraPosition.x += deltaTransform.x;
  initialCameraPosition.y += deltaTransform.y;
  initialCameraPosition.z += deltaTransform.z;

  initialTargetPosition.x += deltaTransform.x;
  initialTargetPosition.y += deltaTransform.y;
  initialTargetPosition.z += deltaTransform.z;
};

views.reset = function reset () {
  viewsByID = [];
  initialCameraPosition = {};
  initialTargetPosition = {};
};

views.public.getCameraPositionWorld = function getCameraPositionWorld () {
  return { x: Number(store.camera.position.x.toFixed(2)), y: Number(store.camera.position.y.toFixed(2)), z: Number(store.camera.position.z.toFixed(2)) };
};

// module.exports = views;

export { views };
