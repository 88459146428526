import React from 'react';
import {withRouter, matchPath} from 'react-router-dom';
import {connect} from 'react-redux';
import {openModal, closeModal} from '../../../Redux/actions/ui.js';
import axios from '../../../axios-config.js';
import DatePicker from 'react-datepicker';

function MeetingDateInfo(props) {
  return (
    <div style={{fontSize:"20px", width:"100%"}}>
      <div>Start Time: <span style={{color:"#25b9a4"}}>{props.startTime.toDateString()} {props.startTime.toLocaleTimeString()}</span></div>
      <div style={{fontSize:"15px"}}>You can start it early if you want.</div>
    </div>
  )
}

class MeetingCreatorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime:null,
      password:"",
      success:false,
      meetingCode:"",
      meetingURL:""
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    let params = {projectID:this.props.projectID, projectType:this.props.pageType == "INVENTUM_REVIEW" ? "REVIEW" : "PUBLIC"}

    if (this.state.password.length > 0) {
      params['password'] = this.state.password
    }

    if (this.state.startTime) {
      params['startTime'] = this.state.startTime;
    }

    axios.post(`https://api.inventum3d.com/meeting`, params)
    .then(response => {
      let meetingCode = response.data.data.code;
      let meetingURL = `https://inventum3D.com/meet/${meetingCode}/`;
      this.setState({meetingURL, meetingCode, success:true});
    })
  }

  render () {
    const handleCopy = () => {
      navigator.clipboard.writeText(this.state.meetingURL)
      .then(() => {
        Inventum.notifications.addNotification({content:"Copied URL to clipboard",displayTime:5000})
      })
      .catch((e) => {
        alert(this.state.meetingURL);
      })
    }

    const handleGo = () => {
      window.location.href = this.state.meetingURL;
      props.closeModal();
    }

    if (this.state.success) {
      return (
        <div style={{width:"400px"}} className="passwordForm">
          <div style={{fontSize:"25px"}}>Created Meeting</div>
          <div style={{padding:"5px",fontSize:"20px",width:"100%"}}>Code: <span style={{userSelect:"all",color:"#25b9a4"}}>{this.state.meetingCode.match(/\d{3}(?=\d{2,3})|\d+/g).join("-")}</span></div>
          {this.state.startTime ? <MeetingDateInfo startTime={this.state.startTime} /> : null}
          {this.state.password.length > 0 ? <div style={{width:"100%", fontSize:"20px",padding:"5px"}}>Password: <span style={{userSelect:"all",color:"#25b9a4"}}>{this.state.password}</span></div> : null}
          <div style={{display:"flex", width:"100%", justifyContent:"space-between"}}>
            <div className="WorldEditorButton" onClick={handleCopy}>Copy URL to clipboard</div>
            <div className="WorldEditorButton" onClick={handleGo}>Go to meeting now</div>
            <div className="WorldEditorButton Disabled" onClick={this.props.closeModal}>Close</div>
          </div>
        </div>
      )
    }

    return (
      <div style={{width:"400px"}} className="passwordForm">
        <div className="passwordFormTitle">Create Meeting</div>
        <div style={{textAlign:"center", padding:"5px"}}>Meetings are a way to interactively share an Inventum3D session with an audience.</div>
        <div style={{textAlign:"center", padding:"5px"}}>After creating a meeting you will be given a code to use to join. Share this code with anyone you want to join.</div>
        <input className="MeetingCreatorPasswordInput" onChange={(e)=>{this.setState({password:e.target.value})}} placeholder="Meeting Password (Optional)" type="text"/>
        <DatePicker
        onChange={(val) =>{console.log(val); this.setState({startTime:val})}}
        selected={this.state.startTime}
        showMonthDropdown
        showTimeSelect
        isClearable
        placeholderText={"Meeting Start Time (Optional)"}
        dateFormat="MMMM d, yyyy h:mm aa"
        className="MeetingCreatorTimeInput"
        />
        <div style={{width:"80%",display:"flex", justifyContent:"space-evenly"}}>
          <div className="passwordFormSubmit" onClick={this.handleSubmit}>Create Meeting</div>
          <div className="passwordFormSubmit" onClick={this.props.closeModal}>Cancel</div>
        </div>
      </div>
    )
  }
}

let mapStateToProps = (state) => ({
  projectID:state.activePage.projectID,
  pageType:state.activePage.type
})


let mapDispatchToProps = dispatch => ({
  closeModal:() => {
    dispatch(closeModal());
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeetingCreatorForm));
