import React from 'react';
import axios from '../../../../axios-config.js';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

const BooleanInput = (props) => {
  return (
    <div className="CreateCompanyInput">
      {props.title}<input type="checkbox" value={props.value} onChange={(e)=>{props.onChange(e.target.checked)}} placeholder={props.placeholder} />
    </div>
  )
}
const InputField = (props) => {
  return (
    <div className="CreateCompanyInput">
      <div>{props.title}</div>
      <input value={props.value} onChange={(e)=>{props.onChange(e.target.value)}} placeholder={props.placeholder} />
    </div>
  )
}

const Submit = (props) => {
  return (
    <div className="CreateCompanySubmit" onClick={props.handleSubmit}>Submit</div>
  )
}


class UserCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName:"",
      lastName:"",
      email:"",
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    if (this.state.firstName.length == 0 || this.state.lastName.length == 0 || this.state.email.length == 0) {
      alert(`Fields can't be blank!`);
      return;
    }

    let data = {
      firstName:this.state.firstName,
      lastName:this.state.lastName,
      email:this.state.email
    }

    axios.post(`https://api.inventum3D.com/register/`,data)
    .then((response) => {
      alert('Created user Succesfully');
    }).catch((error) => {
      console.log(error.response);
    })
  }

  render() {
    return (
      <div>
        <div style={{"textAlign":"center"}}>Create User</div>
        <div className="CreateProjectForm">
          <InputField title={"First Name"} placeholder={"John"} value={this.state.firstName} onChange={(firstName) => {this.setState({firstName})}} />
          <InputField title={"Last Name"} placeholder={"Doe"} value={this.state.lastName} onChange={(lastName) => {this.setState({lastName})}} />
          <InputField title={"Email"} placeholder={"jdoe@example.com"} value={this.state.email} onChange={(email) => {this.setState({email})}} />
          <Submit handleSubmit={this.handleSubmit} />
        </div>
      </div>
    )
  }
}

export default UserCreator
