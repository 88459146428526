export const SET_COMPANY_ID = 'SET_COMPANY_ID';
export const SET_PROJECT_ID = 'SET_PROJECT_ID';
export const SET_TYPE = 'SET_TYPE';

export function setActiveCompanyID(data) {
  return { type:SET_COMPANY_ID, data}
}

export function setActiveProjectID(data) {
  return { type:SET_PROJECT_ID, data}
}

export function setActivePageType(data) {
  return { type:SET_TYPE, data }
}
