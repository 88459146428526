export const LOAD_COMPANY_MAP = 'LOAD_COMPANY_MAP';
export const CLEAR_COMPANY_MAP = 'CLEAR_COMPANY_MAP';

export function clearCompanyMap() {
  return {type:CLEAR_COMPANY_MAP}
}

export function loadCompanyMap(data) {
  return {type:LOAD_COMPANY_MAP,data}
}
