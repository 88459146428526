import React from 'react';
import axios from '../../../axios-config.js';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {closeModal} from '../../../Redux/actions/ui.js';
import {loadProjects} from '../../../Redux/actions/projects.js';
import {setFilter, removeFilter, removeAllFilters, setFilteredResults, clearFilteredResults} from '../../../Redux/actions/projectsFilters.js';
import {setNextBounds} from '../../../Redux/actions/mapData.js';

import CompanyEditor from './editor/CompanyEditor.js';
import ProjectForm from './editor/ProjectForm.js';
import UserEditor from './editor/UserEditor.js';

import CompanyCreator from './editor/CompanyCreator.js';
import UserCreator from './editor/UserCreator.js';


const ProjectEntry = (props) => {
  let urlProject = "";
  let project = props.project;
  let urlCompany = props.urlCompany
  if (!urlCompany) {
    urlCompany = project.company_slug !== null ? "/c/" + project.company_slug : "/g/" + project.company_id; //If urlCompany isn't provided generate it.
  }
  if (project.legacy_url) {
    urlProject = project.legacy_url;
  }else {
    urlProject = urlCompany + "/" + project.project_slug;
  }

  const handleLocationClick = () => {
    props.setNextBounds([[project.latitude - 3,project.longitude - 3],[project.latitude + 3,project.longitude + 3]]);
    props.closeModal();
  }

  return (
    <div className="ProjectEntryHeader">
    <div title="Center map on project" onClick={handleLocationClick}><i className="material-icons projectLocationButton">my_location</i></div>
    {project.legacy_url ? <a className="ProjectEntryLink legacy" title="This project uses an old version of Inventum3D" href={urlProject}>{project.project_name + " - legacy (?)"}</a> :<Link title="Load 3D View" onClick={props.closeModal} className="ProjectEntryLink" to={urlProject}>{project.project_name}</Link>}
    {props.siteAdmin ? <TogglEdit className={"toggleEditButton project"} id={project.project_id} name={"EDIT_PROJECT"} setEditing={props.setEditing} /> : null}
    </div>
  )
}


const TogglEdit = (props) => {
  let handleClick = () => {
    props.setEditing(props.name,props.id)
  }
  let className = "toggleEditButton"
  if (props.className !== undefined) {
    className = props.className;
  }
  return (
    <div className={className} onClick={handleClick}>Edit</div>
  )
}

const CompanyEntry = (props) => {
  let data = props.data;

  let projects = [...data.projects];
  if (props.filtersActive) {
    // Check logic here if we should render anything. (e.g if a company has any valid projects)
    // Check filter status here
    projects = projects.filter(project => props.filteredProjects.includes(project.project_id));
    if (projects.length == 0) {
      //XXX Name Filter check here
      // If name filter is applied then check to see if company name does not match then return null
      // If company name matches name filter then continue and render an empty company container
      // If we are filtering then remove empty companies.
      if (props.filters.hasOwnProperty('NAME')) {
        if (!data.name.toLowerCase().includes(props.filters.NAME.value)) {
          return null
        }
      }else {
        return null
      }
    }
  }

  let urlCompany = data.slug !== null ? "/c/" + data.slug : "/g/" + data.id;
  //props.siteAdmin isn't actually the admin it's a boolean that's decided if the user is an admin AND the staff level is 1. Provided by the higher component
  return (
    <div className="CompanyEntryContainer">
      <div className="CompanyEntryHeader">
        <Link title="View Company Map" onClick={props.closeModal} className="CompanyHeaderLink" to={urlCompany}>{data.name}</Link>
        {props.siteAdmin ? <TogglEdit id={data.id} name={"EDIT_COMPANY"} setEditing={props.setEditing} /> : null}
      </div>
      {projects.map((project,index) => <ProjectEntry setNextBounds={props.setNextBounds} siteAdmin={props.siteAdmin} setEditing={props.setEditing} key={index} urlCompany={urlCompany} project={project} closeModal={props.closeModal} />)}
    </div>
  )
}

/*const ResultsContainer = (props) => {
  return props.companies.map((company,index) => <CompanyEntry setEditing={props.setEditing} key={index} data={company} closeModal={props.closeModal} />
  )
}*/


class ProjectDirectory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies:[],//companies = [{name:"Company Name",projects:[]}]
      searchString:"",
      filteredCompanies:[], // REMOVE
      filters:{}, //NEW
      editingMode:"",
      editingValue:null
    }
    this.handleSearch = this.handleSearch.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.setEditing = this.setEditing.bind(this);
    this.getData = this.getData.bind(this);
    this.reloadMapMarkers = this.reloadMapMarkers.bind(this);
  }

  //NOTE: REACT UPDATE

  componentDidMount() {
    this.getData();
    if (this.props.activeFilters.hasOwnProperty('NAME')) {
      this.setState({searchString:this.props.activeFilters.NAME.value});
    }
    this.searchInput.focus();
    this.searchInput.select();
  }

  componentDidUpdate(prevProps, prevState) {
    //If just exited editingMode then update the companies;.
    if (prevState.editingMode !== "" && this.state.editingMode === "") {
      this.getData();
      this.reloadMapMarkers();
    }else if (!prevProps.siteAdmin && this.props.siteAdmin) {
      this.getData();
      this.reloadMapMarkers();
    }

    //XXX Check Filters
    if (prevProps.activeFilters !== this.props.activeFilters) {
      this.applyFilters();
    }
  }

  getData() {
    axios.get('https://api.inventum3D.com/company')
    .then((response) => {
      response.data.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
      })
      this.setState({companies:response.data},()=>{
        //FIXME ONCE API WORK IS DONE
        //This is a bit of a hack. It'd be better to specially request an update from api/company/#COMPANYID/
        //But the getOne company API endpoint needs adjusting to support companies with no projects
        //So what we currently do is when we do an update to a company we request the updated state for ALL compaies which does report correctly
        //Then we need to find which company we just updated and set editingValue = that company but the NEW state
        if (this.state.editingValue !== null) {
          if (this.state.editingValue.projects) {
            //editingValue is a company so we should update it to propagate the changes down to the company editor
            this.state.companies.map((company)=> {
              if (this.state.editingValue.id === company.id) {
                this.setState({editingValue:company});
              }
            })
          }
        }
      });
    })
  }

  reloadMapMarkers() {
    axios.get('https://api.inventum3d.com/project')
    .then((result) => {
      let data = result.data.data;
      let cleanedData = {};
      data.map((project) => {
        cleanedData[project.project_id] = project;
      })
      this.props.loadProjects(cleanedData);
    })
  }

  setEditing(mode,id) {
    let value = null;

    switch(mode) {
      case "EDIT_COMPANY":
        this.state.companies.map((company) => {
          if (company.id === id) {
            value = company;
          }
        })
        break;
      case "EDIT_PROJECT":
        this.state.companies.map((company) => {
          company.projects.map((project) => {
            if (project.project_id === id) {
              value = project;
            }
          })
        })
        break;
      case "EDIT_USER":
        break
      case "CREATE_COMPANY":
        break;
      case "CREATE_PROJECT":
        //Copies the current value so it isn't overwritten
        value = this.state.editingValue;
        break;
      case "CREATE_USER":
        break;
    }

    if (mode !== this.state.editingMode || value !== null) {
      this.setState({editingMode:mode,editingValue:value})
    }
  }

  handleSearch(e) {
    let query = e.target.value.toLowerCase();
    let matches = [];
    if (query.length == 0) {
      this.props.removeFilter({type:"NAME"});
    }else {
      this.props.setFilter({type:"NAME", value:query});
    }
    this.setState({searchString:e.target.value});
    return;

    //First find all the companies who's name is returned in the query
    matches = this.state.companies.filter((company) => {
      let match = company.name.toLowerCase().includes(query);
      if (!match) {
        company.projects.map((project) => {
          if (project.project_name.toLowerCase().includes(query)) {
            match = true;
          }
        })
      }
      return match;
    })

    //Sort the result by the company Name
    matches.sort((a,b) => {
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    })
    this.setState({searchString:e.target.value,filteredCompanies:matches});
  }

  applyFilters(filter) {
    let results = [];
    let tempFilters = this.props.activeFilters;

    this.state.companies.map(company => {
      company.projects.map(project => {
        let projectValid = true; // Keeps track of whether the project is allowed by the filter rules

        if (tempFilters.hasOwnProperty('NAME')) {
          tempFilters.NAME.value = tempFilters.NAME.value.toLowerCase();
          projectValid = projectValid && (project.project_name.toLowerCase().includes(tempFilters.NAME.value) || company.name.toLowerCase().includes(tempFilters.NAME.value));
        }

        if (tempFilters.hasOwnProperty('PUBLIC')) {
          projectValid = projectValid && project.public == tempFilters.PUBLIC.value;
        }

        /* Example placeholder for commodity
        if (tempFilters.hasOwnProperty('COMMODITY')) {
          projectValid = projectValid && project.commodity == tempFilters.COMMODITY.value;
        }*/

        if (projectValid) {
          results.push(project.project_id)
        }
      })
    })
    this.props.setFilteredResults(results);
  }


  render() {
    let state = this.state;
    let companies = this.state.companies;

    /*if (state.searchString.length > 0) {
      companies = state.filteredCompanies
    }*/

    //EDIT MODE FORMS
    if (state.editingMode.length !== 0) {
      return (
        <div className="ProjectDirectoryContainer">
          <CloseButton handleClick={this.props.closeModal} />
          <div className="BackButtonDirectory" onClick={()=>{this.setEditing("")}}>Back</div>
          {state.editingMode === "EDIT_COMPANY" ? <CompanyEditor updateData={this.getData} handleCreateProject={()=>{this.setEditing("CREATE_PROJECT")}} handleEditProject={(projectID)=>{this.setEditing("EDIT_PROJECT",projectID)}} handleCreateUser={()=>{this.setEditing("CREATE_USER")}} company={state.editingValue} /> : null}
          {state.editingMode === "EDIT_PROJECT" ? <ProjectForm back={()=>{this.setEditing("")}} project={state.editingValue}/> : null}
          {state.editingMode === "CREATE_COMPANY" ? <CompanyCreator back={()=>{this.setEditing("")}}/> : null}
          {state.editingMode === "CREATE_USER" ? <UserCreator/> : null}
          {state.editingMode === "CREATE_PROJECT" ? <ProjectForm back={()=>{this.setEditing("")}} company={state.editingValue}/> : null}
        </div>
      )
    }

    let showEditButton = false;
		let showExportCSV = false;

    if (this.props.siteAdmin) {
      showEditButton = true;
    }

    let filtersActive = Object.keys(this.props.activeFilters).length > 0;

    //SEARCH FORM
    return (
      <div className="ProjectDirectoryContainer">
        <CloseButton handleClick={this.props.closeModal} />
        <div style={{"fontSize":"30px","textAlign":"center"}}>{this.props.siteAdmin ? "Project Directory (Admin)" : "Project Directory"}</div>
        <input ref={(input)=>{this.searchInput=input}} className="ProjectSearchInput" type="text" placeholder="Search" value={state.searchString} onChange={this.handleSearch}/>
        {showEditButton ? <FilterShortcuts siteAdmin={this.props.siteAdmin} staffLevel={this.props.siteStaffLevel} filters={this.props.activeFilters} removeFilter={this.props.removeFilter} setFilter={this.props.setFilter} /> : null}
				<div className="ScrollableBox">
          {this.state.companies.map((company,index) => <CompanyEntry filteredProjects={this.props.filteredProjects} filters={this.props.activeFilters} filtersActive={filtersActive} setNextBounds={this.props.setNextBounds} siteAdmin={showEditButton} setEditing={this.setEditing} key={index} data={company} closeModal={this.props.closeModal} />)}
          {(this.state.companies.length === 0 || (filtersActive && this.props.filteredProjects.length == 0)) ? <div className="NoResultsFound">No Results Found</div>: null}
        </div>
        {showEditButton ? <div onClick={()=>{this.setEditing("CREATE_COMPANY")}} className="CreateCompanyButton">Create Company</div> : null}
      </div>
    )
  }
}


let FilterShortcuts = (props) => {
  let privateActive = false;
  let publicActive = false;
  if (props.filters.hasOwnProperty('PUBLIC')) {
    props.filters.PUBLIC.value === true ? publicActive = true : privateActive = true;
  }

  let toggleFilter = (value) => {
    if (props.filters.hasOwnProperty('PUBLIC')) {
      if (props.filters.PUBLIC.value === value) {
        //Turn off
        props.removeFilter({type:"PUBLIC"});
      }else {
        props.setFilter({type:"PUBLIC", value})
      }
    }else {
      props.setFilter({type:"PUBLIC", value})
    }
  }

	const handleProjectsExport = () => {
		axios.get('https://api.inventum3d.com/admin/export-projects-csv')
		.then((response) => {
			const td = new Date();
			const filename = `projects_${td.getFullYear()}-${td.getMonth() + 1}-${td.getDate()}_${td.getHours()}-${td.getMinutes()}.csv`;
			let blob = new Blob([response.data], { type: 'text/csv' })
			let link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = filename;
			link.click();
		})
	}

	const handleUsersExport = () => {
		axios.get('https://api.inventum3d.com/admin/export-users-csv')
		.then((response) => {
			console.log('blah..');
			const td = new Date();
			const filename = `users_${td.getFullYear()}-${td.getMonth() + 1}-${td.getDate()}_${td.getHours()}-${td.getMinutes()}.csv`;
			let blob = new Blob([response.data], { type: 'text/csv' })
			let link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = filename;
			link.click();
		})
	}

  return (
    <div className="filterControlsContainer">
      <div className={"filterButton" + (privateActive ? " on" : "")} onClick={()=>{toggleFilter(false)}}>Filter Private</div>
      <div className={"filterButton" + (publicActive ? " on" : "")} onClick={()=>{toggleFilter(true)}}>Filter Public</div>
			<div className='filterButton' onClick={handleProjectsExport}>Export Projects</div>
			<div className='filterButton' onClick={handleUsersExport}>Export Users</div>
    </div>
  )
}

let CloseButton = (props) => {
  return (
    <div style={{"position":"relative"}}>
      <div onClick={props.handleClick} style={{"position":"absolute","right":"0px"}}><i className="material-icons notranslate">close</i></div>
    </div>
  )
}

let mapDispatchToProps = dispatch => ({
  closeModal:() => {
    dispatch(closeModal());
  },
  loadProjects:(projects) => {
    dispatch(loadProjects(projects));
  },
  setNextBounds:(bounds) => {
    dispatch(setNextBounds(bounds));
  },
  setFilter:(filter) => {
    dispatch(setFilter(filter))
  },
  removeFilter:(filter) => {
    dispatch(removeFilter(filter))
  },
  removeAllFilters:() => {
    dispatch(removeAllFilters())
  },
  setFilteredResults:(results) => {
    dispatch(setFilteredResults(results))
  },
  clearFilteredResults:() => {
    dispatch(clearFilteredResults())
  }
})

let mapStateToProps = (state) => ({
  siteAdmin:(state.user.siteAdmin) ? true : false,
  siteStaffLevel:state.user.siteStaffLevel,
  activeFilters:state.projectsFilters.active,
  filteredProjects:state.projectsFilters.results
})

const ProjectDirectoryContainer = connect(mapStateToProps,mapDispatchToProps)(ProjectDirectory)

export default ProjectDirectoryContainer
