import * as THREE from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { store } from '../core/store.js';
import { markers } from './screenMarker.js';
import { LabelMesh } from './labelMesh.js';
import { LabelCanvasTexture } from './LabelCanvasTexture.js';

const LABEL_SCALE = 200; // Magic Number. Investigate this.

class WorldLabel {
  constructor (options) {
    this.id = options.id; // Label ID
    this.type = options.type; // Label Type (used for 3D or 2D)
    this.content = options.content; // Label Text Array
    this.renderLimits = options.renderLimits; // Array with the labels renderlimits
		this.visibleStart = false;

		if (Object.prototype.hasOwnProperty.call(options, 'visibleStart')) {
			this.visibleStart = options.visibleStart;
		}

    const anchorPosition = options.anchorPosition;

    const rotation = options.rotation;

    const style = options.content.style;

    let backboardOffset = { x: 0, y: 0, z: 0 };
    if (Object.prototype.hasOwnProperty.call(options, 'offset')) {
      backboardOffset = { x: options.offset.x, y: options.offset.y, z: options.offset.z };
    } else if (Object.prototype.hasOwnProperty.call(options, 'offsetX')) {
      backboardOffset = { x: options.offsetX, y: options.offsetY, z: 0 };
    }

    this.settings = {
      depthTest: true,
      showMarker: true,
      autoRotate: false,
      autoScale: false,
      maxScale: 10,
      minScale: 1,
      baseScale: 0.0001,
      markerHoverSize: 15,
      markerInactiveSize: 10,
      markerInactiveBackground: 'rgba(255,255,255,0.7)',
      markerHoverBackground: 'rgba(255,255,255,0.9)',
      markerOpacity: 1.0
    };

    // Support for legacy label JSON where the keys were stored straight under the label instead of .settings
    for (const key in this.settings) {
      if (!Object.prototype.hasOwnProperty.call(this.settings, key)) continue;
      if (Object.prototype.hasOwnProperty.call(options, key)) {
        this.settings[key] = options[key];
      }
    }

    // Support for new settings property
    if (Object.prototype.hasOwnProperty.call(options, 'settings')) {
      for (const key in this.settings) {
        if (!Object.prototype.hasOwnProperty.call(this.settings, key)) continue;
        if (Object.prototype.hasOwnProperty.call(options.settings, key)) {
          this.settings[key] = options.settings[key];
        }
      }
    }

    if (typeof (options.scale) === 'number') {
      this.settings.baseScale = options.scale / 10000;
    }

    this.userHidden = true;

    // Builds a new HTML Canvas Manager.
    this.canvasManager = new LabelCanvasTexture(this.content.text, style);
    this.backboard = this.canvasManager; // This is to keep consistency with the 2D label when used by the labels.js class. Should probally be renamed

    // The ThreeJS Texture Map which it creates from the HTML Canvas
    this.canvasTexture = new THREE.Texture(this.canvasManager.getCanvas());
    this.canvasTexture.needsUpdate = true;
    this.canvasTexture.minFilter = THREE.LinearFilter;

    var material = new THREE.MeshBasicMaterial({ map: this.canvasTexture, transparent: true, side: THREE.DoubleSide });
    material.depthTest = this.settings.depthTest;

    var geometry = new THREE.PlaneGeometry(1, 1);
    var plane = new THREE.Mesh(geometry, material);

    this.meshManager = new LabelMesh({ backgroundColor: style.backgroundColor, depthTest: this.settings.depthTest });

    const labelMesh = this.meshManager.getMesh();

		labelMesh.visible = false; // Important. labelMesh.visible needs to be false until the backboard loads or the backboard won't appear. Weird bug!?
		// The Label will load in the canvas.render callback at the bottom of this constructor


		labelMesh.position.set(anchorPosition.x, anchorPosition.y, anchorPosition.z);
    plane.position.copy(backboardOffset);
    labelMesh.add(plane);
    this.meshManager.updatePointer();

    // Apply Rotation
    if (Array.isArray(rotation)) {
      labelMesh.quaternion.fromArray(rotation);
    } else if (rotation !== undefined) {
      // legacy
      if (!isNaN(rotation.x)) {
        labelMesh.rotation.x = rotation.x * Math.PI / 180;
      }

      if (!isNaN(rotation.y)) {
        labelMesh.rotation.y = rotation.y * Math.PI / 180;
      }

      if (!isNaN(rotation.z)) {
        labelMesh.rotation.z = rotation.z * Math.PI / 180;
      }
    }

    // Apply Scale
    let scale = 1;
    if (options.scale !== undefined) {
      scale = options.scale;
    }

    labelMesh.scale.setScalar(scale);

    // Add Mesh to Scene
    store.scene.add(labelMesh);

    let markerOpacity = 1.0;

    // If we don't want to see the marker set the opacity to 0.0;
    if (!this.settings.showMarker) {
      markerOpacity = 0.0;
    }

    const markerOptions = {
      hoverSize: this.settings.markerHoverSize,
      inactiveSize: this.settings.markerInactiveSize,
      opacity: markerOpacity,
      inactiveBackground: this.settings.markerInactiveBackground,
      hoverBackground: this.settings.markerHoverBackground
    };

    this.marker = markers.create(markerOptions);

    this.marker.setWorldPosition(anchorPosition);
    options.renderLimits.map((limit) => {
      this.marker.addRenderLimit(limit);
    });
    // If showing the marker then add the callbacks else don't
    if (this.settings.showMarker) {
      this.handleClick = this.handleClick.bind(this);
      this.marker.addClickCallback(this.handleClick);
    }

    // Check if this is a problem and should be moved to inside above
    this.visibilityChange = this.visibilityChange.bind(this);
    this.marker.addVisibilityCallback(this.visibilityChange);

		// Render returns a promise due to async loading of background images.
		// Need to set the billboard scale once we know the height of the image as that updates the canvas background size
		this.canvasManager.render()
			.then(() => {
			// Setting Width Height of Plane
				const width = this.canvasManager.getCanvas().width / LABEL_SCALE;
				const height = this.canvasManager.getCanvas().height / LABEL_SCALE;
				plane.scale.set(width, height, 1);
				plane.material.needsUpdate = true;
				if (this.meshManager) {
					this.meshManager.updatePointer();
				}
				// Once the canvas has loaded it's safe to set it to visible.
				// If done earlier it may cause a bug where the backboard won't load.
				if (this.visibleStart) {
					this.setVisible(true);
				}

			});

  }

  handleClick () {
    const labelMesh = this.meshManager.getMesh();
    if (this.userHidden) {
      labelMesh.visible = true;
      this.userHidden = false;
    } else {
      labelMesh.visible = false;
      this.userHidden = true;
    }
  }

  handleRotate () {
    if (this.settings.autoRotate) {
      const labelMesh = this.meshManager.getMesh();
      labelMesh.lookAt(store.camera.position.x, labelMesh.position.y, store.camera.position.z); // Rotate around Y only
      // this.label.lookAt(store.camera.position);//Rotate all
      this.handleScale();
    }
  }

  handleScale () {
    if (this.settings.autoScale) {
      const labelMesh = this.meshManager.getMesh();
      var distance = labelMesh.position.distanceTo(store.camera.position);
      var size = distance * this.settings.baseScale * store.camera.fov;
      var tempScale = new THREE.Vector3(1, 1, 1);
      tempScale.multiplyScalar(size);
      if (tempScale.x > this.settings.maxScale) {
        tempScale.set(this.settings.maxScale, this.settings.maxScale, this.settings.maxScale);
      } else if (tempScale.x < this.settings.minScale) {
        tempScale.set(this.settings.minScale, this.settings.minScale, this.settings.minScale);
      }
      labelMesh.scale.copy(tempScale);
    }
  }

  setVisible (isVisible) {
    const labelMesh = this.meshManager.getMesh();
    this.userHidden = !isVisible;
    labelMesh.visible = isVisible;
  }

	isVisible () {
		const labelMesh = this.meshManager.getMesh();
		return labelMesh.visible;
	}

  visibilityChange (isVisible) {
    const labelMesh = this.meshManager.getMesh();
    if (!isVisible && labelMesh.visible) {
      labelMesh.visible = false;
    } else if (isVisible && !labelMesh.visible) {
      if (!this.userHidden) {
        labelMesh.visible = true;
      }
    }
  }

  update () {
    this.canvasManager.render()
      .then(() => {
      // Setting Width Height of Plane
        const width = this.canvasManager.getCanvas().width / LABEL_SCALE;
        const height = this.canvasManager.getCanvas().height / LABEL_SCALE;
        const backboard = this.meshManager.getMesh().children[0];
        backboard.scale.set(width, height, 1);
        backboard.updateMatrixWorld();
        this.meshManager.updatePointer();
        backboard.material.needsUpdate = true;
        this.canvasTexture.needsUpdate = true;
        store.requestRender();
      });
  }

  setMarkerStyle (style) {
    if (Object.prototype.hasOwnProperty.call(style, 'showMarker')) this.settings.showMarker = style.showMarker;
    if (Object.prototype.hasOwnProperty.call(style, 'markerHoverSize')) this.settings.markerHoverSize = style.hoverSize;
    if (Object.prototype.hasOwnProperty.call(style, 'markerInactiveSize')) this.settings.markerInactiveSize = style.inactiveSize;
    if (Object.prototype.hasOwnProperty.call(style, 'markerInactiveBackground')) this.settings.markerInactiveBackground = style.inactiveBackground;
    if (Object.prototype.hasOwnProperty.call(style, 'markerHoverBackground')) this.settings.markerHoverBackground = style.hoverBackground;
    this.marker.updateStyle(style);
  }

  replaceText (textArray) {
    this.canvasManager.replaceText(textArray);
    this.update();
  }

  addText (textLine) {
    this.canvasManager.addText(textLine);
    this.update();
  }

  editText (textID, textLine) {
    this.canvasManager.editText(textID, textLine);
    this.update();
  }

  deleteImage () {
    delete this.content.style.image;
    this.canvasManager.setStyle(this.content.style);
    this.update();
    store.requestRender();
  }

  setStyle (newStyle) {
    const labelMesh = this.meshManager.getMesh();
    const tStyle = Object.assign({}, this.content.style, newStyle);
    this.canvasManager.setStyle(tStyle);
    if (Object.prototype.hasOwnProperty.call(tStyle, 'backgroundColor')) {
      labelMesh.material.color.setStyle(tStyle.backgroundColor);
    }
    this.content.style = tStyle;
    this.update();
    store.requestRender();
  }

  modifyScale (newScale) {
    const labelMesh = this.meshManager.getMesh();
    const oldScale = store.globalscale;
    labelMesh.position.sub(store.inventumTransform); // Remove the Inventum Transform temp
    labelMesh.position.divideScalar(oldScale); // Calculate what the original 100% was
    labelMesh.position.multiplyScalar(newScale); // Calculate the new scale
    labelMesh.position.add(store.inventumTransform);// Add the Inventum Transform back
  }

  modifyTransform (deltaTransform) {
    const labelMesh = this.meshManager.getMesh();
    labelMesh.position.add(deltaTransform);
  }

  remove () {
    const labelMesh = this.meshManager.getMesh();
    store.scene.remove(labelMesh);
    store.sceneHUD.remove(this.marker.sprite);
  }

  updateOffsetControls () {
    const pointerMesh = this.meshManager.getMesh();
    pointerMesh.updateMatrixWorld();
    const backboard = pointerMesh.children[0];
    const backboardPosition = backboard.position.clone().applyMatrix4(pointerMesh.matrixWorld);
    this._offsetControlsHelperCube.position.copy(backboardPosition);
    store.requestRender();
  }

  toggleOffsetControls () {
    if (this._offsetControls) {
      this._offsetControls.detach();
      store.scene.remove(this._offsetControls);
      store.scene.remove(this.__offsetControlsHelperCube);
      delete this._offsetControls;
      delete this._offsetControlsHelperCube;
      return;
    }

    this._offsetControls = new TransformControls(store.camera, store.webglRenderer.domElement);
    this._offsetControls.addEventListener('dragging-changed', function (event) {
      store.controls.enabled = !event.value;
    });

    this._offsetControls.addEventListener('change', (e) => {
      backboard.position.copy(pointerMesh.worldToLocal(this._offsetControlsHelperCube.position.clone()));
      this.meshManager.updatePointer();
      store.requestRender();
    });

    var tGeom = new THREE.BoxGeometry(1, 1, 1);
    var tMat = new THREE.MeshBasicMaterial({ color: 0xFF0000 });
    this._offsetControlsHelperCube = new THREE.Mesh(tGeom, tMat);

    const pointerMesh = this.meshManager.getMesh();
    pointerMesh.updateMatrixWorld();
    const backboard = pointerMesh.children[0];
    const backboardPosition = backboard.position.clone().applyMatrix4(pointerMesh.matrixWorld);
    this._offsetControlsHelperCube.position.copy(backboardPosition);
    this._offsetControlsHelperCube.visible = false;
    store.scene.add(this._offsetControlsHelperCube);

    // There's a weird error where the offset in the z plane gets reported as 3.000e15 if the z axis is in direct aligment with the parent object.
    // It needs to be offset by a tiny amount to fix it. Should only need to be done once when the helper cube is created the first time.
    this._offsetControlsHelperCube.position.z += 0.001;

    this._offsetControls.attach(this._offsetControlsHelperCube);
    store.scene.add(this._offsetControls);
  }

  toggleAutoRotate () {
    this.settings.autoRotate = !this.settings.autoRotate;
  }

  toggleAutoScale () {
    this.settings.autoScale = !this.settings.autoScale;
  }

  addRenderLimit (limit) {
    this.renderLimits.push(limit);
    this.marker.addRenderLimit(limit);
  }

  deleteRenderLimit (limitID) {
    this.renderLimits.splice(limitID, 1);
    this.marker.deleteRenderLimit(limitID);
  }

  updateRenderLimit (limitID, updatedValue) {
    this.renderLimits[limitID].value = updatedValue;
    this.marker.updateRenderLimit(limitID, updatedValue);
  }

  serialize () {
    const labelMesh = this.meshManager.getMesh();
    const tempPos = labelMesh.position; // this.marker.getWorldPosition
    let backboardOffset = this.meshManager.getBackboardPosition();

    const anchorPosition = { x: +tempPos.x.toFixed(2), y: +tempPos.y.toFixed(2), z: +tempPos.z.toFixed(2) };
    backboardOffset = { x: +backboardOffset.x.toFixed(2), y: +backboardOffset.y.toFixed(2), z: +backboardOffset.z.toFixed(2) };

    const tempSerialize = {
      id: this.id,
      offset: backboardOffset,
      type: this.type,
      content: JSON.parse(JSON.stringify(this.content)),
      renderLimits: JSON.parse(JSON.stringify(this.renderLimits)),
      anchorPosition: anchorPosition,
      scale: this.meshManager.getScale(),
      rotation: this.meshManager.getRotation(),
      settings: JSON.parse(JSON.stringify(this.settings)),
			visibleStart: this.visibleStart
    };

    return tempSerialize;
  }
}

export { WorldLabel };
