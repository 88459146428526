import React, { useState, useEffect } from 'react';

class InventumClippingEditor extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      open: true,
      transform: {x: 0, y: 0, z: 0},
      gridSkyScale: 1.0,
      gridTextureRepeat: 1.0,
      globalscale: 1.0,
			sceneFOV: 65,
    };
    this.getWorldSettings = this.getWorldSettings.bind(this);
  }

  componentDidMount () {
    this.getWorldSettings();
  }

  getWorldSettings () {
    const transform = Inventum.settings.getGlobalTransform();
    const gridSkyScale = Inventum.world.getScale();
    const gridTextureRepeat = Inventum.world.getGridTextureRepeat();
    const globalscale = Inventum.settings.getGlobalScale();
		const sceneFOV = Inventum.camera.getSceneFOV();
    this.setState({transform, gridSkyScale, gridTextureRepeat, globalscale, sceneFOV})
  }

  render () {
    let style = {
      background:'#FFF',
      pointerEvents:'all'
    }

    const toggleOpen = () => {this.setState({ open: !this.state.open })};

    const setTransform = (key, value) => {
      let transform = this.state.transform;
      transform[key] = value;
      Inventum.settings.setGlobalTransform(transform);
      this.getWorldSettings();
    };

		const handleHelp = (e) => {
			e.stopPropagation();
			window.open('https://docs.inventum3d.com/world/', '_blank');
		};

		const setSceneFOV = (value) => {
			Inventum.camera.setSceneFOV(value);
			this.setState({sceneFOV:value});
		};

    return (
      <div className='LabelsPanel'>
				<div onClick={toggleOpen} className="EditorPanelHeader">
          <span className='PanelOpenStatus'>{this.state.open ? '▾' : '▸'}</span>
          <span>World</span>
					<span title='Opens the Inventum Docs' className="EditorPanelHelpButton" onClick={handleHelp}><i className="material-icons notranslate">help</i></span>
        </div>
        {this.state.open ? <WorldDrawer setSceneFOV={setSceneFOV} sceneFOV={this.state.sceneFOV} setTransform={setTransform} getSettings={this.getWorldSettings} transform={this.state.transform} gridSkyScale={this.state.gridSkyScale} gridTextureRepeat={this.state.gridTextureRepeat} globalscale={this.state.globalscale} /> : null}
      </div>
    )
  }
}

function WorldDrawer (props) {
  return (
    <div style={{marginLeft: '5px'}}>
      <div>
        <span>Inventum Transform</span>
        <div style={{display: 'flex'}}>
          <SpinnerValue name='X' inputWidth='45px' paddingWidth='5px' value={props.transform.x} valueKey='x' handleUpdate={props.setTransform} />
          <SpinnerValue name='Y' inputWidth='45px' paddingWidth='5px' value={props.transform.z} valueKey='z' handleUpdate={props.setTransform} />
					<SpinnerValue name='Z' inputWidth='45px' paddingWidth='5px' value={props.transform.y} valueKey='y' handleUpdate={props.setTransform} />
        </div>
      </div>

      <div><SpinnerValue step={0.01} min={0.01} name='Scene Scale' inputWidth='45px' value={props.globalscale} marginTop='0px' handleUpdate={(value) => {Inventum.settings.setGlobalScale(value); props.getSettings()}} /></div>

      <div style={{marginTop: '15px'}}>
        <span>Grid</span>
        <div><SpinnerValue step={0.01} min={0.01} name='Size' inputWidth='45px' value={props.gridSkyScale} handleUpdate={(value) => {Inventum.world.setScale(value); props.getSettings()}} /></div>
        <div><SpinnerValue step={1} min={1} name='Texture Repeat' inputWidth='45px' value={props.gridTextureRepeat} handleUpdate={(value) => {Inventum.world.setGridTextureRepeat(value); props.getSettings()}}/></div>
        <div style={{marginTop: '10px'}}>
          <div style={{display: 'flex', marginTop: '5px', alignItems: 'center'}}>
            <span style={{fontSize: '14px', marginRight: '5px'}}>Color</span>
            <span className='WorldEditorBtn' onClick={Inventum.world.setGridDark}>Dark</span>
            <span className='WorldEditorBtn' onClick={Inventum.world.setGridLight}>Light</span>
          </div>
        </div>
      </div>

      <div style={{marginTop: '15px'}}>
        <span>Start View</span>
        <div style={{display: 'flex', marginTop: '5px'}}>
          <span className='WorldEditorBtn' onClick={Inventum.camera.showStartView}>Show</span>
          <span className='WorldEditorBtn' onClick={Inventum.camera.setInitialPosition}>Set</span>
        </div>
      </div>

      <div style={{marginTop: '15px'}}>
        <span>Start Models/Labels</span>
        <div style={{display: 'flex', marginTop: '5px'}}>
          <span className='WorldEditorBtn' onClick={Inventum.world.showVisibleStart}>Show</span>
          <span className='WorldEditorBtn' onClick={Inventum.world.setVisibleStart}>Set</span>
        </div>
      </div>

			<div style={{marginTop: '15px'}}>
				<span>Camera FOV</span>
					<div style={{display:"flex", alignItems: "center"}}>
            <input type="range" min="20" max="100" onChange={(e)=>{e.stopPropagation(); props.setSceneFOV(parseInt(e.target.value))}} value={props.sceneFOV} />
            <div style={{marginLeft:"10px"}}>{props.sceneFOV}</div>
          </div>
			</div>

    </div>
  )
}

function SpinnerValue(props) {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = (e) => {
    setValue(Number(e.target.value));
    if (typeof props.handleUpdate === "function") {
      if (props.valueKey) {
        props.handleUpdate(props.valueKey, Number(e.target.value));
      } else {
        props.handleUpdate(Number(e.target.value));
      }
    }
  };

  const inputStyle = {
    width: props.inputWidth,
    borderRadius: "2px",
    outline: "none",
    border: "none",
    marginLeft: "10px",
  };

  return (
    <div
      style={{
        display: "flex",
        fontSize: "14px",
        marginBottom: "10px",
        alignItems: "center",
        marginTop: props.marginTop || "10px",
        marginRight: "10px",
      }}>
      <div style={{ width: props.paddingWidth }} title={props.name}>
        {props.name}
      </div>
      <input style={inputStyle} type="number" min={props.min} max={props.max} step={props.step} value={value} onChange={onChange} />
    </div>
  );
}

export default InventumClippingEditor;
