import React from 'react';
import {connect} from 'react-redux';
import {openContextMenu, closeContextMenu, openModal, closeModal, setModalOptions} from '../../../Redux/actions/ui.js';
import ModelCollection from './ModelCollection';
//import PointcloudCollection from './PointcloudCollection';
import {SortableElement,SortableHandle} from 'react-sortable-hoc';

function PanelExpandButton(props) {
  return (
    <div className="PanelExpandButton">
    <i className="material-icons notranslate">{props.panelOpen ? "keyboard_arrow_down" : "keyboard_arrow_right"}</i>
    </div>
  )
}

class PanelTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {name:props.name}
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);

  }

  handleChange(e) {
    this.setState({name:e.target.value});
  }

  handleSave() {
    Inventum.models.setGroupName(this.props.panelID,this.state.name);
    this.props.cancelEditing();
  }

  componentDidUpdate(prevProps) {
    if (this.props.editing === true && prevProps.editing === false) {
      this.inputBox.focus();
      this.inputBox.select();
    }
  }

  render() {
    let handleKey = (e) => {
      if (e.key === "Enter") {
        this.handleSave();
      }
    }
    if (this.props.editing) {
      return (
        <div className="PanelTitle Editing" onClick={(e)=>{e.stopPropagation()}}>
          <input onKeyDown={handleKey} ref={(input)=>{this.inputBox=input}}  value={this.state.name} onChange={this.handleChange} />
          <div className="PanelTitleEditButton" onClick={this.handleSave}>Save</div>
          <div className="PanelTitleEditButton" onClick={()=>{this.setState({name:this.props.name});this.props.cancelEditing()}}>Cancel</div>
        </div>
      )
    }

    return (
      <div className="PanelTitle">
        {this.props.name}
      </div>
    )
  }
}

function HeaderControls(props) {
  let click = (e) => {
    e.stopPropagation();
    props.visibility.handler(props.contentID);
  }
  let statusIcon = "check_box_outline_blank";
  switch (props.visibility.status) {
    case "all":
      statusIcon = "check_box";
      break;
    case "none":
      statusIcon = "check_box_outline_blank";
      break;
    case "mixed":
      statusIcon = "indeterminate_check_box";
      break;
  }
  return (
    <div className="HeaderControls" onClick={click}>
    <i className="material-icons notranslate">{statusIcon}</i>
    </div>
  )
}

function SideBarPanelContextMenu(props) {
  let handleRename = (e) => {
    if (!props.siteAdmin) {
      return;
    }
    e.stopPropagation();
    props.closeMenu();
    props.setEditing();
  }

  let handleDelete = (e) => {
    if (!props.siteAdmin) {
      return;
    }
    e.stopPropagation();
    if (window.confirm("Delete Group?")) {
      Inventum.models.deleteGroup(props.panelID);
    }
    props.closeMenu();
  }

  let handleAdd = (e) => {
    if (!props.siteAdmin) {
      return;
    }
    props.openModal('InventumAssetManager');
    props.setModalOptions({type:"mesh",referenceID:props.panelID});
    e.stopPropagation();
    props.closeMenu();
    //Inventum.models.addModel(props.panelID);
  }

  let handlePaste = (e) => {
    if (!props.siteAdmin) {
      return;
    }
    e.stopPropagation();
    props.closeMenu();
    Inventum.models.pasteModel(props.panelID);
  }

  if (props.pageMode !== "3D") {
    return null
  }
  if (!props.siteAdmin || !props.editMode) {
    return (
      <div className="SideBarPanelContextMenu" />
    )
  }

  return (
    <div className="SideBarPanelContextMenu">
      <div className="SideBarContextMenuEntry" onClick={handleRename}>Rename Group</div>
      <div className="SideBarContextMenuEntry MenuEntryWarning" onClick={handleDelete}>Delete Group</div>
      <div className="SideBarContextMenuEntry" onClick={handleAdd}>Add Model</div>
      <div className="SideBarContextMenuEntry" onClick={handlePaste}>Paste Model</div>
    </div>
  )
}

const DragHandle = SortableHandle(() => <div style={{"paddingTop":"5px","cursor":"grab"}}><i className="material-icons notranslate">drag_indicator</i></div>);


class SideBarPanelHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state={editingName:false}
    this.handleContext = this.handleContext.bind(this);
    this.handleHeaderClick = this.handleHeaderClick.bind(this);

  }

  handleContext(force) {
    if (this.props.pageMode === "3D" && !this.props.editMode) {
      return
    }
    if (typeof(force) === "boolean") {
      if (force) {
        this.props.openContextMenu(this.props.panelID);
      }else {
        this.props.closeContextMenu();
      }
      return;
    }
    if (this.props.panelID === this.props.activeContextMenu) {
      this.props.closeContextMenu();
    }else {
      this.props.openContextMenu(this.props.panelID);
    }
  }

  handleHeaderClick() {
    this.handleContext(false);
    this.props.click()
  }


  render() {
    let contextMenuOpen = this.props.panelID === this.props.activeContextMenu;
    let showHandle = false;

    if (this.props.pageMode === "2D") {
      showHandle = true;
    }

    if (this.props.siteAdmin && this.props.editMode) {
      showHandle = true;
    }
    return (
      <div className={"SideBarPanelHeader" + (contextMenuOpen ? " contextOpen" : "")} onClick={this.handleHeaderClick} onContextMenu={(e)=>{e.preventDefault();this.handleContext(true)}}>
        {contextMenuOpen ? <SideBarPanelContextMenu setModalOptions={this.props.setModalOptions} openModal={this.props.openModal} pageMode={this.props.pageMode} siteAdmin={this.props.siteAdmin} editMode={this.props.editMode} panelID={this.props.panelID} closeMenu={()=>{this.handleContext(false)}} setEditing={()=>{this.setState({editingName:true})}} /> : null}
        {showHandle ? <DragHandle /> : null}
        <HeaderControls visibility={this.props.visibility} contentID={this.props.contentID} />
        <PanelExpandButton panelOpen={this.props.panelOpen} />
        <PanelTitle panelID={this.props.panelID} name={this.props.name} editing={this.state.editingName} cancelEditing={()=>{this.setState({editingName:false})}} />
      </div>
    )
  }
}

let mapStateToProps = (state) => ({
  siteAdmin:(state.user.siteAdmin) ? true : false,
  activeContextMenu:state.ui.activeContextMenu,
  pageMode:state.sidebarUI.mode,
  editMode:state.ui.editMode
})

let mapDispatchToProps = dispatch => ({
  openContextMenu:(panelID) => {
    dispatch(openContextMenu(panelID));
  },
  closeContextMenu:() => {
    dispatch(closeContextMenu());
  },
  openModal:(value) => {
    dispatch(openModal(value));
  },
  setModalOptions:(options) => {
    dispatch(setModalOptions(options));
  },
  closeModal:() => {
    dispatch(closeModal());
  },
})


let SideBarPanelHeaderWrapper = connect(mapStateToProps,mapDispatchToProps)(SideBarPanelHeader);

function SideBarPanelBody(props) {
  //NOTE FUTURE WORK FOR DIFFERENT TYPES
  //ModelCollection is a sortable container

  let handleModelSort = (data) => {
    Inventum.models.sortModels(props.panelID,data);
  }

  switch(props.type) {
    case "3D_GROUP":
      return (
        <div className="SideBarPanelBody">
          <ModelCollection onSortEnd={handleModelSort} useDragHandle={true} items={props.items} helperClass='sortableHelper' lockAxis={'y'}/>
        </div>
      )
    case "MAP_LAYER":
      return (
        <div className="SideBarPanelBody">
        <MapLayerOpacity opacity={props.opacity} />
        </div>
      )
    default:
      console.warn('Unsupported Item Type');
      return null
  }
}

function MapLayerOpacity(props) {
  let style={display:"flex"}
  let numberBoxStyle={
    "paddingLeft": "5px",
    "paddingRight": "5px",
    "color": "#FFF",
    "fontFamily": 'Sans-Serif'
  }
  return (
    <div style={style}>
     <div style={numberBoxStyle}>Opacity</div>
     <input className="slider" style={{width:"100%",marginLeft:"10px"}} type="range" min="0" max="100" onChange={(e)=>{props.opacity.handler(e.target.value / 100)}} value={props.opacity.value * 100}/>
     <div style={numberBoxStyle}>{parseInt(props.opacity.value * 100)}</div>
   </div>
  )
}


class SideBarPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {panelOpen:false};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.setState({panelOpen:!(this.state.panelOpen)});
  }

  render() {
    let content = this.props.content;
    let items = content.items;
    let type = content.type;
    let visibility = {status:content.visibility,handler:content.visibilityHandler};
    let opacity = {value:content.opacity,handler:content.opacityHandler};
    let name = content.name;
    let id = content.id;
    return (
      <div className={"SideBarPanel" + (this.state.panelOpen ? " open" : "")}>
        <SideBarPanelHeaderWrapper contentID={id} panelID={id} visibility={visibility} panelOpen={this.state.panelOpen} name={name} click={this.handleClick}/>
        {this.state.panelOpen ? <SideBarPanelBody opacity={opacity} items={items} type={type} panelID={id} /> : null }
      </div>
    )
  }
}

const SideBarPanelSortable = SortableElement(({content}) =>
  <SideBarPanel content={content} />
);

export default SideBarPanelSortable;
