export const OPEN_MODAL = 'OPEN_MODAL';
export const SET_MODAL_OPTIONS = 'SET_MODAL_OPTIONS';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_CONTEXT_MENU = 'OPEN_CONTEXT_MENU';
export const CLOSE_CONTEXT_MENU = 'CLOSE_CONTEXT_MENU';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT';
export const SET_HAS_VISITED_HOME = `SET_HAS_VISITED_HOME`;
export const TOGGLE_UI_HIDE_ALL = `TOGGLE_UI_HIDE_ALL`;
export const TOGGLE_UI_HIDE_ANIMATION = `TOGGLE_UI_HIDE_ANIMATION`;
export const TOGGLE_DRAWING_PANEL = 'TOGGLE_DRAWING_PANEL';

export function openModal(data) {
  return { type:OPEN_MODAL, data}
}

export function setModalOptions(data) {
  return { type:SET_MODAL_OPTIONS, data}
}

export function closeModal() {
  return { type:CLOSE_MODAL}
}

export function openContextMenu(data) {
  return { type:OPEN_CONTEXT_MENU,data}
}

export function closeContextMenu() {
  return { type:CLOSE_CONTEXT_MENU}
}

//Sets Edit Mode to true if on an Inventum Review
export function setEditMode(data) {
  return { type:SET_EDIT_MODE,data}
}

//Sets the project ID for use by other modules.
export function setActiveProject(data) {
  return { type:SET_ACTIVE_PROJECT, data}
}

export function setHasVisitedHome() {
  return { type:SET_HAS_VISITED_HOME}
}

export function toggleUIHideAll() {
  return { type:TOGGLE_UI_HIDE_ALL}
}

export function toggleUiHideAnimation() {
  return { type:TOGGLE_UI_HIDE_ANIMATION}
}

export function toggleDrawingPanel() {
	return { type:TOGGLE_DRAWING_PANEL }
}
