import React from 'react';
import axios from '../../../../axios-config.js';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

function prettyElapsedCalc(lastLoginTime) {
  if (lastLoginTime === null) {
    return 'Never';
  }

  let lastLogin = new Date(lastLoginTime);
  let currentTime = new Date();
  let unit = "day";

  let duration = Math.floor((currentTime - lastLogin) / 86400000);

  if (duration == 0) {
    unit = "hour";
    duration = Math.floor((currentTime - lastLogin) / 3600000)
    if (duration == 0) {
      unit = "minute"
      duration = Math.floor((currentTime - lastLogin) / 60000)
      if (duration == 0) {
        unit = "second"
        duration = Math.floor((currentTime - lastLogin) / 1000)
      }
    }

  }

  if (duration > 1) {
    unit = unit + 's';
  }

  return `${duration} ${unit} ago`

}

const CompanyField = (props) => {
  if (props.editing) {
    return (
      <div className="CompanyField">
        <input onChange={props.onChange} value={props.value} placeholder={props.placeholder} />
        <div className="CompanyFieldSave" onClick={()=>{props.onSave();props.toggleEdit();}}>{props.submitText ? props.submitText : "Save"}</div>
      </div>
    )
  }
  return (
    <div className="CompanyField">
      {props.value}
      <div><div onClick={props.toggleEdit} className="toggleEditButton">Edit {props.title}</div></div>
    </div>
  )
}

const AddingUser = (props) => {
  return (
    <div className={"AddingUserField" + (props.visible ? " visible" : "")}>
      <input onChange={props.onChange} value={props.value} placeholder={props.placeholder} />
      <div className="AddingUserSave" onClick={props.handleSubmit}>{props.submitText ? props.submitText : "Save"}</div>
    </div>
  )
}


const ProjectsList = (props) => {
  return (
    <div className="ProjectsList">
      <div>Projects</div>
      <div className="ProjectsEntryContainer">
        <div className="CreateProjectButton" onClick={props.handleCreateProject}>Create Project</div>
        {props.projects.map((project,index) => <div title={"Click to edit"} onClick={()=>{props.handleEditProject(project.project_id)}} key={index} className="EditProjectEntry">{project.project_name}</div>)}
      </div>
    </div>
  )
}


class UsersList extends React.Component {
  constructor(props) {
    super(props);
    this.state= {
      addingUser:false,
      newUserEmail:"",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleSubmit() {
    let state = this.state;
    if (state.newUserEmail.length == 0) {
      window.alert(`Email can't be blank`);
      return;
    }

    axios.post(`https://api.inventum3d.com/company/${this.props.companyID}/users/`,{email:state.newUserEmail})
    .then((response) => {
      console.log('Added User!');
      this.props.updateData();
      this.setState({addingUser:false,newUserEmail:""});
    }).catch((error) => {
      console.log(error.response);
    })
  }

  handleRemove(email) {
    let deleteConfirm = prompt("Type YES to remove user:","");
    if (deleteConfirm.toUpperCase() === "YES") {
      axios.delete(`https://api.inventum3d.com/company/${this.props.companyID}/users/`,{data:{email}})
      .then((response) => {
        this.props.updateData();
      }).catch((error) => {
        console.log(error.response);
      })
    }
  }

  handleResetPassword(email) {
    let resetConfirm = prompt("Type YES to reset password:","");
    if (resetConfirm.toUpperCase() === "YES") {
      axios.post(`https://api.inventum3D.com/forgotpassword/`,{email})
      .then((response) => {
        this.props.updateData();
      }).catch((error) => {
        console.log(error.response);
      })
    }
  }

  render() {
    return (
      <div className="ProjectsList" style={{maxHeight:'250px', overflowY:'auto'}}>
        <div>Users/Tokens</div>
        {this.props.users.map((user,index) => <User {...user} key={index} handleRemove={this.handleRemove} handleResetPassword={this.handleResetPassword} /> )}
        {this.props.tokens.map((token,index) => <Token updateData={this.props.updateData} {...token} key={index} /> )}
        <div className="CreateProjectButton" onClick={()=>{this.setState({addingUser:!this.state.addingUser})}}>Add Existing User</div>
        <div className="CreateProjectButton" onClick={this.props.handleCreateUser}>Create New User</div>
        <AddingUser handleSubmit={this.handleSubmit} value={this.state.newUserEmail} onChange={(e)=>{this.setState({newUserEmail:e.target.value})}} visible={this.state.addingUser} submitText={"Add User"} placeholder={"Email"} />
      </div>
    )

  }
}

const Token = (props) => {
  let shareURL = `https://inventum3d.com/c/${props.company_slug}/${props.project_slug}/?st=${props.token}`;
  let handleURLCopy = () => {
    navigator.clipboard.writeText(shareURL)
    .catch(e => {
      alert(shareURL)
    })
  }

  let detailedLog = ``;
  let logLength = 0;
  for (var i = props.activity.length - 1; i >= 0; i--) {
    detailedLog += `${props.activity[i].ip} ${prettyElapsedCalc(props.activity[i].access_time)} \n`;
    logLength++;
    if (logLength > 9) {
      break;
    }
  }

  let toggleTokenExpiration = () => {
    axios.post(`https://api.inventum3d.com/project/${props.project_id}/share/${props.token}`,{tokenExpired:!props.expired})
    .then((response) => {
      console.log('Token Updated')
      props.updateData();
    })
    .catch((error) => {
      console.log(error);
    })
  }
  return (
    <div className="EditUsersEntry">
      <div className="RemoveUserButton" onClick={toggleTokenExpiration}>{props.expired ? "Unexpire" : "Expire"} Token</div>
      <div className="RemoveUserButton resetPassword" onClick={handleURLCopy} title={shareURL}>Copy URL to clipboard</div>
      <div className="lastSeenCounter" title={detailedLog}>Last Access: {prettyElapsedCalc(props.last_access)}</div>
      <div style={{marginLeft:"5px"}}>{props.project_name}</div>
    </div>
  )
}


const User = (props) => {
  let lastLogin = prettyElapsedCalc(props.last_login);

  return (
    <div className="EditUsersEntry" title={`Email:${props.email}`}>
      <div className="RemoveUserButton" onClick={() => {props.handleRemove(props.email)}}>Remove</div>
      <div className="RemoveUserButton resetPassword" onClick={() => {props.handleResetPassword(props.email)}}>Reset Password</div>
      <div className="lastSeenCounter">Last Login:{lastLogin}</div>
      <div className="UserNameField">{`${props.first_name} ${props.last_name}`}</div>
    </div>
  )
}

const Submit = (props) => {
  return (
    <div onClick={props.onClick} className="CreateCompanySubmit">Company Editing is not currently supported</div>
  )
}


class CompanyEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name:props.company.name,
      website:props.company.website,
      editingName:false,
      editingWebsite:false,
      editingSlug:false,
      id:props.company.id,
      slug:props.company.slug,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    console.log('Updating');
    let state = this.state;
    if (state.name.length === 0) {
      window.alert(`Name can't be blank`);
      return;
    }

    if (state.slug.length === 0) {
      window.alert(`Slug can't be blank`);
      return;
    }

    axios.post(`https://api.inventum3D.com/company/${state.id}`, {name:state.name, website:state.website, slug:state.slug})
    .then((response) => {
      console.log(response);
    })
  }

//props.company.website

  render() {
    let company = this.props.company;
    return (
      <div>
        <div style={{"fontSize":"30px","textAlign":"center"}}>Company Details</div>
        <CompanyField title={"Name"} editing={this.state.editingName} value={this.state.name} onSave={this.handleSubmit} toggleEdit={()=>{this.setState({editingName:!this.state.editingName})}} onChange={(e)=>{this.setState({name:e.target.value})}} />
        <CompanyField title={"Slug"} editing={this.state.editingSlug} value={this.state.slug} onSave={this.handleSubmit} toggleEdit={()=>{this.setState({editingSlug:!this.state.editingSlug})}} onChange={(e)=>{this.setState({slug:e.target.value})}} />
        {this.state.website ? <CompanyField title={"Website"} editing={this.state.editingWebsite} value={this.state.website} onSave={this.handleSubmit} toggleEdit={()=>{this.setState({editingWebsite:!this.state.editingWebsite})}} onChange={(e)=>{this.setState({website:e.target.value})}} /> : null}
        <ProjectsList handleEditProject={this.props.handleEditProject} handleCreateProject={this.props.handleCreateProject} projects={company.projects} />
        <UsersList updateData={this.props.updateData} companyID={this.state.id} handleCreateUser={this.props.handleCreateUser} users={company.users} tokens={company.tokens} />
      </div>
    )
  }
}

export default CompanyEditor
