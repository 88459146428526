export const LOAD_COMPANY = 'LOAD_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const CLEAR_COMPANY = 'CLEAR_COMPANY';


export function loadCompany(data) {
  return { type:LOAD_COMPANY, data}
}

export function updateCompany(data) {
  return { type:UPDATE_COMPANY }
}

export function clearCompany() {
  return { type:CLEAR_COMPANY }
}
