import * as THREE from 'three';
import { store } from '../core/store.js';
import { resolutionManager } from '../core/resolution.js';

import { MarkerCanvas } from './markerCanvas.js';
import { utility } from '../utility/utility.js';

var controller = { public: {} };
var markerList = [];
var hoveredMarker = {};
var renderMarkers = true;
var allMarkersHidden = false; // Flag to prevent unnescary visible = true/false calls on the marker

class ScreenMarker {
  constructor (options) {
    this._onClick = [];
    this._onWorldPositionUpdate = [];
    this._onScreenPositionUpdate = [];
    this._onVisibilityChange = [];

    // Render Limitations
    this._renderLimits = [];

    // Canvas
    if (options.inactiveBackground.toLowerCase().includes('http')) {
      this.inactiveTexture = new THREE.TextureLoader().load(options.inactiveBackground);
    } else {
      this.inactiveCanvas = new MarkerCanvas(options.inactiveBackground);
      this.inactiveTexture = new THREE.Texture(this.inactiveCanvas.getCanvas());
    }

    if (options.hoverBackground.toLowerCase().includes('http')) {
      this.hoverTexture = new THREE.TextureLoader().load(options.hoverBackground);
    } else {
      this.hoverCanvas = new MarkerCanvas(options.hoverBackground);
      this.hoverTexture = new THREE.Texture(this.hoverCanvas.getCanvas());
    }

    // Textures
    this.inactiveTexture.needsUpdate = true;
    this.hoverTexture.needsUpdate = true;

    // Texture Option Cache
    this.hoverBackground = options.hoverBackground;
    this.inactiveBackground = options.inactiveBackground;

    // Sprite Size
    this.hoverSize = options.hoverSize;
    this.inactiveSize = options.inactiveSize;
    this.size = this.inactiveSize;

    // State
    this.isHovering = false;
    var material = new THREE.SpriteMaterial({ map: this.inactiveTexture });
    const opacity = options.opacity;
    material.opacity = opacity;

    this._positionWorld = new THREE.Object3D();
    this._positionWorld.position.set(0, 0, 0);
    this.sprite = new THREE.Sprite(material);
    this.sprite.scale.set(this.size, this.size, this.size);
    store.sceneHUD.add(this.sprite);
  }

  addRenderLimit (limit) {
    // Check if limit is valid object.
    // Should be {type:"LIMIT_TYPE",value:50};
    // Or {type:"LIMIT_TYPE",value:{x,y,z,d}};
    if (!limit) {
      console.log('Please pass an options object with TYPE and VALUE as keys.');
    }

    if (limit.type === undefined) {
      console.log('Please pass a type key');
      return;
    } else if (limit.value === undefined) {
      console.log('Please pass a value key');
      console.log(limit);
      return;
    }

    let tempValue = limit.value;

    if (limit.type === 'EXCLUSION') {
      if (typeof (limit.value) === 'object') {
        if (Object.keys(limit.value).length === 3 || Object.keys(limit.value).length === 4) {
          tempValue = {};
          tempValue.position = new THREE.Vector3(limit.value.x, limit.value.y, limit.value.z);
          tempValue.distance = limit.value.d || 5;
        } else {
          console.log('Invalid Config for EXCLUSION Limitation. Must have 3 properties x,y,z');
        }
      } else {
        console.log('Invalid Config for EXCLUSION Limitation. Must be an Object. e.g {x:0,y:0,z:0}');
      }
    }

    if (limit.type === 'INCLUSION') {
      if (typeof (limit.value) === 'object') {
        if (Object.keys(limit.value).length === 3 || Object.keys(limit.value).length === 4) {
          tempValue = {};
          tempValue.position = new THREE.Vector3(limit.value.x, limit.value.y, limit.value.z);
          tempValue.distance = limit.value.d || 5;
        } else {
          console.log('Invalid Config for INCLUSION Limitation. Must have 3 properties x,y,z');
        }
      } else {
        console.log('Invalid Config for INCLUSION Limitation. Must be an Object. e.g {x:0,y:0,z:0}');
      }
    }

    // Create the limit object
    const tempLimit = {
      type: limit.type,
      value: tempValue
    };

    // If limit type is Exclusion then push it to the end of the list as we want the Altitude and Distance limits to kick in first
    // Then we exclude

    this._renderLimits.push(tempLimit);
    store.requestRender();
  }

	deleteAllRenderLimits () {
		this._renderLimits = [];
	}

  deleteRenderLimit (limitID) {
    this._renderLimits.splice(limitID, 1);
    store.requestRender();
  }

  updateRenderLimit (limitID, updatedValue) {
    let tempValue = updatedValue;
    const limit = this._renderLimits[limitID];

    if (limit.type === 'EXCLUSION' || limit.type === 'INCLUSION') {
      if (typeof (updatedValue) === 'object') {
        if (Object.keys(updatedValue).length === 3 || Object.keys(updatedValue).length === 4) {
          tempValue = {};
          tempValue.position = new THREE.Vector3(updatedValue.x, updatedValue.y, updatedValue.z);
          tempValue.distance = updatedValue.d || 5;
        } else {
          console.log('Invalid Config. Must have 3 properties x,y,z');
        }
      } else {
        console.log('Invalid Config. Must be an Object. e.g {x:0,y:0,z:0}');
      }
    }
    this._renderLimits[limitID].value = tempValue;
    console.log(this._renderLimits[limitID]);
    store.requestRender();
  }

  checkRenderLimit () {
    // If there are no render limits then set it to be visible and move on with our lives.
    const cameraPosition = store.camera.position;
    if (this._renderLimits.length === 0) {
      this.sprite.visible = true;
      return;
    }

    // Inclusion have highest priority. Unless the camera is in range the marker is off
    const inclusionLimits = [];
    // Exclusion will turn off if the camera is in some range
    const exclusionLimits = [];
    // Other limits like distance and altitude are checked secondarily
    const otherLimits = [];

    this._renderLimits.map(limit => {
      if (limit.type === 'INCLUSION') {
        inclusionLimits.push(limit);
      } else if (limit.type === 'EXCLUSION') {
        exclusionLimits.push(limit);
      } else {
        otherLimits.push(limit);
      }
    });

    // If we have any inclusion labels then this is the only thing we need to check
    if (inclusionLimits.length > 0) {
      this.sprite.visible = false;
      for (let i = 0; i < inclusionLimits.length; i++) {
        const limitToCheck = inclusionLimits[i];
        if (cameraPosition.distanceTo(limitToCheck.value.position) < limitToCheck.value.distance) {
          this.sprite.visible = true;
          return; // No more checks are needed we can return
        }
      }
      return; // Even if we don't match a label we return anyway because inclusion limits are always off unless they are in the position
    }

    // Check exclusion secondarily
    if (exclusionLimits.length > 0) {
      this.sprite.visible = true;
      for (let i = 0; i < exclusionLimits.length; i++) {
        const limitToCheck = exclusionLimits[i];
        if (cameraPosition.distanceTo(limitToCheck.value.position) < limitToCheck.value.distance) {
          this.sprite.visible = false;
          return; // We are in an exclusion so we can quit
        }
      }
    }

    // Now we check the status of the other limits
    otherLimits.map(limit => {
      switch (limit.type) {
        case 'DISTANCE': {
          const distance = cameraPosition.distanceTo(this._positionWorld.position);
          if (distance < limit.value) {
            if (!this.sprite.visible) {
              this.sprite.visible = true;
            }
          } else {
            if (this.sprite.visible) {
              this.sprite.visible = false;
            }
          }
          break;
        }
        case 'ALTITUDE':
        // ALTITUDE DEFAULTS TO ABOVE AFTER ADDING BELOW PROPERTY. PREVENTS PRIOR VERSIONS FROM BREAKING
        /* falls through */
        case 'ABOVE_ALTITUDE':
          if (cameraPosition.y > limit.value) {
            if (!this.sprite.visible) {
              this.sprite.visible = true;
            }
          } else {
            if (this.sprite.visible) {
              this.sprite.visible = false;
            }
          }
          break;
        case 'BELOW_ALTITUDE':
          if (cameraPosition.y < limit.value) {
            if (!this.sprite.visible) {
              this.sprite.visible = true;
            }
          } else {
            if (this.sprite.visible) {
              this.sprite.visible = false;
            }
          }
          break;
        default:
          console.warn('Unsupported Marker Limit');
          break;
      }
    });
  }

  setWorldPosition (newVector) {
    // Completly replaces the position with a new position
    if (arguments.length > 1) {
      console.warn('setWorldPosition expects a single object with x y z as keys');
      return;
    }
    if (newVector === undefined) {
      console.warn('Inventum_screenMarker:Unable to set marker position. Bad Vector');
      return;
    }
    this._positionWorld.position.set(newVector.x, newVector.y, newVector.z);
    const tempPosition = { x: newVector.x, y: newVector.y, z: newVector.z };
    this._onWorldPositionUpdate.map((callback, index) => {
      callback(tempPosition);
    });
  }

  modifyScale (newScale) {
    const oldScale = store.globalscale;
    this._positionWorld.position.sub(store.inventumTransform); // Remove the Inventum Transform temp
    this._positionWorld.position.divideScalar(oldScale); // Calculate what the original 100% was
    this._positionWorld.position.multiplyScalar(newScale); // Calculate the new scale
    this._positionWorld.position.add(store.inventumTransform); // Add the Inventum Transform back
    const tPos = this._positionWorld.position;
    this._onWorldPositionUpdate.map((listener, index) => {
      listener({ x: tPos.x, y: tPos.y, z: tPos.z });
    });
  }

  modifyTransform (deltaTransform) {
    // Adds a new transform offset while preserving the original intended position.
    this._positionWorld.position.add(deltaTransform);
    const tPos = this._positionWorld.position;
    this._onWorldPositionUpdate.map((listener, index) => {
      listener({ x: tPos.x, y: tPos.y, z: tPos.z });
    });
  }

  getWorldPosition () {
    /* let tVec = new THREE.Vector3();
    tVec.copy(this._positionWorld.position);
    return tVec; */// REMOVE
    return this._positionWorld.position.clone();
  }

  addWorldPositionCallback (callback) {
    if (typeof (callback) !== 'function') {
      console.warn('Inventum_screenMarker:Argument is not a function. Ignoring');
    } else {
      this._onWorldPositionUpdate.push(callback);
    }
  }

  addScreenPositionCallback (callback) {
    if (typeof (callback) !== 'function') {
      console.warn('Inventum_screenMarker:Argument is not a function. Ignoring');
    } else {
      this._onScreenPositionUpdate.push(callback);
    }
  }

  setScreenPosition (newVector) {
    if (newVector === undefined) {
      console.warn('Inventum_screenMarker:Unable to set marker position. Bad Vector');
      return;
    }
    this.sprite.position.set(newVector.x, newVector.y, 1);
    const tempPosition = { x: newVector.x, y: newVector.y, z: 1 };
    this._onScreenPositionUpdate.map((callback, index) => {
      callback(tempPosition);
    });
  }

  getScreenPosition () {
    return this.sprite.position;
  }

  addClickCallback (callback) {
    if (typeof (callback) !== 'function') {
      console.warn('Inventum_screenMarker:Argument is not a function. Ignoring');
    } else {
      this._onClick.push(callback);
    }
  }

  addVisibilityCallback (callback) {
    if (typeof (callback) !== 'function') {
      console.warn('Inventum_screenMarker:Argument is not a function. Ignoring');
    } else {
      this._onVisibilityChange.push(callback);
    }
  }

  setVisible (shouldBeVisible) {
    // Store a copy of the current visibility state
    const currentVisibleStatus = this.sprite.visible;
    if (shouldBeVisible) {
      this.checkRenderLimit();
      // this.sprite.visible will have been changed by renderLimit. Check to see if it has changed.
      if (this.sprite.visible !== currentVisibleStatus) {
        // Notify interested parties that the visibility state has changed
        this._onVisibilityChange.map((callback) => {
          callback(this.sprite.visible);
        });
      }
    } else { // Marker should *not* be visible
      if (currentVisibleStatus === shouldBeVisible) {
        // Marker is already hidden and nothing needs to be done

      } else {
        // Update marker visibility status to false
        this.sprite.visible = false;
        // Notify interested parties that the visibility state has changed
        this._onVisibilityChange.map((callback) => {
          callback(this.sprite.visible);
        });
      }
    }
  }

  setHover (shouldHover) {
    if (store.followerMode) {
      return;
    }
    if (this.isHovering && !shouldHover) {
      this.sprite.material.map = this.inactiveTexture;
      this.size = this.inactiveSize;
      this.sprite.scale.set(this.size, this.size, this.size);
      this.isHovering = false;
    } else if (!this.isHovering && shouldHover) {
      this.sprite.material.map = this.hoverTexture;
      this.size = this.hoverSize;
      this.sprite.scale.set(this.size, this.size, this.size);
      this.isHovering = true;
    }
    store.requestRender();
  }

  click () {
    if (this._disabled) {
      return;
    }
    this._onClick.map((callback, index) => {
      callback();
    });
    store.requestRender();
  }

  updateStyle (style) {
    // Sprite Size
    if (Object.prototype.hasOwnProperty.call(style, 'hoverSize')) this.hoverSize = style.hoverSize;
    if (Object.prototype.hasOwnProperty.call(style, 'inactiveSize')) this.inactiveSize = style.inactiveSize;

		if (typeof style.showMarker === "boolean") {
			if (style.showMarker) {
				this.sprite.material.opacity = 1.0;
				this._disabled = false;
			} else {
				this.sprite.material.opacity = 0.0;
				this._disabled = true;
			}
		}

    if (this.isHovering) {
      this.size = this.hoverSize;
    } else {
      this.size = this.inactiveSize;
    }
    this.sprite.scale.set(this.size, this.size, this.size);

    // Only recreate textures if needed
    if (Object.prototype.hasOwnProperty.call(style, 'hoverBackground')) {
      if (this.hoverBackground !== style.hoverBackground) {
        this.hoverBackground = style.hoverBackground;
        this.hoverCanvas.setStyle(this.hoverBackground);
        this.sprite.material.needsUpdate = true;
        this.hoverTexture.needsUpdate = true;
      }
    }

    if (Object.prototype.hasOwnProperty.call(style, 'inactiveBackground')) {
      if (this.inactiveBackground !== style.inactiveBackground) {
        this.inactiveBackground = style.inactiveBackground;
        this.inactiveCanvas.setStyle(this.inactiveBackground);
        this.sprite.material.needsUpdate = true;
        this.inactiveTexture.needsUpdate = true;
      }
    }
    store.requestRender();
  }

	remove () {
		markerList.splice(markerList.indexOf(this), 1);
	}
}

controller.create = function (options) {
  var tempMarker = new ScreenMarker(options);
  markerList.push(tempMarker);
  return tempMarker;
};

controller.render = function () {
  if (!renderMarkers) {
    if (allMarkersHidden) {
      // Nothing to do.
      return;
    }
    markerList.map((marker, index) => {
      // Hide all markers
      marker.setVisible(false);
    });
    allMarkersHidden = true;
    return;
  }
  allMarkersHidden = false;

  if (store.markersNeedUpdate) {
    store.markersNeedUpdate = false;
  }

  var frustum = new THREE.Frustum();
  frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(store.camera.projectionMatrix, store.camera.matrixWorldInverse)); // setFromMatrix
  markerList.map((marker, index) => {
    if (!frustum.containsPoint(marker._positionWorld.position)) {
      marker.setVisible(false);
    } else {
      marker.setVisible(true);
      const tempCoords = utility.unproject(marker._positionWorld);
			marker.setScreenPosition(tempCoords);
    }
  });
};

controller.public.toggleAll = function toggleAll () {
  renderMarkers = !renderMarkers;
  store.requestRender();
};

controller.checkSelection = function checkSelection (clientX, clientY, type) {
  // Returns true if the cursor is over a marker false if no marker
	const resolution = resolutionManager.getResolution();
  clientX = clientX - (resolution.width / 2);
  clientY = (clientY - (resolution.height / 2)) * -1;
  var intersectsMarker = false;
  markerList.map((marker, index) => {
    if (!marker.sprite.visible) {
      return;
    }
    const markerPos = marker.sprite.position;
    const halfSize = marker.size / 2;
    const minX = markerPos.x - halfSize;
    const minY = markerPos.y - halfSize;
    const maxX = markerPos.x + halfSize;
    const maxY = markerPos.y + halfSize;
    if (clientX > minX && clientX < maxX && clientY > minY && clientY < maxY) {
      intersectsMarker = true;
      if (type === 'hover') {
        if (Object.keys(hoveredMarker).length > 0) {
          if (marker === hoveredMarker) {
            return;
          }
          hoveredMarker.setHover(false);
        }
        marker.setHover(true);
        hoveredMarker = marker;
      } else if (type === 'click') {
        marker.click();
      }
    }
  });

  if (!intersectsMarker) {
    if (Object.keys(hoveredMarker).length > 0) {
      hoveredMarker.setHover(false);
      hoveredMarker = {};
    }
    return false;
  } else {
    return true;
  }
};

controller.reset = function reset () {
  markerList = [];
  hoveredMarker = {};
  renderMarkers = true;
  allMarkersHidden = false;
};

export { controller as markers };
