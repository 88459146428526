import React from 'react';
import ReactDOM from 'react-dom';
import ViewerControls from './ViewerControls';
import SlideContainer from './SlideContainer';
import LoadingSimple from './LoadingSimple';
import AnimationControls from './AnimationControls';
import GalleryContainer from './GalleryContainer';
import NotificationsContainer from './NotificationsContainer';
import Inventum from '../../../../engine/Inventum.js';
import {connect} from 'react-redux';
import {loadSidebarItems,clearSidebarItems} from '../../../Redux/actions/sidebarItems.js';
import {toggleSidebar,closeSidebar,openSidebar} from '../../../Redux/actions/sidebarUI.js';
import {closeModal} from '../../../Redux/actions/ui.js';

function DragDropLoader() {
  let style = {
    width:"100%",
    height:"100%",
    position:"absolute",
    backgroundColor:"rgba(255,0,0,0.4)",
    pointerEvents:"all",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    color:"#FFFFFF",
    fontSize:"30px",
    fontFamily:"Sans-Serif"
  }

  let handleDrag = (e) => {
    //console.log(e);
    //console.log(e.dataTransfer.items);
    e.preventDefault();
  }

  let handleDrop = (e) => {
    for (var i=0; i < e.dataTransfer.items.length; i++) {
      let item = e.dataTransfer.items[i];
      if (item.kind === "file") {
        let file = item.getAsFile();
        console.log(file.name);
        console.log(file);
        file.text().then(text => {
          console.log('File Read Complete');
          Inventum.models.loadOBJ(text);
        })
      }
    }
    e.preventDefault();
  }

  return (<div style={style} onDrop={handleDrop} onDragOver={handleDrag}>Hello World</div>)
}

class ViewerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLoaded:false,
      splashHidden:false,
      galleryVisible:false,
      galleryMinimized:false,
      sceneKey:0
    };
    this.setLoaded = this.setLoaded.bind(this);
    this.hideSplash = this.hideSplash.bind(this);
    this.toggleSplash = this.toggleSplash.bind(this);
    this.toggleGallery = this.toggleGallery.bind(this);
    this.toggleGalleryMin = this.toggleGalleryMin.bind(this);
  }

  //NOTE: React Update (componentWillReceiveProps)
  componentDidUpdate(nextProps) {
    if (this.props.sceneJSON !== nextProps.sceneJSON) {
      this.props.closeModal();
      Inventum.unload();
      Inventum.load(nextProps.sceneJSON);
      Inventum.onLoadComplete(this.setLoaded);
      this.setState({splashHidden:false,hasLoaded:false,sceneKey:++this.state.sceneKey});
    }
  }

  toggleGallery(force) {
    if (typeof(force) === "boolean") {
      this.setState({galleryVisible:force});
    } else {
      this.setState({galleryVisible:!this.state.galleryVisible});
    }
  }

  toggleGalleryMin(force) {
    if (typeof(force) === "boolean") {
      this.setState({galleryMinimized:force});
    } else {
      this.setState({galleryMinimized:!this.state.galleryMinimized});
    }
  }


  hideSplash(options) {
    this.setState({splashHidden:true});
    if (options !== undefined) {
      if (options.hideSideBar === true) {
        this.props.closeSidebar();
      }
    }
  }

  toggleSplash(force) {
    if (typeof(force) === "boolean") {
      this.setState({splashHidden:force});
    } else {
      this.setState({splashHidden:!this.state.splashHidden});
    }
  }

  setLoaded() {
    let callbackSidebar = function(){this.props.toggleSideBar()};
    callbackSidebar = callbackSidebar.bind(this);
    Inventum.models.registerModelUpdate(this.props.loadSidebarItems);
    this.props.loadSidebarItems(Inventum.models.getGroups());//Seed the state with the current groups.
    let animationCount = Inventum.animations.getAnimations().length;
    this.setState({hasLoaded:true,splashHidden:animationCount == 0 });
  }

  //NOTE: React Update
  componentDidMount(){
    //TODO Inventum.unload();// This should probally be called but Inventum needs to handle it gracefully if it doesn't exist.
    Inventum.load(this.props.sceneJSON);
    Inventum.onLoadComplete(this.setLoaded);
    if (window.innerWidth > 768) {
      setTimeout(this.props.openSidebar,500);
    }


    //Todo add a save prompt if you have unsaved changes
    /*window.addEventListener("beforeunload", (e) => {
      if (0) {
        e.preventDefault();
        e.returnValue = '';
      }
    });*/

  }

  componentWillUnmount() {
    Inventum.unload();
    this.props.clearSidebarItems();
  }
  render() {
    let hideAnimationBar = false;
    if (this.props.hideAnimationBar) {
      hideAnimationBar = this.props.hideAnimationBar;
    }

    if(!this.state.hasLoaded && !this.state.splashHidden) {
      return <LoadingSimple hasLoaded={this.state.hasLoaded} hideSplash={this.hideSplash} />;
    }else {
      //          <DragDropLoader />
      return (
        <div className="ViewerPage">
          {(!this.state.splashHidden && !this.props.noAutoplay) ? <LoadingSimple hasLoaded={this.state.hasLoaded} hideSplash={this.hideSplash} closeSidebar={this.props.closeSidebar} /> : null}
          <ViewerControls />
          <SlideContainer />
          <NotificationsContainer />
          {hideAnimationBar ? null : <AnimationControls uiHideMode={this.props.uiHideMode} sceneKey={this.state.sceneKey} reviewMode={this.props.reviewMode} siteAdmin={this.props.siteAdmin} toggleGallery={this.toggleGallery} toggleSideBar={this.props.toggleSidebar}/> }
          <GalleryContainer uiHideMode={this.props.uiHideMode} sceneKey={this.state.sceneKey} reviewMode={this.props.reviewMode} siteAdmin={this.props.siteAdmin} galleryVisible={this.state.galleryVisible} toggleGallery={this.toggleGallery} galleryMinimized={this.state.galleryMinimized} toggleGalleryMin={this.toggleGalleryMin} />
        </div>
      )
    }
  }
}

let mapStateToProps = (state) => ({
  siteAdmin:(state.user.siteAdmin) ? true : false,
  uiHideMode: state.ui.hideMode
})


let mapDispatchToProps = dispatch => ({
  loadSidebarItems:(items) => {
    dispatch(loadSidebarItems(items));
  },
  clearSidebarItems:() => {
    dispatch(clearSidebarItems());
  },
  toggleSidebar:(force) => {
    dispatch(toggleSidebar(force));
  },
  closeSidebar:() => {
    dispatch(closeSidebar());
  },
  openSidebar:() => {
    dispatch(openSidebar());
  },
  closeModal:() => {
    dispatch(closeModal());
  }
})

const ViewerPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps)(ViewerPage)

export default ViewerPageContainer;
