import * as THREE from 'three';
import { store } from '../core/store.js';
import { markers } from './screenMarker.js';
import { LabelCanvasTexture } from './LabelCanvasTexture.js';

class LabelBackboard {
  constructor (options) {
    this.canvasTexture = new LabelCanvasTexture(options.content.text, {
      color: options.content.style.color,
      backgroundColor: options.content.style.backgroundColor,
      padding: options.content.style.padding
    });

    const canvas = this.canvasTexture.getCanvas();

    this.labelScale = 4;
    var labelScale = this.labelScale;
    const width = canvas.width / labelScale;
    const height = canvas.height / labelScale;

    this.labelTexture = new THREE.Texture(canvas);
    this.labelTexture.minFilter = THREE.LinearFilter;
    this.labelTexture.needsUpdate = true;

    var material = new THREE.SpriteMaterial({ map: this.labelTexture });
    material.opacity = 0.8;
    material.transparent = true;
    this.sprite = new THREE.Sprite(material);
    this.sprite.scale.set(width, height, 1);
  }

  render () {
    const canvas = this.canvasTexture.getCanvas();
    const width = canvas.width / this.labelScale;
    const height = canvas.height / this.labelScale;

    this.labelTexture.needsUpdate = true;
    this.sprite.scale.set(width, height, 1);
  }

  replaceText (textArray) {
    this.canvasTexture.replaceText(textArray);
    this.render();
  }

  addText (textLine) {
    this.canvasTexture.addText(textLine);
    this.render();
  }

  editText (textID, textLine) {
    this.canvasTexture.editText(textID, textLine);
    this.render();
  }

  setStyle (newStyle) {
    this.canvasTexture.setStyle(newStyle);
    this.render();
  }
}

// ScreenLabels and screenMarkers are very tightly linked together.
// ScreenLabels depend on screenMarkers for many things such as getting their position
// Because of this, We are unable to not create a screenMarker for a screenLabel. (The WorldLabels can have the screenMarkers turned off as they are their own 3D objects)
// To not show a screen marker we check if showMarker is set to false (same api as worldLabel) then we set the opacity of the marker to 0.0 to hide it

/* window.testSprite = () => {
  console.log('Hello from test sprite');
  var textureLoader = new THREE.TextureLoader();
  textureLoader.load( "https://placekitten.com/1920/1080", (texture) => {
    var material = new THREE.SpriteMaterial({map:texture});
    var sprite = new THREE.Sprite(material);
    let height = 9 * (window.innerWidth / 16);
    sprite.scale.set(window.innerWidth,height);
    console.log(sprite.position);
    store.sceneHUD.add(sprite);
  });
} */

class ScreenLabel {
  constructor (options) {
    this.userHidden = false;
    this.backboard = new LabelBackboard(options);
    store.sceneHUD.add(this.backboard.sprite);
    this.offsetX = options.offsetX;
    this.offsetY = options.offsetY;
    this.id = options.id;
    this.type = options.type;
    this.content = options.content;
		this.renderLimits = [];
		if (Array.isArray(options.renderLimits) && options.renderLimits.length > 0) {
			this.renderLimits = options.renderLimits;
		}
    this.visibleStart = true;
    this.markerHoverSize = Object.prototype.hasOwnProperty.call(options, 'markerHoverSize') ? options.markerHoverSize : 15;
    this.markerInactiveSize = Object.prototype.hasOwnProperty.call(options, 'markerInactiveSize') ? options.markerInactiveSize : 10;
    this.markerInactiveBackground = Object.prototype.hasOwnProperty.call(options, 'markerInactiveBackground') ? options.markerInactiveBackground : 'rgba(255,255,255,0.7)';
    this.markerHoverBackground = Object.prototype.hasOwnProperty.call(options, 'markerHoverBackground') ? options.markerHoverBackground : 'rgba(255,255,255,0.9)';

		// Controls whether the label can be included in SceneStates
		// Historically ScreenLabels (2D Labels) were excluded.
		// Defaults to false to preserve backwards compatability.
		// New Screen Labels will automatically have options.isSceneStateable = true
		this.isSceneStateable = Object.prototype.hasOwnProperty.call(options, 'isSceneStateable') ? options.isSceneStateable : false;

    let tempPosition = {};
    if (Object.prototype.hasOwnProperty.call(options, 'anchorPosition')) {
      tempPosition = options.anchorPosition;
    } else {
      tempPosition = new THREE.Vector3(0, 0, 0);
    }

    this.handlePositionUpdate = this.handlePositionUpdate.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);

    this.showMarker = true;
    let opacity = 1.0;

    if (typeof (options.showMarker) === 'boolean') {
      this.showMarker = options.showMarker;
    }

    if (!this.showMarker) {
      opacity = 0.0;
    }

    const markerOptions = {
      hoverSize: this.markerHoverSize,
      inactiveSize: this.markerInactiveSize,
      opacity: opacity,
      inactiveBackground: this.markerInactiveBackground,
      hoverBackground: this.markerHoverBackground
    };

    this.marker = markers.create(markerOptions);
    this.marker.setWorldPosition(tempPosition);

		// Only allow limits if isSceneStateable = false;
		if (!this.isSceneStateable) {
			this.renderLimits.map((limit) => {
				this.marker.addRenderLimit(limit);
			});
		}

    this.marker.addScreenPositionCallback(this.handlePositionUpdate);
    this.marker.addVisibilityCallback(this.handleVisibilityChange);

    if (this.showMarker) {
      this.marker.addClickCallback(this.handleClick);
    }

		if (Object.prototype.hasOwnProperty.call(options, 'visibleStart')) {
      if (options.visibleStart === false) {
        this.visibleStart = false;
        this.backboard.sprite.visible = false;
        this.userHidden = true;
      }
    }
  }

  handleClick () {
    if (store.followerMode) {
      return;
    }

		if (this.userHidden) {
      this.backboard.sprite.visible = true;
      this.userHidden = false;
    } else {
      this.backboard.sprite.visible = false;
      this.userHidden = true;
    }
  }

  handlePositionUpdate (newPos) {
		// If label is not visible but should be visible we need to update it.
		if (!this.userHidden && !this.backboard.sprite.visible && this.marker.sprite.visible) {
			this.backboard.sprite.visible = true;
			store.requestRender();
		}

		const tempSprite = this.backboard.sprite;
    tempSprite.position.set(newPos.x + this.offsetX, newPos.y + this.offsetY, 1);
  }

  setMarkerOffset (offsetX, offsetY) {
    offsetX = parseInt(offsetX);
    offsetY = parseInt(offsetY);

    if (isNaN(offsetX) || isNaN(offsetY)) {
      return;
    }

    this.offsetX = offsetX;
    this.offsetY = offsetY;
    this.handlePositionUpdate(this.marker.getScreenPosition());
    store.requestRender();
  }

	toggleVisible () {
		// This is dumb and confusing
		// (userHidden = true) ==== (visible = false)
		// setVisible flips the provided value.
		// So if you want to set visible to true it sets userHidden to false
		// So normally we would do userHidden = !userHidden
		// But since this function will flip it anyway, we should just provide the current userHidden value which will get flipped
		let isVisible = !this.userHidden; // If it is currently visible

		this.setVisible(!isVisible);
	}

	isVisible () {
		return !this.userHidden;
	}

  setVisible (value) {
		if (value === undefined) return;
		// Toggles the userHidden property.
		// If userHidden == true then it won't render even if marker is in frustrum
		this.userHidden = !value;
		if (this.userHidden) {
			// Hide the sprite if userHidden has just been set to true.
			this.backboard.sprite.visible = false;
		}

		// Don't unhide the backboard sprite as it might be offscreen.
		// We unhide it in the handlePositionUpdate function where we can confirm it is onscreen.

		store.requestRender();
  }

  handleVisibilityChange (isVisible, force) {
    if (!isVisible && this.backboard.sprite.visible) {
      this.backboard.sprite.visible = false;
    } else if (isVisible && !this.backboard.sprite.visible) {
      if (!this.userHidden || force) {
        this.backboard.sprite.visible = true;
      }
    }
  }

  remove () {
    store.sceneHUD.remove(this.backboard.sprite);
    store.sceneHUD.remove(this.marker.sprite);
		this.marker.remove();
  }

  addText (textline) {
    this.backboard.addText(textline);
  }

  editText (textID, textline) {
    this.backboard.editText(textID, textline);
  }

  setStyle (style) {
    this.content.style = Object.assign({}, style);
    this.backboard.setStyle(style);
  }

  setMarkerStyle (style) {
    if (Object.prototype.hasOwnProperty.call(style, 'showMarker')) this.showMarker = style.showMarker;
    if (Object.prototype.hasOwnProperty.call(style, 'hoverSize')) this.markerHoverSize = style.hoverSize;
    if (Object.prototype.hasOwnProperty.call(style, 'inactiveSize')) this.markerInactiveSize = style.inactiveSize;
    if (Object.prototype.hasOwnProperty.call(style, 'inactiveBackground')) this.markerInactiveBackground = style.inactiveBackground;
    if (Object.prototype.hasOwnProperty.call(style, 'hoverBackground')) this.markerHoverBackground = style.hoverBackground;
    this.marker.updateStyle(style);
  }

  addRenderLimit (limit) {
		if (this.isSceneStateable) return;
    this.renderLimits.push(limit);
    this.marker.addRenderLimit(limit);
  }

  deleteRenderLimit (limitID) {
    this.renderLimits.splice(limitID, 1);
    this.marker.deleteRenderLimit(limitID);
  }

  updateRenderLimit (limitID, updatedValue) {
		if (this.isSceneStateable) return;
    this.renderLimits[limitID].value = updatedValue;
    this.marker.updateRenderLimit(limitID, updatedValue);
  }

	toggleSceneStatable () {
		this.isSceneStateable = !this.isSceneStateable;
		if (this.isSceneStateable) {
			// If isSceneStateable we need to remove the existing marker limits
			this.marker.deleteAllRenderLimits();
		}else {
			if (this.renderLimits.length > 0) {
				this.renderLimits.map(limit => {
					// Add them back (If they exist)
					this.marker.addRenderLimit(limit);
				})
			}
		}

	}

  serialize () {
    const worldPos = this.marker.getWorldPosition();
    const tPos = { x: worldPos.x, y: worldPos.y, z: worldPos.z };

		let renderLimits = [];

		if (!this.isSceneStateable && this.renderLimits.length > 0) {
			renderLimits = JSON.parse(JSON.stringify(this.renderLimits));
		}

    return {
      id: this.id,
      offsetX: this.offsetX,
      offsetY: this.offsetY,
      type: this.type,
      content: JSON.parse(JSON.stringify(this.content)),
      renderLimits: renderLimits,
      anchorPosition: tPos,
      visibleStart: this.visibleStart,
      markerHoverSize: this.markerHoverSize,
      markerInactiveSize: this.markerInactiveSize,
      markerInactiveBackground: this.markerInactiveBackground,
      markerHoverBackground: this.markerHoverBackground,
      showMarker: this.showMarker,
			isSceneStateable: this.isSceneStateable
    };
  }
}

export { ScreenLabel };
