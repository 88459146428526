import React, { useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { checkUserAuthenticated } from "../Redux/actions/user.js";
import HomeMap from "./Map/HomeMap.js";
import CompanyMap from "./Map/CompanyMap.js";
import MeetMenu from "./Meet/Menu.js";
import MeetAdmin from "./Meet/Admin.js";
import MeetViewer from "./Meet/Viewer.js";
import CanvasEditor from "./Canvas/Editor.js";
import { ThemeProvider } from "@mui/material/styles";
import { getTheme } from "../theme.js";
import NotFound from "./Errors/404.js";
import WebGLError from "./Errors/WebGLError.js";
import SiteControls from "./Common/SiteControls.js";
let sass = require("./scss/main.scss");
import InventumPage from "./InventumViewer/InventumPage.js";

const isDarkMode = false;

const App = ({ checkUserAuthenticated }) => {
  // Helper function to parse JWT
  const parseJWT = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch {
      return null;
    }
  };

  // Helper function to get GPU info
  const getGPUInfo = () => {
    const gl = document.createElement('canvas').getContext('webgl');
    if (!gl) {
      return {
        error: "No WebGL"
      };
    }
    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
    return debugInfo ? {
      vendor: gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL),
      renderer: gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
    } : {
      error: "No WebGL Info"
    };
  };

  // The handleError function to use as a global error handler
  const handleError = (msg, src, lineno, colno, error) => {
    const tokenPayload = parseJWT(window.localStorage.getItem('inventumToken'));
    const errReport = {
      user: {
        id: tokenPayload ? tokenPayload.id : null
      },
      error: {
        message: msg,
        source: src,
        lineNumber: lineno,
        columnNumber: colno,
        stack: error?.stack || null
      },
      page: {
        href: window.location.href
      },
      device: {
        userAgent: window.navigator.userAgent,
        deviceMemory: window.navigator.deviceMemory,
        threads: window.navigator.hardwareConcurrency,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        platform: window.navigator.platform,
        gpuInfo: getGPUInfo()
      },
      timestamp: Date.now(),
    };

    var xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://api.inventum3d.com/logerror', true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify({ report: errReport }));
  };

  useEffect(() => {
    checkUserAuthenticated();
    window.onerror = handleError;
    return () => {
      window.onerror = null;
    };
  }, [checkUserAuthenticated]);
 // TODO: link up light vs dark mode with redux and make switch for it
  const theme = getTheme(isDarkMode ? "dark" : "light");

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route exact path="/" render={()=>(<SiteControls><HomeMap /></SiteControls>)} />
        {<Route exact path="/canvas" component={()=>(<SiteControls><CanvasEditor /></SiteControls>)} />}
        <Route exact path="/app" render={()=>(<SiteControls><HomeMap /></SiteControls>)} />
        <Route exact path="/register" render={()=>(<SiteControls><HomeMap /></SiteControls>)} />
        <Route exact path="/resetpassword" render={()=>(<SiteControls><HomeMap /></SiteControls>)} />
        <Route exact path="/resetpassword/:resetToken" render={()=>(<SiteControls><HomeMap /></SiteControls>)} />
        <Route exact path="/c/:companySlug" render={()=>(<SiteControls><CompanyMap /></SiteControls>)} />
        <Route exact path="/g/:companyID" render={()=>(<SiteControls><CompanyMap /></SiteControls>)} />
        <Route exact path="/c/:companySlug/:projectName" render={()=>(<SiteControls><InventumPage /></SiteControls>)} />
        <Route exact path="/g/:companyID/:projectName" render={()=>(<SiteControls><InventumPage /></SiteControls>)}  />
        <Route exact path="/c/:companySlug/:projectName/review" render={()=>(<SiteControls><InventumPage /></SiteControls>)}  />
        <Route exact path="/g/:companyID/:projectName/review" render={()=>(<SiteControls><InventumPage /></SiteControls>)}  />
        <Route exact path="/meet" component={MeetMenu} />
        <Route exact path="/meet/admin" component={MeetAdmin} />
        <Route exact path="/meet/:meetCode" render={()=>(<SiteControls><MeetViewer /></SiteControls>)} />
        <Route exact path="/webglnotsupported" component={WebGLError} />
        <Route component={NotFound} />
      </Switch>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({checkUserAuthenticated: () => {dispatch(checkUserAuthenticated())}});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
