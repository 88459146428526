import React from 'react';
import axios from '../../axios-config.js';
const queryString = require('query-string');
var INVENTUM_SCENE_TEMPLATE = require('./Template.json');
//var moment = require('moment-timezone');

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Link, Route, Switch} from 'react-router-dom';
import {setTitle,setSubtitle,setLayerContentMode,setWebsite} from '../../Redux/actions/sidebarUI.js';
import {clearSidebarItems} from '../../Redux/actions/sidebarItems.js';
import {setEditMode, setActiveProject, toggleUIHideAll, openModal, closeModal} from '../../Redux/actions/ui.js';
import {setActiveCompanyID, setActiveProjectID, setActivePageType} from '../../Redux/actions/activePage.js';
import {clearActiveLayers} from '../../Redux/actions/mapTileLayers.js';
import {removeAllFilters} from '../../Redux/actions/projectsFilters.js';
import AnnotateContainer from '../Meet/Annotate.js';

var hash = require('object-hash');

import SceneTextEditor from './Components/SceneTextEditor.js';
import ViewerPage from './Components/ViewerPage.js';
import NotFound from '../Errors/404.js';

let PageLoader = (props) => {
  return (
    <div style={{width:"100%", height:"100%", position:"absolute",top:"0px",left:"0px",background:"#FFF"}}></div>
  )
}

class SaveChangesButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleSaveKey = this.handleSaveKey.bind(this);
    this.handleUnload = this.handleUnload.bind(this);
  }

  //Note: React Update
  componentDidMount() {
    document.addEventListener('keydown', this.handleSaveKey)
    window.addEventListener('beforeunload', this.handleUnload);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleSaveKey);
    window.removeEventListener('beforeunload', this.handleUnload);
  }

  handleUnload (e) {
    e.preventDefault();
    if (window.location.href.includes('localhost')) {
      delete e.returnValue;
      return;
    }

    if (!Inventum.scene.isUnsaved()) {
      delete e.returnValue;
      return;
    }
    e.returnValue = '';
  }

  handleSaveKey(e) {
    if (e.ctrlKey && e.keyCode === 83) {
      e.preventDefault();
      this.props.updateReview();
    }
  }

  render() {
    return (
      <div className="SceneControlsButtonLeft" onClick={this.props.updateReview}>Save Current Changes</div>
    )
  }
}

let VersionSelector = (props) => {
  if (props.siteAdmin || props.userBelongsToCompany) {
    return (
      <div className={"projectControlsContainer" + (props.uiHideMode === 'ALL' || props.uiHideMode === 'ANIMATION' ? ' hidden' : '')}>
        <div className="versionInfoBox" title={`Last Updated: ${props.lastUpdated}`}>{props.reviewMode ? "Review" : "Master"} <span style={{"fontSize":"13px"}}> {props.lastUpdated}</span></div>
        <div className="versionSelectorBox">
          <div className="changeVersionButton" onClick={props.toggleReviewMode}>Switch to {props.reviewMode ? "Master" : "Review"}</div>
          {props.siteAdmin ? <AdminEditor {...props} /> : <ClientFeedback />}
        </div>
      </div>
    )
  }else {
    return null
  }
}

let AdminEditor = (props) => {
  if (props.reviewMode) {
    return (
      <div>
        <SaveChangesButton updateReview={props.updateReview} />
        {props.siteStaffLevel == 1 ? <div className="publishReviewButton" onClick={props.publishReview}>Publish Review to Master</div> : null}
        <div className="publishReviewButton" onClick={props.toggleHistoryModal}>Project History</div>
        <div className="publishReviewButton" onClick={props.createShareableURL}>Create Shareable URL</div>
        <div className="publishReviewButton" onClick={props.createMeeting}>Create Meeting</div>
      </div>
    )
  }

  if (props.siteStaffLevel == 1) { //Only show Clone Master to Review button if user is a high level staff Level 1)
    return (
      <div>
        <div className="publishReviewButton" onClick={props.cloneMastertoReview}>Clone Master to Review</div>
        <div className="publishReviewButton" onClick={props.createShareableURL}>Create Shareable URL</div>
        <div className="publishReviewButton" onClick={props.createMeeting}>Create Meeting</div>
      </div>
    )
  }else {
    return (
      <div>
        <div className="publishReviewButton">Create Shareable URL</div>
      </div>
    )
  }

}

let ClientFeedback = (props) => {
	// Placeholder for Client only options
	return null;
  return (
    <div className="SceneControlsButtonLeft" onClick={()=>{alert('Coming soon...')}}>Request Update/Feedback</div>
  )
}

let ViewerHOC = (props) => {
  if (!props.hasLoaded && !props.notFound) {
    return <PageLoader />
  }else if(props.notFound) {
    return <NotFound />
  }
  return <ViewerPage reviewMode={props.reviewMode} sceneJSON={props.sceneJSON} />
}

function DragNotification(props) {
  let style = {
    width:"100%",
    height:"100%",
    position:"absolute",
    display:"flex",
    fontFamily:"Sans-Serif",
    color:"#FFFFFF",
    fontSize:"30px",
    backgroundColor:"rgba(255,0,0,0.5)",
    pointerEvents:"none",
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  }
  return (
    <div style={style}>
    {props.fileLoading ? "Loading Files..." : "Drop to Import"}
    </div>
  )
}

class InventumPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sceneJSON:{},
      lastUpdated:null,
      projectID:null,
      companyID:null,
      shareToken:null,
      hasLoaded:false,
      notFound:false,
      reviewMode:false,
      fileDragging:false,
      fileDragLoading:false
    }
    this.updateSceneJSON = this.updateSceneJSON.bind(this);
    this.parseURL = this.parseURL.bind(this);
    this.loadScene = this.loadScene.bind(this);
    this.toggleReviewMode = this.toggleReviewMode.bind(this);
    this.cloneMastertoReview = this.cloneMastertoReview.bind(this);
    this.publishReview = this.publishReview.bind(this);
    this.supportsWebGL = this.supportsWebGL.bind(this);
    this.handleDocumentKeypress = this.handleDocumentKeypress.bind(this);
    this.createShareableURL = this.createShareableURL.bind(this);
    this.getProjectData = this.getProjectData.bind(this);
  }

  //NOTE: React Update
  componentDidMount () {
    this.parseURL();

    if (!this.supportsWebGL()) {
      this.props.history.push('/webglnotsupported');
      return;
    }
    this.props.setPageMode('3D');

    //NOTE
    //Loading a map tile layer sets it to active under state.mapTileLayers.loaded as well as placing it in the sideBarItems
    //As we are clearing sideBarItems to load the 3D data, we need to set the layer to inactive so they don't get inconsistent
    //In the future it'd be nice if when you go back to the 2D map view it checks .loaded to see what layers need to be readded back to the sideBarItems
    this.props.clearActiveLayers();
    this.props.clearSidebarItems();
    this.props.clearProjectFilters();

    document.addEventListener('keydown',this.handleDocumentKeypress)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown',this.handleDocumentKeypress)
  }

  handleDocumentKeypress(e) {
    if (e.altKey && e.keyCode === 72) {
      this.props.toggleUIHideAll();
    }
    if (e.altKey && e.keyCode == 71) {
      Inventum.world.toggleHide();
    }
  }

  supportsWebGL() {
    try {
        var tempCanvas = document.createElement("canvas");
        return !! ( window.WebGLRenderingContext && ( tempCanvas.getContext( 'webgl' ) || tempCanvas.getContext( 'experimental-webgl' ) ) );
    } catch(e) {
        return false;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.path !== prevProps.match.path) {
      this.loadScene();
    }
  }

  updateSceneJSON() {
    let apiURL = `https://api.inventum3D.com/project/${this.state.projectID}/scene/update`;
    axios.post(apiURL, {name:"New Scene",data: Inventum.generateSceneJSON()})
    .then((result) => {
      Inventum.notifications.addNotification({content:"Updated Review Version",displayTime:3000});
      Inventum.scene.clearUnsaved();
    })
    .catch((error) => {
      Inventum.notifications.addNotification({content:"Something went wrong. Check the console.",displayTime:7000});
      console.log('Error Posting New Scene');
      console.log(error);
    })
  }

  cloneMastertoReview() {
    if (window.confirm('This will overwrite the current Review Version. Are you sure?')) {
      let apiURL = `https://api.inventum3D.com/project/${this.state.projectID}/scene/clone`;
      axios.post(apiURL,{})
      .then((result) => {
        Inventum.notifications.addNotification({content:"Review cloned from Master",displayTime:5000});
      })
      .catch((error) => {
        Inventum.notifications.addNotification({content:"Something went wrong. Check the console.",displayTime:5000});
        console.log(error);
      })
    }
  }

  publishReview() {
    if (window.confirm('This will set the Master version to the current review version. Are you sure?')) {
      let apiURL = `https://api.inventum3D.com/project/${this.state.projectID}/scene/publish`;
      axios.post(apiURL,{})
      .then((result) => {
        Inventum.notifications.addNotification({content:"Published Review Version!",displayTime:5000});
      })
      .catch((error) => {
        Inventum.notifications.addNotification({content:"Something went wrong. Check the console.",displayTime:5000});
        console.log(error);
      })
    }
  }

  toggleReviewMode() {
    //Handles the toggling of review mode and updating the URL correctly
    let urlQueryString = queryString.parse(this.props.location.search);
    let updatedURL = "";

    this.setState({hasLoaded:false, reviewMode:!this.state.reviewMode}, () => {
      if (!this.state.reviewMode) {
        if (this.props.match.path.includes('/review')) {
          updatedURL = this.props.match.url.replace('/review','');
          if (urlQueryString.st) {
            updatedURL += `?st=${urlQueryString.st}`;
          }
          this.props.history.push(updatedURL);//Remove the '/review' part of the URL
        }
      }else {
        if (!this.props.match.path.includes('/review')) {
          updatedURL = this.props.match.url;
          updatedURL[updatedURL.length - 1] == "/" ? updatedURL = updatedURL + 'review' : updatedURL = updatedURL + '/review'; //Checks if last character is a slash and adds review or /review depending.
          if (urlQueryString.st) {
            updatedURL += `?st=${urlQueryString.st}`;
          }
          this.props.history.push(updatedURL)
        }
      }
    });
  }

  getProjectData() {
    const path = this.props.match.path;
    let apiURL = "";

    if (path.includes("/c/")) {
      const slug = this.props.match.params.companySlug;
      apiURL = `https://api.inventum3D.com/company/?slug=${slug}`;
    } else if (path.includes("/g/")) {
      const companyID = this.props.match.params.companyID;
      apiURL = `https://api.inventum3D.com/company/${companyID}`;
    }

    const projectName = this.props.match.params.projectName;

    let shareToken = null;
    //Checks if current URL has a share token (st) queryString. If so attach it for the API request
    let urlQueryString = queryString.parse(this.props.location.search);
    if (urlQueryString.st) {
      shareToken = urlQueryString.st;
      if (apiURL.includes('?')) {
        apiURL += `&st=${shareToken}`
      }else {
        apiURL += `?st=${shareToken}`;
      }
    }

    axios.get(apiURL)
    .then((companyResult) => {
      var projectFound = false;
      var projectID = null;
      var companyName = companyResult.data.name;
      var companyID = companyResult.data.id;

      var projectTrueName = "";

      companyResult.data.projects.map((project) => {
        if (project.project_slug.toLowerCase() === projectName.toLowerCase()) {
          projectFound = true;
          projectID = project.project_id;
          this.props.setActiveProject(projectID);
          this.props.setActiveCompanyID(companyID);
          projectTrueName = project.project_name;
        }
      })

      if (projectFound) {
        this.props.setTitle(projectTrueName.charAt(0).toUpperCase() + projectTrueName.slice(1));//FIXME Get this from the API request when hooking up scenes properlly
        this.props.setSubtitle(companyName.charAt(0).toUpperCase() + companyName.slice(1));
        if (companyResult.data.website) {
          this.props.setWebsite(companyResult.data.website);
        }
        this.setState({projectID, shareToken, companyID}, this.loadScene)//Jump to loadScene();
      } else {
        this.setState({notFound:true});
      }
    })
    .catch((error) => {
      console.log(error);
      this.setState({notFound:true});
    });
  }

  parseURL() {
    let path = this.props.match.path;
    //If URL has /c/ or /g/ then it's an existing Inventum Project we need to fetch from the API
    if (path.includes("/c/") || path.includes("/g/")) {
      this.getProjectData();
    } else if (this.props.match.path.includes("/editor")) {
      //Otherwise it's a new Inventum Scene
      console.log('NEW Inventum Scene 🥳');
      this.props.setTitle('Unsaved Project');//FIXME Get this from the API request when hooking up scenes properlly
      this.setState({hasLoaded:true, sceneJSON:JSON.parse(JSON.stringify(INVENTUM_SCENE_TEMPLATE)), reviewMode:true});
      this.props.setEditMode(true);
      return;
    }
  }

  loadScene() {
    //Clear Sidebar Items
    this.props.clearSidebarItems();
    let urlReview = `https://api.inventum3D.com/project/${this.state.projectID}/scene/review`;
    let urlMaster = `https://api.inventum3D.com/project/${this.state.projectID}/scene/master`;

    if (this.state.shareToken) {
      urlReview += `?st=${this.state.shareToken}`;
      urlMaster += `?st=${this.state.shareToken}`;
    }

    let reviewMode = this.state.reviewMode;
    if (this.props.match.path.includes('/review')) {
      reviewMode = true;
    }

    //If review mode first try and fetch the review scene. Inverse if master
    axios.get(reviewMode ? urlReview : urlMaster)
    .then((sceneResult) => {
      var tempDate = new Date(sceneResult.data.updated_at);
      let formatDate = (d) => {
        let nth = (n) => {return["st","nd","rd"][((n+90)%100-10)%10-1]||"th"};
        let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        return `${d.getDate() + nth(d.getDate())} ${months[d.getMonth()]} ${d.getFullYear()} ${d.toLocaleTimeString()}`
      }
      this.setState({sceneJSON:sceneResult.data.data, reviewMode:reviewMode, hasLoaded:true, lastUpdated:formatDate(tempDate)}, () => { //Moment is bloated as hell. moment(sceneResult.data.updated_at).tz('Australia/Perth').format('YYYY/MM/DD HH:mm')
        //Something has loaded correctly. We need to update the global EditMode if ReviewMode is enabled
        this.props.setEditMode(this.state.reviewMode);
        this.props.setActivePageType(this.state.reviewMode ? "INVENTUM_REVIEW" : "INVENTUM_MASTER");
      });
    })
    .catch((error) => {
      //if review mode then get the MASTER. Else if is NOT review mode then get the REVIEW (do the opposite because the primary has already failed above.)
      axios.get(reviewMode ? urlMaster : urlReview)
      .then((sceneResult) => {
        //We have to update the state.reviewMode as it made not be correct due to our checking of the url above.
        //This is a bit inefficent.
        this.setState({reviewMode:reviewMode},() => {
          //Once we know the state is correct we can call this.toggleReviewMode to change the URL and inverse it.
          //This will retrigger this function once componentDidUpdate detects the URL has changed.
          this.toggleReviewMode();
        })
      })
      .catch((error) => {
        //If that fails then 404
        this.setState({notFound:true});
        return;
      })
    })
  }

  createShareableURL() {
    axios.post(`https://api.inventum3D.com/project/${this.state.projectID}/share`)
    .then((response) => {
      let shareURL = `https://inventum3D.com${this.props.match.url}?st=${response.data.token}`;
      navigator.clipboard.writeText(shareURL)
      .then(() => {
        Inventum.notifications.addNotification({content:"Copied shareURL to clipboard",displayTime:10000})
      })
      .catch((e) => {
        alert(shareURL);
      })
    })
  }

  componentWillDismount() {
    this.props.clearSidebarItems();
    this.setState({sceneJSON:{},hasLoaded:false},()=>{
      console.log('Unmounted Cleanly');
    });
  }

  render() {
    let hasLoaded = this.state.hasLoaded;
    let notFound = this.state.notFound;
    let sceneJSON = this.state.sceneJSON;
    let userBelongsToCompany = false;
    if (this.props.userCompanies) {
      this.props.userCompanies.map((company) => {
        if (company.id == this.state.companyID) {
          userBelongsToCompany = true;
        }
      })
    }

		/*
    let handleFileDrop = (e) => {
		// Add to <canvas> element
		// onDragEnter={(e)=>{this.setState({fileDragging:true})}} onDragLeave={()=>{this.setState({fileDragging:false})}} onDrop={handleFileDrop} onDragOver={(e)=>{e.preventDefault();}}
      e.preventDefault();
      e.persist();
      this.setState({ fileDragLoading: true}, () => {
        const SUPPORTED_FILE_EXTENSIONS = ['obj','fbx','drc'];
        let filesToLoad = e.dataTransfer.items.length;
        for (var i = 0; i < e.dataTransfer.items.length; i++) {
          let item = e.dataTransfer.items[i];
          if (item.kind === "file") {
            let file = item.getAsFile();
            let filename = file.name;
            let fileExtension = filename.split('.').pop().toLowerCase();
            if (!SUPPORTED_FILE_EXTENSIONS.includes(fileExtension)) {
              console.log('Unsupported File!');
              filesToLoad--;
              if (filesToLoad == 0) {
                this.setState({fileDragging:false, fileDragLoading:false});
              }
              return;
            }

            //Plain Text Files
            if (fileExtension == "obj") {
              file.text().then(text => {
                Inventum.models.loadDragged(fileExtension, text, filename);
                filesToLoad--
                if (filesToLoad == 0) {
                  this.setState({fileDragging:false, fileDragLoading:false});
                }
              })
              //Binary Files
            }else if (fileExtension == "drc" || fileExtension == "fbx") {
              file.arrayBuffer().then(buffer => {
                Inventum.models.loadDragged(fileExtension, buffer, filename);
                filesToLoad--
                if (filesToLoad == 0) {
                  this.setState({fileDragging:false, fileDragLoading:false});
                }
              });
            }

          }
        }
      })
    }*/

		const wrapperStyle = {
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      'width': '100%',
      'height': '100%',
      'position': 'absolute',
      'pointerEvents': 'none'
    }

		return (
      <div style={wrapperStyle}>
        <VersionSelector createMeeting={()=>{this.props.openModal('MeetingCreator')}} createShareableURL={this.createShareableURL} uiHideMode={this.props.uiHideMode} toggleHistoryModal={()=>{this.props.openModal('InventumProjectHistory')}} lastUpdated={this.state.lastUpdated} updateReview={this.updateSceneJSON} cloneMastertoReview={this.cloneMastertoReview} publishReview={this.publishReview} reviewMode={this.state.reviewMode} siteAdmin={this.props.siteAdmin} siteStaffLevel={this.props.siteStaffLevel} userBelongsToCompany={userBelongsToCompany} toggleReviewMode={this.toggleReviewMode} />
        {this.state.fileDragging ? <DragNotification fileLoading={this.state.fileDragLoading} /> : null }
        {<canvas style={{ pointerEvents: 'all', position: 'relative' }} id="Inventum3D" />}
        <ViewerHOC hasLoaded={hasLoaded} notFound={notFound} sceneJSON={sceneJSON} reviewMode={this.state.reviewMode} />
      </div>
    )
  }
}

let mapStateToProps = (state) => ({
  token:state.user.token,
  userCompanies:state.user.companies,
  siteAdmin:(state.user.siteAdmin) ? true : false,
  siteStaffLevel:state.user.siteStaffLevel,
  uiHideMode: state.ui.hideMode,
	uiDrawingPanelVisible: state.ui.drawingPanelVisible
})

let mapDispatchToProps = dispatch => ({
  setPageMode:(mode) => {
    dispatch(setLayerContentMode(mode))
  },
  setTitle:(title) => {
    dispatch(setTitle(title))
  },
  setSubtitle:(subtitle) => {
    dispatch(setSubtitle(subtitle))
  },
  setWebsite:(website) => {
    dispatch(setWebsite(website))
  },
  clearActiveLayers:() => {
    dispatch(clearActiveLayers())
  },
  setEditMode:(bool) => {
    dispatch(setEditMode(bool))
  },
  clearSidebarItems:() => {
    dispatch(clearSidebarItems())
  },
  setActiveProject:(projectID) => {
    dispatch(setActiveProject(projectID)) //This one should probally be removed
    dispatch(setActiveProjectID(projectID))
  },
  setActiveCompanyID:(companyID) => {
    dispatch(setActiveCompanyID(companyID))
  },
  setActivePageType:(pageType) => {
    dispatch(setActivePageType(pageType));
  },
  openModal:(modalName) => {
    dispatch(openModal(modalName));
  },
	closeModal: () => {
		dispatch(closeModal());
	},
  toggleUIHideAll:() => {
    dispatch(toggleUIHideAll());
  },
  clearProjectFilters:() => {
    dispatch(removeAllFilters())
  }
})

const InventumContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(InventumPage));

export default InventumContainer;
