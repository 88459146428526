import React, { useState, useEffect, useRef } from "react";

function MaterialTransformInput (props) {
  const [value, setValue] = useState("");
  const [lastValidValue, setLastValidValue] = useState("");
  const inputRef = useRef(null);


  useEffect(() => {
    if (props.value !== value) {
      const newValue = props.value !== undefined ? String(props.value) : "";
      setValue(newValue);
      setLastValidValue(newValue);
    }
  }, [props.value]);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    
    // Allow '-' to be entered or input to be empty without setting state
    if (inputValue === "-" || inputValue === "") {
      setValue(inputValue);
      return;
    }

    const number = Number(inputValue);

    // Only update state if the input is a valid number
    if (!isNaN(number)) {
      setValue(inputValue);
    }
  };

  const handleBlur = () => {
    // Convert empty or invalid '-' input to last valid value when losing focus
    if (value === "" || value === "-") {
      setValue(lastValidValue);
    } else {
      setLastValidValue(value);
      if (typeof props.onChange === "function") {
        props.onChange(props.valueKey, Number(value));
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // Submit the current value when Enter is pressed
      if (value !== "" && value !== "-" && typeof props.onChange === "function") {
        props.onChange(props.valueKey, Number(value));
        setLastValidValue(value);
      } else {
        // Revert to the last valid value if Enter is pressed on an invalid input
        setValue(lastValidValue);
      }

      // Blur the input field after pressing Enter
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  const inputStyle = {
    width: "45px",
    border: "none",
    borderRadius: "2px",
    marginLeft: "5px",
    marginRight: "10px",
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ fontSize: "14px" }}>{props.label}</span>
      <input
        ref={inputRef}
        step={props.step || 1}
        style={inputStyle}
        type="number"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}

export default MaterialTransformInput;
