import React from 'react';
const NOTIFICATION_DISPLAY_TIME = 1000;

function ToastIcon(props) {
  return (
    <div className="ToastIcon">
      <i className="material-icons notranslate">{props.icon}</i>
    </div>
  )
}

function NotificationToast(props) {
  return (
    <div className={"NotificationToast" + (props.visible ? " visible" : "") }>
      {props.icon !== undefined ? <ToastIcon icon={props.icon} /> : null}
      <div className="ToastContent">
        {props.content}
      </div>
    </div>
  )
}

class NotificationsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications:[],
      activeNotification:null,
      status:"NEXT"
    };
    this.updateNotifications = this.updateNotifications.bind(this);
    this.addNotification = this.addNotification.bind(this);
  }

  componentDidMount() {
    Inventum.notifications.registerReciever(this.addNotification);
    this.updateNotifications();
  }

  updateNotifications() {
    let activeNotification = null;
    if (this.state.activeNotification == null) {
      //console.log('Stage 1');
      //console.log('No current notification. Checking if can add one');
      let notifications = this.state.notifications;
      if (notifications.length > 0) {
        //console.log('Can add a notification!');
        activeNotification = notifications[0];
        notifications = notifications.slice(1);

        this.setState({notifications,activeNotification,status:'ADDED'},() => {
          //Call this function again in 50 milliseconds
          window.setTimeout(this.updateNotifications,50);
        })
        return;
      }
      return;
    }
    let displayTime = this.state.activeNotification.displayTime || NOTIFICATION_DISPLAY_TIME;
    switch(this.state.status) {
      case "ADDED":
        //console.log('Stage 2');
        //console.log('Notification has been added to the DOM but isnt visible. Animating it on...');
        this.setState({status:"VISIBLE"},() => {
          //DOM is re rendered to animate new element;
          //Call this function again in 5 seconds
          window.setTimeout(this.updateNotifications,displayTime);
        })
        break;
      case "VISIBLE":
        //console.log('Stage 3');
        //console.log('Notification has been visible for a few seconds. Animating it off...');
        this.setState({status:"REMOVED"},() => {
          //DOM is re rendered to hide the element;
          //Call this function again in 2 seconds
          window.setTimeout(this.updateNotifications,300);
        })
        break;
      case "REMOVED":
        //console.log('Stage 4');
        //console.log('Notification has been animated off. Removing...');
        this.setState({activeNotification:null,status:"NEXT"},() => {
          window.setTimeout(this.updateNotifications,50);
        })
        break;
    }
  }

  addNotification(notificationData) {
    if (notificationData.hasOwnProperty('content')) {
      let notifications = this.state.notifications;
      notifications.push(notificationData);
      this.setState({notifications},() => {
        if (this.state.activeNotification == null) {
          this.updateNotifications();
        }
      });
    }
  }

  render() {
    let setVisible = false;
    if (this.state.status === "VISIBLE") {
      setVisible = true;
    }
    return (
      <div className="NotificationsContainer">
      {this.state.activeNotification !== null ? <NotificationToast content={this.state.activeNotification.content} icon={this.state.activeNotification.icon} visible={setVisible} /> : null}
      </div>
    )
  }
}

export default NotificationsContainer
