import { store } from '../core/store.js';

var screenshot = { public: {} };

var captureHeight = 0;

function renderScreenshot (callback) {
  const renderer = store.webglRenderer;
  const scene = store.scene;
  const camera = store.camera;
  renderer.render(scene, camera);
  renderer.clearDepth();
  store.webglRenderer.render(store.sceneHUD, store.cameraHUD);

  renderer.domElement.toBlob((blob) => {
    callback(blob);
  }, 'image/png');
}

function renderLoadingScreen (callback) {
  const renderer = store.webglRenderer;
  const scene = store.scene;
  const camera = store.camera;
  renderer.setSize(window.innerWidth / 2, window.innerHeight / 2);
  renderer.render(scene, camera);
  renderer.domElement.toBlob((blob) => {
    blobToBase64(blob, callback);
  }, 'image/jpeg', 0.8);
}

function renderThumbnail (options, callback) {
  const renderer = store.webglRenderer;
  const scene = store.scene;
  const camera = store.camera;
  if (options !== undefined) {
    if (options.width && options.height) {
      renderer.setSize(options.width, options.height);
    }
  }

	window.setTimeout(() => {
		renderer.render(scene, camera);
		renderer.domElement.toBlob((blob) => {
			if (options !== undefined) {
				if (options.width || options.height) {
					renderer.setSize(window.innerWidth, window.innerHeight);
				}
			}
			// renderer.render(scene,camera);
			if (options !== undefined) {
				if (options.returnImage) {
					callback(blob);
					return;
				}
			}
			blobToBase64(blob, callback);
		}, 'image/jpeg', 0.8);
	},20); // Small delay after setSize to fix models not loading.

}

function blobToBase64 (blob, callback) {
  var reader = new window.FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = () => {
    const base64Image = reader.result;
    callback(base64Image);
  };
}

screenshot.public.captureForMerge = function captureForMerge () {
	return new Promise((resolve, reject) => {
		renderScreenshot(resolve);
	});
}

screenshot.public.capture = function screenshotCanvas () {
	const renderer = store.webglRenderer;
  var currentTime = Math.floor(Date.now() / 1000);
  currentTime = String(currentTime).slice(2);
  var filename = 'screenshot_' + currentTime + '.png';
  var tempanchor = document.createElement('a');
  tempanchor.setAttribute('download', filename);

	if (captureHeight === 1080) {
		renderer.setSize(1920, 1080);
		store.camera.updateProjectionMatrix();
	} else if (captureHeight === 2160) {
		renderer.setSize(3840, 2160);
		store.camera.updateProjectionMatrix();
	} else if (captureHeight === 4320) {
		renderer.setSize(7860, 4320);
		store.camera.updateProjectionMatrix();
	}

  renderScreenshot((blob) => {
    tempanchor.href = URL.createObjectURL(blob);
    tempanchor.setAttribute('type', 'file');
    tempanchor.click();
  });
};

screenshot.createThumbnail = function createThumbnail (cb) {
  renderThumbnail({ width: 320, height: 180 }, cb);
};

screenshot.createAnimationThumbnail = function createAnimationThumbnail (cb) {
  renderThumbnail({}, cb);
};

screenshot.renderLoadingScreen = (cb) => {
  renderLoadingScreen(cb);
};

screenshot.public.createThumbnail = screenshot.createThumbnail;
screenshot.public.setResolution = (height) => {
	if (isNaN(height)) return;
	captureHeight = height;
}

screenshot.public.getSettings = () => {
	return {
		resolution: captureHeight,
		format: 'png'
	}
}

export { screenshot };
