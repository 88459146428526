export const LOAD_SIDEBAR_ITEMS = 'LOAD_SIDEBAR_ITEMS';
export const CLEAR_SIDEBAR_ITEMS = 'CLEAR_SIDEBAR_ITEMS'
export const ADD_SIDEBAR_ITEM = 'ADD_SIDEBAR_ITEM';
export const TOGGLE_SIDEBAR_ITEM = 'TOGGLE_SIDEBAR_ITEM';
export const REORDER_SIDEBAR_ITEM = 'REORDER_SIDEBAR_ITEM';
export const UPDATE_SIDEBAR_ITEM = 'UPDATE_SIDEBAR_ITEM';

export function loadSidebarItems(data) {
  return {type:LOAD_SIDEBAR_ITEMS,data}
}

export function clearSidebarItems() {
  return {type:CLEAR_SIDEBAR_ITEMS}
}

export function addSidebarItem(data) {
  return {type:ADD_SIDEBAR_ITEM,data}
}

export function toggleSidebarItem(data) {
  return {type:TOGGLE_SIDEBAR_ITEM,data}
}

export function reorderSidebarItem(data) {
  return {type:REORDER_SIDEBAR_ITEM,data}
}

export function updateSidebarItem(data) {
  return {type:UPDATE_SIDEBAR_ITEM,data}
}
