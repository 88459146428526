import * as THREE from 'three';
import { store } from '../core/store.js';

class Grid {
  constructor (props) {
    this.generate = this.generate.bind(this);
    this.toggleGrid = this.toggleGrid.bind(this);
    this.originalMapPath = '/static/img/gridfloor_white.png';
    this.enabled = true; // Not using this.gridObject.visible because sometimes we hide the grid using that method
  }

  generate (options) {
    // Default settings for grid
    const gridFloorGeo = new THREE.PlaneBufferGeometry(40000, 40000, 1000, 1000);
    this.texture = new THREE.TextureLoader().load(this.originalMapPath, store.requestRender);
    this.texture.wrapS = this.texture.wrapT = THREE.RepeatWrapping;
    this.texture.repeat.set(500, 500);
    var maxAnisotropy = store.webglRenderer.capabilities.getMaxAnisotropy();
    this.texture.anisotropy = maxAnisotropy;
    this.material = new THREE.MeshStandardMaterial({ color: 0xFFFFFF, metalness: 0.0, roughness: 1.0, side: THREE.FrontSide, map: this.texture });
    this.material.flatShading = true;
    this.gridObject = new THREE.Mesh(gridFloorGeo, this.material);
    this.gridObject.receiveShadow = true;
    this.gridObject.position.set(0, -2.5, 0);
    this.gridObject.rotation.x = -Math.PI / 2;
    store.scene.add(this.gridObject);
  }

  updateTexture (options) {
    if (options.mapPath) {
      this.texture = new THREE.TextureLoader().load(options.mapPath, store.requestRender);
      this.texture.wrapS = this.texture.wrapT = THREE.RepeatWrapping;
      this.originalMapPath = options.mapPath;
      var maxAnisotropy = store.webglRenderer.capabilities.getMaxAnisotropy();
      this.texture.anisotropy = maxAnisotropy;
      this.material.map = this.texture;
    }

    if (!isNaN(options.tiling)) {
      this.texture.repeat.set(options.tiling, options.tiling);
    }

    if (!isNaN(options.offsetX) && !isNaN(options.offsetY)) {
      this.texture.offset.set(options.offsetX, options.offsetY);
    }
    store.requestRender();
  }

  setGridLight () {
    const options = {
      tiling: this.texture.repeat.x,
      offsetX: this.texture.offset.x,
      offsetY: this.texture.offset.y,
      mapPath: '/static/img/gridfloor_white.png'
    };
    this.updateTexture(options);
  }

  setGridDark () {
    const options = {
      tiling: this.texture.repeat.x,
      offsetX: this.texture.offset.x,
      offsetY: this.texture.offset.y,
      mapPath: '/static/img/gridfloor_gray.png'
    };
    this.updateTexture(options);
  }

  toggleGrid () {
    store.scene.remove(this.gridObject);
  }

  disable () {
    store.scene.remove(this.gridObject);
    this.enabled = false;
  }

  enable () {
    store.scene.add(this.gridObject);
    this.enabled = true;
  }

  scale (scale) {
    if (isNaN(scale)) {
      return;
    }
    this.gridObject.scale.set(scale, scale, scale);
    store.requestRender();
  }

  getTextureRepeat () {
    return this.texture.repeat.x;
  }

  serialize () {
    return {
      tiling: this.texture.repeat.x,
      mapPath: this.originalMapPath,
      offsetX: this.texture.offset.x,
      offsetY: this.texture.offset.y,
      enabled: this.enabled,
      scale: this.gridObject.scale.x
    };
  }
}

const sceneGrid = new Grid();

export { sceneGrid };
