import { sceneStates } from '../tools/sceneStates.js';
import { views } from '../camera/views.js';
import { slides } from '../slides/slides.js';
import { animations } from '../camera/animations.js';
import { models } from '../models/models.js';
import { notifications } from '../ui/notifications.js';
import { tour } from '../tour/tour.js';
import { sceneLoader } from './sceneLoader.js';
import { markers } from '../ui/screenMarker.js';
import { labels } from '../ui/labels.js';
import { resolutionManager } from './resolution.js';
import { debug } from '../tools/debug.js';
import { lights } from '../world/lights.js';
import { settings } from './settings.js';
import { tools } from '../tools/tools.js';
import { clipping } from '../tools/clipping.js';
import { world } from '../world/world.js';
import { sync } from './sync.js';
import { ruler } from '../tools/ruler.js';
import { scale } from '../models/scale.js';
import { video } from '../tools/video.js';

var engineAPI = {};
var api = {};

function registerHandler (name, handler) {
  api[name] = handler;
}

engineAPI.setup = function setup (windowObject) {
  api = windowObject;
  registerHandler('sceneStates', sceneStates.public);
  registerHandler('camera', views.public);
  registerHandler('slides', slides.public);
  registerHandler('animations', animations.public);
  registerHandler('debug', debug.public);
  registerHandler('screenshot', tools.screenshot.public);
  registerHandler('notifications', notifications.public);
  registerHandler('settings', settings.public);
  registerHandler('models', models.public);
  registerHandler('tour', tour.public);
  registerHandler('labels', labels.public);
  registerHandler('markers', markers.public);
  registerHandler('lights', lights);
  registerHandler('clipping', clipping.public);
  registerHandler('world', world.public);
  registerHandler('ruler', ruler.public);
  registerHandler('sync', sync.public);
  registerHandler('resolution', resolutionManager.public);
  registerHandler('scale', scale.public);
  registerHandler('scene', sceneLoader.public);
	registerHandler('video', video.public);
};

export { engineAPI };
