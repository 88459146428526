import {
  LOAD_PROJECTS,
  CLEAR_PROJECTS
} from '../actions/projects.js';

function projects(state = {}, action) {
  switch (action.type) {
    case LOAD_PROJECTS:
      return Object.assign({},action.data)
    case CLEAR_PROJECTS:
      return {}
    default:
      return state
  }
}

export default projects;
