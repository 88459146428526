import React from 'react';
import {withRouter} from 'react-router-dom';
import LeafletContainer from './LeafletContainer.js';
import {connect} from 'react-redux';
import {loadProjects} from '../../Redux/actions/projects.js';
import {openModal, setHasVisitedHome, closeModal} from '../../Redux/actions/ui.js';
import {resetTitles, setLayerContentMode, openSidebar} from '../../Redux/actions/sidebarUI.js';
import {setActivePageType, setActiveCompanyID, setActiveProjectID} from '../../Redux/actions/activePage.js';
import axios from '../../axios-config.js';
const queryString = require('query-string');
var utf8 = require('utf8');
const base64 = require('base-64');

class MapPage extends React.Component {
  constructor(props) {
    super(props);
    this.getPublicProjects = this.getPublicProjects.bind(this);
    this.checkRoute = this.checkRoute.bind(this);
    this.validateGeoJSON = this.validateGeoJSON.bind(this);
    this.state = {
      geoJSON:{}
    }
  }

  //NOTE: React Update
  componentDidMount() {
    this.getPublicProjects();
    this.validateGeoJSON();
    this.props.resetTitles();
    this.props.setPageMode('2D');

    this.props.setActivePageType('HOME');
    this.props.setActiveCompanyID('');
    this.props.setActiveProjectID('');

    this.checkRoute(this.props.match);
    this.props.setHasVisitedHome();

    if (this.props.match.path !== "/resetpassword/:resetToken") {
      this.props.closeModal();
    }

    if (window.innerWidth > 768) {
      setTimeout(this.props.openSidebar, 500);
    }
  }

  //NOTE: React Update (componentWillReceiveProps)
  componentDidUpdate(nextProps) {
    //Fetch projects if token changes
    if (this.props.token != nextProps.token) {
      this.getPublicProjects();
    }
  }

  checkRoute(match) {
    if(match.path == "/resetpassword/:resetToken") {
      this.props.openModal('PasswordReset')
    }
  }

  getPublicProjects() {
    axios.get('https://api.inventum3d.com/project')
    .then((result) => {
      let data = result.data.data;
      let cleanedData = {};
      data.map((project) => {
        if (project.featured_site_map) {
          cleanedData[project.project_id] = project;
        }
      })
      this.props.loadProjects(cleanedData);
    })
  }

  validateGeoJSON() {
    let urlQueryString = queryString.parse(this.props.location.search);

    if (urlQueryString.gjson) {
      try {
          let geoJSON = JSON.parse(utf8.decode(base64.decode(urlQueryString.gjson)));
          this.setState({geoJSON})
      } catch (e) {
          console.log(e);
      }
    }
  }

  render() {
    let projects = Object.assign({}, this.props.projects);

    if (Object.keys(this.props.activeFilters).length > 0) {
      Object.keys(projects).map(key => {
        //d3Y58
        if (!this.props.filteredProjects.includes(key)) {
          delete projects[key];
        }else {
        }
      })
    }

    let mapTileLayers = this.props.mapTileLayers;
    let sidebarItems = this.props.sidebarItems;
    return (
      <LeafletContainer path={this.props.match.path} history={this.props.history} projects={projects} mapLayers={mapTileLayers} sidebarItems={sidebarItems} geoJSON={this.state.geoJSON} />
    )
  }
}
//SIDEBARITEMS NEEDS TO BE MONITORED BY THE MAP TO CHECK THE ORDER
//LAYERS LOADED MIGHT BE ABLE TO BE DELETED
let mapStateToProps = (state) => ({
  token:state.user.token,
  projects:state.projects,
  activeFilters:state.projectsFilters.active,
  filteredProjects:state.projectsFilters.results,
  mapTileLayers:state.mapTileLayers,
  sidebarItems:state.sidebarItems
})


let mapDispatchToProps = dispatch => ({
  loadProjects:(projects) => {
    dispatch(loadProjects(projects));
  },
  resetTitles:(title) => {
    dispatch(resetTitles(title));
  },
  setPageMode:(mode) => {
    dispatch(setLayerContentMode(mode));
  },
  openModal:(modalName) => {
    dispatch(openModal(modalName));
  },
  closeModal:() => {
    dispatch(closeModal())
  },
  setHasVisitedHome:() => {
    dispatch(setHasVisitedHome());
  },
  openSidebar:() => {
    dispatch(openSidebar());
  },
  setActivePageType:(value) => {
    dispatch(setActivePageType(value))
  },
  setActiveCompanyID:(value) => {
    dispatch(setActiveCompanyID(value))
  },
  setActiveProjectID:(value) => {
    dispatch(setActiveProjectID(value))
  }
})

const HomeMapContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MapPage));


export default HomeMapContainer;
