import { sceneGrid } from './grid.js';
import { sky } from './sky.js';
import { models } from '../models/models.js';
import { labels } from '../ui/labels.js';
import { store } from '../core/store.js';
const world = { public: {} };

world.drawSky = function drawSky (options) {
  if (options.type === 'dynamic') {
    console.log('Not Supported');
  } else {
    sky.createStatic(options);
  }
};

world.public.setScale = function updateScale (scale) {
  scale = Number(scale);
  sky.scale(scale);
  sceneGrid.scale(scale);
};

world.public.getScale = function getScale () {
  return sky.getScale();
};

world.public.setGridTextureRepeat = function setGridTextureRepeat (val) {
  sceneGrid.updateTexture({ tiling: Number(val) });
};

world.public.getGridTextureRepeat = function getGridTextureRepeat () {
  return sceneGrid.getTextureRepeat();
};

world.public.setGridLight = function setGridLight () {
  sceneGrid.setGridLight();
};

world.public.setGridDark = function setGridDark () {
  sceneGrid.setGridDark();
};

world.public.toggleHide = function toggleHide () {
  let gridModel = sceneGrid.gridObject;
  let skyModel = sky.staticSky;

  if (gridModel.visible && skyModel.visible) {
    gridModel.visible = false;
  }else if (!gridModel.visible && skyModel.visible) {
    skyModel.visible = false;
  }else {
    gridModel.visible = true;
    skyModel.visible = true;
  }
  store.requestRender();
};

world.public.setVisibleStart = function setVisibleStart () {
	models.setVisibleStartFromCurrentState();
	labels.setVisibleStartFromCurrentState();
};

world.public.showVisibleStart = function showVisibleStart () {
	models.showVisibleStart();
	labels.showVisibleStart();
};

world.serialize = function serialize () {
  return {
    grid: sceneGrid.serialize(),
    sky: sky.serialize()
  };
};

export { world };
