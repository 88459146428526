export const EDIT_HOME_MAP = 'EDIT_HOME_MAP';
export const CLEAR_EDITED_HOME_MAP = 'CLEAR_EDITED_HOME_MAP';

export function clearEditedHomeMap() {
  return {type:CLEAR_EDITED_HOME_MAP}
}

export function editHomeMap(data) {
  return {type:EDIT_HOME_MAP,data}
}
