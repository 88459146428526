import React from 'react';
import {withRouter, matchPath} from 'react-router-dom';
import {connect} from 'react-redux';
import {openModal} from '../../../Redux/actions/ui.js';
import {closeModal} from '../../../Redux/actions/ui.js';

import {loadUser,clearUser} from '../../../Redux/actions/user.js';
import axios from '../../../axios-config.js';

class SignInForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email:"",
      password:"",
      errorMessage:""
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.emailInput.focus();
    this.emailInput.select();
  }

  handleSubmit() {
    this.setState({errorMessage:""})
    axios.post('https://api.inventum3D.com/authenticate',{
      email:this.state.email,
      password:this.state.password
    })
    .then((response) => {
      console.log('Success');
      this.props.onLogin(response.data.token);
      this.setState({email:'',password:'',errorMessage:''})
      this.props.closeModal();
    })
    .catch((error) => {
      let apiMessage = error.response.data.error
      this.setState({errorMessage:apiMessage.userMessage});
    })
  }

  render () {
    let handleKey = (e) => {
      if (e.key === "Enter") {
        this.handleSubmit();
      }
    }


    return (
      <div className="SignInForm">
        <div className="SignInClose" onClick={this.props.closeModal}><i className="material-icons notranslate">close</i></div>
        <div className="SignInFormTitle">Account Sign In</div>
        <form style={{display:"flex",flexDirection:"column"}}>
          <input autoComplete="email" ref={(input)=>{this.emailInput=input}} onKeyPress={handleKey} onChange={(e)=>{this.setState({email:e.target.value})}} value={this.state.email} placeholder="Email/Username" type="email"/>
          <input autoComplete="current-password" onKeyPress={handleKey} onChange={(e)=>{this.setState({password:e.target.value})}} value={this.state.password} placeholder="Password" type="password"/>
          {this.state.errorMessage.length !== 0 ? <div className="errorsBoxSignIn"><div>{this.state.errorMessage}</div></div> : null }
          <div className="SignInBottomControls">
            <div className="NewAccountText">No Account? <span className="NewAccountTextRegister" onClick={()=>{this.props.openModal('RegisterInterest')}}>Register</span></div>
            <input onClick={this.handleSubmit} type="button" value="Sign In" className="SignInFormSubmit"></input>
          </div>
        </form>
      </div>
    )
  }
}

let mapStateToProps = (state) => ({})


let mapDispatchToProps = dispatch => ({
  closeModal:() => {
    dispatch(closeModal());
  },
  openModal:(value) => {
    dispatch(openModal(value));
  },
  onLogin: (user) => {
    dispatch(loadUser(user));
  },
  onLogout: () => {
    dispatch(clearUser());
  }
})


const SignInContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInForm));


export default SignInContainer
