import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import SideBarPanel from './SideBarPanel';
import MapLayerSelector from './MapLayerSelector';

import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {reorderSidebarItem} from '../../../Redux/actions/sidebarItems.js';
import {toggleSidebar} from '../../../Redux/actions/sidebarUI.js';

function CloseButton(props) {
  return (
    <div className="SideBarCloseButton" onClick={props.toggleSidebar}>
      <i className="material-icons notranslate">close</i>
    </div>
  )
}

function CreateCollection(props) {
  let handleClick = (e) => {
    e.preventDefault();
    Inventum.models.createGroup();
  }
  return (
    <div className="CreateNewCollection" onClick={handleClick}>Create New Group</div>
  )
}

const PanelContainer = SortableContainer((props) => {
  let content = props.panelContent
  if (content === undefined) {
    return null
  }

  let disableDrag = true;
  let showCreateCollection = false;

  if (props.pageMode === "2D") {
    disableDrag = false;
  }

  if (props.pageMode === "3D" && props.editMode && props.siteAdmin) {
    showCreateCollection = true;
    disableDrag = false;
  }
  return (
    <div className={"PanelContainer" + (props.pageMode =="2D" ? " noGap" : "")}>
      <div className='TabHeader'>{props.pageMode === '2D' ? 'Active Map Layers' : '3D Models'}</div>
      {content.map((panel, index) => {
        if (panel === null || panel === undefined) {
          return
        }
        let tempKey = index;// We can use the index for 2D content
        if (panel.hasOwnProperty('shortID')) {
          tempKey = panel.shortID;// Only exists for 3D content.
        }
        return <SideBarPanel key={tempKey} index={index} content={panel} disabled={disableDrag}/>
      })}
      {showCreateCollection ? <CreateCollection /> : null}
    </div>
  );
});


function SideBarTitle(props) {
  let style = {
    "backgroundImage": "url(/static/img/logo/inventum_logo.png)",
    "backgroundSize": "contain",
    "backgroundRepeat": "no-repeat"
  };
  //        <div style={style} className="InventumLogo" onClick={()=>{props.history.push('/')}}></div>

  return(
    <div className="SideBarTitle">
      <CloseButton toggleSidebar={props.toggleSidebar} />
        <img title="Back to Inventum Home" onClick={()=>{props.history.push('/')}} src="/static/img/logo/inventum_logo.png"></img>
    </div>
  )
}

function ContentContainer(props) {
  return (
    <div className="ContentContainer">
      <PanelContainer useDragHandle={true} siteAdmin={props.siteAdmin} editMode={props.editMode} pageMode={props.pageMode} distance={5} onSortEnd={props.handleSort} panelContent={props.panelContent} helperClass='sortableHelper' lockAxis={'y'} />
    </div>
  )
}


class SideBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {};
    this.handleSort = this.handleSort.bind(this);
  }

  handleSort(data) {
    if (this.props.pageMode === "3D" && this.props.editMode) {
      Inventum.models.sortGroups(data);
    }
    this.props.reorderSidebarItem(data);
  }

  render() {
    let pageMode = this.props.pageMode;
    return (
      <div className={"SideBar " + (this.props.sideBarVisible ? "visible" : "") }>
        <SideBarTitle history={this.props.history} toggleSidebar={this.props.toggleSidebar}/>
        <ContentContainer siteAdmin={this.props.siteAdmin} editMode={this.props.editMode} handleSort={this.handleSort} toggleSidebar={this.props.toggleSidebar} pageMode={pageMode} panelContent={this.props.sidebarItems} />
        {this.props.pageMode == "2D" ? <MapLayerSelector /> : null}
      </div>
    )
  }
}

let mapStateToProps = (state) => ({
  pageMode:state.sidebarUI.mode,
  sidebarItems:state.sidebarItems,
  sideBarVisible:state.sidebarUI.visible,
  editMode:state.ui.editMode,
  siteAdmin:(state.user.siteAdmin) ? true : false,
})

let mapDispatchToProps = dispatch => ({
  reorderSidebarItem: (data) => {
    dispatch(reorderSidebarItem(data));
  },
  toggleSidebar: () => {
    dispatch(toggleSidebar());
  }
})

const SideBarWrapper = withRouter(connect(mapStateToProps,mapDispatchToProps)(SideBar));

export default SideBarWrapper;
