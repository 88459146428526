const SimpleSkyFragment = `
uniform vec3 topColor;
uniform vec3 bottomColor;
uniform float offset;
uniform float exponent;
varying vec3 vWorldPosition;
void main() {
    float h = normalize( vWorldPosition + offset).y;
    if (h < 0.) {
			// Gray Gradient
			gl_FragColor = vec4( mix( vec3(0.9, 0.9, 0.9), vec3(0.4, 0.4, 0.4), h * -1.0 ), 1.0);
    }else {
			// Sky
      gl_FragColor = vec4( mix( bottomColor, topColor, max( pow(h, exponent),0.0)), 1.0);
    }
}
`;

const SimpleSkyVertex = `
varying vec3 vWorldPosition;
void main() {
  vec4 worldPosition = modelMatrix * vec4( position, 1.0);
  vWorldPosition = worldPosition.xyz;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0);
}
`;

export {
  SimpleSkyFragment,
  SimpleSkyVertex
};
