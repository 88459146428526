import { StaticLabel } from './StaticLabel.js';
import { TextureLoader } from 'three';

class StaticImageLabel extends StaticLabel {
  constructor (options) {
    super();
    this.type = 'STATIC_IMAGE';
    this.name = options.name || "Default Label Name";
    let imageURL = 'https://mapability-website.s3.amazonaws.com/textures/inventum_logo_placeholder.png';

    if (options !== undefined && options.imageURL !== undefined) {
      imageURL = options.imageURL;
    }

    if (options !== undefined && options.scale !== undefined) {
      this.setSize(Number(options.scale));
    }

    if (options !== undefined && options.position !== undefined) {
      this.setPosition(options.position.x, options.position.y);
    }

    this.imageURL = imageURL;

    this.loadImage(this.imageURL);

		if (options!== undefined && options.visibleStart !== undefined) {
			this.visibleStart = options.visibleStart;
			if (this.visibleStart) {
				this.setVisible(true);
			}
		}
  }

  loadImage (url) {
    this.imageURL = url;
    new TextureLoader().load(url, this.setImage.bind(this));
  }

  serialize () {
    return {
      type: 'STATIC_IMAGE',
      name: this.name,
      id: this.id,
      imageURL: this.imageURL,
      position: { x: this.screenPosition.x, y: this.screenPosition.y },
      scale: this.scaleFactor,
			visibleStart: this.visibleStart
    }
  }
}

export { StaticImageLabel }
