class LabelCanvasTexture {
  constructor (textArray, userStyle) {
    this.style = {
      backgroundColor: '#333333',
      color: '#FFFFFF',
      padding: 40,
      font: '90px Sans-Serif, Arial',
      textAlign: 'center',
      lineSpacing: 20
    };
    this.textArray = textArray;
    this.style = Object.assign(this.style, userStyle);
    this.canvas = document.createElement('canvas');
    this.render();
  }

  getCanvas () {
    return this.canvas;
  }

  render () {
    return new Promise((resolve, reject) => {
      const canvas = this.canvas;
      const ctx = canvas.getContext('2d');
      const textArray = this.textArray;

      // Padding Numbers
      if (typeof (this.style.padding) === 'string') {
        this.style.padding = parseInt(this.style.padding);
      }
      const paddingWidth = this.style.padding || 40; // Several old jobs have the padding explicitly set to 0. This fixes it to set it to 40.
      const paddingHeight = this.style.paddingHeight || 50; // Magic Number should this be configurable?
      const fontSize = parseInt(this.style.font.match(/[\d]*/));
      const lineHeight = fontSize;
      const lineSpacing = this.style.lineSpacing;

      // Clear the canvas.
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (this.style.image) {
        const img = new window.Image();
        img.crossOrigin = 'Anonymous';
        img.src = this.style.image;
        img.onload = () => {
          ctx.canvas.width = img.width;
          ctx.canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          resolve();
        };
      } else {
        // Find the widest text line to calculate the canvas width
        let maxWidth = 0;
        ctx.font = this.style.font;
        textArray.map((text) => {
          const tempWidth = ctx.measureText(text).width;
          if (tempWidth > maxWidth) {
            maxWidth = tempWidth;
          }
        });

        // Set Canvas Size
        ctx.canvas.width = maxWidth + paddingWidth;
        ctx.canvas.height = (textArray.length * lineHeight) +  ((textArray.length - 1) * lineSpacing) + paddingHeight;

        // Fill Background Color
        ctx.fillStyle = this.style.backgroundColor;
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        // Drop Shadow

        if (this.style.dropShadow) {
          ctx.shadowOffsetX = 5;
          ctx.shadowOffsetY = 5;
          ctx.shadowColor = "rgba(0, 0, 0, 0.8)";
          ctx.shadowBlur = 5;
        }
        // Fill Text
        ctx.font = this.style.font;
        ctx.textAlign = this.style.textAlign;
        ctx.textBaseline = 'middle';
        for (var i = 0; i < textArray.length; i++) {
          ctx.fillStyle = this.style.color;
          const xPos = (maxWidth / 2) + (paddingWidth / 2);
          let yPos = fontSize + (i * lineHeight) + ((paddingHeight / 2) - (fontSize / 2) + fontSize * 0.1); // Add 10% of font size otherwise it will look uneven due to uppercase being bigger than lowercase
          if (i > 0) { // Don't apply it to the first line
            yPos += lineSpacing;
          }
          ctx.fillText(textArray[i], xPos, yPos);
        }
        resolve();
      }
    });
  }

  setStyle (newStyle) {
    this.style = Object.assign(this.style, newStyle);
    this.render();
  }

  removeStyle (key) {
    if (key) {
      delete this.style[key];
    }
    this.render();
  }

  replaceText (textArray) {
    this.textArray = textArray;
    this.render();
  }

  addText (textLine) {
    this.textArray.push(textLine);
    this.render();
  }

  editText (textID, textLine) {
    if (this.textArray[textID] !== undefined) {
      this.textArray[textID] = textLine;
    }
    this.render();
  }
}

export { LabelCanvasTexture };
