import React from 'react';

const valFromPercent = (percent, total) => {
  return Math.floor(parseFloat(percent) * parseInt(total));
}

const getLength = (a, b) => {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
}

class OverlayEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode:'CLICK_SELECT',
      elements:[],
      helpers:[],
      activeElementIndex:null,
      hoverElementIndex:null,
      highlightHelperIndex:null,
      mouseDown:false,
      mouseDownPosition:{x:0, y:0},
      mouseDownStartPosition:{x:0, y:0},
      fontColor:"#FFFFFF",
      backgroundColor:"rgba(0,0,0,0.4)"
    };
    this.canvasRef = React.createRef();

    this.handleCreateBoxMode = this.handleCreateBoxMode.bind(this);
    this.handleCreateTextMode = this.handleCreateTextMode.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.checkHover = this.checkHover.bind(this);
    this.convertElementNormalizedToPixel = this.convertElementNormalizedToPixel.bind(this);
    this.clearCanvas = this.clearCanvas.bind(this);
    this.drawBox = this.drawBox.bind(this);
    this.drawImage = this.drawImage.bind(this);
    this.drawText = this.drawText.bind(this);
    this.drawHighlight = this.drawHighlight.bind(this);
    this.drawElement = this.drawElement.bind(this);
    this.updateCanvas = this.updateCanvas.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleImageDragDrop = this.handleImageDragDrop.bind(this);
    this.handleCreateImage = this.handleCreateImage.bind(this);
    this.raycast = this.raycast.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.drawTransformHelper = this.drawTransformHelper.bind(this);
    this.convertXYNormalizedToPixel = this.convertXYNormalizedToPixel.bind(this);
  }

  componentDidMount () {
    window.addEventListener('keyup', this.handleKeyPress);
  }

  handleMouseDown (e) {
    const mousePos = {
      x:parseInt(e.nativeEvent.offsetX) / parseInt(this.canvasRef.current.width),
      y:parseInt(e.nativeEvent.offsetY) / parseInt(this.canvasRef.current.height)
    }

    if (this.state.mode === 'CREATE_TEXT') {
      this.handleCreateTextMode(mousePos);
      return;
    }

    this.setState({mouseDown:true, mouseDownStartPosition:mousePos, mouseDownPosition:mousePos})
  }

  handleMouseUp(e) {
    const mousePos = {
      x:parseInt(e.nativeEvent.offsetX) / parseInt(this.canvasRef.current.width),
      y:parseInt(e.nativeEvent.offsetY) / parseInt(this.canvasRef.current.height)
    }

    if (this.state.mode === 'DRAGGING') {
      this.setState({mouseDown:false, mode:'CLICK_SELECT'});
      return;
    }else if (this.state.mode === "CREATE_BOX") {
      this.setState({mouseDown:false, activeElementIndex:null});
      return;
    }else if (this.state.mode === "CLICK_SELECT") {
      let intersects = this.raycast(mousePos);
      if (intersects.length > 0) {
        this.setState({mouseDown:false, activeElementIndex:intersects[0]});
      }else{
        this.setState({mouseDown:false, activeElementIndex:null});
      }
    }
  }

  handleCreateTextMode(mousePos) {
    let ctx = this.canvasRef.current.getContext('2d');
    ctx.font = '40px Arial';
    let elements = this.state.elements;
    let defaultText = "Click to Edit";
    let textWidth = parseInt(ctx.measureText(defaultText).width) / parseInt(this.canvasRef.current.width);
    let textHeight = parseInt(40) / parseInt(this.canvasRef.current.height);

    let id = elements.push({type:'TEXT', content:'Click to Edit', minX:mousePos.x, minY:mousePos.y, maxX:mousePos.x + textWidth, maxY:mousePos.y - textHeight}) - 1;
    this.setState({elements:elements, activeElementIndex:id});
    this.updateCanvas();
  }

  handleCreateBoxMode(mousePos) {
    let elements = this.state.elements;
    if (this.state.activeElementIndex == null) {
      let id = elements.push({type:'BOX', minX:mousePos.x, minY:mousePos.y, maxX:mousePos.x, maxY:mousePos.y, fillStyle:`#${Math.floor(Math.random()*16777215).toString(16)}`, strokeStyle:'#00FF00', lineWidth:1}) - 1;
      this.setState({activeElementIndex:id, elements:elements});
      return;
    }
    let box = this.state.elements[this.state.activeElementIndex];
    box.maxX = mousePos.x;
    box.maxY = mousePos.y;
    elements[this.state.activeElementIndex] = box;
    this.setState({elements:elements});
    this.updateCanvas();
  }

  handleCreateImage(dataURL) {
    const img = new Image();
    let ctx = this.canvasRef.current.getContext('2d');
    img.onload = () => {
      let imgWidth = parseInt(img.width) / parseInt(this.canvasRef.current.width);
      let imgHeight = parseInt(img.height) / parseInt(this.canvasRef.current.height);

      let elements = this.state.elements;
      elements.push({type:'IMAGE', minX:0, minY:0, maxX:imgWidth, maxY:imgHeight, content:img});
      this.setState({elements:elements});
      this.updateCanvas();
    };
    img.src = dataURL;
  }

  updateCanvas() {
    let ctx = this.canvasRef.current.getContext('2d');
    this.clearCanvas();
    this.state.elements.map(el => this.drawElement(el));
    this.state.helpers.map(el => this.drawElement(el));
  }

  drawElement(element) {
    switch (element.type) {
      case 'BOX':
        this.drawBox(element);
        break;
      case 'IMAGE':
        this.drawImage(element);
        break;
      case 'HIGHLIGHT':
        this.drawHighlight(element);
        break;
      case 'TRANSFORM_CONTROLS':
        this.drawTransformHelper(element);
        break;
      case 'TEXT':
        this.drawText(element);
        break;
    }
  }

  drawImage(element) {
    let ctx = this.canvasRef.current.getContext('2d');
    let bounds = this.convertElementNormalizedToPixel(element);
    ctx.drawImage(element.content, bounds.minX, bounds.minY);
  }


  drawBox (element) {
    let ctx = this.canvasRef.current.getContext('2d');
    let bounds = this.convertElementNormalizedToPixel(element);
    ctx.beginPath();
    ctx.rect(bounds.minX, bounds.minY , bounds.maxX - bounds.minX, bounds.maxY - bounds.minY);
    ctx.fillStyle = element.fillStyle;
    ctx.fill();
    if (element.lineWidth) {
      ctx.lineWidth = element.lineWidth;
      ctx.strokeStyle = element.strokeStyle;
      ctx.stroke();
    }
    ctx.lineWidth = 0;
  }

  drawText (element) {
    let ctx = this.canvasRef.current.getContext('2d');
    let bounds = this.convertElementNormalizedToPixel(element);
    ctx.font = '40px Arial';
    ctx.shadowBlur = 4;
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;
    ctx.shadowColor = "#111111";
    ctx.fillStyle = this.state.fontColor;

    ctx.fillText(element.content, bounds.minX, bounds.minY);

    //Reset Shadow Settings
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowColor = "#000000";
  }

  clearCanvas () {
    this.canvasRef.current.getContext('2d').clearRect(0, 0, this.canvasRef.current.width, this.canvasRef.current.height);
  }

  convertElementNormalizedToPixel (element) {
    return {
      minX:valFromPercent(element.minX || 0, this.canvasRef.current.width),
      minY:valFromPercent(element.minY || 0, this.canvasRef.current.height),
      maxX:valFromPercent(element.maxX || 0, this.canvasRef.current.width),
      maxY:valFromPercent(element.maxY || 0, this.canvasRef.current.height)
    }
  }

  convertXYNormalizedToPixel (x, y) {
    return {x:valFromPercent(x, this.canvasRef.current.width), y:valFromPercent(y, this.canvasRef.current.height)}
  }



  drawHighlight (element) {
    const ctx = this.canvasRef.current.getContext('2d');
    let bounds = this.convertElementNormalizedToPixel(element);
    ctx.beginPath();
    ctx.strokeStyle = '#ffe600';
    ctx.lineWidth = 3;
    ctx.strokeRect(bounds.minX, bounds.minY, bounds.maxX - bounds.minX, bounds.maxY - bounds.minY);
  }

  drawTransformHelper (element) {
    const ctx = this.canvasRef.current.getContext('2d');
    let bounds = this.convertElementNormalizedToPixel(element);
    ctx.beginPath();
    ctx.strokeStyle = '#000000';
    ctx.lineWidth = 1;
    const boxSize = 5;
    const drawCorner = (x, y) => {
      ctx.beginPath();
      ctx.strokeRect(x - boxSize * 0.5, y - boxSize * 0.5, boxSize, boxSize);
    }

    drawCorner(bounds.minX, bounds.minY);
    drawCorner(bounds.minX, bounds.maxY);
    drawCorner(bounds.maxX, bounds.minY);
    drawCorner(bounds.maxX, bounds.maxY);
  }

  raycast (mousePos) {
    const mousePosCoord = {x:valFromPercent(mousePos.x, this.canvasRef.current.width), y:valFromPercent(mousePos.y, this.canvasRef.current.height)};
    const pointWithin = (element) => {
      const bounds = this.convertElementNormalizedToPixel(element);
      let minX = bounds.minX < bounds.maxX ? bounds.minX : bounds.maxX;
      let minY = bounds.minY < bounds.maxY ? bounds.minY : bounds.maxY;
      let maxX = bounds.maxX > bounds.minX ? bounds.maxX : bounds.minX;
      let maxY = bounds.maxY > bounds.minY ? bounds.maxY : bounds.minY;

      if (mousePosCoord.x > minX &&
          mousePosCoord.x < maxX &&
          mousePosCoord.y > minY &&
          mousePosCoord.y < maxY
        ) { return true; }
      return false;
    }

    let elements = this.state.elements;
    let elementsIntersect = [];
    for (var i = elements.length - 1; i >= 0; i--) {
      let element = elements[i];
      if (element.type == 'HIGHLIGHT') continue;
      if (pointWithin(element)) {
        elementsIntersect.push(i);
      }
    }
    return elementsIntersect;
  }

  checkHover (mousePos) {
    const mousePosCoord = {x:valFromPercent(mousePos.x, this.canvasRef.current.width), y:valFromPercent(mousePos.y, this.canvasRef.current.height)}

    let elements = this.state.elements;
    let helpers = this.state.helpers;
    let hoverElementIndex = null;
    let intersectElements = this.raycast(mousePos);

    if (intersectElements.length > 0) {
      hoverElementIndex = intersectElements[0];
      let hoverElement = elements[hoverElementIndex];
      if (this.state.hoverElementIndex !== hoverElementIndex) {
        // Create Highlight
        var highlightEl = {
          minX:hoverElement.minX,
          minY:hoverElement.minY,
          maxX:hoverElement.maxX,
          maxY:hoverElement.maxY
        };
        highlightEl.type = 'HIGHLIGHT'
        highlightEl.elementID = hoverElementIndex;
        if (helpers.length > 0) {
          if (helpers[helpers.length - 1].type == 'HIGHLIGHT') {
            helpers[helpers.length - 1] = highlightEl
          }else {
            helpers.push(highlightEl);
          }
        }else {
          helpers.push(highlightEl);
        }
        this.setState({hoverElementIndex:hoverElementIndex, helpers:helpers});
        this.updateCanvas();
      }
    }

    if (hoverElementIndex !== null) {
      if (this.state.mouseDown) {

        // Only Set dragging mode if distance is past a certain threshhold
        let MIN_PIXEL_MOVE = 5;
        let distance = getLength(this.convertXYNormalizedToPixel(this.state.mouseDownPosition.x, this.state.mouseDownPosition.y), this.convertXYNormalizedToPixel(this.state.mouseDownStartPosition.x, this.state.mouseDownStartPosition.y));
        if (distance > MIN_PIXEL_MOVE) {
          this.setState({mode:'DRAGGING'});
        }
      }
    }else {
      if (this.state.hoverElementIndex !== null) {
        if (helpers[helpers.length - 1].type == 'HIGHLIGHT') {
          helpers.pop();
          this.setState({helpers:helpers, hoverElementIndex:null});
          this.updateCanvas();
        }
      }
    }
  }

  handleDrag(mousePos) {
    let elements = this.state.elements;
    let helpers = this.state.helpers;
    let draggingElement = elements[this.state.hoverElementIndex];
    const xDiff = this.state.mouseDownPosition.x - mousePos.x;
    const yDiff = this.state.mouseDownPosition.y - mousePos.y;

    draggingElement.minX -= xDiff;
    draggingElement.maxX -= xDiff;
    draggingElement.minY -= yDiff;
    draggingElement.maxY -= yDiff;

    let highlightEl = helpers[helpers.length - 1];
    highlightEl.minX = draggingElement.minX;
    highlightEl.maxX = draggingElement.maxX;
    highlightEl.minY = draggingElement.minY;
    highlightEl.maxY = draggingElement.maxY;

    this.setState({helpers, mouseDownPosition:mousePos});
    this.updateCanvas();
  }

  handleMouseMove(e) {
    const canvas = this.canvasRef.current;
    const mousePos = {
      x:parseInt(e.nativeEvent.offsetX) / parseInt(canvas.width),
      y:parseInt(e.nativeEvent.offsetY) / parseInt(canvas.height)
    }

    switch (this.state.mode) {
      case "CLICK_SELECT":
        this.checkHover(mousePos);
        break;
      case "RESIZING":
        this.handleResize();
        break
      case "DRAGGING":
        this.handleDrag(mousePos);
        break;
      case "CREATE_BOX":
        if (!this.state.mouseDown) return;
        this.handleCreateBoxMode(mousePos);
        break;
      default:
        break;
    }
    this.setState({mouseDownPosition:mousePos})
  }

  handleImageDragDrop (e) {
    e.preventDefault();
    e.persist();
    let filesToLoad = e.dataTransfer.items.length;
    for (var i = 0; i < filesToLoad; i++) {
      let item = e.dataTransfer.items[i];
      if (item.kind === "file") {
        let file = item.getAsFile();
        if (file.type.indexOf('image') === -1) {
          console.log('Unsupported File!');
          filesToLoad--;
          return;
        }
        var reader = new FileReader();
        reader.onload = (f) => {
          this.handleCreateImage(f.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  handleKeyPress (e) {
    const key = e.key.toLowerCase();
    if (this.state.hoverElementIndex !== null) {
      let elements = this.state.elements;
      let hoverElementIndex = this.state.hoverElementIndex;
      switch (key) {
        case 'f':
          if (hoverElementIndex < elements.length - 1) {
            let currentEl = elements[hoverElementIndex];
            let swapEl = elements[hoverElementIndex + 1];
            elements[hoverElementIndex] = swapEl;
            elements[hoverElementIndex + 1] = currentEl;
            this.setState({elements:elements, hoverElementIndex:hoverElementIndex + 1}, this.updateCanvas);
          }
          break;
        case 'b':
          if (hoverElementIndex > 0) {
            let currentEl = elements[hoverElementIndex];
            let swapEl = elements[hoverElementIndex - 1];
            elements[hoverElementIndex] = swapEl;
            elements[hoverElementIndex - 1] = currentEl;
            this.setState({elements:elements, hoverElementIndex:hoverElementIndex - 1}, this.updateCanvas);
          }
          break;
        case 'delete':
          elements.splice(this.state.hoverElementIndex, 1);
          this.setState({elements:elements, hoverElementIndex:null}, this.updateCanvas);
          break;
        default:
          break;
      }
    }
  }

  render() {
    let showEditText = false;
    let textValue = '';
    if (this.state.activeElementIndex !== null) {
      let activeElement = this.state.elements[this.state.activeElementIndex];
      if (activeElement) {
        if (activeElement.type == 'TEXT') {
          showEditText = true;
          textValue = activeElement.content;
        }
      }
    }
    const updateText = (e) => {
      let activeElementIndex = this.state.activeElementIndex;
      let elements = this.state.elements;
      let element = elements[activeElementIndex];

      let ctx = this.canvasRef.current.getContext('2d');
      let textWidth = parseInt(ctx.measureText(e.target.value).width) / parseInt(this.canvasRef.current.width);
      let textHeight = parseInt(40) / parseInt(this.canvasRef.current.height);

      element.content = e.target.value;
      element.maxX = element.minX + textWidth;
      element.maxY = element.minY - textHeight;
      this.setState({elements});
      this.updateCanvas();
    }

    return (
      <div>
        {showEditText ? <TextInput value={textValue} update={updateText} /> : null}
        <textarea readOnly value={JSON.stringify(this.state, null, 2)} style={{width:"640px", height:"720px", position:"absolute", top:"0px", left:"1280px"}}></textarea>
        <Controls mode={this.state.mode} setMode={mode => this.setState({mode}) } />
        <canvas style={{background:'none', border:'1px solid #000'}} onDrop={this.handleImageDragDrop} onDragOver={(e)=>{e.preventDefault();}} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown} onMouseUp={this.handleMouseUp} ref={this.canvasRef} width={1280} height={720}></canvas>
      </div>
    )
  }
}

function TextInput (props) {
  return <input value={props.value} onChange={props.update} style={{position:'absolute',top:'740px',left:'0px'}}/>
}

function Controls (props) {
  let highlightStyle = {color:'#FF0000'};
  return (
    <div className="NewSlideControls">
      <div style={(props.mode == 'CLICK_SELECT' || props.mode == 'DRAGGING') ? highlightStyle : null} onClick={_ => props.setMode('CLICK_SELECT')}>Select</div>
      <div style={props.mode == 'CREATE_BOX' ? highlightStyle : null} onClick={_ => props.setMode('CREATE_BOX')}>Box</div>
      <div style={props.mode == 'CREATE_TEXT' ? highlightStyle : null} onClick={_ => props.setMode('CREATE_TEXT')}>Text</div>
    </div>
  )
}


export default OverlayEditor
