import {
  EDIT_COMPANY_MAP,
  CLEAR_EDITED_COMPANY_MAP
} from '../actions/companyMapUserState.js';

function companyMapUserState(state = {}, action) {
  switch (action.type) {
    case EDIT_COMPANY_MAP:
      return Object.assign({},state,action.data)
    case CLEAR_EDITED_COMPANY_MAP:
      return {}
    default:
      return state
  }
}

export default companyMapUserState;
