import {
  LOAD_HOME_MAP
} from '../actions/homeMapDefaultState.js';

function homeMapDefaultState(state = {}, action) {
  switch (action.type) {
    case LOAD_HOME_MAP:
      return Object.assign({},state,action.data)
    default:
      return state
  }
}

export default homeMapDefaultState;
