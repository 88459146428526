import React from 'react';

const durationOveride = 200;
const maxPipText = 10;

function KeyRail(props) {
  let style={
    width:"50px",
    height:"4px",
    display:"block",
    backgroundColor:"#FFFFFF",
    marginTop:"10px"
  }

  return (
    <div className="KeyRail"></div>
  )
}

function KeyNode(props) {
  let style={
    width:"25px",
    height:"25px",
    display:"block",
    backgroundColor:"#FFFFFF",
    borderRadius:"50%"
  }
  return (
    <div className="KeyNode" />
  )
}

function Pip(props) {
  let leftOffset = 100 / (props.totalKeys -1) * props.keyframeID
  let leftPercent = leftOffset.toString() + "%";
  let isActive = false;
  if (props.currentKey-1 == props.keyframeID) {
    isActive = true;
  }

  let handleClick = () => {
    if(!props.playing) {
      Inventum.animations.showKeyframe(parseInt(props.keyframeID),{durationOveride:durationOveride});
    }
  }

  return (
    <div className={"pip" + (isActive ? " active" : "")} style={{left:leftPercent}}>
    </div>
  )
}//      {props.totalKeys < maxPipText ? <TickText name={props.name} handleClick={handleClick} /> : null }


function TickText(props) {
  return (
    <div>
      <div className="timelineTick" />
      <div className="timelineTickText" onClick={props.handleClick}>{props.name}</div>
    </div>
  )
}


function TimelineControls(props) {
  let handleChange = (e) => {
    if(!props.playing) {
      Inventum.animations.showKeyframe(parseInt(e.target.value - 1),{durationOveride:durationOveride});
    }
  }

  if (props.keyframes.length < 1) {
    return(
      <div className="timeline">
        <div className="timelineClose" onClick={props.toggleTimeline}>Return</div>
        <div className="timelineTitle">Animation has no actions - Timeline Unavailable.</div>
      </div>
    );
  }

  let progressWidth = 100 / (props.keyframes.length -1) * (props.currentKeyframe - 1);
  progressWidth = progressWidth.toString() + "%";
  let sliderFillStyle = {
    width: progressWidth
  }

  return (
    <div className="timeline">
      <div className="timelineTitle">{props.animationName} ({props.keyframes[props.currentKeyframe - 1].name})</div>
      <div className="timelineClose" onClick={props.toggleTimeline}>Return</div>
      <input type="range" min="1" max={props.keyframes.length} value={props.currentKeyframe} step="1" className={"timelineSlider" + (props.playing ? " disabled" : "")} onChange={handleChange}></input>
      <div className="sliderBackground" />
      <div className="sliderProgressFill" style={sliderFillStyle} />
      <div className="pipContainer">
        {props.keyframes.map((keyframe,index) => <Pip key={index} keyframeID={index} totalKeys={props.keyframes.length} name={keyframe.name} currentKey={props.currentKeyframe} playing={props.playing} />)}
      </div>
  </div>
  )
}

export default TimelineControls
