import { animations } from '../camera/animations.js';

var tour = { public: {} };

var animationID = -1;
var animation = {};

tour.setAnimation = function setAnimation (id) {
  animationID = id;
};

tour.initialize = function initialize () {
  animation = animations.getAnimationByID(animationID);
};

tour.public.registerStateCallback = function registerStateCallback (callback) {
  animations.registerStateCallback(callback);
};

tour.public.toggleAnimation = function toggleAnimation () {
  animations.toggleAnimation(animationID);
};

export { tour };
