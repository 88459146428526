import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import axios from '../../axios-config.js';

class MeetAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rooms:{},
      users:{}
    };
    this.getData = this.getData.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.siteAdmin !== this.props.siteAdmin) {
      this.getData();
    }
  }

  getData() {
    axios.get('https://api.inventum3d.com/meeting/admin')
    .then(result => {
      this.setState({rooms:result.data.rooms, users:result.data.users})
    })
  }

  render() {
    if (!this.props.siteAdmin) {
      return (
        <div className={"MeetAdminContainer"}>
          Not Authorized
        </div>
      )
    }
    return (
      <div className={"MeetAdminContainer"}>
        <div>
        {Object.keys(this.state.rooms).map(roomID => <Room key={roomID} data={this.state.rooms[roomID]} />)}
        </div>
      </div>
    )
  }
}

function Room(props) {
  console.log(props);
  return (
    <div>
      <table>
      <thead>
        <tr>
          <th>Key</th>
          <th>Value</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>active</td>
          <td>{props.data.active}</td>
        </tr>
        <tr>
          <td>creatorID</td>
          <td>{props.data.creatorID}</td>
        </tr>
        <tr>
          <td>Leader</td>
          <td>{props.data.leader}</td>
        </tr>
        <tr>
          <td>projectName</td>
          <td>{props.data.projectName}</td>
        </tr>
        <tr>
          <td>roomID</td>
          <td>{props.data.roomID}</td>
        </tr>
        </tbody>
      </table>
    </div>
  )
}

function Users() {
  return (
    <div>Users</div>
  )
}

function Messages() {
  return (
    <div>Messages</div>
  )
}

let mapStateToProps = (state) => ({
  inventumToken:state.user.token,
  siteAdmin:(state.user.siteAdmin) ? true : false
})

let mapDispatchToProps = dispatch => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeetAdmin))
