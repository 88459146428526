import React from 'react';
import {withRouter} from 'react-router-dom';
import LeafletContainer from './LeafletContainer.js';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {loadProjects} from '../../Redux/actions/projects.js';
import {loadCompanyMap} from '../../Redux/actions/companyMapDefaultState.js';
import {setTitle,setSubtitle,setWebsite,setLayerContentMode,setLogo} from '../../Redux/actions/sidebarUI.js';
import {setActiveCompanyID, setActiveProjectID, setActivePageType} from '../../Redux/actions/activePage.js';
import {closeModal} from '../../Redux/actions/ui.js';
import axios from '../../axios-config.js';

class MapPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      geoJSON:{}
    };
    this.checkRoute = this.checkRoute.bind(this);
    this.getProjectsByCompanyID = this.getProjectsByCompanyID.bind(this);
    this.getProjectsByCompanySlug = this.getProjectsByCompanySlug.bind(this);
    this.handleResult = this.handleResult.bind(this);
  }

  //NOTE: React Update
  componentDidMount() {
    this.checkRoute(this.props.match);
    this.props.setPageMode('2D');
    this.props.closeModal();
  }

  //NOTE: React Update (componentWillReceiveProps)
  componentDidUpdate(prevProps) {
    if (this.props.match.path != prevProps.match.path) {
      this.checkRoute(this.props.match);
    }

    if (this.props.token != prevProps.token) {
      this.checkRoute(this.props.match);
    }
  }

  checkRoute(match) {
    if(match.path == "/c/:companySlug") {
      this.getProjectsByCompanySlug(match.params.companySlug);
    }else if(match.path == "/g/:companyID") {
      this.getProjectsByCompanyID(match.params.companyID);
    }
  }

  getProjectsByCompanyID(id) {
    axios.get(`https://api.inventum3d.com/company/${id}`)
    .then(this.handleResult)
  }

  handleResult(result) {
    let data = result.data.projects;
    if (data === undefined || data.length == 0) {
      //FIXME BUG
      //There is an annoying Race Condition where the following happens
      //The user directly navigates to a private company page
      //The token hasn't loaded yet so axios gets into a race condition where it doesn't have the auth token yet
      //This causes no data to be retreived from the server and then the page navigates back to the homepage
      //this.props.history.push('/');
      return;
    }
    //If the user has come to the page with an ID but the company has a slug then we redirect to the slug version for a nicer URL;
    //NOTE This is a bit inefficent as we are doing the same API query twice. It should only occur when clicking on the myCompany link though
    if (this.props.match.path == "/g/:companyID" && result.data.slug) {
      this.props.history.push(`/c/${result.data.slug}`);
    }

    //Update ActivePage with CompanyID
    this.props.setActiveCompanyID(result.data.id);
    this.props.setActiveProjectID('');
    this.props.setActivePageType("COMPANY");

    this.props.setTitle(result.data.name);
    this.props.setWebsite(result.data.website);
    this.props.setSubtitle(); //Clears the subtitle
    this.props.setLogo(result.data.logo);
    let cleanedProjects = {};
    let projectIDList = [];
    data.map((project) => {
      cleanedProjects[project.project_id] = project;
      projectIDList.push(project.project_id);
    })
    let mapData = {};
    mapData[result.data.id] = Object.assign({},result.data);
    mapData[result.data.id].projects = projectIDList;
    this.props.loadProjects(cleanedProjects);
    this.props.loadCompanyMap(mapData);
  }

  getProjectsByCompanySlug(slug) {
    axios.get(`https://api.inventum3d.com/company/?slug=${slug}`)
    .then(this.handleResult);
  }

  render() {
    let projects = this.props.projects;
    let mapTileLayers = this.props.mapTileLayers;
    let sidebarItems = this.props.sidebarItems;
    //TODO Entry point for Company Editing Settings
    //        {this.state.myCompany ? <div>This Company Belongs To You</div> : null}

    let BackButton = (props) => {
      return (
        <Link to="/"><div className={"backHomeButton" + (this.props.sidebarVisible ? " moved" : "") + (this.props.logo ? " logo" : "")}>Show all Inventum3D Projects</div></Link>
      )
    }
    return (
      <div>
        {this.props.hasVisitedHome ? <BackButton /> : null}
        <LeafletContainer path={this.props.match.path} history={this.props.history} projects={projects} mapLayers={mapTileLayers} sidebarItems={sidebarItems} geoJSON={this.state.geoJSON} />
      </div>
    )
  }
}

let mapStateToProps = (state) => ({
  token:state.user.token,
  myCompanies:state.user.companies,
  projects:state.projects,
  mapTileLayers:state.mapTileLayers,
  sidebarItems:state.sidebarItems,
  sidebarVisible:state.sidebarUI.visible,
  hasVisitedHome:state.ui.hasVisitedHome,
  logo:state.sidebarUI.logo
})


let mapDispatchToProps = dispatch => ({
  loadProjects:(projects) => {
    dispatch(loadProjects(projects));
  },
  loadCompanyMap:(mapData) => {
    dispatch(loadCompanyMap(mapData))
  },
  setTitle:(companyName) => {
    dispatch(setTitle(companyName))
  },
  setSubtitle:(title) => {
    dispatch(setSubtitle(title))
  },
  setWebsite:(website) => {
    dispatch(setWebsite(website))
  },
  setLogo:(logo) => {
    dispatch(setLogo(logo))
  },
  setPageMode:(mode) => {
    dispatch(setLayerContentMode(mode))
  },
  closeModal:() => {
    dispatch(closeModal())
  },
  setActiveCompanyID:(id) => {
    dispatch(setActiveCompanyID(id))
  },
  setActivePageType:(type) => {
    dispatch(setActivePageType(type))
  },
  setActiveProjectID:(id) => {
    dispatch(setActiveProjectID(id))
  }
})

const CompanyMapContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MapPage));


export default CompanyMapContainer;
