import * as THREE from 'three';
import { store } from '../core/store.js';

const scales = [];
const scalesByID = {};

const scale = {public:{}};

function createScale () {
  const scaleBarLength = len || 5;
  const scaleBarHeight = thickness || 1;
  const scaleTipHeight = thickness * 3;
  const scaleBarWidth = scaleBarHeight;
  const scaleTipLength = scaleBarWidth;

  const scaleCenter = new THREE.Vector3(0, 0, 0);

  const endBox = new THREE.BoxBufferGeometry(scaleTipLength, scaleTipHeight, scaleBarWidth);
  const barBox = new THREE.BoxBufferGeometry(scaleBarLength, scaleBarHeight, scaleBarWidth);
  var meshEndL = new THREE.Mesh(endBox, new THREE.MeshStandardMaterial());
  var meshEndR = new THREE.Mesh(endBox, new THREE.MeshStandardMaterial());
  var meshBar = new THREE.Mesh(barBox, new THREE.MeshStandardMaterial());
  store.scene.add(meshBar);
  store.scene.add(meshEndL);
  store.scene.add(meshEndR);

  meshEndL.position.copy(scaleCenter);
  meshEndR.position.copy(scaleCenter);
  meshEndL.position.add(new THREE.Vector3(-(scaleBarLength / 2), (scaleTipHeight / 2) - (scaleBarHeight / 2), 0));
  meshEndR.position.add(new THREE.Vector3( (scaleBarLength / 2), (scaleTipHeight / 2) - (scaleBarHeight / 2), 0));

  scales.push({})
}

scale.public.createScale = createScale;

export { scale };
