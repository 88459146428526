import * as THREE from 'three';
var store = {
  globalscale: 0.5,
  container: {},
  scene: {},
  camera: {},
	sceneFOV: 75,
  controls: {},
  sceneHUD: {},
  cameraHUD: {},
  webglRenderer: {},
  canvasBackgroundColor: '#FFFFFF',
  modelCount: 0,
  modelsLoaded: 0,
  cameraTargetPosition: new THREE.Vector3(),
  mapabilityTransform: {
    x: 0,
    y: 0,
    z: 0
  },
  regionalTransform: {
    x: 0,
    y: 0,
    z: 0
  },
  inventumTransform: {
    x: 0,
    y: 0,
    z: 0
  },
  storagePath: '',
  oldStoragePaths: [],
  editingLabels: false,
  markersNeedUpdate: false,
  broadcastMode: false,
  followerMode: false,
  forcedAspect: null,
  presenterWidth: 0,
  presenterHeight: 0,
  animationMixers: []
};

export { store };
