import React from 'react';

const valFromPercent = (percent, total) => {
  return Math.floor(parseFloat(percent) * parseInt(total));
}

class AnnotateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLinePoints:[],
      lines:[],
      canvasState:{},
      mouseDown:false,
      penEnabled:false,
      penColor:"#18636e",
      penSize:5,
      lastMouseEmitTime:0
    };
    this.canvasRef = React.createRef();
    this.canvasMouseRef = React.createRef();
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.drawPoints = this.drawPoints.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.setPenColor = this.setPenColor.bind(this);
    this.setPenSize = this.setPenSize.bind(this);
    this.togglePen = this.togglePen.bind(this);
    this.drawCanvasState = this.drawCanvasState.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.drawCursor = this.drawCursor.bind(this);
		this.handleClearAll = this.handleClearAll.bind(this);
		this.handleClearOwn = this.handleClearOwn.bind(this);
		this.handleUndo = this.handleUndo.bind(this);
		this.handleScreenshot = this.handleScreenshot.bind(this);
  }

  componentDidMount() {
    this.memCanvas = document.createElement('canvas');
    this.memCanvas.width = this.props.resolution.width;
    this.memCanvas.height = this.props.resolution.height;

    this.canvasMouseRef.current.width = this.props.resolution.width;
    this.canvasMouseRef.current.height = this.props.resolution.height;

    this.canvasRef.current.width = this.props.resolution.width;
    this.canvasRef.current.height = this.props.resolution.height;

    window.addEventListener('resize', this.handleResize);
    if (this.props.socket) {
      this.props.socket.on('inventum-annotation-lines', (state) => {
        this.setState({canvasState:state}, () => {
          this.drawCanvasState();
        })
      })
      this.props.socket.on('inventum-annotation-mouse-move', (mouse) => {
        this.drawCursor(mouse);
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resolution.width !== this.props.resolution.width || prevProps.resolution.height !== this.props.resolution.height) {
      this.memCanvas.width = this.props.resolution.width;
      this.memCanvas.height = this.props.resolution.height;
      //Only should need to update memCanvas as canvas will update automatically in the renderfunction
      //this.canvasRef.current.width = this.props.resolution.width;
      //this.canvasRef.current.height = this.props.resolution.height;
    }

    //Clear the mouse canvas when the controller changes.
    if (prevProps.isController !== this.props.isController) {
      if (!this.canvasMouseRef.current) return;
      let cursctx = this.canvasMouseRef.current.getContext('2d');
      if (!cursctx) return;
      cursctx.clearRect(0, 0, this.canvasMouseRef.current.width, this.canvasMouseRef.current.height);
    }

  }

  componentWillUnmount() {

  }

  handleResize() {
    this.props.updateResolution(window.innerWidth, window.innerHeight);
    if (this.props.isAdmin) {
      if (this.state.lines.length > 0) {
        this.setState({lines:[]});
        this.props.socket.emit('inventum-annotation-lines', []);
      }
    }
  }

  handleMouseUp(e) {
    let memctx = this.memCanvas.getContext('2d');
    memctx.clearRect(0, 0, this.memCanvas.width, this.memCanvas.height);
    //memctx.drawImage(this.canvasRef.current, 0, 0);
		let state = {points:this.state.activeLinePoints,color:this.state.penColor,size:this.state.penSize};
    if (this.props.socket) {
      this.props.socket.emit('inventum-annotation-lines', state);
    } else {
			let tempCanvasState = this.state.canvasState;
			if (tempCanvasState.hasOwnProperty("NO_SOCKET_MODE")) {
				tempCanvasState["NO_SOCKET_MODE"].push(state);
			} else {
				tempCanvasState["NO_SOCKET_MODE"] = [];
				tempCanvasState["NO_SOCKET_MODE"].push(state);
			}

			this.setState({canvasState: tempCanvasState}, () => {
				this.drawCanvasState();
			})

		}
    this.setState({mouseDown:false, activeLinePoints:[]})

  }

  handleMouseDown(e) {
    this.setState({mouseDown:true})
  }

  drawCursor(data) {
    if (!this.canvasRef.current || this.props.isController) return
    let cursctx = this.canvasMouseRef.current.getContext('2d');
    cursctx.clearRect(0, 0, this.canvasMouseRef.current.width, this.canvasMouseRef.current.height);
    let cursX = valFromPercent(data.x, this.canvasMouseRef.current.width);
    let cursY = valFromPercent(data.y, this.canvasMouseRef.current.height);

    cursctx.beginPath();
    cursctx.moveTo(cursX, cursY);
    cursctx.lineTo(cursX, cursY + 16);
    cursctx.lineTo(cursX + 4, cursY + 13);
    cursctx.lineTo(cursX + 7, cursY + 18);
    cursctx.lineTo(cursX + 9, cursY + 18);
    cursctx.lineTo(cursX + 7, cursY + 12);
    cursctx.lineTo(cursX + 7, cursY + 12);
    cursctx.lineTo(cursX + 11, cursY + 12);
    cursctx.lineTo(cursX, cursY);
    //cursctx.arc(cursX, cursY, 3, 0, 2 * Math.PI, false);
    cursctx.fillStyle = 'white';
    cursctx.strokeStyle = 'black';
    cursctx.fill();
    cursctx.stroke();

    let textWidth = cursctx.measureText(data.user).width
    let textHeight = cursctx.measureText(data.user).height
    cursctx.beginPath();
    cursctx.rect(cursX + 13, (cursY + 16) - 18, textWidth + 6, 18);
    cursctx.fillStyle = 'rgba(0,0,0,0.8)';
    cursctx.fill();

    //Username
    cursctx.font = "15px Arial";
    cursctx.fillStyle = "#FFFFFF";
    //cursctx.textAlign = "center";
    cursctx.fillText(data.user, cursX + 16, cursY + 12);


  }

  drawCanvasState() {
    let cState = this.state.canvasState;
    let canvas = this.canvasRef.current;
    if (!canvas) return;
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const drawLine = (line) => {
      const points = line.points;
      if (!points) return;
      if (points.length == 0) return
      const color = line.color;
      const size = line.size;
      ctx.beginPath();
      ctx.moveTo(valFromPercent(points[0].x, canvas.width), valFromPercent(points[0].y, canvas.height));
      for (var i = 1; i < points.length - 2; i++) {
        var c = (valFromPercent(points[i].x, canvas.width) + valFromPercent(points[i + 1].x, canvas.width)) / 2;
        var d = (valFromPercent(points[i].y, canvas.height) + valFromPercent(points[i + 1].y, canvas.height)) / 2;
        ctx.quadraticCurveTo(valFromPercent(points[i].x, canvas.width), valFromPercent(points[i].y, canvas.height), c, d);
      }
      ctx.quadraticCurveTo(valFromPercent(points[i].x, canvas.width),
      valFromPercent(points[i].y, canvas.height),
      valFromPercent(points[i + 1].x, canvas.width),
      valFromPercent(points[i + 1].y, canvas.height));

      ctx.strokeStyle = color;
      ctx.lineWidth = size;
      ctx.stroke();
    }

    Object.keys(cState).map(userLines => {
      cState[userLines].map(line => {
        drawLine(line);
      })
    })

    let memctx = this.memCanvas.getContext('2d');
    memctx.clearRect(0, 0, this.memCanvas.width, this.memCanvas.height);
    memctx.drawImage(this.canvasRef.current, 0, 0);

  }

  drawPoints() {
    let canvas = this.canvasRef.current;
    let ctx = canvas.getContext('2d');
    let points = this.state.activeLinePoints;
    if (points.length < 6) return;
    ctx.beginPath();
    ctx.moveTo(valFromPercent(points[0].x, canvas.width), valFromPercent(points[0].y, canvas.height));
    for (var i = 1; i < points.length - 2; i++) {
      var c = (valFromPercent(points[i].x, canvas.width) + valFromPercent(points[i + 1].x, canvas.width)) / 2;
      var d = (valFromPercent(points[i].y, canvas.height) + valFromPercent(points[i + 1].y, canvas.height)) / 2;
      ctx.quadraticCurveTo(valFromPercent(points[i].x, canvas.width), valFromPercent(points[i].y, canvas.height), c, d);
    }
    ctx.quadraticCurveTo(valFromPercent(points[i].x, canvas.width),
    valFromPercent(points[i].y, canvas.height),
    valFromPercent(points[i + 1].x, canvas.width),
    valFromPercent(points[i + 1].y, canvas.height));

    ctx.strokeStyle = this.state.penColor;
    ctx.lineWidth = this.state.penSize;
    ctx.stroke();
  }

  handleMouseMove(e) {
    let canvas = this.canvasRef.current;
    let ctx = canvas.getContext('2d');
    let mouseX = parseInt(e.nativeEvent.offsetX) / parseInt(canvas.width);
    let mouseY = parseInt(e.nativeEvent.offsetY) / parseInt(canvas.height);

    //Don't show mouse movements for drawing mode
    /*if (this.props.socket) {
      if (Date.now() - this.state.lastMouseEmitTime > 100) {
        this.props.socket.emit('inventum-annotation-mouse-move', {x:mouseX, y:mouseY});
        this.setState({lastMouseEmitTime:Date.now()});
      }
    }*/

    if (!this.state.mouseDown) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(this.memCanvas, 0, 0);
    let points = this.state.activeLinePoints;
    points.push({x:parseInt(e.nativeEvent.offsetX) / parseInt(canvas.width), y:parseInt(e.nativeEvent.offsetY) / parseInt(canvas.height)}); //clientX clientY
    this.drawPoints();
  }

  setPenColor() {
    var penColor = prompt("Pen Color:", this.state.penColor);

    if (/^#[0-9A-F]{6}$/i.test(penColor)) {
      this.setState({penColor:penColor})
    }
  }

  setPenSize() {
    var penSize = prompt("Pen Size:", this.state.penSize);

    if (!isNaN(penSize)) {
      this.setState({penSize:parseFloat(penSize)})
    }
  }

  togglePen() {
    this.setState({penEnabled:!this.state.penEnabled});
  }

	handleClearAll() {
		if (this.props.socket) {
			this.props.socket.emit('inventum-annotation-clear-drawing');
		} else {
			let tState = this.state.canvasState;
			tState['NO_SOCKET_MODE'] = [];
			this.setState({canvasState: tState}, () => {
				this.drawCanvasState();
			});
		}
	}

	handleClearOwn() {
		if (!this.props.socket) return;
		this.props.socket.emit('inventum-annotation-clear-own-lines');
	}

	handleUndo() {
		if (this.props.socket) {
			this.props.socket.emit('inventum-annotation-undo');
		} else {
			let tState = this.state.canvasState;
			if (tState['NO_SOCKET_MODE'] && tState['NO_SOCKET_MODE'].length > 0) {
				tState['NO_SOCKET_MODE'].pop();
				this.setState({canvasState: tState}, () => {
					this.drawCanvasState();
				})
			}
		}
	}

	handleScreenshot() {
		const saveDrawingCanvas = () => {
			return new Promise((resolve, reject) => {
				let canvas = this.canvasRef.current;
				canvas.toBlob(resolve);
			})
		};

		let blob3DPromise = Inventum.screenshot.captureForMerge();
		let blobDrawingPromise = saveDrawingCanvas();

		Promise.all([blob3DPromise, blobDrawingPromise]).then((blobArray) => {
			console.log('Done');
			console.log(blobArray);

			let tCanvas = document.createElement('canvas');
			let ctx = tCanvas.getContext('2d');
			tCanvas.width = window.innerWidth;
			tCanvas.height = window.innerHeight;

			const saveFinalImage = (blob) => {
				var tempanchor = document.createElement('a');
			  tempanchor.setAttribute('download', 'test.png');
		    tempanchor.href = URL.createObjectURL(blob);
		    tempanchor.setAttribute('type', 'file');
		    tempanchor.click();
				console.log('Done!');
			};

			let bgImage = new Image();

			bgImage.onload = () => {
				let fgImage = new Image();
				fgImage.onload = () => {
					ctx.drawImage(bgImage,0,0);
					ctx.drawImage(fgImage,0,0);
					tCanvas.toBlob(saveFinalImage);
				}
				fgImage.src = URL.createObjectURL(blobArray[1]);
			}

			bgImage.src = URL.createObjectURL(blobArray[0]);

		});
	}

  render() {
    return (
      <div>
      <div style={{position:"absolute", top:"0px", left:"0px", width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
        <canvas style={{position:"relative", zIndex:2, background:"none", pointerEvents:"none"}} ref={this.canvasMouseRef} width={this.props.resolution.width} height={this.props.resolution.height} />
      </div>
      <div style={{position:"absolute", top:"0px", left:"0px", width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
        {this.props.canUsePen ? <DrawingControls handleScreenshot={this.handleScreenshot} handleUndo={this.handleUndo} handleClearAll={this.handleClearAll} handleClearOwn={this.handleClearOwn} socket={this.props.socket} isAdmin={this.props.isAdmin} penEnabled={this.state.penEnabled} togglePen={this.togglePen} setPenSize={this.setPenSize} setPenColor={this.setPenColor} /> : null}
        <canvas style={{position:"relative", zIndex:1, background:"none", pointerEvents:this.state.penEnabled ? "all" : "none"}} ref={this.canvasRef} onMouseDown={this.handleMouseDown} onMouseUp={this.handleMouseUp} onMouseMove={this.handleMouseMove} width={this.props.resolution.width} height={this.props.resolution.height} />
      </div>
      </div>
    )
  }
}

function DrawingControls(props) {
  return (
    <div className="DrawingControls">
      <div className="DrawingControlsHeader">Drawing Controls</div>
      <div className="DrawingControlsButton" onClick={props.togglePen}>{props.penEnabled ? "Disable" : "Enable"} Pen</div>
      {props.socket ? <div className="DrawingControlsButton" onClick={props.handleClearOwn}>Clear Your Lines</div> : null}
      {props.isAdmin    ? <div className="DrawingControlsButton" onClick={props.handleClearAll}>Clear Entire Drawing</div> : null}
      {props.penEnabled ? <div className="DrawingControlsButton" onClick={props.handleUndo}>Undo</div> : null}
      {props.penEnabled ? <div className="DrawingControlsButton" onClick={props.setPenColor}>Set Pen Color</div> : null}
      {props.penEnabled ? <div className="DrawingControlsButton" onClick={props.setPenSize}>Set Pen Size</div>   : null}
		 	<div className="DrawingControlsButton" onClick={props.handleScreenshot}>Save Screenshot</div>

    </div>
  )
}

export default AnnotateContainer;
