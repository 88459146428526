import React from 'react';
import axios from '../../../axios-config.js';
import {connect} from 'react-redux';
import {closeModal} from '../../../Redux/actions/ui.js';

const responseGoogle = (response) => {
  console.log(response);
}

class RegisterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactName:"",
      companyName:"",
      email:"",
      phone:"",
      description:"",
      activePage:"INTEREST",
      errorMessage:""
    }
    this.submitInterest = this.submitInterest.bind(this);
    this.submitProject = this.submitProject.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.closeModal();
  }

  submitInterest() {
    axios.post('https://api.inventum3D.com/requestinterest', {
      contactName:this.state.contactName,
      email:this.state.email
    })
    .then((response) => {
      this.setState({errorMessage:"", activePage:"SUBMIT_INTEREST"});
    })
    .catch((error) => {
      console.log(error);
    })
  }

  submitProject() {
    axios.post('https://api.inventum3D.com/requestproject', {
      contactName:this.state.contactName,
      companyName:this.state.companyName,
      email:this.state.email,
      phone:this.state.phone,
      description:this.state.description
    })
    .then((response) => {
      this.setState({errorMessage:"", activePage:"SUBMIT_PROJECT"});
    })
    .catch((error) => {
      console.log(error);
    })
  }

  render() {
    let style = {
      "display":"flex",
      "flexDirection":"row",
      "pointerEvents":"auto",
      "fontSize":"20px",
      "color":"#FFFFFF",
      "fontFamily":'Sans-Serif',
      "borderRadius":"20px",
      "overflow":"hidden",
      "boxShadow":"rgb(0, 0, 0, 0.8) 0px 0 13px 0",
      "width":"80%",
      "maxWidth":"1000px",
      "height":"60%",
      "position":"relative"
    }
    let textAreaStyle = {
      height:"250px"
    }

    const handleAddProject = () => {
      this.setState({activePage:"REQUEST_PROJECT"});
    };

    const updateValue = (key, value) => {
      const update = {};
      update[key] = value;
      this.setState(update);
    };

    const setError = message => this.setState({errorMessage:message});
    return (
      <div style={style}>
        <HeroElement />
        {this.state.activePage === "INTEREST" ? <RegisterInterest handleClose={this.handleClose} setError={setError} error={this.state.errorMessage} updateValue={updateValue} email={this.state.email} contactName={this.state.contactName} submitInterest={this.submitInterest} handleAddProject={e=>this.setState({activePage:"REQUEST_PROJECT"})} /> : null}
        {this.state.activePage === "REQUEST_PROJECT" ? <RequestProject handleBack={()=>{this.setState({activePage:"INTEREST"})}} handleClose={this.handleClose} setError={setError} error={this.state.errorMessage} updateValue={updateValue} email={this.state.email} contactName={this.state.contactName} companyName={this.state.companyName} phone={this.state.phone} details={this.state.description} submitProject={this.submitProject} /> : null}
        {(this.state.activePage === "SUBMIT_INTEREST" || this.state.activePage === "SUBMIT_PROJECT") ? <AfterSubmit handleClose={this.handleClose} activePage={this.state.activePage} /> : null}
      </div>
    )
  }
}

function HeroElement () {
  return (
    <div className="RegisterHeroElement">
      <div className="RegisterHeroElementText">
        <div style={{width:"100%",textAlign:"center"}}>Unlock Your 3D Data</div>
        <div style={{fontSize:"15px"}}>Use Inventum3D to view your 3D models in the browser.<br/>No plugins or downloads required.<br/>Stand out from the crowd with a 3D viewer in your website or presentation.</div>
      </div>
    </div>
  );
}

function RegisterInterest (props) {
  let handleSubmit = () => {
    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(props.email)) {
      props.setError('Valid Email Address Required');
      return;
    };
    props.submitInterest();
  }
  return (
    <div style={{position:"relative", backgroundColor:"#FFFFFF", width:"50%", height:"100%", color:"#000", display:"flex", alignItems:"center", justifyContent:"center"}}>
      <div style={{maxWidth:"80%"}}>
        <div className="RegisterCloseButton" onClick={props.handleClose}><i className="material-icons notranslate">close</i></div>
        <div style={{fontSize:"30px", fontWeight:"bold"}}>Register Interest</div>
        <div style={{fontSize:"16px", marginTop:"10px"}}>
          <div>We are still developing our self service system.<br />Submit your email and we'll keep you updated.</div>
        </div>
        <input className="RegisterEmailInput" type="text" placeholder="Name (Optional)" onChange={(e)=>{props.updateValue('contactName', e.target.value)}} value={props.contactName} />
        <input className="RegisterEmailInput" type="email" placeholder="Email" onChange={(e)=>{props.updateValue('email', e.target.value)}} value={props.email} />
        {props.error.length > 0 ? <div className="RegisterErrorMessage">{props.error}</div> : null}
        <div className="RegisterSubmitButton" onClick={handleSubmit}>Submit</div>
        <div className="RegisterOrContainer">
          <div className="RegisterOrLineDivide" />OR<div className="RegisterOrLineDivide" />
        </div>
        <div className="RegisterSubmitButton" onClick={props.handleAddProject}>Add My Project!</div>
      </div>
    </div>
  )
}

function RequestProject (props) {
  let handleSubmit = () => {
    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(props.email)) {
      props.setError('Valid Email Address Required');
      return;
    };
    if (props.contactName.length == 0) {
      props.setError('Contact Name Required');
      return;
    }
    props.submitProject();
  }
  return (
    <div style={{position:"relative", backgroundColor:"#FFFFFF", width:"50%", height:"100%", color:"#000", display:"flex", alignItems:"center", justifyContent:"center"}}>
      <div style={{maxWidth:"80%"}}>
        <div className="RegisterCloseButton" onClick={props.handleClose}><i className="material-icons notranslate">close</i></div>
        <div className="RegisterBackButton" onClick={props.handleBack}>Back</div>
        <div style={{fontSize:"30px", fontWeight:"bold"}}>Add My Project</div>
        <div style={{fontSize:"16px", marginTop:"10px"}}>
          <div>Currently Projects are added by the site team.<br/>Fill out this form and we'll be in touch.</div>
        </div>
        <input className="RegisterEmailInput" type="text" placeholder="Contact Name" onChange={(e)=>{props.updateValue('contactName', e.target.value)}} value={props.contactName} />
        <input className="RegisterEmailInput" type="email" placeholder="Email" onChange={(e)=>{props.updateValue('email', e.target.value)}} value={props.email}/>
        <input className="RegisterEmailInput" type="text" placeholder="Company Name (Optional)" onChange={(e)=>{props.updateValue('companyName', e.target.value)}} value={props.companyName}/>
        <input className="RegisterEmailInput" type="text" placeholder="Phone (Optional)" onChange={(e)=>{props.updateValue('phone', e.target.value)}} value={props.phone}/>
        <textarea placeholder="Tell us about your project" className="ProjectDetailsTextArea" onChange={(e)=>{props.updateValue('description', e.target.value)}} value={props.description}/>
        {props.error.length > 0 ? <div className="RegisterErrorMessage">{props.error}</div> : null}
        <div className="RegisterSubmitButton" onClick={handleSubmit}>Submit</div>
      </div>
    </div>
  )
}

function AfterSubmit (props) {
  return (
    <div style={{position:"relative", backgroundColor:"#FFFFFF", width:"50%", height:"100%", color:"#000", display:"flex", alignItems:"center", justifyContent:"center"}}>
      <div style={{maxWidth:"80%"}}>
        <div className="RegisterCloseButton" onClick={props.handleClose}><i className="material-icons notranslate">close</i></div>
        <div style={{fontSize:"30px", fontWeight:"bold"}}>Thank You For Your Interest</div>
        <div style={{fontSize:'16px', paddingTop:'10px', paddingBottom:'10px'}}>{props.activePage == "SUBMIT_PROJECT" ? "We'll contact you soon to discuss your project" : "We'll keep you updated with Inventum3D"}</div>
        <div className="RegisterSubmitButton" onClick={props.handleClose}>Close</div>
      </div>
    </div>
  )
}

/*
<GoogleLogin
   clientId="992705493990-9qrb5375eur86ta59eenv8a1dvsmj7jf.apps.googleusercontent.com"
   buttonText="Sign In"
   onSuccess={responseGoogle}
   onFailure={responseGoogle}
   cookiePolicy={'single_host_origin'}
 />
*/

let mapDispatchToProps = dispatch => ({
  closeModal:() => {
    dispatch(closeModal());
  }
})

let mapStateToProps = (state) => ({
})

export default connect(mapStateToProps,mapDispatchToProps)(RegisterContainer);
