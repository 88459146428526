import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import { TweenManager } from '../core/TweenManager.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { store } from '../core/store.js';
import { models } from '../models/models.js';
import { notifications } from '../ui/notifications.js';

var HELPER_SIZE = 10000;// 100000
var planesByID = {}; // Object with keys indexed to ID of Plane from scene.json file
var activePlanes = []; // Flat list of active planes IDs

var controller = { public: {} };

var modelsClipboard = [];

const controls = [];

function toggleLocalClipping () {
  // Enables/Disables the localClipping as needed
  if (activePlanes.length > 0 && store.webglRenderer.localClippingEnabled === false) {
    store.webglRenderer.localClippingEnabled = true;
  } else if (activePlanes.length === 0 && store.webglRenderer.localClippingEnabled === true) {
    store.webglRenderer.localClippingEnabled = false;
  }
}

function generatePlane (position) {
  var plane = new THREE.Plane(new THREE.Vector3(position.x, position.y, position.z), position.c); // Takes a XYZ Vector and a Scalar/Constant
  return plane;
}

function generateHelper (plane) {
	let helper = new THREE.PlaneHelper(plane, HELPER_SIZE, 0xffff00);
	helper.children[0].material.opacity = 0.8;
  return helper;
}

function generateCube () {
  var geometry = new THREE.BoxGeometry(1, 1, 1);
  var material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
  var cube = new THREE.Mesh(geometry, material);
  return cube;
}

function generateControls (light) {
  const control = new TransformControls(store.camera, store.webglRenderer.domElement);
  control.addEventListener('change', (e) => {
    unitVector.copy(tCube.position);
    magnitude = tCube.position.length();
    unitVector.normalize();
    const tVec = {
      x: unitVector.x * -1,
      y: unitVector.y * -1,
      z: unitVector.z * -1,
      c: magnitude
    };
    controller.public.editPosition(1, 'START', tVec);
    store.requestRender();
  });
  control.addEventListener('dragging-changed', function (event) {
    store.controls.enabled = !event.value;
    store.requestRender();
  });
  let magnitude = 0;
  const unitVector = new THREE.Vector3();
  const tCube = generateCube();
  store.scene.add(tCube);
  control.attach(tCube);
  store.scene.add(control);
  controls.push(control);
  store.requestRender();
}

function findUnusedID () {
  let idFound = false;
  let id = 1;
  while (!idFound) {
    if (planesByID[id] === undefined) {
      idFound = true;
    } else {
      id++;
    }
  }
  return id;
}

controller.loadPlane = function loadPlane (data) {
  if (Object.prototype.hasOwnProperty.call(planesByID, data.id)) {
    console.log('Duplicate Plane Found! Ignoring');
    return;
  }

  const cp = Object.assign({}, data);
  cp.startPosition = Object.assign({}, cp.startPosition); // startVector should always be defined;

  if (cp.endPosition !== null) {
    cp.endPosition = Object.assign({}, cp.endPosition); // endVector should only be copied if it isn't null;
  }

  cp.plane = generatePlane(cp.startPosition);
  cp.helper = generateHelper(cp.plane);
  planesByID[cp.id] = cp;
  store.requestRender();
};

controller.public.createPlane = function createPlane () {
  const cp = {
    id: findUnusedID(),
    startPosition: { x: 0, y: 0, z: 1, c: 0 },
    endPosition: { x: 0, y: 0, z: 1, c: 0 },
    duration: 0,
    models: [],
    name: 'Untitled Clipping Plane'
  };
  cp.plane = generatePlane(cp.startPosition);
  cp.helper = generateHelper(cp.plane);
  planesByID[cp.id] = cp;
  store.requestRender();
  return cp;
};

controller.public.clonePlane = function clonePlane (id) {
  const cp = planesByID[id];
  if (!cp) {
    console.log('Bad ID. Clipping Plane not found.');
    return;
  }

  const newPlane = {
    id: findUnusedID(),
    startPosition: Object.assign({}, cp.startPosition),
    endPosition: Object.assign({}, cp.endPosition),
    duration: cp.duration,
    models: cp.models.slice(),
    name: cp.name + ' (Copy)'
  };

  newPlane.plane = generatePlane(newPlane.startPosition);
  newPlane.helper = generateHelper(newPlane.plane);
  planesByID[newPlane.id] = newPlane;
  store.requestRender();
  return newPlane;
};

controller.public.deletePlane = function deletePlane (id) {
  const cp = planesByID[id];
  if (!cp) {
    console.log('Bad ID. Clipping Plane not found.');
    return;
  }
  controller.public.deactivate(cp.id);
  controller.public.removeHelper(cp.id);
  delete planesByID[id];
};

controller.public.copyModels = function copyModels (id) {
  const cp = planesByID[id];
  if (!cp) {
    console.log('Bad ID. Clipping Plane not found.');
    return;
  }
  modelsClipboard = cp.models.slice();
  notifications.add({ content: 'Models copied to clipboard', displayTime: 2000 });
};

controller.public.pasteModels = function pasteModels (id) {
  const cp = planesByID[id];
  if (!cp) {
    console.log('Bad ID. Clipping Plane not found.');
    return;
  }
  cp.models = modelsClipboard.slice();
  notifications.add({ content: 'Models pasted', displayTime: 2000 });
};

controller.batchActivate = function batchActivate (idArr) {
  idArr.map(id => {
    controller.public.activate(id);
  });
};

controller.public.editPosition = function editStart (id, mode, values) {
  // mode should be START or END
  const cp = planesByID[id];
  if (!cp) {
    console.log('Bad ID. Clipping Plane not found.');
    return;
  }

  controller.initialize(id); // Check if it needs initializing

  if (!cp.helper.parent) {
    store.scene.add(cp.helper);
  }

  const s = new THREE.Spherical(1, degToRad(values.phi), degToRad(values.theta));
  let unitVec = new THREE.Vector3().setFromSpherical(s);
  //unitVec.normalize();

  if (mode === 'START') {
    cp.startPosition.x = unitVec.x;
    cp.startPosition.y = unitVec.y;
    cp.startPosition.z = unitVec.z;
    cp.startPosition.c = values.distance;
    controller.resetStart(id);
  } else if (mode === 'END') {
    cp.endPosition.x = unitVec.x;
    cp.endPosition.y = unitVec.y;
    cp.endPosition.z = unitVec.z;
    cp.endPosition.c = values.distance;
    controller.resetEnd(id);
  }
};

controller.initialize = function activate (id) {
  const cp = planesByID[id];
  if (!cp) {
    console.log('Bad ID. Clipping Plane not found.');
    return;
  }

  if (activePlanes.indexOf(id) === -1) {
    cp.models.map(modelID => {
      // Get model material and add clipping plane to it.
      const model = models.getModelByOriginalID(modelID);
      if (!model) {
        console.log('Problem Model');
        console.log(model);
        console.log(modelID);
        return;
      }
      if (Array.isArray(model.material.clippingPlanes)) {
        model.material.clippingPlanes.push(cp.plane);
      } else {
        model.material.clippingPlanes = [cp.plane];
      }
    });
    activePlanes.push(id);
    toggleLocalClipping();
  }

  store.requestRender();
};

controller.public.removeHelper = function removeHelper (id) {
  const cp = planesByID[id];
  if (!cp) {
    console.log('Bad ID. Clipping Plane not found.');
    return;
  }
  store.scene.remove(cp.helper);
  store.requestRender();
};

controller.public.showHelper = function showHelper (id) {
	const cp = planesByID[id];
	if (!cp) {
		console.log('Bad ID. Clipping Plane not found.');
		return;
	}
	store.scene.add(cp.helper);
	store.requestRender();
}

controller.public.toggleHelper = function toggleHelper (id) {
	const cp = planesByID[id];
	if (!cp) {
		console.log('Bad ID. Clipping Plane not found.');
		return;
	}

	if (cp.helper.parent === store.scene) {
		store.scene.remove(cp.helper);
	}else {
		store.scene.add(cp.helper);
	}
	store.requestRender();
}

controller.resetStart = function resetStart (id) {
  const cp = planesByID[id];
  if (!cp) {
    console.log('Bad ID. Clipping Plane not found.');
    return;
  }

  if (cp.plane.normal.x !== cp.startPosition.x ||
  cp.plane.normal.y !== cp.startPosition.y ||
  cp.plane.normal.z !== cp.startPosition.z ||
  cp.plane.constant !== cp.startPosition.c) {
    cp.plane.normal.x = cp.startPosition.x;
    cp.plane.normal.y = cp.startPosition.y;
    cp.plane.normal.z = cp.startPosition.z;
    cp.plane.constant = cp.startPosition.c;
  }
};

controller.resetEnd = function resetEnd (id) {
  const cp = planesByID[id];
  if (!cp) {
    console.log('Bad ID. Clipping Plane not found.');
    return;
  }

  if (cp.plane.normal.x !== cp.endPosition.x ||
  cp.plane.normal.y !== cp.endPosition.y ||
  cp.plane.normal.z !== cp.endPosition.z ||
  cp.plane.constant !== cp.endPosition.c) {
    cp.plane.normal.x = cp.endPosition.x;
    cp.plane.normal.y = cp.endPosition.y;
    cp.plane.normal.z = cp.endPosition.z;
    cp.plane.constant = cp.endPosition.c;
  }
};

controller.public.activate = function activate (id) {
  //
  const cp = planesByID[id];
  if (!cp) {
    console.log('Bad ID. Clipping Plane not found.');
    return;
  }

  if (activePlanes.indexOf(id) === -1) {
    controller.initialize(id);
  }

  if (cp.duration === 0) {
    return;
  }

  // Copy the start and end to new objects so they don't get mutated.
  const start = Object.assign({}, cp.startPosition);
  const end = Object.assign({}, cp.endPosition);

  TweenManager.createTween(start)
    .to(end, cp.duration)
    .easing(TWEEN.Easing.Cubic.InOut)// Cubic.InOut
    .onUpdate(function () {
      cp.plane.normal.set(start.x, start.y, start.z);
      cp.plane.constant = start.c;
    })
    .start();
  store.requestRender();
};

controller.deactivateAll = function deactivateAll () {
  activePlanes.map(id => {
    controller.public.deactivate(id);
  });
};

controller.public.deactivate = function deactivate (id) {
  if (activePlanes.indexOf(id) === -1) return;
  const cp = planesByID[id];
  if (!cp) {
    console.log('Bad clipping plane ID. Not Found');
    return;
  }
  cp.models.map(id => {
    const model = models.getModelByOriginalID(id);
    if (!model) return;

    if (Array.isArray(model.material.clippingPlanes)) {
      const index = model.material.clippingPlanes.indexOf(cp.plane);
      if (index !== -1) {
        model.material.clippingPlanes.splice(index, 1);
      }
    }
  });

	if (cp.helper.parent === store.scene) {
		store.scene.remove(cp.helper);
	}

  controller.resetStart(id);
  activePlanes.splice(activePlanes.indexOf(id), 1);
  store.requestRender();
};

controller.reset = function reset (id) {
  const cp = planesByID[id];
  if (!cp) {
    console.log('Bad Plane ID');
    return;
  }
  cp.plane.normal.set(cp.startPosition.x, cp.startPosition.y, cp.startPosition.z);
  cp.plane.constant = cp.startPosition.c;
  store.requestRender();
};

controller.getPlane = function getPlane (id) {
  const cp = planesByID[id];
  if (!cp) return {};
  return cp;
};

controller.public.setStartVector = function setStartVector (id, startVec) {
  const cp = planesByID[id];
  if (!cp) return;

  cp.startPosition.x = startVec.x;
  cp.startPosition.y = startVec.y;
  cp.startPosition.z = startVec.z;
  cp.startPosition.c = startVec.c;
};

controller.public.setDuration = function setDuration (id, duration) {
  const cp = planesByID[id];
  if (!cp) return;

  cp.duration = duration;
};

controller.public.setName = function setName (id, name) {
  const cp = planesByID[id];
  if (!cp) return;
  cp.name = name;
};

controller.public.setEndVector = function setEndVector (id, endVec) {
  const cp = planesByID[id];
  if (!cp) return;

  cp.endPosition.x = endVec.x;
  cp.endPosition.y = endVec.y;
  cp.endPosition.z = endVec.z;
  cp.endPosition.c = endVec.c;
};

controller.public.addModel = function addModel (id, modelID) {
  const cp = planesByID[id];
  if (!cp) return;

  const model = models.getModelByOriginalID(modelID);
  if (!model) {
    console.log('Bad model ID. Doesn\'t exist');
    return;
  }

  cp.models.push(modelID);

  if (activePlanes.indexOf(id) !== -1) {
    // Plane is currently active so we need to add the model
    if (Array.isArray(model.material.clippingPlanes)) {
      model.material.clippingPlanes.push(cp.plane);
    } else {
      model.material.clippingPlanes = [cp.plane];
    }
  }
  store.requestRender();
};

controller.public.removeModel = function removeModel (id, modelID) {
  const cp = planesByID[id];
  if (!cp) return;

  const model = models.getModelByOriginalID(modelID);
  if (!model) {
    console.log('Bad model ID. Doesn\'t exist');
    return;
  }

  const modelIndex = cp.models.indexOf(modelID);
  if (modelIndex === -1) {
    return;
  }

  if (activePlanes.indexOf(id) !== -1) {
    if (Array.isArray(model.material.clippingPlanes)) {
      const clippingIndex = model.material.clippingPlanes.indexOf(cp.plane);
      model.material.clippingPlanes.splice(clippingIndex, 1);
    }
  }
  cp.models.splice(modelIndex, 1);
  store.requestRender();
};

controller.public.getClippingPlanes = function getClippingPlanes () {
  const tempArr = [];
  for (var key in planesByID) {
    if (!Object.prototype.hasOwnProperty.call(planesByID, key)) continue;
    const cp = planesByID[key];

    const startDistance = cp.startPosition.c;
    const endDistance = cp.endPosition.c;


    let startSpherical = new THREE.Spherical().setFromCartesianCoords(cp.startPosition.x, cp.startPosition.y, cp.startPosition.z);
    let endSpherical = new THREE.Spherical().setFromCartesianCoords(cp.endPosition.x, cp.endPosition.y, cp.endPosition.z);

    const startPhi = Math.round(radToDeg(startSpherical.phi));
    const endPhi = Math.round(radToDeg(endSpherical.phi));

    const startTheta = Math.round(radToDeg(startSpherical.theta));
    const endTheta = Math.round(radToDeg(endSpherical.theta));

    const tSerialPlane = {
      id: cp.id,
      startPosition: {
        phi: startPhi,
        theta: startTheta,
        distance: startDistance
      },
      endPosition: {
        phi: endPhi,
        theta: endTheta,
        distance: endDistance
      },
      duration: cp.duration,
      models: cp.models.slice(),
      name: cp.name,
      active: activePlanes.indexOf(cp.id) !== -1,
			helperVisible: cp.helper.parent === store.scene
    };

    tempArr.push(tSerialPlane);
  }
  return tempArr;
};

controller.clean = function removeMissingModels () {
	// Clipping Plane Cleanup Work
	Object.keys(planesByID).map(key => {
		if (!Object.prototype.hasOwnProperty.call(planesByID, key)) return;
		const plane = planesByID[key];
		const modelsIDS = window.Inventum.models.getModelsIDS();
		plane.models = plane.models.filter(id => modelsIDS.includes(id.toString()));
	})
};

controller.generateJSON = function generateJSON () {
  const tArray = [];
  Object.keys(planesByID).map(key => {
    if (Object.prototype.hasOwnProperty.call(planesByID, key)) {
      const plane = planesByID[key];
      const tempPlane = {
        id: plane.id,
        startPosition: Object.assign({}, plane.startPosition),
        endPosition: Object.assign({}, plane.endPosition),
        duration: plane.duration,
        models: plane.models.slice(),
        name: plane.name
      };
      tArray.push(tempPlane);
    }
  });
  return { clipping: tArray };
};

controller.getActive = function getActive () {
  return activePlanes.slice();
};

controller.public.getClippingPlanesIDS = function getClippingPlanesIDS () {
  return Object.keys(planesByID);
};

controller.reset = function reset () {
  planesByID = {};
  activePlanes = [];
  modelsClipboard = [];
};

function radToDeg (radians) {
  return radians * (180 / Math.PI);
}

function degToRad (degrees) {
  return degrees * (Math.PI / 180);
}

export { controller as clipping };
