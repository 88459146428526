import * as THREE from 'three';
import { store } from './store.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { models } from '../models/models.js';
import { engineAPI } from './engineAPI.js';
import { selectTool } from '../tools/select.js';
import { tour } from '../tour/tour.js';
import { sceneLoader } from './sceneLoader.js';
import { TweenManager } from '../core/TweenManager.js';
import { markers } from '../ui/screenMarker.js';
import { labels } from '../ui/labels.js';
import { notifications } from '../ui/notifications.js';
import { resolutionManager } from './resolution.js';
import { resetData } from './resetData.js';
import { sceneGrid } from '../world/grid.js';
import { views } from '../camera/views.js';

var onLoadCompleteCallbacks = [];
var hasLoaded = false;
let animationFrameID = null;
let renderRequested = false;
const clock = new THREE.Clock();
var Engine = { public: {} };

function createRenderer () {
  const canvas = document.getElementById('Inventum3D');
  store.webglRenderer = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true,
    canvas: canvas,
    preserveDrawingBuffer: true
  });
  store.webglRenderer.autoClear = false;
	const resolution = resolutionManager.getResolution();
  store.webglRenderer.setSize(resolution.width, resolution.height);
  store.webglRenderer.shadowMap.enabled = true;
  store.webglRenderer.shadowMap.type = THREE.PCFSoftShadowMap;
}

function createScenes () {
  store.scene = new THREE.Scene();
  store.scene2 = new THREE.Scene(); // For Rendering On Top
  // store.scene.background = new THREE.Color(0x000000);
  store.sceneHUD = new THREE.Scene();
}

function createCameras () {
	const resolution = resolutionManager.getResolution();
  store.perspectiveCamera = new THREE.PerspectiveCamera(75, resolution.width / resolution.height, 0.1, 100000);
  store.orthographicCamera = new THREE.OrthographicCamera(resolution.width / -2, resolution.height / 2, resolution.height / 2, resolution.height / -2, -50000, 50000); // -50000
  store.camera = store.perspectiveCamera;
  store.cameraHUD = new THREE.OrthographicCamera(-resolution.width / 2, resolution.width / 2, resolution.height / 2, -resolution.height / 2, 0.1, 10);
  store.cameraHUD.position.z = 10;

  store.controls = new OrbitControls(store.camera, store.webglRenderer.domElement);
  window.controls = store.controls;

  // Enable new Inventum Controls
  store.controls.screenSpacePanning = true;
  store.controls.enableDamping = true;
  store.controls.dampingFactor = 0.2;
  store.controls.maxPolarAngle = 2.2;
  store.controls.zoomSpeed = 0.8;
  store.controls.rotateSpeed = 0.2;
  store.controls.panSpeed = 0.5;
  store.controls.enableKeys = false;
  store.controls.addEventListener('change', () => {
		views.broadcastCamera();
		requestRender();
	});
}

function registerEvents (windowObject) {
  window.addEventListener('resize', resolutionManager.update, false);
  store.webglRenderer.domElement.addEventListener('pointerdown', selectTool.handleClick, false);
  store.webglRenderer.domElement.addEventListener('touchstart', selectTool.handleTouch, false);
  store.webglRenderer.domElement.addEventListener('pointermove', selectTool.handleMove, false);
  engineAPI.setup(windowObject);
}

Engine.setup = function setup (windowObject) {
  reset();
  createRenderer();
  createScenes();
  createCameras();
  resolutionManager.update();
  sceneGrid.generate();
  registerEvents(windowObject);
  requestRender();
};

function reset () {
  onLoadCompleteCallbacks = [];
  hasLoaded = false;
  resetData();
}

Engine.cleanup = function cleanup () {
  renderRequested = false;
  window.cancelAnimationFrame(animationFrameID);
  disposeNode(store.scene);
  disposeNode(store.scene2);

  for (var i = store.scene.children.length - 1; i >= 0; i--) {
    const obj = store.scene.children[i];
    store.scene.remove(obj);
  }

  for (var j = store.scene2.children.length - 1; j >= 0; j--) {
    const obj2 = store.scene2.children[j];
    store.scene2.remove(obj2);
  }

  store.scene = null;
  store.scene2 = null;
  store.sceneHUD = null;
  store.webglRenderer.clear();
  store.webglRenderer.renderLists.dispose();
  store.webglRenderer = null;
  store.camera = null;
  store.cameraHUD = null;
  store.orthographicCamera = null;
  store.controls = null;
  store.cameraTargetPosition = new THREE.Vector3();
  reset();
};

function disposeNode (parentObject) {
  parentObject.traverse(function (node) {
    if (node instanceof THREE.Mesh) {
      if (node.geometry) {
        node.geometry.dispose();
      }
      if (node.material) {
        var materialArray;
        if (node.material instanceof THREE.MeshFaceMaterial || node.material instanceof THREE.MultiMaterial) {
          materialArray = node.material.materials;
        } else if (node.material instanceof Array) {
          materialArray = node.material;
        }

        if (materialArray) {
          materialArray.forEach(function (mtrl, idx) {
            if (mtrl.map) mtrl.map.dispose();
            if (mtrl.lightMap) mtrl.lightMap.dispose();
            if (mtrl.bumpMap) mtrl.bumpMap.dispose();
            if (mtrl.normalMap) mtrl.normalMap.dispose();
            if (mtrl.specularMap) mtrl.specularMap.dispose();
            if (mtrl.envMap) mtrl.envMap.dispose();
            mtrl.dispose();
          });
        } else {
          if (node.material.map) node.material.map.dispose();
          if (node.material.lightMap) node.material.lightMap.dispose();
          if (node.material.bumpMap) node.material.bumpMap.dispose();
          if (node.material.normalMap) node.material.normalMap.dispose();
          if (node.material.specularMap) node.material.specularMap.dispose();
          if (node.material.envMap) node.material.envMap.dispose();
          node.material.dispose();
        }
      }
    }
  });
}

Engine.load = function load (sceneJSON) {
  sceneLoader.load(sceneJSON, onSceneProcessed);
};

Engine.generateSceneJSON = function generateSceneJSON () {
  return sceneLoader.generateSceneJSON();
};

// Engine functions to run after scene file has been processed.
function onSceneProcessed () {
  models.load(Engine.onLoadComplete);
}

Engine.registerLoadCallback = function (callback) {
  if (!hasLoaded) {
    // console.info('Waiting for the scene to populate');
    onLoadCompleteCallbacks.push(callback);
  } else {
    // console.info('Scene population completed');
    callback();
  }
};

Engine.onLoadComplete = function onLoadComplete () {
  hasLoaded = true;
  tour.initialize();
  tour.setAnimation(1);
  onLoadCompleteCallbacks.map((callback, index) => {
    callback();
  });
  notifications.add({ content: 'Scene Load Complete' });
};

function requestRender () {
  if (!renderRequested) {
    renderRequested = true;
    animationFrameID = window.requestAnimationFrame(render);
  }
}

function render () {
  renderRequested = false;
  if (store.controls !== null) {
    if (typeof (store.controls.update) === 'function') {
      store.controls.update();
    }
  }

  // animationFrameID = requestAnimationFrame(render);
  store.webglRenderer.clear();
  store.webglRenderer.render(store.scene, store.camera);

  // Scene 2 is for rendering 3D overlays ontop of the scene.
  store.webglRenderer.clearDepth();
  store.webglRenderer.render(store.scene2, store.camera);

  // sceneHUD is for static overlays rendered in screenspace
  store.webglRenderer.clearDepth();
  markers.render();
  labels.render();
  store.webglRenderer.render(store.sceneHUD, store.cameraHUD);

  if (TweenManager.update()) {
    requestRender();
  }

  if (store.animationMixers.length > 0) {
    store.animationMixers.map(mixer => {
      mixer.update(clock.getDelta());
    });
    requestRender();
  }
  // TWEEN.update();
}
store.render = render;
store.requestRender = requestRender;

export { Engine };
