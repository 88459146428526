import React from 'react';

class WorldMeshPanel extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      activeInput: null,
      tempScale: props.scale,
      open: false
    };
  }

  componentDidUpdate (prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        tempScale: this.props.scale
       });
    }
  }

  render () {
    const updateScale = () => {
      if (isNaN(Number(this.state.tempScale))) {
        this.setState({ tempScale:this.props.scale });
        return;
      }

      Inventum.labels.scale(this.props.id, Number(this.state.tempScale))
      this.props.getLabels();
    }

    const updateChecked = (option) => {
      if (option === 'autoRotate') {
        Inventum.labels.toggleAutoRotate(this.props.id);
      }else if (option === 'autoScale') {
        Inventum.labels.toggleAutoScale(this.props.id);
      }
      this.props.getLabels();
    }

    return (
      <div>
        <div className='EditorDrawerItemHeader' onClick={() => { this.setState({ open: !this.state.open }) }}>
          <span className='PanelOpenStatus'>{this.state.open ? '▾' : '▸'}</span>
          <span>Mesh</span>
        </div>
        {this.state.open ? <StyleDrawer
           {...this.props}
           tempScale={this.state.tempScale}
           updateScale={updateScale}
           updateChecked={updateChecked}
           activeInput={this.state.activeInput}
           handleScaleChange={(e) => {this.setState({ tempScale: e.target.value })}}
           handleToggle={(key) => {if (this.state.activeInput) return; this.setState({activeInput:key})}}
           setActiveInput={(input) => {this.setState({ activeInput: input })}}
           clearActiveInput={() => {this.setState({ activeInput: null })}}
           />
        : null}
      </div>
    )
  }
}

function StyleDrawer (props) {
  return (
      <div>
        <SetScale {...props} />
        <ToggleAutoScale {...props} />
        <ToggleAutoRotate {...props} />
      </div>
  )
}

function SetScale (props) {
  if (!Object.prototype.hasOwnProperty.call(props, 'scale')) {
    return null;
  }

  return (
    <div style={{ display:'flex', fontSize: '14px', marginBottom: '10px' }}>
      <div className='PanelKeyCell' title={'Label Scale'}>Label Scale</div>
      <div className='PanelValueCell' title={'Label Scale'} onClick={() => {props.setActiveInput('scale')}}>
       { props.activeInput === 'scale' ? <div><input style={{ width: '100px' }} onChange={props.handleScaleChange} value={props.tempScale} /> <span className='PanelValueCellUpdateBtn' onClick={(e) => {e.stopPropagation(); props.updateScale(); props.clearActiveInput(); }}>Update</span> </div> : <span>{props.tempScale}</span> }
      </div>
    </div>
  )
}

function ToggleAutoRotate (props) {
  return (
    <div style={{ display:'flex', fontSize: '14px', marginBottom: '10px' }}>
      <div className='PanelKeyCell' title='Auto-Rotate'>Auto-Rotate</div>
      <div className='PanelValueCell' title='Auto-Rotate' style={{ padding: '5px', minWidth: 'unset' }}>
        <input type='checkbox' checked={props.autoRotate} onChange={ (e) => { props.updateChecked('autoRotate') } } />
      </div>
    </div>
  )
}

function ToggleAutoScale (props) {
  return (
    <div style={{ display:'flex', fontSize: '14px', marginBottom: '10px' }}>
      <div className='PanelKeyCell' title='Auto-Scale'>Auto-Scale</div>
      <div className='PanelValueCell' title='Auto-Scale' style={{ padding: '5px', minWidth: 'unset' }}>
        <input type='checkbox' checked={props.autoScale} onChange={ (e) => { props.updateChecked('autoScale') } } />
      </div>
    </div>
  )
}


export { WorldMeshPanel }
