import * as THREE from 'three';
import { store } from '../core/store.js';
import { SimpleSkyVertex, SimpleSkyFragment } from '../shaders/shaders.glsl.js';
const STATIC_SKY_SIZE = 20000;

class Sky {
  constructor (options) {
    this.createStatic = this.createStatic.bind(this);
    this.toggleSky = this.toggleSky.bind(this);
    this.scale = this.scale.bind(this);
    this.serialize = this.serialize.bind(this);

    this.type = 'static';
    this.colorTop = '#1155a5';
    this.colorBottom = '#d2e8f6';
  }

  createStatic (options) {
    var bottomColor = new THREE.Color();
    var topColor = new THREE.Color();

    bottomColor.setStyle(this.colorBottom);
    topColor.setStyle(this.colorTop);

    var uniforms = {
      topColor: { type: 'c', value: topColor },
      bottomColor: { type: 'c', value: bottomColor },
      offset: { type: 'f', value: 50 },
      exponent: { type: 'f', value: 0.6 }
    };

    var skyMaterial = new THREE.ShaderMaterial({ vertexShader: SimpleSkyVertex, fragmentShader: SimpleSkyFragment, uniforms: uniforms, side: THREE.BackSide, fog: false });
    this.staticSky = new THREE.Mesh(new THREE.SphereGeometry(STATIC_SKY_SIZE, 60, 40), skyMaterial);

    if (!isNaN(options.staticScale)) {
      this.staticSky.scale.set(options.staticScale, options.staticScale, options.staticScale);
    }

    store.scene.add(this.staticSky);
  }

  createSkyBox () {
    const envMap = new THREE.CubeTextureLoader()
      .setPath('/static/img/skies/photo/kloo/')
      .load([
        'px.jpg',
        'nx.jpg',
        'py.jpg',
        'ny.jpg',
        'pz.jpg',
        'nz.jpg'
      ]);
    store.scene.background = envMap;
    store.envMap = envMap;
    store.scene.background = new THREE.CubeTextureLoader()
      .setPath('/static/img/skies/photo/kloo/')
      .load([
        'px.jpg',
        'nx.jpg',
        'py.jpg',
        'ny.jpg',
        'pz.jpg',
        'nz.jpg'
      ]);
  }

  toggleSky () {
    store.scene.remove(this.staticSky);
  }

  scale (scale) {
    if (isNaN(scale)) {
      console.log('Bad Scale');
      return;
    }
    this.staticSky.scale.set(scale, scale, scale);
    store.requestRender();
  }

  getScale () {
    return this.staticSky.scale.x;
  }

  serialize () {
    // Only serializes a static sky at the moment. Dynamic isn't properly implented nor are skydomes
    // TODO Add support for other sky types
    return {
      type: this.type,
      top: this.colorTop,
      bottom: this.colorBottom,
      staticScale: this.staticSky.scale.x
    };
  }
}

const sceneSky = new Sky();
export { sceneSky as sky };
