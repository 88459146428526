import { views } from '../camera/views.js';
import { slides } from '../slides/slides.js';
import { notifications } from '../ui/notifications.js';
import { models } from '../models/models.js';
import { animations } from '../camera/animations.js';
import { markers } from '../ui/screenMarker.js';
import { labels } from '../ui/labels.js';
import { sceneStates } from '../tools/sceneStates.js';
import { lights } from '../world/lights.js';
import { clipping } from '../tools/clipping.js';
import { ruler } from '../tools/ruler.js';
import { resolutionManager } from './resolution.js';

function resetData () {
  slides.reset();
  models.reset();
  views.reset();
  animations.reset();
  labels.reset();
  markers.reset();
  lights.reset();
  sceneStates.reset();
  notifications.reset();
  clipping.reset();
  ruler.reset();
	resolutionManager.reset();
}

export { resetData };
