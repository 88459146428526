import React from 'react';
import axios from '../../../axios-config.js';
import {connect} from 'react-redux';
import {closeModal} from '../../../Redux/actions/ui.js';

class SubmitDeposit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactName:"",
      companyName:"",
      projectName:"",
      email:"",
      phone:"",
      description:"",
      submitted:false,
    }
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({
      contactName:"",
      companyName:"",
      projectName:"",
      email:"",
      phone:"",
      description:"",
      authorized:false,
      submitted:false
    })
    this.props.closeModal();
  }

  handleInput(data) {
    let tState = this.state;
    tState[data.key] = data.value;
    this.setState(tState);
  }

  handleSubmit() {
    let formValues = this.state;
    axios.post('https://api.inventum3D.com/requestproject',{
      contactName:formValues.contactName,
      companyName:formValues.companyName,
      projectName:formValues.projectName,
      email:formValues.email,
      phone:formValues.phone,
      description:formValues.description,
      authorized:formValues.authorized
    })
    .then((response) => {
      console.log(response);
      this.setState({submitted:true});
    })
    .catch((error) => {
      console.log(error);
    })
  }

  render() {
    let style = {
      "display":"flex",
      "flexDirection":"column",
      "pointerEvents":"auto",
      "padding":"10px",
      "backgroundColor":"#333333",
      "fontSize":"20px",
      "color":"#FFFFFF",
      "fontFamily":'Sans-Serif'
    }
    let textAreaStyle = {
      width:"450px",
      height:"250px"
    }
    let state = this.state;
    if (state.submitted) {
      return (
        <div style={style}>
        Thank you for your submission. <br/> We will soon be in touch to discuss.<br/>
        <input type="Submit" value="Close" onClick={this.handleClose}/>
        </div>
      )
    }
    return (
      <div style={style}>
        <div>Add a Project</div>
        <div style={{fontSize:"14px",width:"450px",fontStyle:"italic"}}>Projects are manually added by the Inventum3D team using data supplied by the company in charge of the project.</div>
          <input type="text" value={state.contactName} placeholder="Contact Name" onChange={(e)=>{this.handleInput({key:"contactName",value:e.target.value})}} />
          <input type="text" value={state.companyName} placeholder="Company Name" onChange={(e)=>{this.handleInput({key:"companyName",value:e.target.value})}} />
          <input type="text" value={state.projectName} placeholder="Project Name" onChange={(e)=>{this.handleInput({key:"projectName",value:e.target.value})}} />
          <input type="text" value={state.email} placeholder="Email" onChange={(e)=>{this.handleInput({key:"email",value:e.target.value})}} />
          <input type="text" value={state.phone} placeholder="Phone" onChange={(e)=>{this.handleInput({key:"phone",value:e.target.value})}} />
          <textarea style={textAreaStyle} value={state.description} placeholder="Description" onChange={(e)=>{this.handleInput({key:"description",value:e.target.value})}} />
          <label style={{"fontSize":"15px"}}>I am an authorized agent for this project<input type="checkbox" value={state.authorized} onChange={(e)=>{this.handleInput({key:"authorized",value:e.target.value})}} /></label>
          <input type="Submit" defaultValue="Submit" onClick={this.handleSubmit}/>
          <input type="Submit" defaultValue="Cancel" onClick={this.handleClose}/>
      </div>
    )
  }
}

let mapDispatchToProps = dispatch => ({
  closeModal:() => {
    dispatch(closeModal());
  }
})

let mapStateToProps = (state) => ({
})

const SubmitDepositContainer = connect(mapStateToProps,mapDispatchToProps)(SubmitDeposit)


export default SubmitDepositContainer
