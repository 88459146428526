import * as THREE from 'three';
import { store } from '../core/store.js';
import { models } from '../models/models.js';
import { views } from '../camera/views.js';
import { labels } from '../ui/labels.js';
import { slides } from '../slides/slides.js';
import { lights } from '../world/lights.js';

var settings = { public: {} };

settings.public.getGlobalTransform = () => {
  return {
    x: store.inventumTransform.x,
    y: store.inventumTransform.y,
    z: store.inventumTransform.z
  };
};

settings.public.getGlobalScale = () => {
  return store.globalscale;
};

settings.public.setGlobalScale = (scale) => {
  if (isNaN(scale) || scale <= 0) {
    return;
  }

  // Need to calculate the percentage difference between the current scale and the new scale
  // Unbake the numbers to 1.0 and then do the transforms based on that
  // 1.0 to 0.1 should give it 0.1
  // 0.1 to 0.2 should give it 2.0
  models.modifyScale(scale);
  views.modifyScale(scale);
  lights.modifyScale(scale);
  labels.modifyScale(scale);
  slides.modifyScale(scale);
  // ToDo. Add Clipping Planes?

  // Important this must be set after doing the scaling as we need access to the old scale.
  store.globalscale = scale;
  store.requestRender();
};

settings.public.setGlobalTransform = function (vec3) {
  if (!vec3) {
    return;
  }

  if (isNaN(vec3.x) || isNaN(vec3.y) || isNaN(vec3.z)) {
    console.log('Not a number');
    return;
  }

  const newTransform = new THREE.Vector3(vec3.x, vec3.y, vec3.z);

  // Other properties like Views,Lights,Labels etc have the old transform "baked" into their current values. We need to subtract the old transform from the new one
  // Which will give us a delta transform. The difference between the two.
  // At this point we can Add this delta to the current transforms for these properties and it'll be correct.

  const oldTransform = new THREE.Vector3(store.inventumTransform.x, store.inventumTransform.y, store.inventumTransform.z);
  const deltaTransform = new THREE.Vector3();
  deltaTransform.copy(newTransform);
  deltaTransform.sub(oldTransform);
  views.modifyTransform(deltaTransform);
  lights.modifyTransform(deltaTransform);
  labels.modifyTransform(deltaTransform);
  slides.modifyTransform(deltaTransform);
  models.modifyTransform(deltaTransform);

  store.inventumTransform = new THREE.Vector3().copy(newTransform);
  store.requestRender();
};

settings.public.getStoragePath = () => {
  return store.storagePath;
};

settings.public.setStoragePath = function () {
  const templatePath = 'https://mapability-website.s3.amazonaws.com/inventum-client-assets/';
  let examplePath = templatePath;
  if (store.storagePath.length > 0) {
    examplePath = store.storagePath;
  }
  const newPath = window.prompt('Type Storage Path:', examplePath);
  if (newPath === null || newPath === '' || newPath === 'https://mapability-website.s3.amazonaws.com/inventum-client-assets/') {
    return;
  }

  if (!newPath.includes(templatePath)) {
    console.log(`Storage Path should be a https server path on AWS. i.e start with ${templatePath}`);
    return;
  }
  // Store old path tempoarily for updating models later
  store.oldStoragePaths.push(store.storagePath);
  store.storagePath = newPath;
  console.log('Updated storage path. Changes won\'t take affect until page is reloaded');
  console.warn('If you have assets that don\'t exist relatively in the new storage path things WILL break');
};

export { settings };
