import { labels } from '../ui/labels.js';
import { models } from '../models/models.js';
import { slides } from '../slides/slides.js';
import { clipping } from '../tools/clipping.js';

var controller = { public: {} };

var allSceneStates = []; // Flat list of SceneStates like in the scene.json file
var sceneStateByID = {}; // SceneStates indexed to the ID of the SceneState for easy access

class SceneState {
  constructor (options) {
    this.name = options.name;
    this.id = options.id;

    this.models = [];

    this.labels = [];

    this.slides = [];

    this.clippingPlanes = [];
  }

  addSlides (idArray) {
    idArray.map((id) => {
			id = parseInt(id);
      if (isNaN(id)) {
        console.log('SlideID should be a number.');
      } else {
        this.slides.push(id);
      }
    });
  }

  addModels (idArray) {
    idArray.map((id) => {
			id = parseInt(id);
      if (isNaN(id)) {
        console.log('ModelID should be a number.');
      } else {
        this.models.push(id);
      }
    });
  }

  addLabels (idArray) {
    idArray.map((id) => {
			id = parseInt(id);
      if (isNaN(id)) {
        console.log('LabelID should be a number');
				console.log(id);
      } else {
        this.labels.push(id);
      }
    });
  }

  addClippingPlanes (idArray) {
    idArray.map((id) => {
			id = parseInt(id);
      if (isNaN(id)) {
        console.log('clippingPlaneID should be a number');
      } else {
        this.clippingPlanes.push(id);
      }
    });
  }
}

controller.create = function createSceneState (options) {
  if (options === undefined) {
    options = {};
  }

  if (!options.name) {
    options.name = 'Untitled Scene State';
  }
  if (!Object.prototype.hasOwnProperty.call(options, 'id')) {
    options.id = findID();
  }

  const tempSceneState = new SceneState(options);
  sceneStateByID[options.id] = tempSceneState;
  allSceneStates.push(tempSceneState);
  return tempSceneState;
};

controller.clone = function createSceneState (targetID) {
  const toClone = sceneStateByID[targetID];
  if (!toClone) {
    console.log('Unable to clone SceneState. Invalid ID');
    return;
  }

  const id = findID();

  const tempSceneState = new SceneState({ name: toClone.name + ' (copy)' });
  tempSceneState.id = id;
  sceneStateByID[id] = tempSceneState;
  allSceneStates.push(tempSceneState);

  tempSceneState.addModels(toClone.models.slice(0));
  tempSceneState.addSlides(toClone.slides.slice(0));
  tempSceneState.addLabels(toClone.labels.slice(0));
  tempSceneState.addClippingPlanes(toClone.clippingPlanes.slice(0));
  return id;
};

controller.public.getContainingModel = function getContainingModel (modelID) {
  const idList = [];
  allSceneStates.map(ss => {
    if (ss.models.includes(modelID)) {
      idList.push(ss.id);
    }
  });
  return idList;
};

controller.public.addModelToList = function addModelToList (modelID, list) {
  // Remove model from all scene states first
  allSceneStates.map(state => {
    if (!state) return;
    if (state.models.includes(modelID)) {
      if (!list.includes(state.id)) {
        state.models.splice(state.models.indexOf(modelID), 1);
      }
    } else if (list.includes(state.id)) {
      state.models.push(modelID);
    }
  });
};

controller.createFromCurrentState = function createFromCurrentState () {
  const tempState = controller.create();
  const modelsVisible = models.getVisible();
  const slidesVisible = slides.getVisible();
  const labelsVisible = labels.getVisible();
  const clippingPlanesActive = clipping.getActive();

  tempState.addModels(modelsVisible);
  tempState.addSlides(slidesVisible);
  tempState.addLabels(labelsVisible);
  tempState.addClippingPlanes(clippingPlanesActive);
  return tempState.id;
};

controller.idIsValid = function idIsValid (id) {
  if (sceneStateByID[id] === undefined) {
    return false;
  }
  return true;
};


function findID () {
	// Find the largest ID number in use and return 1 greater.
	let idArray = Object.keys(sceneStateByID)
	.filter(key => !isNaN(parseInt(key)))
	.map(key => parseInt(key))
	.sort((a, b) => a - b);
	if (idArray.length == 0) {
		return 1;
	}

	let id = idArray[idArray.length - 1] + 1;
	return id;
}

controller.public.activateSceneState = function activateSceneState (sceneStateID, options) {
  const tempSceneState = sceneStateByID[sceneStateID];
  if (tempSceneState === undefined) {
    console.log('Invalid Scenestate');
    return;
  }

  let excludeModels = false;
  let exludeLabels = false;
  let excludeSlides = false;
  const excludeClippingPlanes = false;

  if (options !== undefined) {
    if (options.excludeModels) {
      excludeModels = options.excludeModels;
    }

    if (options.exludeLabels) {
      exludeLabels = options.excludeLabels;
    }

    if (options.excludeSlides) {
      excludeSlides = options.excludeSlides;
    }
  }

  if (!excludeModels) {
    models.hideAll();
    models.batchSetVisible(tempSceneState.models, true);
  }

  if (!exludeLabels) {
    labels.hideAll();
    labels.batchSetVisible(tempSceneState.labels, true);
  }

  if (!excludeSlides) {
    slides.hideAll();
    slides.batchSetVisible(tempSceneState.slides, true);
  }

  if (!excludeClippingPlanes) {
    clipping.deactivateAll();
    clipping.batchActivate(tempSceneState.clippingPlanes);
  }
};

// Finds Unused SceneStates that can be deleted.
function removeOrphaned () {
  // This deletes scene states that exist but are not used by anything.
  // HACK
  // FIXME THIS SHOULD NOT USE THE GLOBAL WINDOW.INVENTUM. IMPORTING views causes a failure elsewhere. Possibly a cyclic dependency or something.
  const animationScenestates = window.Inventum.animations.getScenestates();
  const viewScenestates = window.Inventum.camera.getScenestates();

  allSceneStates = allSceneStates.filter((sceneState) => {
    let isUsed = false; const id = sceneState.id;

    if (animationScenestates[id] || viewScenestates[id]) {
      isUsed = true;
    }

    if (!isUsed) {
      delete sceneStateByID[id];
    }

    return isUsed;
  });
}

function removeMissingRefs () {
  // Deletes references from scenestates that no longer exist.
  // i.e if a model was number 7 and then was deleted this will deletes the references
  const modelsIDS = window.Inventum.models.getModelsIDS();
  const labelsIDS = window.Inventum.labels.getLabelIDS();
  const slidesIDS = window.Inventum.slides.getSlidesIDS();
  const clippingPlanesIDS = window.Inventum.clipping.getClippingPlanesIDS();

  allSceneStates.map((sceneState) => {
    sceneState.models = sceneState.models.filter((modelID) => {
      return modelsIDS.includes(modelID.toString());
    });
    sceneState.labels = sceneState.labels.filter((labelID) => {
      return labelsIDS.includes(labelID.toString());
    });
    sceneState.slides = sceneState.slides.filter((slideID) => {
      return slidesIDS.includes(slideID.toString());
    });
    sceneState.clippingPlanes = sceneState.clippingPlanes.filter((clippingPlaneID) => {
      return clippingPlanesIDS.includes(clippingPlaneID.toString());
    });
  });
}

controller.public.clean = function clean () {
  // Runs the cleanup functions. Modifys the variables allSceneStates and sceneStateByID;
  removeOrphaned();
  removeMissingRefs();
};

// Returns all the SceneStates being used in the scene in the format of the scene.json file
controller.generateJSON = function generateJSON () {
  controller.public.clean();
  return { scenestates: allSceneStates };
};

controller.reset = function reset () {
  allSceneStates = [];
  sceneStateByID = {};
};

export { controller as sceneStates };
