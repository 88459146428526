import React from 'react';
import TitleBox from './TitleBox';
import SideBar from './SideBar';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

let SideBarContainer = (props) => {
  return (
    <div className={"SideBarContainer" + (props.uiHideMode === 'ALL' ? ' hidden' : '')}>
      <SideBar panelContent={props.panelContent} />
      <TitleBox />
    </div>
  )
}

let mapStateToProps = (state) => ({
  uiHideMode: state.ui.hideMode
})

let mapDispatchToProps = dispatch => ({})

const SideBarContainerWrapper = withRouter(connect(mapStateToProps, mapDispatchToProps)(SideBarContainer));

export default SideBarContainerWrapper;
