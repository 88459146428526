import {LOAD_MAP_TILE_LAYERS, CLEAR_MAP_TILE_LAYERS, TOGGLE_TILE_LAYER_ACTIVE, CLEAR_ACTIVE_LAYERS, CHANGE_LOADED_LAYER_OPACITY} from '../actions/mapTileLayers.js'


//TODO Change loaded to take an object describing the loaded layers state (visible, opacity)
//The order of this array will be used for sorting


function mapTileLayers(state = {available:{}, loaded:[]}, action) {
  switch (action.type) {
    case LOAD_MAP_TILE_LAYERS:
      return Object.assign({},state,{available:action.data})
    case CLEAR_MAP_TILE_LAYERS:
      return Object.assign({},state,{available:{}})
    case TOGGLE_TILE_LAYER_ACTIVE:
      let updatedLoaded = Object.assign([],state.loaded);

      let filteredLayers = updatedLoaded.filter((layer) => {
        return !(layer.id === action.id)
      })

      if (filteredLayers.length === updatedLoaded.length) {
        filteredLayers.push({id:action.id,opacity:1.0,visible:true});
      }
      return Object.assign({}, state, {loaded:filteredLayers});
    case CLEAR_ACTIVE_LAYERS:
      return Object.assign({}, state, {loaded:[]});
    default:
      return state
  }
}

export default mapTileLayers
