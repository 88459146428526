import React from 'react';
import Slide from './Slide.js';

class SlideContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slides:[]
    };
    this.setSlides = this.setSlides.bind(this);
    Inventum.slides.registerUpdateCallback(this.setSlides);
  }

  //NOTE: React Update
  componentDidMount() {
    this.setSlides(Inventum.slides.getVisible());
  }

  setSlides(slideArray) {
    //slideArray is sent from Inventum.
    //Inventum.slides.registerUpdateCallback used to just send the visible slides. Now sends ALL slides as other things need all the data
    //So we need to filter out the slides that aren't visible
    let visibleSlides = slideArray.filter((slide) => {
      return slide.visible;
    })
    this.setState({slides:visibleSlides})
  }

  render() {
    const slides = this.state.slides;
    return (
      <div className="SlideContainer">
      {slides.map((slideData)=><Slide key={slideData.id} id={slideData.id} elements={slideData.elements} canClose={slideData.canClose} style={slideData.style} clickEnabled={slideData.clickEnabled} />)}
      </div>
    )
  }
}

export default SlideContainer;
