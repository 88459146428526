import { combineReducers } from 'redux'

import user from './user.js';
import company from './company.js';
import companyMapDefaultState from './companyMapDefaultState.js';
import companyMapUserState from './companyMapUserState.js';
import projects from './projects.js';
import projectsFilters from './projectsFilters.js';
import mapTileLayers from './mapTileLayers.js';
import inventumScenes from './inventumScenes.js';
import homeMapDefaultState from './homeMapDefaultState.js';
import homeMapUserState from './homeMapUserState.js';
import sidebarItems from './sidebarItems.js';
import sidebarUI from './sidebarUI.js';
import mapData from './mapData.js';
import ui from './ui.js';
import activePage from './activePage.js';

const inventumApp = combineReducers({
  user,
  company,
  companyMapDefaultState,
  companyMapUserState,
  homeMapDefaultState,
  homeMapUserState,
  projects,
  projectsFilters,
  mapTileLayers,
  inventumScenes,
  sidebarItems,
  sidebarUI,
  mapData,
  ui,
  activePage
})

export default inventumApp
