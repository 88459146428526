import React, { useState } from 'react';

function EditTextPill (props) {
	const [isEditing, setEditing] = useState(false);
	const [textValue, setTextValue] = useState(props.value);

	React.useEffect(() => {
		setTextValue(props.value);
	},[props.value]);

	const applyValue = () => {
		props.setText(textValue);
		setEditing(false);
	};

	const handleKey = (e) => {
		if (e.charCode === 13) {
			applyValue();
		}
	};

	if (isEditing) {
		return (
			<span onClick={(e) => {e.stopPropagation()}} style={{ display: 'flex', alignItems: 'center' }} className='LabelTextPill active'>
				<input onKeyPress={handleKey} autoFocus onClick={(e) => {e.stopPropagation()}} style={{ width: '50px', border: 'none', outline: 'none' }} type='input' value={textValue} onChange={(e) => {setTextValue(e.target.value)}} />
				<div className="ApplyTextEditButton" onClick={applyValue}>Apply</div>
			</span>
		)
	}

	return (
		<span onClick={(e) => {e.stopPropagation(); setEditing(true)}} className='LabelTextPill' title={props.title || props.label}>{props.label}</span>
	)
}

export {
	EditTextPill
}
