import {
  LOAD_USER,
  CLEAR_USER
} from '../actions/user.js';

function user(state = {hasCheckedStorage:false}, action) {
  switch (action.type) {
    case LOAD_USER:
      return Object.assign({},state,action.data)
    case CLEAR_USER:
      return {}
    default:
      return state
  }
}

export default user;
