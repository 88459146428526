// import {arrayMove} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';
import {LOAD_SIDEBAR_ITEMS,CLEAR_SIDEBAR_ITEMS,ADD_SIDEBAR_ITEM,TOGGLE_SIDEBAR_ITEM,REORDER_SIDEBAR_ITEM,UPDATE_SIDEBAR_ITEM} from '../actions/sidebarItems.js'

function sidebarItems(state = [], action) {
  switch (action.type) {
    case LOAD_SIDEBAR_ITEMS:
      return action.data;
    case CLEAR_SIDEBAR_ITEMS:
      return [];
    case ADD_SIDEBAR_ITEM:
      return [...state,action.data]
    case REORDER_SIDEBAR_ITEM:
      let reordedSidebar = Object.assign([],state);
      reordedSidebar = arrayMoveImmutable(reordedSidebar, action.data.oldIndex, action.data.newIndex);
      return reordedSidebar;
    case TOGGLE_SIDEBAR_ITEM:
      let updatedSidebar = Object.assign([],state);
      let exists = false;
      for (let i = 0; i < updatedSidebar.length; i++) {
        if (updatedSidebar[i].id === action.data.id) {
          exists = true;
          updatedSidebar.splice(i,1);
        };
      }
      if (!exists) {
        updatedSidebar.unshift(action.data);
      }
      return updatedSidebar
    case UPDATE_SIDEBAR_ITEM:
      let tUpdatedSidebar = Object.assign([],state);
      //Search through existing sidebar items. If data.id matches the ID of the existing slide then update it
      tUpdatedSidebar.map((item) => {
        if (item.id === action.data.id) {
          item = action.data;
        }
      })
      return tUpdatedSidebar
    default:
      return state
  }
}

export default sidebarItems
