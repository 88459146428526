import {LOAD_GEOJSON, CLEAR_GEOJSON, TOGGLE_MARKERS, TOGGLE_TENEMENTS_LAYER, SHOW_MARKERS, HIDE_MARKERS, HIDE_TENEMENTS_LAYER, SET_NEXT_BOUNDS, CLEAR_NEXT_BOUNDS} from '../actions/mapData.js'

function mapData(state = {geojson:[],markersVisible:true,tenementsLayerVisible:false,nextBounds:null}, action) {
  switch (action.type) {
    case TOGGLE_MARKERS:
      return Object.assign({},state,{markersVisible:!state.markersVisible})
    case HIDE_MARKERS:
      return Object.assign({},state,{markersVisible:false})
    case SHOW_MARKERS:
      return Object.assign({},state,{markersVisible:true})
    case TOGGLE_TENEMENTS_LAYER:
      return Object.assign({},state,{tenementsLayerVisible:!state.tenementsLayerVisible})
    case HIDE_TENEMENTS_LAYER:
      return Object.assign({},state,{tenementsLayerVisible:false})
    case LOAD_GEOJSON:
      let updatedGeojson = state.geojson.slice();
      updatedGeojson.push(action.data)
      return Object.assign({},state,{geojson:updatedGeojson})
    case CLEAR_GEOJSON:
      return Object.assign({},state,{geojson:[]})
    case SET_NEXT_BOUNDS:
      return Object.assign({}, state, {nextBounds:action.data})
    case CLEAR_NEXT_BOUNDS:
      return Object.assign({}, state, {nextBounds:null})
    default:
      return state
  }
}

export default mapData
