import jwt_decode from 'jwt-decode';
export const LOAD_USER = 'LOAD_USER';
export const CLEAR_USER = 'CLEAR_USER';

export function loadUser(token) {
  let data = {};
  try {
    data = jwt_decode(token);
  }catch(error) {
    console.log(error);
    return { type:LOAD_USER, data:{}}
  }
  localStorage.setItem('inventumToken', token);
  data.token = token;
  return { type:LOAD_USER, data}
}

export function checkUserAuthenticated() {
  let data = {};
  let token = localStorage.getItem('inventumToken');
  if (!token) {
    //If Token doesn't exist return an empty user object
    return {type:LOAD_USER, data:{hasCheckedStorage:true}}
  }

  try {
    data = jwt_decode(token)
  }catch(error) {
    console.log(error);
    return { type:LOAD_USER, data:{hasCheckedStorage:true}}
  }
  data.token = token;
  let currentTime = new Date().getTime() / 1000
  if (currentTime > data.exp) {
    console.log('Token Expired');
    localStorage.removeItem('inventumToken');
    return { type:CLEAR_USER }
  };
  data.hasCheckedStorage = true;
  return {type:LOAD_USER, data}
}

export function clearUser() {
  localStorage.removeItem('inventumToken');
  return { type:CLEAR_USER }
}
