export const LOAD_MAP_TILE_LAYERS = 'LOAD_MAP_TILE_LAYERS';
export const CLEAR_MAP_TILE_LAYERS = 'CLEAR_MAP_TILE_LAYERS'
export const TOGGLE_TILE_LAYER_ACTIVE = 'TOGGLE_TILE_LAYER_ACTIVE';
export const CLEAR_ACTIVE_LAYERS = 'CLEAR_ACTIVE_LAYERS';

export function loadMapTileLayers(data) {
  return {type:LOAD_MAP_TILE_LAYERS,data}
}

export function clearMapTileLayers() {
  return {type:CLEAR_MAP_TILE_LAYERS}
}

export function clearActiveLayers() {
  return {type:CLEAR_ACTIVE_LAYERS}
}

export function toggleTileLayerActive(id) {
  return {type:TOGGLE_TILE_LAYER_ACTIVE,id}
}
