import * as THREE from 'three';
import { store } from '../core/store.js';
import { resolutionManager } from '../core/resolution.js';
const utility = {};

utility.generateUID = function generateUID () {
  function s4 () {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
};

utility.unproject = function unproject (positionWorld) {
  var vector = new THREE.Vector3();
	const resolution = resolutionManager.getResolution();
  var widthHalf = 0.5 * resolution.width;
  var heightHalf = 0.5 * resolution.height;

  positionWorld.updateMatrixWorld();
  vector.setFromMatrixPosition(positionWorld.matrixWorld);
  vector.project(store.camera);
  // Converts -1 1 screen coordinates to resolution specfic coordinates
  vector.x = (vector.x * widthHalf) + widthHalf;
  vector.y = -(vector.y * heightHalf) + heightHalf;
  vector.x = vector.x - widthHalf;
  vector.y = (vector.y - heightHalf) * -1;

  return (vector);
};

export { utility };
