import * as THREE from 'three';
import { store } from '../core/store.js';
import { markers } from '../ui/screenMarker.js';

var controller = { public: {} };
var slidesByID = [];
var slidesByOID = {}; // slidesByOID and slidesByID are almost the same. slidesByID uses the id as an array index i.e [undefined,undefined,someslide,undefined,someslide]. slidesByOID uses the key implictly
var elementsByID = [];
var onUpdateCallbacks = [];
let callbackCount = 0;
let clipboardSlideStyle = {};
let clipboardElementStyle = {};
let lastGeneratedSlideID = null;
let lastGeneratedElementID = null;

class SlideElement {
  constructor (options) {
    const defaultSettings = {
      name: 'Untitled Element',
      style: {},
      type: 'TEXT',
      content: 'Example Caption'
    };

    const config = Object.assign({}, defaultSettings, options);

    this.name = config.name;
    this.style = config.style;
    this.type = config.type;
    this.content = config.content;
    this.id = config.id;
  }

  setStyle (style) {
    this.style = style;
    slidesHaveUpdated();
  }

  getData () {
    // Returns data formatted for passing to React
    return {
      id: this.id,
      style: Object.assign({}, this.style),
      type: this.type,
      content: this.content
    };
  }
}

class Slide {
  constructor (options) {
    const defaultSettings = {
      name: 'Untitled Slide',
      style: {},
      canClose: true,
      visible: false,
      visibleStart: false
    };

    const config = Object.assign({}, defaultSettings, options);

    if (config.visibleStart) {
      config.visible = config.visibleStart;
    }

    this.id = config.id;
    this.name = config.name;
    this.style = config.style;
    this.onCloseCallbacks = [];
    this.elementIDList = [];
    this.canClose = config.canClose;
    this.visible = config.visibleStart || false;
    this.visibleStart = config.visibleStart || false;
    this.setVisible = this.setVisible.bind(this);
    if (Object.prototype.hasOwnProperty.call(config, 'worldPosition')) {
      this.worldPosition = config.worldPosition;
      const markerOptions = {
        hoverSize: 15,
        inactiveSize: 10,
        opacity: 1.0,
        inactiveBackground: 'rgba(255,255,255,0.7)',
        hoverBackground: 'rgba(255,255,255,0.9)'
      };
      const marker = markers.create(markerOptions);
      marker.addClickCallback(() => {
        if (!store.followerMode) {
          this.setVisible();
        }
      });
      marker.setWorldPosition({ x: this.worldPosition.x, y: this.worldPosition.y, z: this.worldPosition.z });
      this.marker = marker;
    }
  }

  setVisible (state) {
    if (state === undefined) {
      state = !this.visible;
    }

    this.visible = state;
    if (state === false) {
      this.onCloseCallbacks.map((callback) => {
        // Notify interested parties that the slide has closed.
        callback();
      });
    }
    slidesHaveUpdated();
  }

  setStyle (style) {
    this.style = style;
    slidesHaveUpdated();
    store.setUnsavedScene();
  }

  addStyle (key, value) {
    if (key === undefined || value === undefined) {
      return;
    }
    this.style[key] = value;
    slidesHaveUpdated();
    store.setUnsavedScene();
  }

  removeStyle (key) {
    delete this.style[key];
    slidesHaveUpdated();
    store.setUnsavedScene();
  }

  clearStyle () {
    this.style = {};
    slidesHaveUpdated();
    store.setUnsavedScene();
  }

  registerCloseCallback (callback) {
    if (typeof (callback) !== 'function') {
      console.warn('Callback must be a function.');
      return;
    }
    this.onCloseCallbacks.push(callback);
  }

  setElements (elementIDList) {
    // Replaces all the elements in a slide with an array of provided element.
    // Useful for init
    const missingEls = [];

    elementIDList.map((elID) => {
      if (elementsByID[elID] === undefined) {
        missingEls.push(elID);
      }
    });

    if (missingEls.length > 0) {
      console.warn('Some of the provided elements are missing. Ignoring set.');
      console.log(missingEls);
      return;
    } else {
      this.elementIDList = elementIDList;
    }
    slidesHaveUpdated();
  }

  addElement (id) {
    // Adds a element to a slide. Elements are just ID's in the slide.
    if (elementsByID[id] === undefined) {
      console.log('Invalid Element ID. Ignoring');
      return;
    }

    var index = this.elementIDList.indexOf(id);
    if (index > -1) {
      console.log('Slide already contains this Element. Cloning it.');
      const existingElement = elementsByID[id];

      const newElement = controller.createElement({
        style: Object.assign({}, existingElement.style),
        content: existingElement.content,
        type: existingElement.type,
        name: existingElement.name + ' (clone)'
      });

      console.log(newElement);

      this.elementIDList.push(newElement.id);
      return;
    } else {
      this.elementIDList.push(id);
      slidesHaveUpdated();
    }
    store.setUnsavedScene();
  }

  removeElement (id) {
    var index = this.elementIDList.indexOf(id);
    if (index > -1) {
      this.elementIDList.splice(index, 1);
    }
    slidesHaveUpdated();
    store.setUnsavedScene();
  }

  getData () {
    // Formats data for react. Gives explicit instructions for rendering. No past or future state is passed on
    // Loop over active elements and store the element data in an array
    const elementData = [];
    this.elementIDList.map((elID) => {
      const element = elementsByID[elID];
      elementData.push(element.getData());
    });

    return {
      id: this.id,
      style: Object.assign({}, this.style),
      name: this.name,
      elements: elementData,
      elementsIDs: this.elementIDList.slice(),
      canClose: this.canClose,
      visible: this.visible,
      visibleStart: this.visibleStart
    };
  }
}

function findUnusedSlideID () {
	// If lastGeneratedSlideID is set to null then search for the largest number
	// Find the largest ID number in use and return 1 greater.
	if (lastGeneratedSlideID === null || isNaN(lastGeneratedSlideID) || slidesByID[lastGeneratedSlideID + 1] !== undefined) {
		let idArray = slidesByID.filter(slide => slide !== undefined)
		.filter(slide => !isNaN(parseInt(slide.id)))
		.map(slide => parseInt(slide.id))
		.sort((a, b) => a - b);

		if (idArray.length == 0) {
			lastGeneratedSlideID = 1;
		} else {
			lastGeneratedSlideID = idArray[idArray.length - 1] + 1;
		}

	} else {
		// Else return one largest than the current lastGeneratedSlideID
		// Important to cache lastgeneratedID so we don't reuse ID's that have been deleted.
		lastGeneratedSlideID += 1;
	}
	return lastGeneratedSlideID;
}

function findUnusedElementID () {
	// Find the largest ID number in use and return 1 greater.
	if (lastGeneratedElementID === null || isNaN(lastGeneratedElementID) || elementsByID[lastGeneratedElementID + 1] !== undefined) {
		let idArray = elementsByID.filter(element => element !== undefined)
		.filter(element => !isNaN(parseInt(element.id)))
		.map(element => parseInt(element.id))
		.sort((a, b) => a - b);
		if (idArray.length == 0) {
			lastGeneratedElementID = 1;
		} else {
			lastGeneratedElementID = idArray[idArray.length - 1] + 1;
		}
	} else {
		lastGeneratedElementID += 1;
	}
	return lastGeneratedElementID;
}

window.testSlides = () => {
	console.log(slidesByID);
	console.log(elementsByID);
}

controller.createSlide = function createSlide (options) {
  let slideID = -1;
  if (options !== undefined) {
    if (options.id !== undefined) {
      if (slidesByID[options.id] !== undefined) {
        // ID Is explicitly given but is already taken. We need to error as we can't have the user using an incorrect ID.
        console.log('Slide ID must be unique.');
        return;
      }
      slideID = options.id;
    } else {
      // Options was supplied but ID wasn't supplied so we need to generate one.
      slideID = findUnusedSlideID();
    }
  } else {
    // Options wasn't supplied so we need to generate an ID as a minimum
    options = {};
    slideID = findUnusedSlideID();
  }

  options.id = slideID;

  var tempSlide = new Slide(options);
  slidesByID[options.id] = tempSlide;
  slidesByOID[options.id] = tempSlide;
  slidesHaveUpdated();
  return tempSlide;
};

controller.createElement = function createElement (options) {
  let elementID = -1;
  if (options !== undefined) {
    if (options.id !== undefined) {
      if (elementsByID[options.id] !== undefined) {
        // ID Is explicitly given but is already taken. We need to error as we can't have the user using an incorrect ID.
        console.log('Element ID must be unique.');
        return;
      }
      elementID = options.id;
    } else {
      // Options was supplied but ID wasn't supplied so we need to generate one.
      elementID = findUnusedElementID();
    }
  } else {
    // Options wasn't supplied so we need to generate an ID as a minimum
    options = {};
    elementID = findUnusedElementID();
  }

  options.id = elementID;

  const el = new SlideElement(options);
  elementsByID[options.id] = el;
  slidesHaveUpdated();
  return el;
};

function getVisibleSlideData () {
  const tempSlideData = [];
  slidesByID.map((slide) => {
    if (slide !== undefined) {
      tempSlideData.push(slide.getData());
    }
  });
  return tempSlideData;
}

function slidesHaveUpdated () {
  var tempSlideData = getVisibleSlideData();
  onUpdateCallbacks.map((receiver, index) => {
    receiver.callback(tempSlideData);
  });
}

controller.public.registerUpdateCallback = function registerUpdateCallback (callback) {
  callbackCount++;
  onUpdateCallbacks.push({ callback, id: callbackCount });
  return callbackCount;
};

controller.public.unregisterUpdateCallback = function unregisterUpdateCallback (id) {
  // Removes a callback based on ID
  onUpdateCallbacks = onUpdateCallbacks.filter((receiver) => {
    return id !== receiver.id;
  });
};

controller.public.getVisible = () => {
  return getVisibleSlideData();
};

controller.public.getSlides = () => {
  const sorted = [];
  slidesByID.map((slide) => {
    if (slide !== undefined) {
      sorted.push({
        id: slide.id,
        name: slide.name,
        style: Object.assign({}, slide.style),
        canClose: slide.canClose,
        visibleStart: slide.visibleStart,
        visible: slide.visible,
        elementsIDs: slide.elementIDList.slice(),
        elements: slide.elementIDList.slice()
      });
    }
  });

  return sorted;
};

controller.public.getSelectedElements = (idArr) => {
  if (!idArr) {
    return;
  }

  const sorted = [];
  idArr.map((id) => {
    if (elementsByID[id] !== undefined) {
      const element = elementsByID[id];
      sorted.push({
        id: element.id,
        name: element.name,
        style: Object.assign({}, element.style),
        type: element.type,
        content: element.content
      });
    }
  });
  return sorted;
};

controller.public.setElementName = (id, text) => {
  const tElement = elementsByID[id];
  if (tElement === undefined) {
    console.log('Invalid Element ID');
    return;
  }

	if (text === undefined) {
		text = window.prompt('Name of Element:', tElement.name);
		if (text === null || text === '') {
			return;
		}
	}

  tElement.name = text;
  slidesHaveUpdated();
  store.setUnsavedScene();
};

controller.public.setElementContent = (id, text) => {
  const tElement = elementsByID[id];
  if (tElement === undefined) {
    console.log('Invalid Element ID');
    return;
  }

	if (text === undefined) {
		text = window.prompt('Name of Element:', tElement.content);
		if (text === null || text === '') {
			return;
		}
	}

  tElement.content = text;
  slidesHaveUpdated();
  store.setUnsavedScene();
};

controller.public.deleteElement = (id) => {
  // TODO Needs to search for slides that reference this element and update them.
  elementsByID[id] = undefined;
  slidesHaveUpdated();
  store.setUnsavedScene();
};

controller.public.getAllElements = () => {
  const sorted = [];
  elementsByID.map((element) => {
    if (element !== undefined) {
      sorted.push({
        id: element.id,
        name: element.name
      });
    }
  });
  return sorted;
};

controller.getVisible = function getVisible () {
  const tempSlideData = [];
  slidesByID.map((slide) => {
    if (!slide.visible) {
      return;
    }
    tempSlideData.push(slide.id);
  });
  return tempSlideData;
};

controller.public.setVisible = function setVisible (id, state) {
  const tSlide = slidesByID[id];
  if (tSlide === undefined) {
    console.log('Invalid Slide ID');
    return;
  }
  tSlide.setVisible(state);
  store.setUnsavedScene();
};

controller.public.addElementToSlide = function addElementToSlide (slideID, elementID) {
  const tSlide = slidesByID[slideID];
  if (tSlide === undefined) {
    console.log('Invalid Slide ID');
    return;
  }
  tSlide.addElement(parseInt(elementID));
  slidesHaveUpdated();
  store.setUnsavedScene();
};

controller.public.toggleVisibleStart = function toggleVisibleStart (id) {
  const tSlide = slidesByID[id];
  if (tSlide === undefined) {
    console.log('Invalid Slide ID');
    return;
  }
  tSlide.visibleStart = !tSlide.visibleStart;
  store.setUnsavedScene();
};

controller.public.setCanClose = function setCanClose (id) {
  const tSlide = slidesByID[id];
  if (tSlide === undefined) {
    console.log('Invalid Slide ID');
    return;
  }
  tSlide.canClose = !tSlide.canClose;
  slidesHaveUpdated();
  store.setUnsavedScene();
};

controller.public.setName = function setName (id, text) {
  const tSlide = slidesByID[id];
  if (tSlide === undefined) {
    console.log('Invalid Slide ID');
    return;
  }

	if (text === undefined) {
		text = window.prompt('Name of Slide:', tSlide.name);
		if (text === null || text === '') {
			return;
		}
	}

  tSlide.name = text;
  slidesHaveUpdated();
  store.setUnsavedScene();
};

controller.public.setElementType = function setElementType (id, type) {
  const tElement = elementsByID[id];
  if (tElement === undefined) {
    console.log('Invalid Slide ID');
    return;
  }

	if (type === undefined) {
		type = window.prompt('New Type.\nMust be TEXT, IMAGE, MARKDOWN or YOUTUBE_VIDEO:', tElement.type);
		if (type === null || type === '') {
			return;
		}
	}

	type = type.toUpperCase();

  if (type !== 'TEXT' &&
      type !== 'IMAGE' &&
      type !== 'MARKDOWN' &&
      type !== 'YOUTUBE_VIDEO') { window.alert("Invalid Slide Type. Must be 'TEXT', 'IMAGE', 'MARKDOWN' or 'YOUTUBE_VIDEO'"); return; }

  tElement.type = type;
  slidesHaveUpdated();
  store.setUnsavedScene();
};

controller.public.setSlideStyle = function setSlideStyle (id, style) {
  const tSlide = slidesByID[id];
  if (tSlide === undefined) {
    console.log('Invalid Slide ID');
    return;
  }

  tSlide.setStyle(style);
  store.setUnsavedScene();
};

controller.public.setElementStyle = function setElementStyle (id, style) {
  const tElement = elementsByID[id];
  if (tElement === undefined) {
    console.log('Invalid Element ID');
    return;
  }

  tElement.setStyle(style);
  store.setUnsavedScene();
};

controller.public.copySlideStyle = function copySlideStyle (id) {
	const tSlide = slidesByID[id];
	if (tSlide === undefined || tSlide.style === undefined) {
		console.log('Invalid Slide ID');
		return;
	}
	clipboardSlideStyle = JSON.parse(JSON.stringify(tSlide.style));
	Inventum.notifications.addNotification({ content: "Copied Slide Style", displayTime:3000});
};

controller.public.copyElementStyle = function copyElementStyle (id) {
	const tElement = elementsByID[id];
  if (tElement === undefined || tElement.style === undefined) {
    console.log('Invalid Element ID');
    return;
  }
	clipboardElementStyle = JSON.parse(JSON.stringify(tElement.style));
	Inventum.notifications.addNotification({ content: "Copied Element Style", displayTime:3000});
};

controller.public.pasteSlideStyle = function pasteSlideStyle (id) {
	const tSlide = slidesByID[id];
	if (tSlide === undefined) {
		console.log('Invalid Slide ID');
		return null;
	}

	if (Object.keys(clipboardSlideStyle).length == 0) {
		console.log('Clipboard is empty!');
		return null;
	}
	tSlide.style = JSON.parse(JSON.stringify(clipboardSlideStyle));
	Inventum.notifications.addNotification({ content: "Pasted Slide Style", displayTime:3000});
	slidesHaveUpdated();
	store.setUnsavedScene();
	// Return a copy of the updated slide element to change the local UI version.
	// This is a bit hacky but the slide editor won't be developed much more.
	return JSON.parse(JSON.stringify(clipboardSlideStyle));
};

controller.public.pasteElementStyle = function pasteElementStyle (id) {
	const tElement = elementsByID[id];
	if (tElement === undefined) {
		console.log('Invalid Slide ID');
		return null;
	}

	if (Object.keys(clipboardElementStyle).length == 0) {
		console.log('Clipboard is empty!');
		return null
	}

	tElement.style = JSON.parse(JSON.stringify(clipboardElementStyle));
	Inventum.notifications.addNotification({ content: "Pasted Element Style", displayTime:3000});
	slidesHaveUpdated();
  store.setUnsavedScene();
	// Return a copy of the updated slide element to change the local UI version.
	// This is a bit hacky but the slide editor won't be developed much more.
	return JSON.parse(JSON.stringify(clipboardElementStyle));
};

controller.public.deleteSlide = function deleteSlide (id) {
  slidesByID[id] = undefined;
  slidesHaveUpdated();
  store.setUnsavedScene();
};

controller.renderSlide = function renderSlide (slideID, callback) {
  slidesByID[slideID].render(callback);
};

controller.getSlide = function getSlide (slideID) {
  const tSlide = slidesByID[slideID];
  if (tSlide === undefined) {
    console.log('Slide not found');
    return;
  }
  return tSlide;
};

controller.hideAll = function hideAll () {
  slidesByID.map((slide) => {
    if (slide !== undefined) {
      slide.setVisible(false);
    }
  });
  slidesHaveUpdated();
};

controller.broadcastSlides = function broadcastSlides () {
  if (!store.broadcastMode) {
    return;
  }
  const visibleSlidesID = controller.getVisible();
  store.sync.private.broadcast({ action: 'SLIDES_BATCH_SET_VISIBLE', payload: visibleSlidesID });
};

controller.batchSetVisible = function batchSetVisible (slideArray) {
  slidesByID.map((slide, slideID) => {
    if (slideArray.includes(slideID)) {
      slide.setVisible(true);
    } else {
      slide.setVisible(false);
    }
  });
  /* slideArray.map((slideID) => {
    let slide = slidesByID[slideID];
    if (slide !== undefined) {
      slide.setVisible(true);
    }
  }) */

  slidesHaveUpdated();
  controller.broadcastSlides();
};

controller.validateSlideID = function validateSlideID (id) {
  if (slidesByID[id] !== undefined) {
    return true;
  }
  return false;
};

// Important. Used for calculating scenestates.
controller.public.getSlidesIDS = function getSlidesIDS () {
  return Object.keys(slidesByOID);
};

controller.public.createElement = function createElementPublic (slideID) {
  const tSlide = slidesByID[slideID];
  if (tSlide === undefined) {
    console.log('Slide not found');
    return;
  }
  const element = controller.createElement();
  tSlide.addElement(element.id);
  slidesHaveUpdated();
  store.setUnsavedScene();
};

controller.public.removeElement = function createElementPublic (slideID, elementID) {
  const tSlide = slidesByID[slideID];
  if (tSlide === undefined) {
    console.log('Slide not found');
    return;
  }
  tSlide.removeElement(elementID);
  slidesHaveUpdated();
  store.setUnsavedScene();
};

controller.public.createSlide = function createSlidePublic () {
  controller.createSlide();
  slidesHaveUpdated();
  store.setUnsavedScene();
};

controller.public.createCaption = function createCaption (captionText) {
	if (captionText === undefined) {
		captionText = window.prompt('Caption Content:', 'Example Caption Text');
		if (captionText === null || captionText === '') {
			return;
		}
	}

  const elementOptions = {
    name: captionText,
    style: {
      textAlign: 'left',
      backgroundColor: 'rgba(20,20,20,0.8)',
      fontFamily: 'Arial',
      color: '#FFFFFF',
      fontSize: '40px',
      paddingLeft: '10px',
      paddingRight: '10px'
    },
    content: captionText
  };

  const element = controller.createElement(elementOptions);

  const slideOptions = {
    name: captionText,
    style: {
      left: '20px',
      top: '20%',
      backgroundColor: 'transparent',
      pointerEvents: 'none',
      userSelect: 'none',
      position: 'absolute'
    },
    canClose: false
  };
  const newSlide = controller.createSlide(slideOptions);
  newSlide.addElement(element.id);
  newSlide.setVisible(true);
  slidesHaveUpdated();
  store.setUnsavedScene();
};

controller.modifyTransform = function modifyTransform (deltaTransform) {
  slidesByID.map((slide) => {
    if (slide !== undefined) {
      if (slide.marker !== undefined) {
        slide.marker.modifyTransform(deltaTransform);
        slide.worldPosition.x += deltaTransform.x;
        slide.worldPosition.y += deltaTransform.y;
        slide.worldPosition.z += deltaTransform.z;
      }
    }
  });
};

controller.modifyScale = function modifyScale (newScale) {
  const oldScale = store.globalscale;
  slidesByID.map((slide) => {
    if (slide !== undefined) {
      if (slide.marker !== undefined) {
        slide.marker.modifyScale(newScale);

        // Create a temp vec3 to do the vector math
        const tVec3 = new THREE.Vector3(slide.worldPosition.x, slide.worldPosition.y, slide.worldPosition.z);
        tVec3.sub(store.inventumTransform);
        tVec3.divideScalar(oldScale);
        tVec3.multiplyScalar(newScale);
        tVec3.add(store.inventumTransform);

        slide.worldPosition.x = tVec3.x;
        slide.worldPosition.y = tVec3.y;
        slide.worldPosition.z = tVec3.z;
      }
    }
  });
};

controller.reset = function resetData () {
  // console.log('Resetting Slides');
  slidesByID = [];
  elementsByID = [];
  onUpdateCallbacks = [];
  slidesByOID = {};
  callbackCount = 0;
	lastGeneratedSlideID = null;
	lastGeneratedElementID = null;
};

controller.generateJSON = function generateJSON () {
  const containers = [];
  const elements = [];

  slidesByID.map((slide) => {
    if (slide !== undefined) {
      const serializedSlide = {
        name: slide.name,
        canClose: slide.canClose,
        visibleStart: slide.visibleStart,
        id: slide.id,
        style: slide.style,
        elements: slide.elementIDList
      };

      if (slide.worldPosition) {
        serializedSlide.worldPosition = slide.worldPosition;
      }
      containers.push(serializedSlide);
    }
  });

  elementsByID.map((element) => {
    const serializedElement = {
      id: element.id,
      name: element.name,
      content: element.content,
      style: element.style,
      type: element.type
    };
    elements.push(serializedElement);
  });
  return ({ slides: { elements, containers } });
};

export { controller as slides };
