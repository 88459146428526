export const LOAD_PROJECTS = 'LOAD_PROJECTS';
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';

export function loadProjects(data) {
  return { type:LOAD_PROJECTS, data}
}

export function clearProjects() {
  return { type:CLEAR_PROJECTS }
}
