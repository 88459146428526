import React, { useState, useEffect, useRef } from "react";

function MaterialPropertiesInput(props) {
  const [inputValue, setInputValue] = useState("");
  const [lastValidValue, setLastValidValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (props.value !== inputValue) {
      const newValue = props.value !== undefined ? String(Math.round(props.value)) : "";
      setInputValue(newValue);
      setLastValidValue(newValue);
    }
  }, [props.value]);

  const handleSliderChange = (e) => {
    const value = Math.round(parseInt(e.target.value, 10));
    updateValue(value);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Allow '-' or an empty string to be entered without setting state
    if (inputValue === "-" || inputValue === "") {
      setInputValue(inputValue);
      return;
    }

    const number = Math.round(Number(inputValue));

    // Only update state if the input is a valid number
    if (!isNaN(number)) {
      setInputValue(inputValue);
    }
  };

  const handleBlur = () => {
    // Convert empty or invalid '-' input to last valid value when losing focus
    if (inputValue === "" || inputValue === "-") {
      setInputValue(lastValidValue);
    } else {
      setLastValidValue(inputValue);
      if (typeof props.onChange === "function") {
        props.onChange(Number(inputValue));
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // Submit the current value when Enter is pressed
      if (inputValue !== "" && inputValue !== "-" && typeof props.onChange === "function") {
        props.onChange(Number(inputValue));
        setLastValidValue(inputValue);
      } else {
        // Revert to the last valid value if Enter is pressed on an invalid input
        setInputValue(lastValidValue);
      }

      // Blur the input field after pressing Enter
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  const setSliderPosition = (value) => {
    const slider = document.querySelector(".slider");
    if (slider) {
      slider.value = value;
    }
  };

  const updateValue = (value) => {
    const roundedValue = Math.round(value);
    setInputValue(roundedValue.toString());
    setLastValidValue(roundedValue.toString());
    setSliderPosition(roundedValue);
    if (typeof props.onChange === "function") {
      props.onChange(roundedValue);
    }

  };

  return (
    <div style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
      <div style={{ flex: 1, marginRight: "10px" }}>{props.title}</div>
      <input
        className="slider"
        type="range"
        min="0"
        max="100"
        step="1"
        onChange={handleSliderChange}
        value={inputValue}
        style={{ flex: 3 }}
      />
      <input
        ref={inputRef}
        type="number"
        min="0"
        max="100"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        style={{ width: "45px", marginLeft: "10px", marginRight: "0.5rem" }}
      />
    </div>
  );
}

export default MaterialPropertiesInput;
