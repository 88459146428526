//import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import App from './components/App';
import store from './Redux/store';
import withTracker from './withTracker.js';
//import * as serviceWorker from './serviceWorker';

ReactDOM.render((
  <Provider store={store}>
  <BrowserRouter>
    <Route component={withTracker(App)} />
  </BrowserRouter>
  </Provider>
),document.getElementById('root'));

//serviceWorker.register();
