import { createTheme } from "@mui/material/styles";

const darkThemePalette = {
  mode: "dark",
  common: {
    black: "#1f1f1f",
    white: "#fff",
  },
  background: {
    default: "#1f1f1f",
    paper: "#28292a",
  },
  primary: {
    main: "#9cd67d",
    light: "#9cd67d",
    dark: "#0c3900",
    contrastText: "#062e6f",
  },
  secondary: {
    main: "#7fcfff",
    dark: "#004a77",
    contrastText: "#035",
  },
  error: {
    main: "#ffb4a9",
    contrastText: "#680003",
  },
  warning: {
    main: "#e2e290",
    contrastText: "#473e00",
  },
  info: {
    main: "#b4e5f2",
    contrastText: "#073550",
  },
  success: {
    main: "#c4eed0",
    contrastText: "#0f5223",
  },
  text: {
    primary: "#e3e3e3",
    secondary: "#c4c7c5",
    disabled: "#8e918f",
    hint: "#747775",
  },
  action: {
    active: "#e3e3e3",
    hover: "#f2f2f2",
    selected: "#2d2f31",
    disabled: "#34363a",
    disabledBackground: "#333438",
    focus: "#383a3e",
    hoverOpacity: 0.08,
    disabledOpacity: 0.38,
  },
  copy: {
    main: "#f2f2f2"
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
};


const lightThemePalette = {
  common: {
    black: "#1f1f1f",
    white: "#fff",
  },
  background: {
    default: "#fff",
    paper: "#f8fafd",
  },
  primary: {
    main: "#386a20",
    light: "#9cd67d",
    dark: "#0c3900",
    contrastText: "#fff",
  },
  secondary: {
    main: "#55624c",
    light: "#d9e7cb",
    dark: "#131f0d",
    contrastText: "#fff",
  },
  error: {
    main: "#ba1b1b",
    contrastText: "#fff",
  },
  warning: {
    main: "#e8e971",
    contrastText: "#1f1f1f",
  },
  info: {
    main: "#e6f4ea",
    contrastText: "#188038",
  },
  success: {
    main: "#c4eed0",
    contrastText: "#072711",
  },
  text: {
    primary: "#1f1f1f",
    secondary: "#444746",
    disabled: "#747775",
    hint: "#e3e3e3",
  },
  action: {
    active: "#1f1f1f",
    hover: "#f2f2f2",
    selected: "#f3f6fc",
    disabled: "#edf2fa",
    disabledBackground: "#eff3fa",
    focus: "#eaf0f9",
    hoverOpacity: 0.08,
    disabledOpacity: 0.38,
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
};

export const getTheme = (mode) =>
  createTheme({
    palette: mode === "dark" ? darkThemePalette : lightThemePalette,
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @import url('https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500;700&display=swap');
        
        body {
          font-family: 'Google Sans', sans-serif;
        }
      `,
      },
    },
});

