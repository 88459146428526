import React from 'react';
import axios from '../../../../axios-config.js';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

const BooleanInput = (props) => {
  return (
    <div className="CreateCompanyInput">
      {props.title}<input type="checkbox" checked={props.value} onChange={(e)=>{props.onChange(e.target.checked)}} placeholder={props.placeholder} />
    </div>
  )
}
const InputField = (props) => {
  return (
    <div className="CreateCompanyInput">
      <div>{props.title}</div>
      <input value={props.value} onChange={(e)=>{props.onChange(e.target.value)}} placeholder={props.placeholder} />
    </div>
  )
}

const Submit = (props) => {
  return (
    <div onClick={props.onClick} className="CreateCompanySubmit">Submit</div>
  )
}


class ProjectForm extends React.Component {
  constructor(props) {
    super(props);
    let currentProject = {
      public:false,
      project_name:"",
      latitude:0.0,
      longitude:0.0,
      project_slug:"",
      project_id:"",
      legacy_url:"",
      company_id:"",
      flipLabel:false
    };
    if (props.company) {
      currentProject.company_id = props.company.id;
    }

    if (props.project) {
      Object.assign(currentProject,props.project);
    }

    this.state = {
      name:currentProject.project_name,
      visiblePublic:currentProject.public,
      latitude:currentProject.latitude,
      longitude:currentProject.longitude,
      slug:currentProject.project_slug,
      existingID:currentProject.project_id,
      legacyURL:currentProject.legacy_url || "",
      flipLabel:currentProject.flip_map_label,
      companyID:currentProject.company_id
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    let state = this.state;
    let latitude = parseFloat(this.state.latitude);
    let longitude = parseFloat(this.state.longitude);

    if (isNaN(latitude) || isNaN(longitude)) {
      return;
    }

    if (this.state.existingID.length > 0) {
      axios.post(`https://api.inventum3d.com/project/${this.state.existingID}`,{
        name:state.name,
        visiblePublic:state.visiblePublic,
        latitude:latitude,
        longitude:longitude,
        slug:state.slug,
        flipLabel:state.flipLabel,
        legacyURL:state.legacyURL
      })
      .then((response) => {
        console.log(response);
        this.props.back();
      })
      .catch((error) => {
        window.alert('Something went wrong. Check the console');
        console.log(error);
      })
    }else {
      console.log('Submitting New Project');
      console.log(this.state);
      axios.post('https://api.inventum3d.com/project',{
        name:state.name,
        visiblePublic:state.visiblePublic,
        latitude:state.latitude,
        longitude:state.longitude,
        slug:state.slug,
        companyID:state.companyID,
        flipLabel:state.flipLabel,
        legacyURL:state.legacyURL
      })
      .then((response) => {
        console.log(response);
        this.props.back();
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }



  render() {
    let state = this.state;
    return (
      <div>
        <div style={{"textAlign":"center"}}>{state.existingID.length > 0 ? "Edit" : "Create"} Project</div>
        <div className="CreateProjectForm">
          <InputField title={"Name"} placeholder={"ABC Project"} value={state.name} onChange={(name) => {this.setState({name})}} />
          <InputField title={"Slug"} placeholder={"projectname"} value={state.slug} onChange={(slug) => {this.setState({slug})}} />
          <BooleanInput title={"public"} placeholder={"true"} value={state.visiblePublic} onChange={(visiblePublic) => {this.setState({visiblePublic})}} />
          <div style={{"fontSize":"14px","textAlign":"center"}}>Projects need to have a master version and be set as public to appear on the map for non company users.</div>
          <div className="LatLongInput">
            <InputField title={"Latitude"} placeholder={"0.0"} value={state.latitude} onChange={(latitude) => {this.setState({latitude})}} />
            <InputField title={"Longitude"} placeholder={"0.0"} value={state.longitude} onChange={(longitude) => {this.setState({longitude})}} />
          </div>
          <div style={{marginTop:"10px"}}>
            <div className="WorldEditorButton" onClick={() => {this.setState({flipLabel:!state.flipLabel})}}>Label Direction: {state.flipLabel ? "Left" : "Right"}</div>
          </div>
          {state.legacyURL.length > 0 ? <InputField title={"Legacy URL"} placeholder={"http://mapability.com.au/review/"} value={state.legacyURL} onChange={(legacyURL) => {this.setState({legacyURL})}} /> : null}
          <Submit onClick={this.handleSubmit} />
        </div>
      </div>
    )
  }
}

export default ProjectForm
