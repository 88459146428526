import React from 'react';

class LimitsEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: 'INCLUSION'
    }
    this.updateSelected = this.updateSelected.bind(this);
    this.addLimit = this.addLimit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleValue = this.handleValue.bind(this);
    this.handleOther = this.handleOther.bind(this);
		console.log(this.props);
  }

  componentWillUnmount() {
    Inventum.labels.clearLimitBounds();
  }

  updateSelected(option) {
    this.setState({selectedOption:option})
  }

  addLimit() {
    Inventum.labels.addLimit(this.props.data.id, this.state.selectedOption);
    this.props.data.getLabels();
  }

  handleDelete(limitID) {
    Inventum.labels.deleteRenderLimit(this.props.data.id, limitID)
    this.props.data.getLabels();
  }

  handleValue(limitID) {
    let labelID = this.props.data.id;
    let limit = this.props.data.renderLimits[limitID];
    if (limit.type === 'EXCLUSION' || limit.type === 'INCLUSION') {
      //For inclusion and exclusion we don't provide an optionalVAlue.
      //Instead we ask inventum camera manager to get us the current camera position
      var position = Inventum.camera.getCameraPositionWorld(); //Get an {x,y,z} for the camera position
      //Merge the new position object with the existing position which has d for distance
      var updatedValue = Object.assign({},limit.value,position);
      Inventum.labels.updateRenderLimit(this.props.data.id, limitID, updatedValue)
    }else {
      //For everything else we can just use the value as provided
      var providedValue = prompt('Value:', limit.value);

      if (providedValue == null || providedValue == "") return;
      providedValue = Number(providedValue);
      if (isNaN(providedValue)) {
        return;
      }
      Inventum.labels.updateRenderLimit(labelID, limitID, providedValue)
    }
    this.props.data.getLabels();
  }

  handleOther(limitID) {
    let labelID = this.props.data.id;
    let limit = this.props.data.renderLimits[limitID];
    if (limit.type !== 'EXCLUSION' && limit.type !== 'INCLUSION') {
      return;
    }

    var currentValue = limit.value;
    var newDistance = prompt('Distance:', currentValue.d);
    if (newDistance == null || newDistance == '') return;
    newDistance = Number(newDistance);
    if (isNaN(newDistance)) {
      return;
    }

    currentValue.d = newDistance;

    Inventum.labels.updateRenderLimit(labelID, limitID, currentValue)
    this.props.data.getLabels();
  }

  render() {
    let incExcLimits = [];
    let otherLimits = [];

    this.props.data.renderLimits.map((limit, index) => {
      limit.limitID = index; //Since we split the array here we need to reference the original ID so we can tell Inventum which limit we are editing;
      if (limit.type === 'INCLUSION' || limit.type === 'EXCLUSION') {
        incExcLimits.push(limit);
      }else {
        otherLimits.push(limit);
      }
    })

		if (this.props.data.isSceneStateable) {
			return (
				<div>
					<h3>Disabled</h3>
					<p>Limits only work on Screen labels that are not "SceneState-able"</p>
					<p>If you wish to use limits you will need to disable the scene state option in the header</p>
					<p>Click the green box icon</p>
				</div>
			)
		}


    return (
      <div>
        <div style={{lineHeight:"30px",top:"0px",position: "absolute",right: "70px", fontSize: "13px",textDecoration:"underline"}} onClick={this.props.closeLimitsEditor}>Return</div>
          <InclusionExclusionLimitTable limits={incExcLimits} handleDelete={this.handleDelete} handleValue={this.handleValue} handleOther={this.handleOther} />
          <OtherLimitTable limits={otherLimits} handleValue={this.handleValue} handleDelete={this.handleDelete} />
          <div style={{display:"flex"}}>
            <select value={this.state.selectedOption} onChange={(e)=>{this.updateSelected(e.target.value)}}>
              <option value="INCLUSION">INCLUSION</option>
              <option value="EXCLUSION">EXCLUSION</option>
              <option value="ABOVE_ALTITUDE">ABOVE_ALTITUDE</option>
              <option value="BELOW_ALTITUDE">BELOW_ALTITUDE</option>
              <option value="DISTANCE">DISTANCE</option>
            </select>
            <div className="WorldEditorButton" onClick={this.addLimit}>Add Limit</div>
          </div>
      </div>
    )
  }
}

let InclusionExclusionLimitTable = (props) => {
  return (
    <div className="LimitTableContainer">
      Inclusion/Exclusion Limits
      <table className="LightsEditorContainer">
        <thead>
          <tr className="CSSEditorRow">
            <th>Attribute</th>
            <th>Value</th>
            <th>Radius</th>
            <th>Debug</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {props.limits.map((limit, index) => <InclusionLimitRow handleValue={props.handleValue} handleOther={props.handleOther} handleDelete={props.handleDelete} key={index} {...limit} />)}
        </tbody>
        </table>
      </div>
  )
}

let OtherLimitTable = (props) => {
  return (
    <div className="LimitTableContainer">
    Other Limits
      <table className="LightsEditorContainer">
        <thead>
          <tr className="CSSEditorRow">
            <th>Attribute</th>
            <th>Value</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {props.limits.map((limit, index) => <OtherLimitRow handleValue={props.handleValue} handleDelete={props.handleDelete} key={index} {...limit} />)}
        </tbody>
        </table>
      </div>
  )
}

let InclusionLimitRow = (props) => {
  let value = props.value;
  let otherValue = 'N/A';
  if (props.type === "INCLUSION" || props.type === "EXCLUSION") {
    value = "Set Position"
    otherValue = props.value.d;
  }

  let createDebug = () => {
    Inventum.labels.showLimitBounds(props.value);
  }

  return (
    <tr className="CSSEditorRow">
      <td>{props.type}</td>
      <td className="WorldEditorButton" title={JSON.stringify(props.value)} onClick={()=>{props.handleValue(props.limitID)}}>{value}</td>
      <td className="WorldEditorButton" onClick={()=>{props.handleOther(props.limitID)}}>{otherValue}</td>
      <td className="WorldEditorButton" onClick={createDebug}>Show</td>
      <td className="WorldEditorButton" onClick={()=>{props.handleDelete(props.limitID)}}>Delete</td>
    </tr>
  )
}

let OtherLimitRow = (props) => {
  let value = props.value;
  let otherValue = 'N/A';
  if (props.type === "INCLUSION" || props.type === "EXCLUSION") {
    value = "Set Position"
    otherValue = props.value.d;
  }
  return (
    <tr className="CSSEditorRow">
      <td>{props.type}</td>
      <td className="WorldEditorButton" title={JSON.stringify(props.value)} onClick={()=>{props.handleValue(props.limitID)}}>{value}</td>
      <td className="WorldEditorButton" onClick={()=>{props.handleDelete(props.limitID)}}>Delete</td>
    </tr>
  )
}


class LimitsPanel extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      open:false
    };
  }

  render () {
    return (
      <div>
        <div className='EditorDrawerItemHeader' onClick={() => { this.setState({ open: !this.state.open }) }}>
          <span className='PanelOpenStatus'>{this.state.open ? '▾' : '▸'}</span>
          <span>Limits</span>
        </div>
        { this.state.open ? <LimitsEditor data={{...this.props}} /> : null }
      </div>
    )
  }
}

export { LimitsPanel }
