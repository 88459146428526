import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import io from 'socket.io-client';
import {withRouter} from 'react-router-dom';
import ViewerPage from '../InventumViewer/Components/ViewerPage.js';
import AnnotateContainer from './Annotate.js';
import {setTitle, setSubtitle, resetTitles} from '../../Redux/actions/sidebarUI.js';

function calculateAspectRatio(targetWidth, targetHeight) {
  let forceHeight = {width:window.innerWidth, height:(targetHeight / targetWidth) * window.innerWidth};
  let forceWidth = {width:(targetWidth / targetHeight) * window.innerHeight, height:window.innerHeight};
  let updatedResolution = null;
  if (forceHeight.height < window.innerHeight) {
    //console.log('Aspect Ratio forcing height');
    updatedResolution = forceHeight;
  }else if (forceWidth.width < window.innerWidth) {
    //console.log('Aspect Ratio Forcing Width');
    updatedResolution = forceWidth;
  }else {
    console.log('Bad Aspect Ratio');
  }
  updatedResolution.width = Math.floor(updatedResolution.width);
  updatedResolution.height = Math.floor(updatedResolution.height);

  if (updatedResolution.width == 0) {
    updatedResolution.width = 1;
  }
  if (updatedResolution.height == 0) {
    updatedResolution.height = 1;
  }
  //console.log(`New Resolution is:${updatedResolution.width}x${updatedResolution.height}`);
  return updatedResolution
}


class MeetViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inRoom:false,
      socket:null,
      startTime:null,
      lastMouseMoveUpdateTime:0,
      chatMessages:[],
      chatUsers:{},
      chatTyping:[],
      chatNick:"Guest",
      chatColor:"#FF0000",
      sceneJSON:null,
      admin:false,
      controller:false,
      canUsePen:false,
      loadComplete:false,
      mode:"CONNECTING",
      width:window.innerWidth,
      height:window.innerHeight,
      controllerWidth:window.innerWidth,
      controllerHeight:window.innerHeight,
      meetingPassword:"",
      companyName:"",
      projectName:""
    };
    this.openConnection = this.openConnection.bind(this);
  }

  componentDidMount() {

  }


  componentWillUnmount() {
    this.state.socket.close();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hasCheckedStorage !== this.props.hasCheckedStorage) {
      this.openConnection();
    }
  }


  openConnection() {
    const socket = io('https://api.inventum3d.com');
    this.setState({socket:socket});
    socket.on('connect',() => {
      console.log('Connected');
      if (this.props.inventumToken) {
        socket.emit('inventum-token-validate-v2', this.props.inventumToken);
      }else {
        //Join as a guest
        let meetingCode = this.props.match.params.meetCode.replace(/-/g,"");
        socket.emit('join', meetingCode);
      }
    })

    socket.on('meeting-not-started-admin', (startTime) => {
      this.setState({startTime:new Date(startTime), mode:"MEETING_NOT_STARTED_ADMIN"});
    })

    socket.on('meeting-not-started-audience', (startTime) => {
      this.setState({startTime:new Date(startTime), mode:"MEETING_NOT_STARTED_AUDIENCE"});
    })


    socket.on('inventum-token-validate-success', () => {
      if (!this.state.inRoom) {
        let meetingCode = this.props.match.params.meetCode.replace(/-/g,"");
        socket.emit('join', meetingCode);
      }else {
        console.log('Debug:Already in room!');
      }
    })

    socket.on('inventum-token-validate-error', () => {
      console.log('Server failed to validate Token');
    })

    socket.on('meeting-info', (data) => {
      this.props.setTitle(data.projectName);
      this.props.setSubtitle(data.companyName);
      this.setState({ companyName: data.companyName, projectName: data.projectName });
    })

    socket.on('bad-meeting-code', () => {
      this.setState({mode:"INVALID_MEETING_CODE"});
    })

    socket.on('meeting-ended-presenter', () => {
      this.setState({mode:"MEETING_ENDED_PRESENTER"});
    })

    socket.on('meeting-ended-audience', () => {
      this.setState({mode:"MEETING_ENDED_AUDIENCE"});
    })

    socket.on('meeting-expired', () => {
      this.setState({mode:"MEETING_EXPIRED"});
    })

    socket.on('require-password',() => {
      this.setState({mode:"REQUIRE_PASSWORD"});
    })

    socket.on('wrong-password', () => {
      this.setState({mode:"WRONG_PASSWORD"});
    })

    socket.on('disconnect', () => {
      console.log('was disconnected');
      //this.setState({mode:"DISCONNECTED"});
    })

    socket.on('join-request-rejected', (reason) => {
      console.log(reason);
    })

    socket.on('join-success', () => {
      this.setState({inRoom:true});
    })

    socket.on('awaiting-admin', () => {
      this.setState({mode:"WAITING_FOR_ADMIN"});
    })

    socket.on('inventum-scene', (sceneJSON) => {
      this.setState({sceneJSON});
    })

    socket.on('kicked-room', () => {
      this.setState({mode:"KICKED"});
    })

    socket.on('banned-room', () => {
      this.setState({mode:"BANNED"});
    })

    socket.on('inventum-update', (data) => {
      if (!this.state.loadComplete) return;

      if (!this.state.controller) {
        Inventum.sync.setState(data);
        if (data.action == "SET_RESOLUTION") {
          let calcReso = calculateAspectRatio(data.payload.width, data.payload.height);
          this.setState({width:calcReso.width, height:calcReso.height, controllerWidth:data.payload.width, controllerHeight:data.payload.height});
        }
      }

    })

    socket.on('inventum-request-current-state',() => {
      if (!this.state.controller) return;
      Inventum.sync.getCurrentState();
    })

    socket.on('inventum-set-admin',() => {
      this.setState({canUsePen:true, controller:true, admin:true, mode:"READY"});
    });

    socket.on('inventum-set-controller', () => {
      this.setState({controller:true})
    })

    socket.on('inventum-remove-controller', () => {
      this.setState({controller:false});
    })

    socket.on('inventum-controller-ready', (sceneJSON) => {
      if (this.state.sceneJSON) {
        this.setState({mode:"READY"});
        return;
      };
      this.setState({sceneJSON:sceneJSON, mode:"READY"});
    })

    socket.on('inventum-chat-message', (messages) => {
      let chatMessages = this.state.chatMessages;
      messages.map(message => chatMessages.push(message));
      this.setState({chatMessages});
    })

    socket.on(`users-in-room`, (users) => {
      //Returns a object with the users UUID as the key
      this.setState({chatUsers:users});
    })

    socket.on('set-nick', (nick) => {
      this.setState({chatNick:nick});
    })

    socket.on('inventum-annotation-grant-pen', () => {
      this.setState({canUsePen:true});
    })

    socket.on('inventum-annotation-revoke-pen', () => {
      this.setState({canUsePen:false});
    })

    socket.on('users-typing', (usersTyping) => {
      if (usersTyping.includes(this.state.chatNick)) {
        usersTyping.splice(usersTyping.indexOf(this.state.chatNick), 1);
      }
      this.setState({chatTyping:usersTyping.slice()});
    })
  }

  render() {
    const setNick = () => {
      let nick = prompt("Set Nickname:", this.state.chatNick);
      if (nick == null || nick == "" || nick == this.state.chatNick) {
        return;
      }
      this.state.socket.emit('set-nick', nick);
    }

    const requestControllerState = () => {
      this.state.socket.emit('inventum-request-current-state')
    }

    const submitPassword = () => {
      console.log('Submitting Password');
      this.state.socket.emit('password-submit', this.state.meetingPassword);
      this.setState({meetingPassword:""})
    }

    const startMeetingEarly = () => {
      this.state.socket.emit('inventum-start-meeting-early')
      this.setState({mode:'STARTING_MEETING'})
    }



    const updateResolution = (width, height) => {
      if (this.state.controller) {
        //If controller then update your own width height.
        this.setState({width, height})
      }else {
        //If not controller then recalculate the aspect ratio based on the controllers previous width and height
        let calcReso = calculateAspectRatio(this.state.controllerWidth, this.state.controllerHeight);
        this.setState({width:calcReso.width, height:calcReso.height});
      }
    }


    const onLoadComplete = () => {
      this.setState({loadComplete:true});
    }

    if (this.state.mode == "READY") {
      return (
        <div className="MeetingViewerContainer">
          <Chat isController={this.state.controller} isAdmin={this.state.admin} nick={this.state.chatNick} setNick={setNick} messages={this.state.chatMessages} users={this.state.chatUsers} typing={this.state.chatTyping} socket={this.state.socket} />
          <AnnotateContainer canUsePen={this.state.canUsePen} updateResolution={updateResolution} isAdmin={this.state.admin} isController={this.state.controller} resolution={{width:this.state.width, height:this.state.height}} socket={this.state.socket} />
          {this.state.sceneJSON ? <InventumSyncViewer socket={this.state.socket} onLoadComplete={onLoadComplete} loadComplete={this.state.loadComplete} requestControllerState={requestControllerState} isController={this.state.controller} sceneJSON={this.state.sceneJSON}/> : null}
        </div>
      )
    }

    if (this.state.mode == "MEETING_NOT_STARTED_AUDIENCE" || this.state.mode == "MEETING_NOT_STARTED_ADMIN" ) {
      return <MeetingNotYetStarted startMeetingEarly={startMeetingEarly} setNick={setNick} {...this.state} />
    }

    if (this.state.mode === "WAITING_FOR_ADMIN") {
      return <WaitingForPresenter setNick={setNick} {...this.state} />
    }

    if (this.state.mode == "REQUIRE_PASSWORD" || this.state.mode == "WRONG_PASSWORD") {
      return <PasswordPrompt mode={this.state.mode} handleChange={e => this.setState({meetingPassword:e.target.value})} handleSubmit={submitPassword} />
    }

    //Otherwise
    let messageTitle = "Something went wrong";
    let messageDetail = "...";

    switch(this.state.mode) {
      case "KICKED":
        messageTitle = "You have been kicked";
        messageDetail = "The admin of the meeting has kicked you."
        break;
      case "BANNED":
        messageTitle = "You have been banned";
        messageDetail = "The admin of the meeting has banned you. You will be unable to rejoin this meeting."
        break;
      case "INVALID_MEETING_CODE":
        messageTitle = "Invalid Meeting Code";
        messageDetail = "This meeting code doesn't exist or has expired."
        break;
      case "DISCONNECTED":
        messageTitle = "Disconnected";
        break;
      case "WAITING_FOR_ADMIN":
        messageTitle = "Waiting for Presenter";
        messageDetail = ""
        break;
      case "MEETING_ENDED_AUDIENCE":
        messageTitle = "Meeting Over";
        messageDetail = "Thank you for attending."
        break;
      case "MEETING_ENDED_PRESENTER":
        messageTitle = "Meeting Over";
        messageDetail = "Thank you for presenting."
        break;
      case "CONNECTING":
        messageTitle = "Connecting";
        messageDetail = "Please wait a moment..."
        break;
      case "STARTING_MEETING":
        messageTitle = "Starting Meeting";
        messageDetail = "Please Wait...";
        break;
      default:
        messageTitle = "Something went wrong"
        break;
    }

    return <GeneralMessage title={messageTitle} message={messageDetail} />
  }

}

function GeneralMessage(props) {
  return (
    <div className="MeetingViewerContainer connecting">
      <div className="ConnectionUIBox">
        <div className="MeetingPasswordTitle">{props.title}</div>
        <div className="MeetingPasswordInfo">{props.message}</div>
      </div>
    </div>
  )
}

function MeetingNotYetStarted(props) {
  return (
    <div className="MeetingViewerContainer connecting">
      <Chat isAdmin={props.admin} nick={props.chatNick} setNick={props.setNick} messages={props.chatMessages} users={props.chatUsers} typing={props.chatTyping} socket={props.socket} />
      <div className="ConnectionUIBox">
        <div className="MeetingPasswordTitle">Meeting hasn't started.</div>
        <div className="MeetingPasswordInfo">Meeting is scheduled to start at <br /><span style={{fontWeight:"bold"}}>{props.startTime.toLocaleTimeString()} on {props.startTime.toLocaleDateString()}</span></div>
        {props.mode === "MEETING_NOT_STARTED_ADMIN" ? <div className="MeetingPasswordButton" onClick={props.startMeetingEarly}>Start Now</div> : null}
      </div>
    </div>
  )
}

function WaitingForPresenter(props) {
  return (
    <div className="MeetingViewerContainer connecting">
      <Chat isAdmin={props.admin} nick={props.chatNick} setNick={props.setNick} messages={props.chatMessages} users={props.chatUsers} typing={props.chatTyping} socket={props.socket} />
      <div className="ConnectionUIBox">
        <div className="MeetingPasswordTitle">Waiting for Presenter.</div>
        <div className="MeetingPasswordInfo">Presenter has not yet joined. This meeting will start automatically when they have</div>
      </div>
    </div>
  )
}


function PasswordPrompt(props) {
  return (
    <div className="MeetingViewerContainer connecting">
      <div className="ConnectionUIBox">
        <div className="MeetingPasswordTitle">This meeting is password protected</div>
        <div className="MeetingPasswordInfo">If you don't know the password please contact the person who provided you this meeting code or URL.</div>
        <div className="MeetingPasswordInputContainer">
          <span>Password</span>
          <input type="password" value={props.meetingPassword} onChange={props.handleChange} />
        </div>
        {props.mode == "WRONG_PASSWORD" ? <div>Wrong Password</div> : null}
        <div className="PasswordSubmitButtonsContainer">
          <div className="MeetingPasswordButton PasswordCancelButton" onClick={()=>{window.location.href="/meet"}}>Cancel</div>
          <div className="MeetingPasswordButton" onClick={props.handleSubmit}>Submit</div>
        </div>
      </div>
    </div>
  )
}

class InventumSyncViewer extends React.Component {
  constructor(props) {
    super(props);
    this.setController = this.setController.bind(this);
  }

  componentDidMount() {
    Inventum.sync.setFollowerMode(true);
    Inventum.onLoadComplete(() => {
      //Sets the parent state loadComplete to true.
      this.props.onLoadComplete();
      //If not the controller then we don't need to do anything other than request the current state.
      //Inventum loads assuming you aren't the controller
      if (!this.props.isController) {
        this.props.requestControllerState();
      }else {
        //If we are the controller then we call this function
        this.setController(true);
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (!this.props.loadComplete) return;
    if (prevProps.isController !== this.props.isController) {
      if (!this.props.isController) {
        //Remove Control
        this.setController(false);
      }else {
        //Grant Control
        this.setController(true);
      }
    }
  }

  setController(isController) {
    if (isController) {
      Inventum.sync.setFollowerMode(false);
      Inventum.sync.setBroadcastMode(true);
      Inventum.sync.registerCallback((action) => {
        //Event property can be an override if you don't want to use inventum-update
        if (action.eventOverride) {
          //Remove eventOverride as we don't need it anymore.
          let tEvent = action.eventOverride;
          delete action.eventOverride;
          this.props.socket.emit(tEvent, action);
        }else {
          this.props.socket.emit('inventum-update', action);
        }
      })
      Inventum.resolution.forceUpdate(); // Issue
      this.props.socket.emit('inventum-controller-ready');
    }else {
      Inventum.sync.setFollowerMode(true);
      Inventum.sync.setBroadcastMode(false);
      Inventum.sync.removeCallbacks(); //Removes all callbacks. There should only be one but it doesn't matter currently.
    }
  }

  render() {
    return (
      <div style={{display:"flex",alignItems:"center", justifyContent:"center", pointerEvents:"none",width:"100%",height:"100%",position:"absolute"}}>
        <canvas style={{position:"relative", pointerEvents:"all"}} id="Inventum3D"></canvas>
        <ViewerPage noAutoplay={true} hideAnimationBar={!this.props.isController} sceneJSON={this.props.sceneJSON}/>
      </div>
    )
  }
}

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message:"",
      lastTypedTime:null,
      lastTypedIntervalID:null
    };
  }

  render() {
    const handleSubmit = () => {
      this.props.socket.emit('inventum-chat-message', this.state.message);
      if (this.state.lastTypedIntervalID) {
        clearInterval(this.state.lastTypedIntervalID);
        this.setState({lastTypedIntervalID:null});
      }
      this.setState({message:"", lastTypedTime:null})
    }

    const hasRecentlyTyped = () => {
      if ((Date.now() - this.state.lastTypedTime) > 3000) {
        //Broadcast Not Typing
        this.props.socket.emit('stopped-typing');
        console.log('Stopped Typing');
        clearInterval(this.state.lastTypedIntervalID);
        this.setState({lastTypedTime:null, lastTypedIntervalID:null});
      }
    }


    const handleKeyPress = (e) => {
      if (e.key == "Enter" && !e.shiftKey) {
        e.preventDefault();
        clearInterval(this.state.lastTypedIntervalID);
        handleSubmit();
      }else if (!this.state.lastTypedIntervalID) {
        //Broadcast Start Typing
        this.props.socket.emit('started-typing');
        this.setState({lastTypedTime:Date.now(), lastTypedIntervalID:setInterval(hasRecentlyTyped, 1000)});
      }else {
        this.setState({lastTypedTime:Date.now()});
      }
    }

    const handleEndMeeting = () => {
      if(confirm('Do you want to end the meeting?')) {
        this.props.socket.emit('end-meeting');
      }
    }

    const handleTakeControl = () => {
      if(confirm('Do you want to take back control?')) {
        this.props.socket.emit('inventum-return-control');
      }
    }

    const handleReturnControl = () => {
      if(confirm('Do you want to return control?')) {
        this.props.socket.emit('inventum-return-control');
      }
    }

    return (
      <div className="MeetingChatContainer">
        <div className="MeetingChatLeftPanel">
          {this.props.isAdmin ? <div className="EndMeetingButton" onClick={handleEndMeeting}>End Meeting</div> : null}
          {(this.props.isAdmin && !this.props.isController) ? <div className="EndMeetingButton" onClick={handleTakeControl}>Take Control</div> : null}
          {(!this.props.isAdmin && this.props.isController) ? <div className="EndMeetingButton" onClick={handleReturnControl}>Return Control</div> : null}
          <ChatUsers socket={this.props.socket} users={this.props.users} />
          <CurrentUser nick={this.props.nick} setNick={this.props.setNick} />
        </div>
        <div className="MeetingChatRightPanel">
          <ChatMessages users={this.props.users} messages={this.props.messages} />
          <div className="MeetingChatBottomBar">
            <textarea placeholder="Send Message" className="MeetingChatMessageInput" value={this.state.message} onKeyPress={handleKeyPress} onChange={(e) => this.setState({message:e.target.value})} />
            <div className="MeetingChatIsTyping">
            {this.props.typing.map((nick, index) => {
              if (this.props.typing.length > 1 && index !== this.props.typing.length - 1) {
                return (<span key={index}>{nick}, </span>)
              }
              return (<span key={index}>{nick}</span>)
            })
            } {this.props.typing.length > 0 ? " is Typing" : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function ChatMessage(props) {
  let time = new Date(props.timestamp).toLocaleTimeString();
  let style = {backgroundColor:props.color};
  if (props.avatar) {
    if (props.avatar.length > 0) {
      style.backgroundImage = `url('${'http://mapability-website.s3.amazonaws.com/avatars/' + props.avatar}')`;
      style.backgroundSize = 'cover';
    }
  }
  return (
    <div className="MeetingChatMessage">
      <div className="MeetingChatAvatar">
        <div style={style} className="MeetingChatAvatarImage"></div>
      </div>
      <div>
        <div className="MeetingChatMessageHeader">
          <div className="MeetingChatMessageUsername">{props.nick}</div>
          <div className="MeetingChatMessageTimestamp">{time}</div>
        </div>
        <div className="MeetingChatMessageContent">{props.message}</div>
      </div>
    </div>
  )
}

function ChatMessages(props) {
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({behavior:'smooth'});
  }

  useEffect(scrollToBottom, [props.messages.length]);
  return (
    <div className="MeetingChatMessagesBox">
      {props.messages.map((container, index) => <ChatMessage key={index} {...container} />)}
      <div ref={messagesEndRef} />
    </div>
  )
}

function ChatUsers(props) {
  const [openedUser, openUser] = useState("");
  return (
    <div className="MeetingChatUsersBox">
    <div style={{"fontWeight":"bold"}}>Users</div>
    {Object.keys(props.users).map((uuid, index) => <UserEntry socket={props.socket} key={index} handleClick={()=>{openedUser == uuid ? openUser("") : openUser(uuid)}} isOpen={openedUser == uuid} {...props.users[uuid]} /> )}
    </div>
  )
}

function UserEntry(props) {
  return (
    <div onClick={props.handleClick} className="ChatUserEntry">
      <div style={{display:"flex"}}><i style={{lineHeight:"15px",width:"20px",marginLeft:"-5px"}} className="material-icons notranslate">{props.isOpen ? "keyboard_arrow_down" : "keyboard_arrow_right"}</i>{props.nick}</div>
      {props.isOpen ? <UserAdminTools usePen={props.usePen} nick={props.nick} uuid={props.uuid} socket={props.socket} /> : null}
    </div>
  )
}

function UserAdminTools(props) {
  const handleKick = (e) => {
    if(confirm(`Are you sure you want to kick ${props.nick}?`)) {
      props.socket.emit('kick-user', {uuid:props.uuid})
    }else {
      e.stopPropagation();
    }
  }

  const handleBan = (e) => {
    if(confirm(`Are you sure you want to ban ${props.nick}?`)) {
      props.socket.emit('ban-user', {uuid:props.uuid})
    }else {
      e.stopPropagation();
    }
  }

  const handleControl = (e) => {
    if(confirm(`Are you sure you want to give control to ${props.nick}?`)) {
      props.socket.emit('inventum-grant-control', {uuid:props.uuid})
    }else {
      e.stopPropagation();
    }
  }

  const handlePen = (e) => {
    if (props.usePen) {
      if(confirm(`Are you sure you want to revoke the pen tool from ${props.nick}?`)) {
        props.socket.emit('inventum-annotation-revoke-pen', {uuid:props.uuid})
      }else {
        e.stopPropagation();
      }
    }else {
      if(confirm(`Are you sure you want to grant the pen tool to ${props.nick}?`)) {
        props.socket.emit('inventum-annotation-grant-pen', {uuid:props.uuid})
      }else {
        e.stopPropagation();
      }
    }
  }

  return (
    <div className="ChatAdminControlsContainer">
      <div className="ChatAdminControlsEntry" onClick={handlePen}>{props.usePen ? "Revoke Pen" : "Grant Pen"}</div>
      <div className="ChatAdminControlsEntry" onClick={handleControl}>Grant Control</div>
      <div className="ChatAdminControlsEntry" onClick={handleKick}>Kick</div>
      <div className="ChatAdminControlsEntry" onClick={handleBan}>Ban</div>
    </div>
  )
}


function CurrentUser(props) {
  return (
    <div className="MeetingChatUserStatusBox" onClick={props.setNick}>{props.nick}</div>
  )
}


let mapStateToProps = (state) => ({
  hasCheckedStorage:state.user.hasCheckedStorage,
  inventumToken:state.user.token,
})

let mapDispatchToProps = dispatch => ({
  setTitle:(text) => {
    dispatch(setTitle(text));
  },
  setSubtitle:(text) => {
    dispatch(setSubtitle(text));
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MeetViewer))
