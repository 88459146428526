import React from 'react';

class StaticPanel extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render () {
    return (
      <div>
        <div className='EditorDrawerItemHeader' onClick={() => { this.setState({ open: !this.state.open }) }}>
          <span className='PanelOpenStatus'>{this.state.open ? '▾' : '▸'}</span>
          <span>Position & Scale</span>
        </div>
        {this.state.open ? <StyleDrawer
           {...this.props}
           positionX={this.props.positionX}
           positionY={this.props.positionY}
           scale={this.props.scale}
           />
        : null}
      </div>
    )
  }
}

function StyleDrawer (props) {
	let scaleDisplayRange = { min: 1, max: 20 };
	let scaleNormRange = { min: 0.1, max: 1 };

  return (
    <div>
      <ValueInput title='Scale' tooltip='1 - 20. Bigger may clip.' min={1} max={20} inputKey='scale' value={Math.round(remapRange(props.scale, scaleNormRange.min, scaleNormRange.max, scaleDisplayRange.min, scaleDisplayRange.max))} handleChange={(value) => {Inventum.labels.setStaticScale(props.id, remapRange(value, scaleDisplayRange.min, scaleDisplayRange.max, scaleNormRange.min, scaleNormRange.max)); props.getLabels()}} {...props} />
      <ValueInput title='X Position' tooltip='0 - 100%. Bigger or smaller may clip.' inputKey='positionX' value={Math.round(props.positionX * 100)} handleChange={(value) => {Inventum.labels.setStaticPosition(props.id, {x: value / 100}); props.getLabels()}} {...props} />
      <ValueInput title='Y Position' tooltip='0 - 100%. Bigger or smaller may clip.' inputKey='positionY' value={Math.round(props.positionY * 100)} handleChange={(value) => {Inventum.labels.setStaticPosition(props.id, {y: value / 100}); props.getLabels()}} {...props} />
      <div>
        <span className='CenterLabelPositionButton' onClick={() => { Inventum.labels.centerStaticLabel(props.id); props.getLabels(); }}>Center Position</span>
      </div>
    </div>
  )
}

function ValueInput (props) {
  if (!Object.prototype.hasOwnProperty.call(props, 'scale')) {
    return null;
  }

  return (
    <div style={{ display:'flex', fontSize: '14px', marginBottom: '10px' }}>
      <div className='PanelKeyCell' title={props.title + ':' + props.tooltip}>{props.title}</div>
      <div className='PanelValueCell' title={props.title + ':' + props.tooltip}>
       <input type='number' step={1} max={props.max} min={props.min} style={{ width: '100px' }} onChange={(e) => {props.handleChange(e.target.value)}} value={props.value} />
      </div>
    </div>
  )
}

function remapRange (value, fmin, fmax, tmin, tmax) {
    return tmin + (tmax - tmin) * (value - fmin) / (fmax - fmin);
}

export { StaticPanel }
