import React from 'react';
import Draggable from 'react-draggable';
import axios from '../../../axios-config.js';
import {connect} from 'react-redux';
import {closeModal} from '../../../Redux/actions/ui.js';

let CloseButton = (props) => {
  return (
    <div onClick={props.handleClick} className="titlebarCloseButton"><i className="material-icons notranslate">close</i></div>
  )
}

let MinimizeButton = (props) => {
  return (
    <div className="titlebarMinMaxButton" onClick={props.toggleMinimize}>
      <i className="material-icons notranslate">{props.minimized ? "crop_square" : "remove"}</i>
    </div>
  )
}

let AssetForm = (props) => {
  let size = Number((props.size / 1024 / 1024).toFixed(3));

  let handleSelect = () => {
    if (props.modalType === "mesh") {
      Inventum.models.addModel(props.referenceID,props.s3_path,props.original_file_name);
    }else {
      Inventum.models.setTexture(props.referenceID,props.s3_path);
    }
  }

  let handleDelete = () => {
    /*let deleteConfirm = prompt("Type YES to Delete:","");
    if (deleteConfirm.toUpperCase() !== "YES") {
      return;
    }*/

    let url = `https://api.inventum3D.com/project/${props.projectID}/assets/${props.id}`;
    axios.delete(url)
    .then(response => {
      props.getAssets();//Update list of assets after deleting.
    })
    .catch(error => {
      console.log(error);
    });
  }

  let handleLink = () => {
    navigator.clipboard.writeText(props.s3_path)
    .then(() => {
      Inventum.notifications.addNotification({content:"Copied asset link to clipboard",displayTime:3000})
    })
  }

  var updated_at = new Date(props.updated_at);
  return (
    <tr>
      <td style={{width:"400px"}}>{props.original_file_name}</td>
      <td style={{fontSize:"11px"}} title={updated_at}>{`${updated_at.getDate()}/${updated_at.getMonth() + 1}/${updated_at.getFullYear().toString().substr(-2)}`}</td>
      <td>{props.category}</td>
      <td className="WorldEditorButton" onClick={handleLink}>Cp Link</td>
      <td>{size < 1 ? size * 1024 : size} {size < 1 ? "KB" :"MB"}</td>
      <td className="WorldEditorButton" onClick={handleSelect}>Select</td>
      <td style={{backgroundColor:"#FF0000"}} className="WorldEditorButton" onClick={handleDelete}>Delete</td>
    </tr>
  )
}

class AssetsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assets:[],
      uploadComplete:false,
      currentlyUploading:false,
      fileCount:0,
      fileIndex:0,
      erroredFiles:[],
      activeFileName:'',
      activeFileSize:0,
      progressPercent:0
    };
    this.getAssets = this.getAssets.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  componentDidMount() {
    this.getAssets();
  }

  handleUpload(e) {
    e.persist();
    let fileIndex = 0;
    let fileCount = e.target.files.length;
    let erroredFiles = [];
    if (fileCount === 0) {
      return;
    }

    let activeFileName = e.target.files[fileIndex].name;
    let activeFileSize = e.target.files[fileIndex].size;
    this.setState({currentlyUploading:true,fileIndex,fileCount,activeFileName,activeFileSize});

    let onUploadProgress = (e) => {
      this.setState({progressPercent:Math.round((e.loaded * 100) / e.total)});
    }

    let sendFile = (file) => {
      var form = new FormData();
      form.append('asset', file);
      form.append('projectID', this.props.activeProject);
      axios.post(`https://api.inventum3D.com/upload/`, form, { onUploadProgress })
      .then(response => {
        fileIndex++;
        if (fileIndex < fileCount) {
          activeFileName = e.target.files[fileIndex].name;
          activeFileSize = e.target.files[fileIndex].size;
          this.setState({fileIndex,fileCount,activeFileName,activeFileSize,progressPercent:0});
          sendFile(e.target.files[fileIndex]);
        } else {
          this.setState({uploadComplete:true,currentlyUploading:false,fileIndex:0,fileCount:0,activeFileName:'',activeFileSize:0,progressPercent:0});
        }
      })
      .catch(error => {
        console.log(error);
        //Update the errored Files List
        erroredFiles.push(e.target.files[fileIndex].name);
        this.setState({erroredFiles});
        fileIndex++;
        if (fileIndex < fileCount) {
          activeFileName = e.target.files[fileIndex].name;
          activeFileSize = e.target.files[fileIndex].size;
          this.setState({fileIndex,fileCount,activeFileName,activeFileSize,progressPercent:0});
          sendFile(e.target.files[fileIndex]);
        }else{
          this.setState({uploadComplete:true,currentlyUploading:false,fileIndex:0,fileCount:0,activeFileName:'',activeFileSize:0,progressPercent:0});
        }
      });
    }

    sendFile(e.target.files[fileIndex]);

  }

  getAssets() {
    axios.get(`https://api.inventum3d.com/project/${this.props.activeProject}/assets`)
    .then((result) => {
      let data = result.data;
      data.sort((a, b) => {
        let dateA = new Date(a.updated_at);
        let dateB = new Date(b.updated_at);

        if (dateA > dateB) {
          return -1
        }

        if (dateA < dateB) {
          return 1
        }

        return 0;
      })
      this.setState({assets:data})
    })
    .catch((error) => {
      console.log(error);
    })
  }

  render() {
    //Only shows assets that match the type of modalOptions.type
    //This is so we can only show the mesh models when selecting a mesh or images when selecting a texture
    let assets = this.state.assets.filter(asset => {
      return asset.type === this.props.modalOptions.type;
    })

    if (this.state.currentlyUploading) {
      return (
        <div className="WorldEditorDrawer">
          <div>Uploading {this.state.fileIndex + 1} of {this.state.fileCount}</div>
            <div>Name: {this.state.activeFileName}</div>
            <div>Size: Size:{((this.state.activeFileSize / 1024) / 1024).toFixed(2)} Mb</div>
            <ProgressBar percent={this.state.progressPercent} />
        </div>
      )
    }

    if (this.state.uploadComplete) {
      return (
        <div className="WorldEditorDrawer">
          <div>Upload Completed!</div>
            <p>
              It will take a few seconds to a few minutes to process the upload.
            </p>
            <p>
              Once it's completed you will see it in this menu after you reload the menu.
            </p>
            <div className="WorldEditorButton" onClick={()=>{this.getAssets();this.setState({uploadComplete:false})}}>Got It!</div>
        </div>
      )
    }

    let accepts = "";
    let modalType = this.props.modalOptions.type;
    let selectButtonText = "Add To Scene";
    if (modalType === "mesh") {
      selectButtonText = "Add To Scene";
      accepts = ".obj";
    } else if (modalType === "image") {
      selectButtonText = "Use as Texture";
      accepts = ".jpg, .jpeg, .png"
    }

		const uploadDisabled = false; // Temporary disabled uploading assets.

		return (
      <div className="WorldEditorDrawer">
        <table className="LightsEditorContainer">
          <thead>
            <tr>
              <th style={{width:"400px"}}>Name</th>
              <th>Date</th>
              <th>Category</th>
              <th>Cp Link.</th>
              <th>Size</th>
              <th>{selectButtonText}</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {assets.map((asset,index) => <AssetForm modalType={modalType} projectID={this.props.activeProject} referenceID={this.props.modalOptions.referenceID} closeModal={this.props.closeModal} getAssets={this.getAssets} key={index} {...asset}/>)}
          </tbody>
        </table>
        <input accept={accepts} multiple id="myInput" type="file" ref={(ref) => this.upload = ref} style={{display: 'none'}} onChange={this.handleUpload}/>
        { uploadDisabled ? <div className="WorldEditorButton">Uploading Disabled</div> : <div className="WorldEditorButton" onClick={()=>{this.upload.click()}}>Upload Asset</div>}
        <div className="AssetRefreshButton" onClick={this.getAssets}>Refresh</div>
      </div>
    )
  }
}

function ProgressBar(props) {
  let width = props.percent + "%";
  return (
    <div className="UploadProgressContainer">
      <div style={{width}} className="UploadProgressBar"></div>
    </div>
  )
}

function WindowControls(props) {
  return (
    <div className="windowControlsContainer">
      <MinimizeButton minimized={props.minimized} toggleMinimize={props.toggleMinimize} />
      <CloseButton handleClick={props.handleClose} />
    </div>
  )
}

class AssetManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {title:"Asset Manager"}
    this.setTitle = this.setTitle.bind(this);
    this.toggleMinimize = this.toggleMinimize.bind(this);
  }

  toggleMinimize(force) {
    if (typeof(force) === "boolean") {
      this.setState({minimized:force});
    } else {
      this.setState({minimized:!this.state.minimized});
    }
  }

  setTitle(newTitle) {
    this.setState({title:newTitle})
  }

  render () {
    return (
      <Draggable handle=".popupDragHandle">
      <div className={"WorldEditorContainer" + (this.state.minimized ? " minimized" : "")}>
        <div className="popupDragHandle"><div style={{paddingLeft:"5px"}}>{this.state.title}</div><WindowControls minimized={this.state.minimized} toggleMinimize={this.toggleMinimize} handleClose={this.props.closeModal} /></div>
          {this.state.minimized ? null : <AssetsList closeModal={this.props.closeModal} activeProject={this.props.activeProject} modalOptions={this.props.modalOptions} />}
        </div>
      </Draggable>
    )
  }
}


let mapDispatchToProps = dispatch => ({
  closeModal:() => {
    dispatch(closeModal());
  }
})

let mapStateToProps = (state) => ({
  activeProject:state.ui.activeProject,
  modalOptions:state.ui.modalOptions
})

const AssetManagerContainer = connect(mapStateToProps,mapDispatchToProps)(AssetManager)

export default AssetManagerContainer
