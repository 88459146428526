import {
  SET_COMPANY_ID,
  SET_PROJECT_ID,
  SET_TYPE
} from '../actions/activePage.js';

function activePage(state = {type:'/',companyID:'',projectID:''}, action) {
  switch (action.type) {
    case SET_COMPANY_ID:
      return Object.assign({},state,{companyID:action.data})
    case SET_PROJECT_ID:
      return Object.assign({},state,{projectID:action.data})
    case SET_TYPE:
      return Object.assign({},state,{type:action.data})
    default:
      return state
  }
}

export default activePage;
