import React from 'react';
import TimelineControls from './TimelineControls';
import {connect} from 'react-redux';
import {toggleUiHideAnimation} from '../../../Redux/actions/ui.js';

function ReplayButton(props) {
  let btnEnabled = !props.playing;
  const handleClick = () => {
    if (btnEnabled) {
      Inventum.animations.restart();
    }
  }
  return (
    <div className={"button" + (btnEnabled ? "" : " disabled")} onClick={handleClick} title="Reset Animation">
      <i className="material-icons notranslate">replay</i>
    </div>
  )
}

function ResetActionButton(props) {
  let btnEnabled = !props.playing;
  const handleClick = () => {
    if (btnEnabled) {
      Inventum.animations.resetAction();
    }
  }
  return (
    <div className={"button" + (btnEnabled ? "" : " disabled")} onClick={handleClick} title="Reset Action">
      <i className="material-icons notranslate">undo</i>
    </div>
  )
}


function SkipBackButton(props) {
  let btnEnabled = !props.playing;
  const handleClick = () => {
    if (btnEnabled) {
      Inventum.animations.previous();
    }else {
      console.log('Cannot click back while playing');
    }
  }
  return (
    <div className={"button" + (btnEnabled ? "" : " disabled")} onClick={handleClick} title="Previous Action">
      <i className="material-icons notranslate">skip_previous</i>
    </div>
  )
}

function PlayButton(props) {
  const handleClick = () => {
    if (props.playing) {
      Inventum.animations.stop();
      //props.toggleSideBar(true);
    }else {
      props.toggleSideBar(false);
      Inventum.animations.play();
    }
  }
  return (
    <div className="button" onClick={handleClick} title="Play Animation">
      <i className="material-icons notranslate">{props.playing === true ? "pause" : "play_arrow"}</i>
    </div>
  )
}

function SkipNextButton(props) {
  let btnEnabled = !props.playing;
  const handleClick = () => {
    if (btnEnabled) {
      Inventum.animations.next();
    } else {
      console.log('Cannot click next while playing');
    }
  }
  return (
    <div className={"button" + (btnEnabled ? "" : " disabled")} onClick={handleClick} title="Next Action">
      <i className="material-icons notranslate">skip_next</i>
    </div>
  )
}

function RepeatButton(props) {
  let btnActive = false;
  if (props.willLoop) {
    btnActive = true;
  }
  const handleClick = () => {
    Inventum.animations.toggleLoop();
  }
  return (
    <div className={"button" + (btnActive ? " active" : "")} onClick={handleClick} title="Loop">
      <i className="material-icons notranslate">repeat</i>
    </div>
  )
}


function StatusBar(props) {
  if (props.activeAnimation === "No Animation Selected") {
    return null;
  }
  return (
    <div className="StatusBar">
      {props.currentKeyframe} / {props.keyframeCount}
    </div>
  )
}

function SelectBar(props) {
  return (
    <div className="SelectBar" onClick={props.toggleSelectMenu}>
      <div className="SelectorArrow"><i className="material-icons notranslate">arrow_drop_up</i></div>
      <AnimationSelector reviewMode={props.reviewMode} siteAdmin={props.siteAdmin} activeAnimation={props.activeAnimation} toggleSideBar={props.toggleSideBar} toggleSelectMenu={props.toggleSelectMenu} selectorMenuOpen={props.selectorMenuOpen} animations={props.animations} />
      <StatusBar activeAnimation={props.activeAnimation} currentKeyframe={props.currentKeyframe} keyframeCount={props.keyframeCount} />
    </div>
  )
}

function AnimationSelector(props) {
  return (
    <div className="AnimationSelector">
      {props.activeAnimation}
      {props.selectorMenuOpen ? <AnimationList reviewMode={props.reviewMode} siteAdmin={props.siteAdmin} animations={props.animations} toggleSideBar={props.toggleSideBar}/> : null }
    </div>
  )
}

function AnimationList(props) {
  //props.siteAdmin gets pushed down from ./ViewerPage. It is set when a user logs into the Inventum API.
  let handleClick = () => {
    if (!props.reviewMode) {
      alert(`You can only create an Animation in Review Mode!\n\nPlease switch to review mode by clicking the review button at the top of the screen\n\nYou should probally clone the current Master scene to a review link first by pressing the "Clone Master to Review Button"`)
      return;
    }
    Inventum.animations.createAnimation();
  }
  let showEditor = false;
  if (props.siteAdmin && props.reviewMode) {
    showEditor = true;
  }
  return (
    <div className="AnimationList">
      {showEditor ? <div className="AnimationEntry CreateAnimation" onClick={handleClick}>Create Animation</div> : null }
      {props.animations.map((entryData, index) => <AnimationEntry key={index} name={entryData.name} id={entryData.id} toggleSideBar={props.toggleSideBar} />)}
    </div>
  )
}

function AnimationEntry(props) {
  let handleClick = () => {
    Inventum.animations.selectAnimation(props.id);
    //props.toggleSideBar(false);
  }
  return (
    <div className="AnimationEntry" onClick={handleClick}>{props.name}</div>
  )
}


function ControlsButton(props) {
  return (
    <div className="button">
      <i className="material-icons notranslate">{props.icon}</i>
    </div>
  )
}

function ControlsPanel(props) {
  return (
    <div className="ControlsPanel">
      <ReplayButton playing={props.playing}/>
      <ResetActionButton playing={props.playing}/>
      <SkipBackButton playing={props.playing} />
      <PlayButton toggleSideBar={props.toggleSideBar} playing={props.playing} />
      <SkipNextButton playing={props.playing} />
      <RepeatButton willLoop={props.willLoop}/>
      <GalleryButton toggleGallery={props.toggleGallery}/>
      <TimelineButton toggleTimeline={props.toggleTimeline} animationName={props.animationName} />
    </div>
  )
}

function GalleryButton(props) {
  let handleClick = () => {
    props.toggleGallery();
  }
  return (
    <div className="button" onClick={handleClick} title="Show Gallery">
      <i className="material-icons notranslate">apps</i>
    </div>
  )
}

function TimelineButton(props) {
  let handleClick = () => {
    props.toggleTimeline();
  }
  let btnEnabled = true;
  if (props.animationName === "No Animation Selected") {
    btnEnabled = false;
  }
  return (
    <div className={"button" + (btnEnabled ? "" : " disabled")} onClick={handleClick} title="Show Timeline">
      <i className="material-icons notranslate">linear_scale</i>
    </div>
  )
}


class AnimationControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name:"Unselected Animation",
      controlsVisible:false,
      currentKeyframe:null,
      playing:false,
      keyframes:[],
      keyframeCount:0,
      timelineVisible:false,
      selectorMenuOpen:false,
      animations:[]
    };
    this.updateAnimationState = this.updateAnimationState.bind(this);
    this.toggleTimeline = this.toggleTimeline.bind(this);
    this.toggleSelectMenu = this.toggleSelectMenu.bind(this);
    this.registerHandlers = this.registerHandlers.bind(this);
  }

  componentDidMount() {
    this.registerHandlers();
  }

  registerHandlers() {
    Inventum.animations.registerStateCallback(this.updateAnimationState);
    this.updateAnimationState(Inventum.animations.getState());
  }

  //NOTE: React Update (componentWillReceiveProps)
  componentDidUpdate(prevProps, prevState) {
    if (this.props.sceneKey !== prevProps.sceneKey) {
      this.registerHandlers();
    }
  }

  updateAnimationState(animationState) {
    if (animationState.currentKeyframe == 1 && this.state.currentKeyframe !== 1 && this.state.playing == true && animationState.playing == false) {
      this.props.toggleSideBar(true);
    }

    if (animationState.playing && (this.props.uiHideMode !== 'ANIMATION' && this.props.uiHideMode !== 'ALL')) {
      this.props.toggleUiHideAnimation();
    }else if (!animationState.playing && this.props.uiHideMode === 'ANIMATION') {
      this.props.toggleUiHideAnimation();
    }

    this.setState({...animationState});
  }

  toggleTimeline() {
    if (this.state.name !== "No Animation Selected") {
      this.setState({timelineVisible:!this.state.timelineVisible});
    }
  }

  toggleSelectMenu() {
    let animations = Inventum.animations.getAnimations();
    this.setState({selectorMenuOpen:!this.state.selectorMenuOpen,animations});
  }

  render() {
    let shouldHideControls = false;
    if (!this.state.controlsVisible && !this.state.selectorMenuOpen) {
      //Controls temp set to always visible
      //shouldHideControls = true;
    }

    if (this.state.timelineVisible) {
      return (
        <div className={"AnimationTimelineBar" + (this.props.uiHideMode === 'ALL' ? ' hidden' : '')}>
          <div className="TimelineBar">
            <TimelineControls toggleTimeline={this.toggleTimeline} animationName={this.state.name} playing={this.state.playing} keyframes={this.state.keyframes} currentKeyframe={this.state.currentKeyframe} />
          </div>
        </div>
      )
    }

    return (
      //this.props.siteAdmin gets pushed down from ./ViewerPage. It is set when a user logs into the Inventum API.
      //In the future this should take into account "Local Admins. i.e allow people who own the project to be able to edit their own stuff"
      //ReviewMode is passed down from InventumPage.js. It's set when the page is in a Review Mode. We use this to show if the editor tools should be available.
      <div className={"AnimationTimelineBar" + (this.props.uiHideMode === 'ALL' ? ' hidden' : '')}>
        <SelectBar currentKeyframe={this.state.currentKeyframe} keyframeCount={this.state.keyframeCount} reviewMode={this.props.reviewMode} siteAdmin={this.props.siteAdmin} animations={this.state.animations} selectorMenuOpen={this.state.selectorMenuOpen} toggleSelectMenu={this.toggleSelectMenu} activeAnimation={this.state.name} toggleSideBar={this.props.toggleSideBar}/>
        <ControlsPanel toggleSideBar={this.props.toggleSideBar} toggleGallery={this.props.toggleGallery} toggleTimeline={this.toggleTimeline} animationName={this.state.name} playing={this.state.playing} willLoop={this.state.willLoop}/>
      </div>
    );
  }
}

let mapDispatchToProps = dispatch => ({
  toggleUiHideAnimation:() => {
    dispatch(toggleUiHideAnimation());
  }
})

let mapStateToProps = (state) => ({
  uiHideMode: state.ui.hideMode,
})


export default connect(mapStateToProps, mapDispatchToProps)(AnimationControls);
