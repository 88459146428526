import { StaticLabel } from './StaticLabel.js';
import { LabelCanvasTexture } from './LabelCanvasTexture.js';
import { Texture } from 'three';

class StaticTextLabel extends StaticLabel {
  constructor (options) {
    super();
    this.type = 'STATIC_TEXT';
    this.autoScale = true; // Automatically adjusts the scale of image to a certain font size based on the text length

    let content = {
      style: {
        color: '#FFFFFF',
        backgroundColor: 'rgba(20, 20, 20, 0.8)',
        padding: 50,
        paddingHeight: 20,
        font: '150px Sans-Serif, Arial'
      },
      text: ['Static Screen Label']
    }

    if (options !== undefined && options.content !== undefined) {
      content = Object.assign(content, options.content);
    }

    let scale = 0.3;
    if (options !== undefined && options.scale !== undefined) {
      scale = Number(options.scale);
    }

    let position = { x: 0, y:0 };
    if (options !== undefined && options.position) {
      position = { x: Number(options.position.x), y: Number(options.position.y) };
    }

    this.canvasTexture = new LabelCanvasTexture(content.text, content.style);
    let texture = new Texture(this.canvasTexture.getCanvas());
    texture.needsUpdate = true;
    this.setImage(texture);
    this.setPosition(position.x, position.y);
    this.setSize(scale);

		if (options!== undefined && options.visibleStart !== undefined) {
			this.visibleStart = options.visibleStart;
			if (this.visibleStart) {
				this.setVisible(true);
			}
		}

  }

  getText () {
    return [...this.canvasTexture.textArray];
  }

  getStyle () {
    return JSON.parse(JSON.stringify(this.canvasTexture.style));
  }

  setStyle (style) {
    this.canvasTexture.setStyle(style);
    this.sprite.material.needsUpdate = true;
    this.sprite.material.map.needsUpdate = true;
  }

  addText (text) {
    this.canvasTexture.addText(text);
    let texture = new Texture(this.canvasTexture.getCanvas());
    texture.needsUpdate = true;
    this.setImage(texture);
    this.sprite.material.needsUpdate = true;
    if (this.autoScale) {
      this.calculateScaleFromText();
    }
  }

  editText (index, text) {
    this.canvasTexture.editText(index, text);
    let texture = new Texture(this.canvasTexture.getCanvas());
    texture.needsUpdate = true;
    this.setImage(texture);
    this.sprite.material.needsUpdate = true;
    if (this.autoScale) {
      this.calculateScaleFromText();
    }
  }

  clearText (index) {
    let textArray = [...this.canvasTexture.textArray];
    if (index) {
      textArray.splice(index, 1);
    }else {
      textArray = [];
    }
    this.canvasTexture.replaceText(textArray);
  }

  addStyle (rule) {
    this.canvasTexture.setStyle(rule);
  }

  removeStyle (key) {
    this.canvasTexture.removeStyle(key);
  }

  calculateScaleFromText () {
    // Calculates the scalefactor based on a constant font size
    // Base Size 0.04
    // Add 0.02 per character
    const BASE_SCALE = 0.03;
    const CHAR_FACTOR = 0.015;
    const textArray = this.canvasTexture.textArray;
    let maxLength = 0;
    textArray.map(text => {
      if (text.length > maxLength) {
        maxLength = text.length;
      }
    });
    let scale = Number(Math.min(1, BASE_SCALE + ((maxLength - 1) * CHAR_FACTOR)).toFixed(4));
    this.setSize(scale);
  }

  serialize () {
    let text = [...this.canvasTexture.textArray];
    let style = JSON.parse(JSON.stringify(this.canvasTexture.style));

    return {
      type: 'STATIC_TEXT',
      id: this.id,
      position: { x: this.screenPosition.x, y: this.screenPosition.y },
      scale: this.scaleFactor,
      content: { text, style },
			visibleStart: this.visibleStart
    }
  }

}

export { StaticTextLabel }
