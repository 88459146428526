import {
  LOAD_INVENTUM_SCENES,
  CLEAR_INVENTUM_SCENES
} from '../actions/inventumScenes.js';

function inventumScenes(state = {}, action) {
  switch (action.type) {
    case LOAD_INVENTUM_SCENES:
      return Object.assign({},state,action.data)
    case CLEAR_INVENTUM_SCENES:
      return {}
    default:
      return state
  }
}

export default inventumScenes;
