import React from 'react';
import { ScreenLabel } from './ScreenLabel.js';
import { WorldLabel } from './WorldLabel.js';
import { StaticTextLabel } from './StaticText.js';
import { StaticImageLabel } from './StaticImage.js';

class LabelItem extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      open:false
    };
  }

  render () {
    const toggleOpen = () => {this.setState({ open: !this.state.open })};

    let LabelHeader = () => { return (<div>Label Header</div>)};
    let LabelDrawer = () => { return (<div>Label Drawer</div>) };

    if (this.props.children.length == 2) {
      LabelHeader = this.props.children[0];
      LabelDrawer = this.props.children[1];
    }

    return (
      <div>
        <LabelHeader toggleOpen={toggleOpen} isOpen={this.state.open} />
        <div style={{marginLeft:'5px', backgroundColor:'#303030'}}>
          { this.state.open ? <LabelDrawer /> : null }
        </div>
      </div>
    )
  }
}

class LabelTypeDrawer extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false
    };
  }

  render () {
    let children = this.props.children;
    if (children.length == 0) {
      children = (() => {<div style={{padding:'5px'}}>No Entries</div>})();
    }
    return (
      <div className='LabelTypeDrawer'>
        <div className='LabelTypeDrawerHeader' onClick={()=>{this.setState({open:!this.state.open})}}>
          <span className='PanelOpenStatus'>{this.state.open ? '▾' : '▸'}</span>
          <span title='Add New Label' className='CreateNewLabelBtn' onClick={(e) => {e.stopPropagation(); this.props.handleCreate()}}><i className="material-icons notranslate">add</i></span>
          <span>{ this.props.name }</span>
        </div>
        <div style={{marginLeft:'5px', backgroundColor:'#383838'}}>
          {(this.state.open) ? children : null }
        </div>
      </div>
    )
  }
}

function LabelDrawerCollection (props) {
  return (
      <div className='LabelDrawCollection'>
        <LabelTypeDrawer name='Screen Labels' handleCreate={props.handleCreate2D}>
          {props.screenLabels.map((label, index) => <ScreenLabel key={index} getLabels={props.getLabels} {...label} />)}
        </LabelTypeDrawer>

        <LabelTypeDrawer name='World Labels' handleCreate={props.handleCreate3D}>
          {props.worldLabels.map((label, index) => <WorldLabel key={index} getLabels={props.getLabels} {...label} />)}
        </LabelTypeDrawer>

        <LabelTypeDrawer name='Static Text Labels' handleCreate={props.handleCreateStaticText}>
          {props.staticTextLabels.map((label, index) => <StaticTextLabel key={index} getLabels={props.getLabels} {...label} />)}
        </LabelTypeDrawer>

        <LabelTypeDrawer name='Static Image Labels' handleCreate={props.handleCreateStaticImage}>
          {props.staticImageLabels.map((label, index) => <StaticImageLabel key={index} getLabels={props.getLabels} {...label} />)}
        </LabelTypeDrawer>
      </div>
    )
}

class LabelsEditor extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      open: true,
			listenerID: null
    };
    this.LABEL_TYPES = {
      '2D': 'screenLabels',
      '3D': 'worldLabels',
      'STATIC_TEXT': 'staticTextLabels',
      'STATIC_IMAGE': 'staticImageLabels'
    }
    Object.keys(this.LABEL_TYPES).map(key => {
      this.state[this.LABEL_TYPES[key]] = [];
    })
    this.getLabels = this.getLabels.bind(this);
  }

  componentDidMount () {
		let listenerID = Inventum.labels.addUpdateListener(() => {
			this.getLabels();
		});
		this.setState({listenerID}, () => {
			this.getLabels();
		});
  }

	componentWillUnmount() {
		Inventum.labels.removeUpdateListener(this.state.listenerID);
	}

  getLabels () {
    const labels = Inventum.labels.getLabels();
    let tState = this.state;
    Object.keys(this.LABEL_TYPES).map(key => {
      tState[this.LABEL_TYPES[key]] = [];
    })

    labels.map(label => {
      if (!Object.prototype.hasOwnProperty.call(this.LABEL_TYPES, label.type)) return;
      tState[this.LABEL_TYPES[label.type]].push(label);
    });
    this.setState(tState);
  }

  render () {
    let style = {
      background:'#FFF',
      pointerEvents:'all'
    }

    const toggleOpen = () => {this.setState({ open: !this.state.open })};

    const handleCreate2D = () => {
      Inventum.labels.create('2D');
      // 2D Labels are almost always visible
      this.getLabels();
    };

    const handleCreate3D = () => {
      let id = Inventum.labels.create('3D');
      Inventum.labels.toggleVisible(id);
      this.getLabels();
    };

    const handleCreateStaticText = () => {
      let id = Inventum.labels.create('STATIC_TEXT');
      Inventum.labels.toggleVisible(id);
      this.getLabels();
    };

    const handleCreateStaticImage = () => {
      let id = Inventum.labels.create('STATIC_IMAGE');
      Inventum.labels.toggleVisible(id);
      this.getLabels();
    };

		const handleHelp = (e) => {
			e.stopPropagation();
			window.open('https://docs.inventum3d.com/labels/', '_blank');
		};

    return (
      <div className='LabelsPanel'>
        <div onClick={toggleOpen} className="EditorPanelHeader">
          <span className='PanelOpenStatus'>{this.state.open ? '▾' : '▸'}</span>
          <span>Labels</span>
					<span title='Opens the Inventum Docs' className="EditorPanelHelpButton" onClick={handleHelp}><i className="material-icons notranslate">help</i></span>
        </div>
        {this.state.open ? <LabelDrawerCollection
          handleCreate2D={handleCreate2D}
          handleCreate3D={handleCreate3D}
          handleCreateStaticText={handleCreateStaticText}
          handleCreateStaticImage={handleCreateStaticImage}
          screenLabels={this.state.screenLabels}
          getLabels={this.getLabels}
          worldLabels={this.state.worldLabels}
          staticTextLabels={this.state.staticTextLabels}
          staticImageLabels={this.state.staticImageLabels}
          /> : null}
      </div>
    )
  }
}

export default LabelsEditor
