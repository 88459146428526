import * as THREE from 'three';
import { store } from '../core/store.js';
import { resolutionManager } from '../core/resolution.js';
import { models } from '../models/models.js';
import { labels } from '../ui/labels.js';
import { notifications } from '../ui/notifications.js';

const controller = { public: {} };

const LABEL_TEMPLATE = {
  anchorPosition: { x: 0, y: 0, z: 0 },
  type: '2D',
  content: {
    text: [],
    style: {
      backgroundColor: '#FFFFFF',
      color: '#000000',
      fontAlign: 'center',
      padding: 0
    }
  },
  scale: 10,
  offsetX: 0,
  offsetY: 25,
  renderLimits: [],
  showMarker: false,
  id: 0
};

// HTML Elements
var currentDistanceDiv = document.createElement('div');
var currentDistanceDivContent = document.createTextNode('300m');
{
  const style = 'pointer-events:none; user-select:none; position: absolute; background: #333333; padding: 5px; border-radius: 5px; color: #FFFFFF; font-family: sans-serif; font-size: 20px;';
  currentDistanceDiv.style.cssText = style;
  currentDistanceDiv.appendChild(currentDistanceDivContent);
}
// add the text node to the newly created div

const LEFT_MOUSE = 1;
const RIGHT_MOUSE = 3;

const mouseState = {
  1: false,
  3: false
};

var rulerEnabled = false;
let guideInScene = false;
let mouseHasMoved = false; // Used for keeping track of right click for ending ruler vs panning camera.
let lastHoverPosition = null;
let completedRulers = [];
let activeRuler = null;
let planarMode = true;

function showDistanceDiv () {
  document.body.appendChild(currentDistanceDiv);
}

function hideDistanceDiv () {
  document.body.removeChild(currentDistanceDiv);
}

function updateDistanceDivContent (text) {
  currentDistanceDivContent.nodeValue = text;
}

function updateDistanceDivPosition (x, y) {
  currentDistanceDiv.style.transform = `translate(${x + 10}px,${y - 15}px)`;
}

function convertSceneDistancetoWorld (distance) {
  distance *= 10; // Inventum Scale from max is always 0.1 so multiply it to get the correct scale again.
  distance /= store.globalscale; // Inventum scale might be 0.5 (half) which means a measurement of 10m should actually be 20m etc
  return distance;
}

function toggleRuler () {
  rulerEnabled = !rulerEnabled;
  if (rulerEnabled) {
    notifications.add({ content: 'Ruler Mode Enabled.', icon: 'straighten', displayTime: 1000 });
    setTimeout(() => {
      notifications.add({ content: 'Left Click to Place Ruler Point', displayTime: 1500 });
      setTimeout(() => {
        notifications.add({ content: 'Right Click to End Ruler', displayTime: 1500 });
      }, 500);
    }, 500);

    store.webglRenderer.domElement.addEventListener('pointermove', onMouseMove, false);
    store.webglRenderer.domElement.addEventListener('pointerdown', onMouseDown, false);
    store.webglRenderer.domElement.addEventListener('pointerup', onMouseUp, false);
  } else {
    notifications.add({ content: 'Ruler Mode Disabled', icon: 'straighten', displayTime: 1500 });
    store.webglRenderer.domElement.removeEventListener('pointermove', onMouseMove, false);
    store.webglRenderer.domElement.removeEventListener('pointerdown', onMouseDown, false);
    store.webglRenderer.domElement.removeEventListener('pointerup', onMouseUp, false);

    store.scene.remove(sphereGuide);
    guideInScene = false;
  }
  return rulerEnabled;
}

var mouse = new THREE.Vector2();
var raycaster = new THREE.Raycaster();
var sphereNode = {};
var sphereGuide = {};

var geometry = new THREE.SphereGeometry(0.25, 32, 32);
var guideMaterial = new THREE.MeshBasicMaterial({ color: 0xFFE84B });
var nodeMaterial = new THREE.MeshBasicMaterial({ color: 0xFFFFFF });
sphereGuide = new THREE.Mesh(geometry, guideMaterial);
sphereNode = new THREE.Mesh(geometry, nodeMaterial);

var lineGuide = {};
{
  const lineMat = new THREE.LineBasicMaterial({ color: 0xFFFFFF });
  const lineGeom = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 1)]);
  lineGuide = new THREE.Line(lineGeom, lineMat);
  lineGuide.name = 'RULER_LINE_GUIDE';
}

function raycastScene (clientX, clientY, snapNearest) {
	const resolution = resolutionManager.getResolution();
  mouse.x = (clientX / resolution.width) * 2 - 1;
  mouse.y = -(clientY / resolution.height) * 2 + 1;

  raycaster.setFromCamera(mouse, store.camera);
  var intersects = raycaster.intersectObjects(models.getSceneObjects());
  let hit = {
    model: null,
    position: null
  };

  snapNearest = false;

  for (var i = 0; i < intersects.length; i++) {
    const intersect = intersects[i];
    if (intersect.object.visible === true) {
      hit = { model: intersect.object, position: intersect.point };
      if (snapNearest) {
        let vA = new THREE.Vector3();
        let vB = new THREE.Vector3();
        let vC = new THREE.Vector3();
        const face = intersect.face;
        const geom = intersect.object.geometry;
        const posArr = geom.attributes.position;

        vA = intersect.object.localToWorld(vA.fromBufferAttribute(posArr, face.a));
        vB = intersect.object.localToWorld(vB.fromBufferAttribute(posArr, face.b));
        vC = intersect.object.localToWorld(vC.fromBufferAttribute(posArr, face.c));

        const vADist = vA.distanceTo(intersect.point);
        const vBDist = vB.distanceTo(intersect.point);
        const vCDist = vC.distanceTo(intersect.point);

        let minDist = vADist;
        let nearestVert = vA;
        // Check if B is closer
        if (vBDist < minDist) {
          minDist = vBDist;
          nearestVert = vB;
        }
        // Check if C is closer
        if (vCDist < minDist) {
          minDist = vCDist;
          nearestVert = vC;
        }

        hit.position = nearestVert;
      }
      break;
    }
  }
  return hit;
}

function onMouseMove (e) {
  mouseHasMoved = true;
  // Don't bother processing mouse move if any of the buttons are down. Camera is probally being moved.
  if (mouseState[LEFT_MOUSE] || mouseState[RIGHT_MOUSE]) {
    if (guideInScene) {
      store.scene.remove(sphereGuide);
      store.scene2.remove(lineGuide);
      guideInScene = false;
      lastHoverPosition = null;
    }
    return;
  }

  const result = raycastScene(e.clientX, e.clientY);
  if (result.position) {
    if (!guideInScene) {
      guideInScene = true;
      store.scene.add(sphereGuide);
      // If there is at least one node then create a helper line
      const scale = 1.0 * store.globalscale;
      sphereGuide.scale.set(scale, scale, scale);
    }
    sphereGuide.position.copy(result.position);

    if (activeRuler) {
      if (activeRuler.nodes.length > 0) {
        const lastNodePos = activeRuler.nodes[activeRuler.nodes.length - 1].position;
        lineGuide.geometry.attributes.position.setXYZ(0, lastNodePos.x, lastNodePos.y, lastNodePos.z);
        lineGuide.geometry.attributes.position.setXYZ(1, result.position.x, result.position.y, result.position.z);
        lineGuide.geometry.attributes.position.needsUpdate = true;
        if (!store.scene2.getObjectByName('RULER_LINE_GUIDE')) {
          store.scene2.add(lineGuide);
        }
        // measure distance;
        const tDistance = convertSceneDistancetoWorld(lastNodePos.distanceTo(result.position));
        updateDistanceDivContent(`${tDistance.toFixed(2)} meters`);
        updateDistanceDivPosition(e.clientX, e.clientY);
      }
    }

    if (!lastHoverPosition) {
      lastHoverPosition = new THREE.Vector3();
      lastHoverPosition.copy(result.position);
    } else {
      lastHoverPosition.copy(result.position);
    }
  } else {
    store.scene.remove(sphereGuide);
    store.scene2.remove(lineGuide);
    guideInScene = false;
    lastHoverPosition = null;
  }
  store.requestRender();
}

function onMouseDown (e) {
  mouseState[e.which] = true;
  mouseHasMoved = false;
}

function onMouseUp (e) {
  if (mouseState[LEFT_MOUSE] && e.which === LEFT_MOUSE) {
    if (lastHoverPosition) {
      // Set ruler point
      createRulerNode();
    }
  } else if (mouseState[RIGHT_MOUSE] && e.which === RIGHT_MOUSE) {
    if (!mouseHasMoved) {
      endRuler();
    }
  }
  mouseState[e.which] = false;
}

function createRulerNode () {
  if (activeRuler === null) {
    notifications.add({ content: 'Creating new Ruler', displayTime: 1000 });
    activeRuler = {
      nodes: [],
      labels: [],
      lines: [],
      totalLength: 0
    };
    showDistanceDiv();
  }
  const tSphere = sphereNode.clone();
  const scale = 1.0 * store.globalscale;
  tSphere.scale.set(scale, scale, scale);
  tSphere.position.copy(lastHoverPosition);
  store.scene2.add(tSphere);
  activeRuler.nodes.push(tSphere);

  // If we have more than 1 node.
  if (activeRuler.nodes.length > 1) {
    if (planarMode) {
      activeRuler.nodes[activeRuler.nodes.length - 1].position.setY(activeRuler.nodes[0].position.y);
    }

    // Create line between the previous point and the new point
    const previousNodePosition = activeRuler.nodes[activeRuler.nodes.length - 2].position;
    const currentNodePosition = activeRuler.nodes[activeRuler.nodes.length - 1].position;
    const startingNodePosition = activeRuler.nodes[0].position;

    if (activeRuler.nodes.length > 3 && planarMode) {
      // Check if last point is the same as first point;
      const distanceToStart = startingNodePosition.distanceTo(currentNodePosition);
      if (distanceToStart < 1.0) {
        // Create shape and return;
        const verts = [];
        // Copy all positions except last one as it will be replaced with the initial one.
        for (var i = 0; i < activeRuler.nodes.length - 1; i++) {
          verts.push(new THREE.Vector3().copy(activeRuler.nodes[i].position));
        }

        verts.push(new THREE.Vector3().copy(activeRuler.nodes[0].position));

        activeRuler.areaMesh = createShape(verts);
        store.scene2.add(activeRuler.areaMesh);

        // Final Distance from previous node to starting node
        // Note this isn't currentNode as currentNode and starting node will be almost identical and we are using startingNode instead of currentNode
        let lastNodeToStartDistance = previousNodePosition.distanceTo(startingNodePosition);
        lastNodeToStartDistance = convertSceneDistancetoWorld(lastNodeToStartDistance);
        activeRuler.totalLength += lastNodeToStartDistance;

        // Create distance label
        var tDir = previousNodePosition.clone().sub(startingNodePosition);
        var len = tDir.length();
        tDir = tDir.normalize().multiplyScalar(len * 0.5);
        var halfwayPos = startingNodePosition.clone().add(tDir);

        var options = JSON.parse(JSON.stringify(LABEL_TEMPLATE));
        options.anchorPosition = { x: halfwayPos.x, y: halfwayPos.y, z: halfwayPos.z };
        options.content.text.push(`${lastNodeToStartDistance.toFixed(2)} meters`);

        var areaLabel = labels.createTemporaryScreenLabel(options);
        activeRuler.labels.push(areaLabel);

        // Area Calculation
        const area = (convertSceneDistancetoWorld(calculateArea(activeRuler.areaMesh)) * 10) / 1000;

        const center = calculateCenter(verts);

        var options = JSON.parse(JSON.stringify(LABEL_TEMPLATE));
        options.content.style.backgroundColor = '#151c33';
        options.content.style.color = '#FFFFFF';
        options.anchorPosition = { x: center.x, y: center.y, z: center.z };
        options.content.text.push(`Total Area:${area.toFixed(2)}km²`);
        // Create Area Label
        var distanceLabel = labels.createTemporaryScreenLabel(options);
        activeRuler.labels.push(distanceLabel);
        store.markersNeedUpdate = true;

        activeRuler.nodes.map(node => { store.scene2.remove(node); });
        activeRuler.lines.map(line => { store.scene2.remove(line); });
        activeRuler.nodes = [];
        activeRuler.lines = [];

        store.markersNeedUpdate = true;
        store.scene2.remove(lineGuide);
        completedRulers.push(activeRuler);
        activeRuler = null;
        hideDistanceDiv();
        return;
      }
    }

    var line = createLine(previousNodePosition, currentNodePosition);
    activeRuler.lines.push(line);
    store.scene2.add(line);

    // Calculate Distance
    var distance = previousNodePosition.distanceTo(currentNodePosition);
    distance = convertSceneDistancetoWorld(distance);
    activeRuler.totalLength += distance;
    // Get halfway position between two nodes.
    var tDir = previousNodePosition.clone().sub(currentNodePosition);
    var len = tDir.length();
    tDir = tDir.normalize().multiplyScalar(len * 0.5);
    var halfwayPos = currentNodePosition.clone().add(tDir);

    var options = JSON.parse(JSON.stringify(LABEL_TEMPLATE));
    options.anchorPosition = { x: halfwayPos.x, y: halfwayPos.y, z: halfwayPos.z };
    options.content.text.push(`${distance.toFixed(2)} meters`);

    // Create Label
    var label = labels.createTemporaryScreenLabel(options);
    activeRuler.labels.push(label);
    store.markersNeedUpdate = true;
  }
  store.requestRender();
}

function createShape (nodes) {
  var geometry = new THREE.BufferGeometry();
  const verts = new Float32Array(nodes.length * 3);
  var material = new THREE.MeshBasicMaterial({ color: 0x00E0FF, opacity: 0.5, transparent: true, side: THREE.DoubleSide });

  const verts2D = [];
  for (let i = 0; i < nodes.length; i++) {
    verts2D.push(new THREE.Vector2(nodes[i].x, nodes[i].z));
    verts[(3 * i)] = nodes[i].x;
    verts[(3 * i) + 1] = nodes[i].y;
    verts[(3 * i) + 2] = nodes[i].z;
  }

  const tris = THREE.ShapeUtils.triangulateShape(verts2D, []);
  const indices = new Uint32Array(tris.length * 3);
  for (let i = 0; i < tris.length; i++) {
    indices[(i * 3)] = tris[i][0];
    indices[(i * 3) + 1] = tris[i][1];
    indices[(i * 3) + 2] = tris[i][2];
  }
  geometry.setAttribute('position', new THREE.BufferAttribute(verts, 3));
  geometry.setIndex(new THREE.BufferAttribute(indices, 1));
  geometry.computeVertexNormals();

  return new THREE.Mesh(geometry, material);
}

function calculateArea (mesh) {
  let area = 0;
  const indices = mesh.geometry.index.array;
  const pos = mesh.geometry.attributes.position.array;

  for (let i = 0; i < indices.length; i += 3) {
    const ia = indices[i] * 3;
    const ib = indices[i + 1] * 3;
    const ic = indices[i + 2] * 3;

    const va = new THREE.Vector3(pos[ia], pos[ia + 1], pos[ia + 2]);
    const vb = new THREE.Vector3(pos[ib], pos[ib + 1], pos[ib + 2]);
    const vc = new THREE.Vector3(pos[ic], pos[ic + 1], pos[ic + 2]);
    const tri = new THREE.Triangle(va, vb, vc);
    area += tri.getArea();
  }
  return area;
}

function calculateCenter (vec3Arr) {
  let minX = Infinity;
  let minY = Infinity;
  let minZ = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  let maxZ = -Infinity;

  vec3Arr.map(vec3 => {
    minX = Math.min(minX, vec3.x);
    minY = Math.min(minY, vec3.y);
    minZ = Math.min(minZ, vec3.z);

    maxX = Math.max(maxX, vec3.x);
    maxY = Math.max(maxY, vec3.y);
    maxZ = Math.max(maxZ, vec3.z);
  });

  return new THREE.Vector3((minX + maxX) * 0.5, (minY + maxY) * 0.5, (minZ + maxZ) * 0.5);
}

/*
function createLineThick (from, to) {
  const fromVec = new THREE.Vector3().copy(from);
  const toVec = new THREE.Vector3().copy(to);
  const points = [fromVec.x, fromVec.y, fromVec.z, toVec.x, toVec.y, toVec.z];

  var matLine = new LineMaterial({
    color: 0xFFFFFF,
    linewidth: 0.0025, // in pixels
    vertexColors: false,
    dashed: false
  });

  var geometry = new LineGeometry();
  geometry.setPositions(points);
  var line = new Line2(geometry, matLine);
  return line;
}
*/

function createLine (from, to) {
  const fromVec = new THREE.Vector3().copy(from);
  const toVec = new THREE.Vector3().copy(to);
  const points = [fromVec, toVec];
  var material = new THREE.LineBasicMaterial({ color: 0xFFFFFF });
  const mesh = new THREE.BufferGeometry().setFromPoints(points);
  const x = new THREE.Line(mesh, material);
  return x;
}

function endRuler () {
  if (!activeRuler) {
    return;
  }

  if (activeRuler.nodes.length === 1) {
    notifications.add({ content: 'Cancelled Ruler', displayTime: 1000 });
    store.scene2.remove(activeRuler.nodes[0]);
  } else {
    // If has at least 2 segments then create a total
    if (activeRuler.nodes.length >= 3) {
      // Add Total
      var options = JSON.parse(JSON.stringify(LABEL_TEMPLATE));
      const finalPos = activeRuler.nodes[activeRuler.nodes.length - 1].position;
      options.content.style.backgroundColor = '#000000';
      options.content.style.color = '#FFFFFF';
      options.anchorPosition = { x: finalPos.x, y: finalPos.y, z: finalPos.z };
      options.content.text.push(`Total:${activeRuler.totalLength.toFixed(2)} meters`);
      // Create Label
      const label = labels.createTemporaryScreenLabel(options);
      activeRuler.labels.push(label);
      store.markersNeedUpdate = true;
    }

    store.scene2.remove(lineGuide);

    completedRulers.push(activeRuler);
    notifications.add({ content: 'Ruler Finished', displayTime: 1000 });
  }
  activeRuler = null;
  hideDistanceDiv();
  store.requestRender();
}

function removeRulers () {
  if (activeRuler) {
    endRuler();
  }
  completedRulers.map(ruler => {
    ruler.nodes.map(node => { store.scene2.remove(node); });
    ruler.lines.map(line => { store.scene2.remove(line); });
    ruler.labels.map(label => { label.remove(); });
    if (ruler.areaMesh) {
      store.scene2.remove(ruler.areaMesh);
    }
  });
  completedRulers = [];
  notifications.add({ content: 'Cleared All Rulers', displayTime: 1000 });
  store.requestRender();
}

function removeRulersQuiet () {
  if (activeRuler) {
    endRuler();
  }
  completedRulers.map(ruler => {
    ruler.nodes.map(node => { store.scene2.remove(node); });
    ruler.lines.map(line => { store.scene2.remove(line); });
    ruler.labels.map(label => { label.remove(); });
  });
  completedRulers = [];
  store.requestRender();
}

function togglePlanar () {
  if (activeRuler) {
    endRuler();
  }
  planarMode = !planarMode;
  notifications.add({ content: `Area Measurement ${planarMode ? 'Enabled' : 'Disabled'}`, icon: 'texture', displayTime: 1000 });
  return planarMode;
}

function reset () {
  guideInScene = false;
  mouseHasMoved = false;
  lastHoverPosition = null;
  completedRulers = [];
  activeRuler = null;
  planarMode = false;
}

controller.public.togglePlanar = togglePlanar;
controller.public.clearAll = removeRulers;
controller.public.toggle = toggleRuler;
controller.reset = reset;
controller.removeRulersQuiet = removeRulersQuiet;

export { controller as ruler };
