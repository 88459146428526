import React from 'react';
import {connect} from 'react-redux';
import {loadUser,clearUser} from '../../Redux/actions/user.js';
import {openModal} from '../../Redux/actions/ui.js';
import {Link,withRouter} from 'react-router-dom';

let SubmitButton = (props) => {
  return (
    <div onClick={props.handleClick} className="map-horizontal-button"><span><i className="material-icons notranslate">account_circle</i>Sign In</span></div>
  )
}

let ProfileControls = (props) => {
  return (
    <div className="ProfileControls">
    <div className="WelcomeMessage">Welcome {props.name},</div>
    <div>
      <div>Your Companies:</div>
      {props.companies.map((company,index) => <div key={index} className="CompanyListEntry"><Link to={"/g/" + company.id}>{company.name}</Link></div>)}
    </div>
    <div className="SignOutBtn" onClick={props.handleSignOut}>Sign Out</div>
    </div>
  )
}

class AccountLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileControlsVisible:false,
      tokenExpiryWatchdogID:null,
      hasReauthorizedWarned:false
    };
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
  }

  componentWillUnmount() {

  }

  componentDidUpdate(prevProps) {
    if (prevProps.tokenExp !== this.props.tokenExp) {
      let date = new Date(this.props.tokenExp * 1000);
      if (!this.state.tokenExpiryWatchdogID) {
        let id = setInterval(() => {
          let expiryDate = new Date(this.props.tokenExp * 1000);
          let currentTime = new Date();
          let difference = expiryDate - currentTime;
          if (difference <=0 ) {
            console.log('Token Expired');
            //this.props.history.push('/');
            //this.props.onLogout();
            //clearInterval(this.state.tokenExpiryWatchdogID);
            //this.setState({tokenExpiryWatchdogID:null});
          }else if (difference <= (10 * (60 * 1000)) && ! this.state.hasReauthorizedWarned) { //10 minutes
            this.setState({hasReauthorizedWarned:true})
            console.log('About to be logged out!');
            this.props.openModal('ReAuthorize');
          }
        }, 10000);
      }
    }
  }

  logout() {
    if (this.state.tokenExpiryWatchdogID) {
      clearInterval(this.state.tokenExpiryWatchdogID);
      this.setState({tokenExpiryWatchdogID:null});
    }
    this.props.history.push('/');
    this.props.onLogout();
  }

  render() {
    if (this.props.name) {
      return (
        <div onClick={()=>{this.setState({profileControlsVisible:!this.state.profileControlsVisible})}}>
          <div className="map-horizontal-button"><span><i className="material-icons notranslate">account_circle</i>{this.props.name}</span></div>
          {this.state.profileControlsVisible ? <ProfileControls handleSignOut={this.logout} companies={this.props.companies} name={this.props.name} />  : null}
        </div>
      )
    }
    return (
      <div>
        <SubmitButton handleClick={()=>{this.props.openModal('SignIn')}}  />
      </div>
    )
  }
}

let mapStateToProps = state => ({
  name:state.user.name,
  companies:state.user.companies,
  tokenExp:state.user.exp
})

let mapDispatchToProps = dispatch => ({
  onLogin: (user) => {
    dispatch(loadUser(user));
  },
  onLogout: () => {
    dispatch(clearUser());
  },
  openModal:(modalName) => {
    dispatch(openModal(modalName));
  }
})

let AccountLoginContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountLogin));




export default AccountLoginContainer;
