import React, { useState } from 'react';
import Draggable from 'react-draggable';
import {connect} from 'react-redux';
import {closeModal} from '../../../Redux/actions/ui.js';

let CloseButton = (props) => {
  return (
    <div onClick={props.handleClick} className="titlebarCloseButton"><i className="material-icons notranslate">close</i></div>
  )
}

let MinimizeButton = (props) => {
  return (
    <div className="titlebarMinMaxButton" onClick={props.toggleMinimize}>
      <i className="material-icons notranslate">{props.minimized ? "crop_square" : "remove"}</i>
    </div>
  )
}


const SlideRow = (props) => {
  const handleVisible = () => {
    Inventum.slides.setVisible(props.id);
    props.updateSlides();
  };

  const handleCanClose = () => {
    Inventum.slides.setCanClose(props.id);
    props.updateSlides();
  };

  const handleSetName = (text) => {
    Inventum.slides.setName(props.id, text);
    props.updateSlides();
  };

  const handleVisibleStart = () => {
    Inventum.slides.toggleVisibleStart(props.id);
    props.updateSlides();
  };

  const handleDelete = () => {
    Inventum.slides.deleteSlide(props.id);
    props.updateSlides();
  };

	return (
    <tr>
      <EditTableText value={props.name} setText={(text) => {handleSetName(text)}} />
      <td className={"WorldEditorButton" + (props.visible ? " Enabled" : " Disabled")} onClick={handleVisible}>{props.visible ? "True" : "False"}</td>
      <td className="WorldEditorButton" onClick={()=>{props.openElementsEditor({slideName:props.name,slideID:props.id,elementIDs:props.elements})}}>Edit</td>
      <td className="WorldEditorButton" onClick={()=>{props.openCSSEditor({receiver:"SLIDE",id:props.id,style:props.style,visible:props.visible})}}>Edit</td>
      <td className={"WorldEditorButton" + (props.canClose ? " Enabled" : " Disabled")} onClick={handleCanClose}>{props.canClose ? "True" : "False"}</td>
      <td className={"WorldEditorButton" + (props.visibleStart ? " Enabled" : " Disabled")} onClick={handleVisibleStart}>{props.visibleStart ? "True" : "False"}</td>
      <td className="WorldEditorButton" onClick={handleDelete}>Delete</td>
    </tr>
  )
}

function EditTableText (props) {
	const [isEditing, setEditing] = useState(false);
	const [textValue, setTextValue] = useState(props.value);

	React.useEffect(() => {
		setTextValue(props.value);
	},[props.value]);

	const applyValue = () => {
		props.setText(textValue);
		setEditing(false);
	};

	const handleKey = (e) => {
		if (e.charCode === 13) {
			applyValue();
		}
	};

	if (isEditing) {
		return (
			<td style={{width:"400px", display:'flex'}} title={props.title || props.label}>
				<input onKeyPress={handleKey} autoFocus onClick={(e) => {e.stopPropagation()}} style={{ flexGrow: 1, width: '200px', border: 'none', outline: 'none' }} type='input' value={textValue} onChange={(e) => {setTextValue(e.target.value)}} />
				<div className="ApplyTextEditButton" onClick={applyValue}>Apply</div>
			</td>
		)
	}

	return (
		<td onClick={(e) => {e.stopPropagation(); setEditing(true)}} style={{width:"400px"}} title={props.title || props.label}>{props.value}</td>
	)
}

class SlideTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CSSEditorVisible:false,
      CSSEditorData:{},
      ElementsEditorVisible:false,
      ElementsEditorData:{},
      slides:[],
      inventumOnUpdateID: null,
			caption: "New Caption"
    };
    this.getSlides = this.getSlides.bind(this);
    this.openCSSEditor = this.openCSSEditor.bind(this);
    this.openElementsEditor = this.openElementsEditor.bind(this);
    this.closeCSSEditor = this.closeCSSEditor.bind(this);
    this.closeElementsEditor = this.closeElementsEditor.bind(this);
    this.updateCSS = this.updateCSS.bind(this);
    this.replaceCSS = this.replaceCSS.bind(this);
		this.replaceCSSLocal = this.replaceCSSLocal.bind(this);
  }

  componentDidMount() {
    //returns an ID from Inventum slides for unregistering the callback when the component is dismounted;
    let id = Inventum.slides.registerUpdateCallback(this.getSlides);
    this.setState({inventumOnUpdateID:id});
    this.getSlides();
  }

  componentWillUnmount() {
    //Unregisters the callback from Inventum Slides Manager. Prevents memory leak;
    Inventum.slides.unregisterUpdateCallback(this.state.inventumOnUpdateID);
  }

  getSlides() {
    var slides = Inventum.slides.getSlides();
    let ElementsEditorData = this.state.ElementsEditorData;
    if (ElementsEditorData.slideID !== undefined) {
      //We need to update the ElementEditorData too;
      slides.map((slide) => {
        if (slide.id == ElementsEditorData.slideID) {
          //Update the Data;
          ElementsEditorData.slideName = slide.name;
          ElementsEditorData.slideID = slide.id;
          ElementsEditorData.elementIDs = slide.elementsIDs;
        }
      })
    }
    this.setState({slides,ElementsEditorData})
  }

  openCSSEditor(data) {
    this.setState({CSSEditorVisible:true,CSSEditorData:data});
  }

  openElementsEditor(data) {
    this.setState({ElementsEditorVisible:true,ElementsEditorData:data});
  }

  closeElementsEditor() {
    this.setState({ElementsEditorVisible:false,ElementsEditorData:{}});
  }

  closeCSSEditor() {
    this.setState({CSSEditorVisible:false,CSSEditorData:{}});
  }

  updateCSS(key,value) {
    let style = this.state.CSSEditorData.style;
    if (style === undefined) {
      return;
    }
    if (key === undefined || value === undefined) {
      return;
    }

    if (value === "__DELETE") {
      delete style[key];
    }else {
      style[key] = value;
    }

    let tempData = this.state.CSSEditorData;
    tempData.style = style;
    this.setState({CSSEditorData:tempData});
    if (this.state.CSSEditorData.receiver === "SLIDE") {
      Inventum.slides.setSlideStyle(tempData.id,tempData.style);
    }else if (this.state.CSSEditorData.receiver === "ELEMENT") {
      Inventum.slides.setElementStyle(tempData.id,tempData.style);
    }
  }

  replaceCSS(newStyle) {
    let style = this.state.CSSEditorData.style;

    if (style === undefined || newStyle == undefined) {
      return;
    }

    if (Object.keys(newStyle).length == 0) {
      Inventum.notifications.addNotification({content:"Style Clipboard is Empty",displayTime:2000});
      return;
    }

    let tempData = this.state.CSSEditorData;
    tempData.style = newStyle;
    this.setState({CSSEditorData:tempData});

    if (this.state.CSSEditorData.receiver === "SLIDE") {
      Inventum.slides.setSlideStyle(tempData.id,tempData.style);
    }else if (this.state.CSSEditorData.receiver === "ELEMENT") {
      Inventum.slides.setElementStyle(tempData.id,tempData.style);
    }
    Inventum.notifications.addNotification({content:"Updated Style",displayTime:2000});
  }

	replaceCSSLocal (newStyle) {
		// Only updates the react copy.
		// This is used because Inventum engine now manages the CSS copy and pasting and we need an easy way to change the CSS locally when Inventum updates
		// Bad design but this won't be developed much going forward
		let CSSEditorData = this.state.CSSEditorData;
		CSSEditorData.style = JSON.parse(JSON.stringify(newStyle));
		this.setState({CSSEditorData});
	}

  render() {
    if (this.state.CSSEditorVisible) {
      //Priority 1 is to show the CSS Style Editor as it's accessible from both Elements and Slides
      return (
        <CSSEditor closeCSSEditor={this.closeCSSEditor} updateCSS={this.updateCSS} replaceCSS={this.replaceCSS} data={this.state.CSSEditorData} replaceCSSLocal={this.replaceCSSLocal} />
      )
    }else if(this.state.ElementsEditorVisible) {
      //Priority 2 is Elements Editor
      return (
        <ElementsEditor updateSlides={this.getSlides} openCSSEditor={this.openCSSEditor} closeElementsEditor={this.closeElementsEditor} {...this.state.ElementsEditorData} />
      )
    }

    const handleCreateBlank = () => {
      Inventum.slides.createSlide();
      this.getSlides();
    };

    const handleCreateCaption = () => {
      Inventum.slides.createCaption(this.state.caption);
			this.setState({caption:"New Caption"});
      this.getSlides();
    };

    return (
      <div className="WorldEditorDrawer">
        <table className="LightsEditorContainer" style={{color:'#FFF'}}>
          <thead>
            <tr>
              <th style={{width:"400px"}}>Name</th>
              <th>Visible</th>
              <th>Elements</th>
              <th>Style</th>
              <th>CloseBtn</th>
              <th>InitVis</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {this.state.slides.map((slide,index) => <SlideRow openElementsEditor={this.openElementsEditor} openCSSEditor={this.openCSSEditor} updateSlides={this.getSlides} key={index} {...slide}/>)}
          </tbody>
        </table>
        <div style={{display:"flex",width:"100%",margin:"5px"}}>
          <div className="WorldEditorButton" style={{marginRight:"5px"}} onClick={handleCreateBlank}>Create Blank Slide</div>
					<div style={{display:"flex"}}>
						<input type="text" value={this.state.caption} onChange={(e) => {this.setState({caption:e.target.value})}} />
						<div className="WorldEditorButton" onClick={handleCreateCaption}>Create Caption</div>
					</div>
        </div>
      </div>
    )
  }
}

let CSSRow = (props) => {
  const handleValue = (value) => {
    props.updateCSS(props.attribute, value);
  };

  const handleDelete = () => {
    //If __DELETE is passed as a value to updateCSS then the attribute is deleted.
    props.updateCSS(props.attribute,"__DELETE");
  };

  return (
    <tr className="CSSEditorRow">
    <td>{props.attribute}</td>
		<EditTableText value={props.value} setText={(text) => {handleValue(text)}} />
    <td className="WorldEditorButton Disabled" onClick={handleDelete}>Delete</td>
    </tr>
  )
}

let CSSEditor = (props) => {
  let cleanedData = {};
  let handleCreate = () => {
    props.updateCSS(newAttr, newValue);
		setAttr("cssAttribute");
		setValue("cssValue");
  }

  let handleCopy = () => {
		if (!props.data) return;
		let id = props.data.id;
		if (props.data.receiver === "ELEMENT" ) {
			Inventum.slides.copyElementStyle(id)
		}else if (props.data.receiver === "SLIDE") {
			Inventum.slides.copySlideStyle(id);
		}
  }

  let handlePaste = () => {
		if (!props.data) return;
		let id = props.data.id;
		// Pasting the style in Inventum returns a copy of the updated style
		// This is so we can quickly update the UI
		var newStyle = {};
		if (props.data.receiver === "ELEMENT" ) {
			newStyle = Inventum.slides.pasteElementStyle(id);
		}else if (props.data.receiver === "SLIDE") {
			newStyle = Inventum.slides.pasteSlideStyle(id);
		}
		if (newStyle !== null) {
			props.replaceCSSLocal(newStyle);
		}
  }

	const [newAttr, setAttr] = useState("cssAttribute");
	const [newValue, setValue] = useState("cssValue");

  return (
    <div>
    <div style={{lineHeight:"30px",top:"0px",position: "absolute",right:"70px", fontSize:"13px",textDecoration:"underline"}} onClick={props.closeCSSEditor}>Return</div>
    <table className="LightsEditorContainer" style={{color:'#FFF'}}>
      <thead>
        <tr className="CSSEditorRow">
          <th>Attribute</th>
          <th>Value</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
				<tr className="CSSEditorRow">
			    <td><input type="text" style={{width:"90%"}} value={newAttr} onChange={(e) => {setAttr(e.target.value)}} /></td>
					<td><input type="text" style={{width:"90%"}} value={newValue} onChange={(e) => {setValue(e.target.value)}} /></td>
			    <td className="WorldEditorButton" onClick={handleCreate}>Add</td>
		    </tr>
				{Object.keys(props.data.style).map((key,index) => <CSSRow key={index} updateCSS={props.updateCSS} attribute={key} value={props.data.style[key]} />)}
      </tbody>
      </table>
      <div className="CSSEditorCopyPasteContainer">
        <div style={{"width":"100%","borderRight":"1px solid #FFF"}} className="WorldEditorButton" onClick={handleCopy}>Copy Style</div>
        <div style={{"width":"100%"}} className="WorldEditorButton" onClick={handlePaste}>Paste Style</div>
      </div>
    </div>
  )
}

const ElementRow = (props) => {
  const handleRemove = () => {
    Inventum.slides.removeElement(props.slideID,props.id);
    props.updateSlides();
  };

  const handleSetName = (text) => {
    Inventum.slides.setElementName(props.id, text);
    props.updateData();
  };

  const handleContent = (text) => {
    Inventum.slides.setElementContent(props.id, text);
    props.updateData();
  };

  const handleType = (type) => {
    Inventum.slides.setElementType(props.id, type);
    props.updateData();
  };

  return (
    <tr className="ElementsEditorRow">
			<EditTableText value={props.name} setText={(text) => {handleSetName(text)}} />
      <td style={{width:"150px"}}>
				<select onChange={(e) => {handleType(e.target.value)}} value={props.type}>
					<option>TEXT</option>
					<option>IMAGE</option>
					<option>MARKDOWN</option>
					<option>YOUTUBE_VIDEO</option>
				</select>
			</td>
			<EditTableText value={props.content} setText={(text) => {handleContent(text)}} />
      <td className="WorldEditorButton" onClick={()=>{props.openCSSEditor({receiver:"ELEMENT",id:props.id,style:props.style})}}>Edit</td>
      <td className="WorldEditorButton" onClick={handleRemove}>Remove</td>
    </tr>
  )
}

let ElementSelector = (props) => {
  let handleAddExisting = () => {
    Inventum.slides.addElementToSlide(props.slideID,props.selectorActive);
    props.updateSlides();
  }
  return (
    <div style={{display:"flex",marginBottom:"5px"}}>
      <select value={props.selectorActive} onChange={(e)=>{props.handleSelector(e.target.value)}}>
        {props.options.map((option,index) => <option key={index} value={option.id}>{option.name}</option>)}
      </select>
      <div style={{width:"100%"}}className="WorldEditorButton" onClick={handleAddExisting}>Add Existing Element</div>
    </div>
  )
}

class ElementsEditor extends React.Component {
  //Takes slideID and elementsIDs array
  //Elements Array is a list of the elements used by that slide
  //This component takes the elements ID array and gets the data for it
  constructor(props) {
    super(props);
    this.state = {
      elementData:[],
      allElements:[],
      selectorActive:''
    };
    this.getElementData = this.getElementData.bind(this);
    this.handleSelector = this.handleSelector.bind(this);
  }

  //NOTE: REACT UPDATE
  componentDidMount() {
    this.getElementData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.elementIDs !== prevProps.elementIDs) {
      this.getElementData();
    }
  }

  getElementData() {
    let allElements = Inventum.slides.getAllElements();
    let selectorActive = this.state.selectorActive;
    if (selectorActive === null) {
      selectorActive = allElements[0].id;
    }
    this.setState({
      elementData:Inventum.slides.getSelectedElements(this.props.elementIDs),
      allElements,
      selectorActive
    });
  }

  handleSelector(id) {
    this.setState({selectorActive:id})
  }

  render() {
    let handleCreateElement = () => {
      Inventum.slides.createElement(this.props.slideID);
      this.props.updateSlides();
    }
    return (
      <div style={{padding:"5px"}}>
        <div style={{textAlign:"center"}}>Slide - {this.props.slideName}</div>
        <div style={{lineHeight:"30px",top:"0px",position: "absolute",right: "70px", fontSize: "13px",textDecoration:"underline"}} onClick={this.props.closeElementsEditor}>Return</div>
        <table className="LightsEditorContainer" style={{color:'#FFF'}}>
          <thead>
            <tr className="ElementsEditorRow">
              <th style={{width:"400px"}}>Name</th>
              <th style={{width:"150px"}}>Type</th>
              <th style={{width:"400px"}}>Content</th>
              <th>Style</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {this.state.elementData.map((element,index) => <ElementRow updateSlides={this.props.updateSlides} slideID={this.props.slideID} openCSSEditor={this.props.openCSSEditor} updateData={this.getElementData} key={index} {...element} />)}
          </tbody>
          </table>
          <ElementSelector updateSlides={this.props.updateSlides} slideID={this.props.slideID} handleSelector={this.handleSelector} selectorActive={this.state.selectorActive} options={this.state.allElements} />
          <div className="WorldEditorButton" onClick={handleCreateElement}>Create New Element</div>
      </div>
    )
  }
}

//export default SlideEditorContainer
const InventumSlideEditor = () => {
	return (
		<div>
			<div style={{padding:'5px'}}>Legacy Slide Editor</div>
			<div style={{padding:'5px', fontSize: '12px'}}>Consider using Static Image & Static Text Labels Instead</div>
			<SlideTable />
		</div>
	)
};
export default InventumSlideEditor
