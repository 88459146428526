import * as THREE from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { mergeVertices } from 'three/examples/jsm/utils/BufferGeometryUtils.js';

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('/static/static_libs/draco/');

import { store } from '../core/store.js';
import { utility } from '../utility/utility.js';
import { CTMLoader } from '../loaders/CTMLoader';
import { notifications } from '../ui/notifications.js';
import { sceneStates } from '../tools/sceneStates.js';
import { clipping } from '../tools/clipping.js';

var models = { public: {} };
var onModelUpdateCallbacks = [];

var modelCount = 0;
var modelsLoaded = 0;
var modelsErrored = 0;
var modelsSkipped = 0;
var collectionsDeleted = 0; // Keep track of this so we can increment this so it doesn't break stuff in reacts diffing
var lastGeneratedModelID = null; // Cache for last generated modelID
// Collections
var modelCollectionsKeys = {}; // Model Collections with a UUID as the key. Used for toggling group visibility and group operations.
var modelCollections = []; // Same collection above but dictates the order things appear in the sidebar. Order is from the scene.json

// Model Props *NEW*
// Model props are stored under collections. This is a easy way to access them by their Inventum ID
const modelPropsByID = {}; // Inventum ID used as index

// 3D Meshes
var sceneObjectsOriginalID = {}; // Every object in the Scene.json indexed to the generated "ID" listed in the scene.json file. This is used for explicitly toggling the ID of objects without having to do a search
var sceneObjectKeys = {}; // Every object in the scene indexed by it's threeJS UUID. This is used for things like clicking on an object and hiding it etc.
var sceneObjects = []; // Flat unordered array of every object in the scene.

// Cut/Paste Operations
var clipboard = ''; // Stores the UUID of the model that will be moved;
var materialClipboard = ''; // Stores the UUID of the model that will have it's material cloned;
var modelStateClipboard = {}; // Should store two keys when actived. visibleStart and sceneStates. Used for transfering between models via copy paste

// Transform Models
var modelTransformControls = {}; // Indexed by Object ID for models being transformed with a gizmo
var controlsMode = 'transform';

// Private

function arrayMove (arr, previousIndex, newIndex) {
  var array = arr.slice(0);
  if (newIndex >= array.length) {
    var k = newIndex - array.length;
    while (k-- + 1) {
      array.push(undefined);
    }
  }
  array.splice(newIndex, 0, array.splice(previousIndex, 1)[0]);
  return array;
}

function extractObjectProps (modelPropsID) {
  const modelProps = modelPropsByID[modelPropsID];

  const serialized = {
    name: modelProps.name,
    id: modelProps.id,
    shortID: modelProps.id,
    isLoaded: modelProps.isLoaded
  };

  const rtd = (r) => {return r * (180 / Math.PI)};

  if (modelProps.isLoaded) {
    const targetModel = sceneObjectsOriginalID[modelProps.id];// 3D Mesh
    serialized.color = '#' + (targetModel.highlighted ? targetModel.oldMaterial.color.getHexString() : targetModel.material.color.getHexString());
    serialized.wireframe = targetModel.material.wireframe;
    serialized.visible = targetModel.visible;
    serialized.id = targetModel.uuid;
    serialized.shortID = modelProps.id; // id is the threeJS UUID. It's different to originalID. shortID is used as a key in react.
    serialized.highlighted = targetModel.highlighted;
    serialized.visibleStart = targetModel.visibleStart;
    serialized.castShadow = targetModel.castShadow;
    serialized.receiveShadow = targetModel.receiveShadow;
    serialized.needsReview = targetModel.userData.needsReview;
    serialized.downloadAutomatically = modelProps.downloadAutomatically;
		serialized.transformActive = modelTransformControls[targetModel.id] ? true : false; //targetModel.id is the THREE.js id per model.

    let tPosition = targetModel.position;
    let tRotation = targetModel.rotation;
    let tScale = targetModel.scale;

    // If it's currently being transformed we have to use the data from the transform object due to parenting.
    if (modelTransformControls[targetModel.id]) {
      tPosition = modelTransformControls[targetModel.id].decompPosition;
      tRotation = new THREE.Euler().setFromQuaternion(modelTransformControls[targetModel.id].decompoRotation);
      tScale = modelTransformControls[targetModel.id].decompScale;
    }

    serialized.position = {
      x: Number(tPosition.x.toFixed(2)),
      y: Number(tPosition.y.toFixed(2)),
      z: Number(tPosition.z.toFixed(2))
    };

    serialized.rotation = {
      x: Number(rtd(tRotation.x).toFixed(2)),
      y: Number(rtd(tRotation.y).toFixed(2)),
      z: Number(rtd(tRotation.z).toFixed(2))
    };

    serialized.scale = {
      x: Number(tScale.x.toFixed(2)),
      y: Number(tScale.y.toFixed(2)),
      z: Number(tScale.z.toFixed(2))
    };

    serialized.material = models.public.getMaterial(targetModel.uuid);
  }


  return serialized;
}

function checkAlternativeFileFormat (path, extension) {
	// Takes a S3 path and checks to see if an alternative format exists.
	// Resolves if it does exist, rejects if not
	return new Promise ((resolve, reject) => {
		const splitPath = path.split('/');
		//Get base filename
		let filename = splitPath[splitPath.length - 1];
		// Remove the existing extension
		filename = filename.substring(0, filename.lastIndexOf('.'));
		// Add the new desired extension
		splitPath[splitPath.length - 1] = `${filename}.${extension}`;

		const newPath = splitPath.join('/');

		fetch(newPath, { method: 'HEAD' })
		.then((response) => {
			if (response.status === 200) {
				resolve(newPath);
			} else {
				reject();
			}
		})

	});
}

function modelHasUpdated () {
  const updatedGroups = models.public.getGroups();
  onModelUpdateCallbacks.map((callback) => {
    callback(updatedGroups);
  });
  // Sync Broadcast Mode
  models.broadcastModels();
  store.requestRender();
}

models.public.getModelData = function getModelData () {
  modelHasUpdated();
}

models.broadcastModels = function broadcastModels () {
  if (!store.broadcastMode) {
    return;
  }
  const tSceneObjectsState = getModelsForBroadcast();
  store.sync.private.broadcast({ action: 'MODEL_VISIBLE_SCENE_STATE', payload: tSceneObjectsState });
};

function getModelsForBroadcast () {
  const tSceneObjectsState = [];
  Object.keys(sceneObjectsOriginalID).map(key => {
    if (Object.prototype.hasOwnProperty.call(sceneObjectsOriginalID, key)) {
      tSceneObjectsState.push({ id: key, visible: sceneObjectsOriginalID[key].visible });
    }
  });
  return tSceneObjectsState;
}

function parseOBJ (obj) {
  return new Promise((resolve, reject) => {
    const loader = new OBJLoader();
    const mesh = loader.parse(obj);
    const material = new THREE.MeshStandardMaterial({ color: 0xAAAAAA });
    obj.traverse(child => {
      if (child instanceof THREE.Mesh) {
        child.material = material;
      }
    });
    resolve(mesh);
  });
}

function parseFBX (buffer) {
  return new Promise((resolve, reject) => {
    var loader = new FBXLoader();
    const group = loader.parse(buffer);
    // Removes child if it isn't a Mesh.
    for (var i = 0; i < group.children.length; i++) {
      const child = group.children[i];
      if (!(child instanceof THREE.Mesh)) {
        group.children.splice(i, 1);
        i--;
      }
    }

    // NEEDS FIXING IF IMPLENTING FBX PROPERLY
    // FBX IS TYPE GROUP WHICH SHOULD NOT HAVE MATERIAL.
    // REST OF INVENTUM ASSUMES IT'S A MESH.
    // CAN'T CONVERT TO A MESH AS IT WILL BREAK ANIMATIONS. SHOULD HANDLE GROUPS
    group.material = group.children[0].material;

    if (group.animations.length > 0) {
      var mixer = new THREE.AnimationMixer(group);
      var action = mixer.clipAction(group.animations[1]);
      store.animationMixers.push(mixer);
      action.play();
    }
    resolve(group);
  });
}

function parseDraco (buffer) {
  return new Promise((resolve, reject) => {
    dracoLoader.parse(buffer, (geom) => {
      geom.computeVertexNormals();
      const mesh = new THREE.Mesh(geom, new THREE.MeshStandardMaterial({ color: 0xAAAAAA }));
      resolve(mesh);
    });
  });
}

models.public.loadDragged = (type, content, filename) => {
  const onLoad = (mesh) => {
    mesh.name = filename;
    sceneObjectKeys[mesh.uuid] = mesh;
    sceneObjects.push(mesh);
    store.scene.add(mesh);

    var collection = modelCollections.find(collection => collection.name === 'Unsorted Models');

    if (!collection) {
      const uuid = utility.generateUID();
      const shortID = modelCollections.length + collectionsDeleted;
      collection = { name: 'Unsorted Models', models: [], uuid, shortID };
      modelCollections.push(collection);
      modelCollectionsKeys[uuid] = collection;
    }

    collection.models.push(mesh);
    modelHasUpdated();
    store.requestRender();
  };

  if (type === 'obj') {
    parseOBJ(content).then(onLoad);
  } else if (type === 'fbx') {
    parseFBX(content).then(onLoad);
  } else if (type === 'drc') {
    parseDraco(content).then(onLoad);
  } else {
    console.log('Unsupported File');
  }
};

function processModel (geometry, modelProps, onComplete, opts) {

	if (!geometry.attributes.normal) {
		geometry = mergeVertices(geometry);
		geometry.computeVertexNormals();
	}

	let options = {};
  if (opts) {
    options = opts;
  }
  modelProps.isLoaded = true;
  var dataset = new THREE.Mesh(geometry, modelProps.material);

  if (modelProps.position) {
    dataset.position.set(modelProps.position.x, modelProps.position.y, modelProps.position.z);
  } else {
    // Scenes that haven't been updated to store the objects position yet..
    dataset.position.set(store.inventumTransform.x, store.inventumTransform.y, store.inventumTransform.z);
  }

  if (modelProps.rotation) {
    dataset.rotation.set(modelProps.rotation.x, modelProps.rotation.y, modelProps.rotation.z);
  }

  if (modelProps.scale) {
    dataset.scale.set(modelProps.scale.x, modelProps.scale.y, modelProps.scale.z);
  }else {
    dataset.scale.set(store.globalscale, store.globalscale, store.globalscale);
  }

  (modelProps.castShadow === undefined) ? dataset.castShadow = false : dataset.castShadow = modelProps.castShadow;
  (modelProps.receiveShadow === undefined) ? dataset.receiveShadow = false : dataset.receiveShadow = modelProps.receiveShadow;
  if (options.forceVisible) {
    dataset.visibleStart = true;
  } else {
    (modelProps.visibleStart === undefined) ? dataset.visibleStart = true : dataset.visibleStart = modelProps.visibleStart;
  }

  dataset.highlighted = false;
  dataset.name = modelProps.name;
  dataset.oldMaterial = dataset.material.clone();
  modelProps.uuid = dataset.uuid;
  dataset.originalID = modelProps.id;
  dataset.userData.needsReview = modelProps.needsReview;


  //Object Centering Tests
/*
  let center = new THREE.Vector3();
  const box = new THREE.Box3().setFromObject(dataset);
  box.getCenter(center);
  dataset.geometry.center();

  // If not baked difference
  if (!modelProps.transformBaked) {
    dataset.position.copy(center);
    modelProps.transformBaked = true;
  }*/

  // End model centering tests

  store.scene.add(dataset);

  if (!dataset.visibleStart) {
    dataset.visible = false;
  }

  sceneObjects.push(dataset);
  sceneObjectKeys[dataset.uuid] = dataset;
  sceneObjectsOriginalID[dataset.originalID] = dataset;

  if (typeof (onComplete) === 'function') {
    onComplete();
  }
  store.requestRender();
}

function loadModelDynamic (path, collection, name) {
  if (path === undefined) {
    return;
  }

  // if path is relative then add storagePath
  if (!path.includes('http')) {
    path = store.storagePath + path;
  }

  const callback = (geom) => {
    processModelDynamic(geom, path, collection, name);
  };

  const fileExtension = path.split('.').pop().toLowerCase();
  switch (fileExtension) {
    case 'js':
      console.log('JS Files are no longer supported');
      break;
    case 'ctm':
      CTMLoader(path, callback);
      break;
		case 'drc':
			dracoLoader.load(path, callback);
			break;
    case 'obj':
      console.log('OBJ is Unsupported');
      break;
    default:
      console.error('Unsupported file format' + fileExtension);
  }
}

function processModelDynamic (geometry, path, collection, name) {
  // Finds a new unused ID.
  const generatedID = findID();

  const material = new THREE.MeshStandardMaterial();
  material.roughness = 0.8;
  material.metalness = 0.2;
  material.flatShading = true;
	material.side = THREE.DoubleSide;
  var model = new THREE.Mesh(geometry, material);
  model.position.set(store.inventumTransform.x, store.inventumTransform.y, store.inventumTransform.z);
  model.scale.set(store.globalscale, store.globalscale, store.globalscale);
  model.castShadow = true;
  model.receiveShadow = true;
  model.visibleStart = true;
  model.highlighted = false;
  model.name = name;
  model.oldMaterial = model.material.clone();
  model.visibleStart = false;
  model.originalID = generatedID; // Stores a copy of the generatedID on the model.
  model.userData.needsReview = false;
  store.scene.add(model);
  sceneObjects.push(model);
  sceneObjectKeys[model.uuid] = model;
  sceneObjectsOriginalID[generatedID] = model;
  const modelProps = {
    name: model.name,
    path: path,
    receiveShadow: true,
    castShadow: true,
    uuid: model.uuid,
    visibleStart: false,
    id: generatedID,
    material: model.material,
    isLoaded: true
  };
  collection.models.push(modelProps);
  modelPropsByID[generatedID] = modelProps;
  modelHasUpdated();
}

function findID () {
	// Find the largest ID number in use and return 1 greater.
	// Cache it in lastGeneratedModelID
	if (lastGeneratedModelID === null || isNaN(lastGeneratedModelID) || sceneObjectsOriginalID[lastGeneratedModelID + 1] !== undefined) {
		let idArray = Object.keys(sceneObjectsOriginalID)
		.filter(key => !isNaN(parseInt(key)))
		.map(key => parseInt(key))
		.sort((a, b) => a - b);
		if (idArray.length == 0) {
			lastGeneratedModelID = 1;
		} else {
			lastGeneratedModelID = idArray[idArray.length - 1] + 1;
		}
	}else {
		// From cache
		console.log('From cache');
		lastGeneratedModelID += 1;
	}
	return lastGeneratedModelID;
}

// Shared
models.addCollection = function addCollection (collection) {
  const collectionLength = collection.models.length;
  modelCount = modelCount + collectionLength;
  modelCollections.push(collection);
};

models.getSceneObjects = function getSceneObjects () {
  return sceneObjects;
};

models.load = function loadModels (onInitialLoadCallback) {
	// onInitialLoadCallback is called when all models are finished loading
	// Models are loaded at different speeds so we keep track of it in the isLoadComplete function below


	// Handles a case where no collections exist. We should just immediately return
  if (modelCollections.length === 0) {
    onInitialLoadCallback();
    return;
  }

  for (var i = 0; i < modelCollections.length; i++) {
    for (var j = 0; j < modelCollections[i].models.length; j++) {
      const modelProps = modelCollections[i].models[j];
      modelPropsByID[modelProps.id] = modelProps;

      const isLoadComplete = () => {
        if (modelsLoaded + modelsErrored + modelsSkipped === modelCount) {
          onInitialLoadCallback();
        }
      };

      const onModelLoad = (geometry) => {
        if (modelCollections === undefined || modelCollections.length === 0) {
          // NOTE This is to catch the case for the user clicking back in the browser while the initial load is happening.
          // It causes an error when processModels tries to load to memory that has been cleared
          // console.log('Project Unloaded. Returning...');
          return;
        }

        processModel(geometry, modelProps, () => {
          modelsLoaded++;
          isLoadComplete();
        });
      };

      const onError = () => {
        modelsErrored++;
        isLoadComplete();
      };

      if (!modelProps.downloadAutomatically) {
        modelsSkipped++;
        isLoadComplete();
        continue;
      }

      const fileExtension = modelProps.path.split('.').pop().toLowerCase();
      switch (fileExtension) {
        case 'js':
					onError();
          console.log('JS Files are no longer supported');
          break;
        case 'ctm':
					CTMLoader(modelProps.path, onModelLoad, onError);
					// Uncomment the below lines when draco support is enabled.
					/*checkAlternativeFileFormat(modelProps.path, 'drc')
					.then((newPath) => {
						console.log('Updating to DRC');
						modelProps.path = newPath;
						dracoLoader.load(modelProps.path, onModelLoad);
					})
					.catch(() => {
						console.warn('No DRC file exists. Using CTM');
						CTMLoader(modelProps.path, onModelLoad, onError);
					})*/
          break;
				case 'drc':
					//dracoLoader.load(modelProps.path, onModelLoad);
					// Temporary disable DRC until issues with texture compression are solved
					checkAlternativeFileFormat(modelProps.path, 'ctm')
					.then((newPath) => {
						console.log('Reverting to legacy CTM format');
						modelProps.path = newPath;
						CTMLoader(modelProps.path, onModelLoad, onError);
					})
					.catch(() => {
						console.warn('No CTM file exists. Using draco');
						dracoLoader.load(modelProps.path, onModelLoad);
					})
					break;
        case 'obj':
					onError();
          console.log('OBJ is Unsupported');
          break;
        default:
					onError();
          console.error('Unsupported file format' + fileExtension);
      }
    }

    // Create ID for group. Important as React uses this key
    if (!modelCollections[i].uuid) {
      const groupUUID = utility.generateUID();
      modelCollections[i].uuid = groupUUID;
      modelCollections[i].shortID = i;
      modelCollectionsKeys[groupUUID] = modelCollections[i];
    }
  }

	// Handles a case where only empty collections exist.
	// We still need to assign a UUID for each collection (in the loop above)
	// But as there are no models to load, we now need to return
	if (modelCount === 0) {
		onInitialLoadCallback();
		return;
	}

};

models.getBoundingFrame = function getBoundingFrame (uuid) {
  const tempModel = sceneObjectKeys[uuid];
  var center = new THREE.Vector3();

  tempModel.geometry.computeBoundingBox();
  center.x = (tempModel.geometry.boundingBox.max.x + tempModel.geometry.boundingBox.min.x) / 2;
  center.y = (tempModel.geometry.boundingBox.max.y + tempModel.geometry.boundingBox.min.y) / 2;
  center.z = (tempModel.geometry.boundingBox.max.z + tempModel.geometry.boundingBox.min.z) / 2;
  tempModel.localToWorld(center);
  var distance = tempModel.geometry.boundingBox.max.distanceTo(tempModel.geometry.boundingBox.min);

  return { center, distance };
};

models.getModelByOriginalID = function getModelByOriginalID (originalID) {
  return sceneObjectsOriginalID[originalID];
};

models.hideAll = function hideAll () {
  sceneObjects.map((object) => {
    object.visible = false;
  });
  modelHasUpdated();
};

models.batchSetVisible = function setVisibility (modelArray, isVisible) {
  if (isVisible === undefined) {
    isVisible = true;
  }
  modelArray.map((entry, index) => {
    if (typeof (entry) === 'number') {
      const tempModel = sceneObjectsOriginalID[entry];
      if (tempModel !== undefined) {
        tempModel.visible = isVisible;
      } else {
        // console.log('Invalid ID:' + entry);
      }
    } else if (Object.prototype.hasOwnProperty.call(entry, 'visible')) {
      console.warn('Deprecated. Only supply IDs to batchSetVisible');
      entry.visible = isVisible;
    } else {
      console.warn('Invalid Entry');
    }
  });
  modelHasUpdated();
};

models.showVisibleStart = function showVisibleStart () {
  const tArr = [];
  Object.keys(sceneObjectsOriginalID).map(key => {
    if (sceneObjectsOriginalID[key].visibleStart) tArr.push(parseInt(key));
  });
  models.hideAll();
  models.batchSetVisible(tArr, true);
};

models.setVisibleStartFromCurrentState = function setVisibleStartFromCurrentState () {
  Object.keys(sceneObjectsOriginalID).map(key => {
    const model = sceneObjectsOriginalID[key];
    model.visibleStart = model.visible;
    modelPropsByID[model.originalID].visibleStart = model.visibleStart;
  });
  modelHasUpdated();
  store.setUnsavedScene();
  notifications.add({ content: `Visible Start Models Updated`, displayTime: 2000 });
};

models.arrayVisibleStateOriginalID = function arrayVisibleStateOriginalID (modelArray) {
  // Array is supplied with each object reference by OriginalID and a visible flag set to true or false
  // Used by the sync module.
  // Model array = [{id:,visible:}]
  modelArray.map(model => {
    if (!sceneObjectsOriginalID[model.id]) {
      // Model needs downloading first
      models.fetchModel(model.id);
    } else {
      sceneObjectsOriginalID[model.id].visible = model.visible;
    }
  });
  store.requestRender();
  modelHasUpdated();
};

models.getVisible = function getVisible () {
  const tempIDList = [];
  sceneObjects.map((model) => {
    if (Object.prototype.hasOwnProperty.call(model, 'visible')) {
      if (model.visible) {
        if (!model.originalID) {
        }
        tempIDList.push(model.originalID);
      }
    }
  });
  const sortFunc = (a, b) => { return a - b; };
  tempIDList.sort(sortFunc);
  return tempIDList;
};

// Public/API

models.public.getGroups = function getGroups () {
  var groups = [];
  modelCollections.map((collection, index) => {
    let allVisible = true;
    let allHidden = true;
    const tempCollection = {};
    tempCollection.type = '3D_GROUP';
    tempCollection.visibilityHandler = () => { this.toggleGroupVisibility(collection.uuid); };
    tempCollection.name = collection.name;
    tempCollection.items = [];
    tempCollection.id = collection.uuid;
    tempCollection.shortID = collection.shortID;
    collection.models.map((modelSettings, index) => {
      var processedModel = extractObjectProps(modelSettings.id);
      if (!processedModel.visible && allVisible) {
        allVisible = false;
      } else if (processedModel.visible && allHidden) {
        allHidden = false;
      }
      tempCollection.items.push(processedModel);
    });

    if (!allVisible && !allHidden) {
      tempCollection.visibility = 'mixed';
    } else if (allVisible && !allHidden) {
      tempCollection.visibility = 'all';
    } else if (allHidden && !allVisible) {
      tempCollection.visibility = 'none';
    }
    groups.push(tempCollection);
  });
  return groups;
};

models.public.getModelsSerialized = function getModelsSerialized () {
  const results = [];
  modelCollections.map(collection => {
    const group = {
      name: collection.name,
      models: []
    };
    collection.models.map(model => {
      group.models.push({
        id: model.id,
        name: model.name
      });
    });
    results.push(group);
  });
  return results;
};

models.public.resetVisibility = function resetVisibility () {
  sceneObjects.map((object) => {
    object.visibleStart ? object.visible = true : object.visible = false;
  });
  modelHasUpdated();
};

models.public.registerModelUpdate = function registerModelUpdate (callback) {
  onModelUpdateCallbacks.push(callback);
};

models.public.toggleWireframe = function toggleWireframe (uuid) {
  const tempModel = sceneObjectKeys[uuid];
  tempModel.material.wireframe = !tempModel.material.wireframe;
  modelHasUpdated();
};

models.public.setModelName = function setModelName (uuid, newName) {
  if (newName === '' || newName === undefined) { return; }
  const tempModel = sceneObjectKeys[uuid];
  tempModel.name = newName;
  modelCollections.map((collection) => {
    collection.models.map((model) => {
      if (model.uuid === uuid) {
        model.name = newName;
      }
    });
  });
  modelHasUpdated();
  store.setUnsavedScene();
};

models.public.cutModel = function cutModel (modelUUID) {
  clipboard = modelUUID;
  notifications.add({ content: `${sceneObjectKeys[modelUUID].name} copied to clipboard`, displayTime: 2000 });
};

models.public.pasteModel = function pasteModel (collectionUUID) {
  if (clipboard.length === 0) {
    window.alert('No object on clipboard!');
    return;
  }

  let tempModel = {};
  // Stores model that has been cut in tempModel and removes it from the models array
  modelCollections.map((collection) => {
    collection.models = collection.models.filter((model) => {
      if (model.uuid === clipboard) {
        tempModel = model;
      }
      return model.uuid !== clipboard;
    });
  });

  modelCollections.map((collection) => {
    if (collection.uuid === collectionUUID) {
      collection.models.push(tempModel);
    }
  });
  // reset the clipboard
  clipboard = '';
  modelHasUpdated();
  store.setUnsavedScene();
};

models.public.copyMaterial = function copyMaterial (modelUUID) {
  materialClipboard = modelUUID;
  notifications.add({ content: `${sceneObjectKeys[modelUUID].name} material copied to clipboard`, displayTime: 2000 });
};

models.public.pasteMaterial = function pasteMaterial (modelUUID) {
  if (materialClipboard.length === 0) {
    window.alert('No material on clipboard!');
    return;
  }

  const originalModel = sceneObjectKeys[materialClipboard];
  const targetModel = sceneObjectKeys[modelUUID];

  if (originalModel === undefined) {
    window.alert('Original material could not be found. Was it deleted?');
    materialClipboard = '';
    return;
  }

  if (targetModel === undefined) {
    window.alert('Could not find target model. This shouldn\'t happen');
    return;
  }

  if (targetModel.material.type === originalModel.material.type) {
    targetModel.material.copy(originalModel.material);
    notifications.add({ content: `${sceneObjectKeys[modelUUID].name} material updated`, displayTime: 2000 });
  } else {
    notifications.add({ content: `${sceneObjectKeys[modelUUID].name} material updated (mismatch)`, displayTime: 2000 });
    targetModel.material.color.copy(originalModel.material.color);
    if (originalModel.material.map) {
      targetModel.material.map = originalModel.material.map.clone();
    }
  }
  modelHasUpdated();
  store.setUnsavedScene();
};

models.public.copySceneStates = function getSceneStatesModelIn (modelUUID) {
  const model = sceneObjectKeys[modelUUID];
  if (!model) return;
  modelStateClipboard = {};
  modelStateClipboard.sceneStates = sceneStates.public.getContainingModel(model.originalID);
  modelStateClipboard.visibleStart = model.visibleStart;
  modelStateClipboard.castShadow = model.castShadow;
  modelStateClipboard.receiveShadow = model.receiveShadow;
  notifications.add({ content: `${model.name} scene states copied.`, displayTime: 2000 });
};

models.public.pasteSceneStates = function pasteSceneStates (modelUUID) {
  if (Object.keys(modelStateClipboard).length === 0) return;
  const model = sceneObjectKeys[modelUUID];
  if (!model) return;

  if (typeof (modelStateClipboard.castShadow) === 'boolean') {
    model.castShadow = modelStateClipboard.castShadow;
  }

  if (typeof (modelStateClipboard.receiveShadow) === 'boolean') {
    model.receiveShadow = modelStateClipboard.receiveShadow;
  }

  model.visibleStart = modelStateClipboard.visibleStart;
  modelPropsByID[model.originalID].visibleStart = model.visibleStart;

  sceneStates.public.addModelToList(model.originalID, modelStateClipboard.sceneStates.slice());
  notifications.add({ content: `${model.name} scene states updated.`, displayTime: 2000 });
  modelHasUpdated();
  store.setUnsavedScene();
};

models.public.toggleVisibleStart = function toggleVisibleStart (modelUUID) {
  const model = sceneObjectKeys[modelUUID];
  // Check if model exists;
  if (!model) {
    return;
  }

  // Update THREE.js Model
  const newState = !model.visibleStart;
  model.visibleStart = newState;
  modelPropsByID[model.originalID].visibleStart = newState;

  modelHasUpdated();
  store.setUnsavedScene();
};

models.public.toggleCastShadow = function toggleCastShadow (modelUUID) {
  const model = sceneObjectKeys[modelUUID];
  // Check if mmodel exists;
  if (!model) {
    return;
  }

  // Update THREE.js Model
  const newState = !model.castShadow;
  model.castShadow = newState;
  modelPropsByID[model.originalID].castShadow = newState;
  modelHasUpdated();
  store.setUnsavedScene();
};

models.public.toggleReceiveShadow = function toggleReceiveShadow (modelUUID) {
  const model = sceneObjectKeys[modelUUID];
  // Check if model exists;
  if (!model) {
    return;
  }

  // Update THREE.js Model
  const newState = !model.receiveShadow;
  model.receiveShadow = newState;
  modelPropsByID[model.originalID].receiveShadow = newState;
  model.material.needsUpdate = true;
  modelHasUpdated();
  store.setUnsavedScene();
};

models.public.toggleNeedsReview = function toggleNeedsReview (uuid) {
  const model = sceneObjectKeys[uuid];
  // Check if mmodel exists;
  if (!model) {
    return;
  }
  model.userData.needsReview = !model.userData.needsReview;
  modelPropsByID[model.originalID].needsReview = model.userData.needsReview;
  modelHasUpdated();
  store.setUnsavedScene();
};

models.public.toggleDownloadAutomatically = function toggleDownloadAutomatically (inventumID) {
  const modelProps = modelPropsByID[inventumID];
  // Check if mmodel exists;
  if (!modelProps) {
    return;
  }
  modelProps.downloadAutomatically = !modelProps.downloadAutomatically;
  modelHasUpdated();
};

models.public.removeModel = function removeModel (uuid) {
  store.scene.remove(sceneObjectKeys[uuid]);
	const originalID = sceneObjectKeys[uuid].originalID;

	delete sceneObjectsOriginalID[originalID]
  delete sceneObjectKeys[uuid];

	// Deletes model if the uuid matches
  modelCollections.map((collection) => {
    collection.models = collection.models.filter((model) => {
      return model.uuid !== uuid;
    });
  });

  sceneStates.public.clean();// FIXME Ugly refencing global objec
	clipping.clean();

	modelHasUpdated();
  store.setUnsavedScene();
};

models.public.transformModelNumeric = function transformModelNumeric (uuid, transform) {
  const tempModel = sceneObjectKeys[uuid];
  window.tModel = tempModel;
  if (!tempModel || !transform) {
    return;
  }

  const modelBeingTransformed = modelTransformControls.hasOwnProperty(tempModel.id);
  const dtr = (degrees) => { return degrees * (Math.PI / 180); };
  const rtd = (radians) => { return radians * (180 / Math.PI); };

  if (!tempModel.geometry.boundingSphere) {
    tempModel.geometry.computeBoundingSphere();
  }

  const center = new THREE.Vector3();
  center.copy(tempModel.geometry.boundingSphere.center);
  center.applyMatrix4(tempModel.matrixWorld);


  if (transform.position) {
    let position = transform.position;
    if (modelBeingTransformed) {
      console.log('Not Supported');
    } else {
      tempModel.position.set(position.x, position.y, position.z);
    }
  }

  if (transform.rotation) {
    let rotation = {
      x: dtr(transform.rotation.x),
      y: dtr(transform.rotation.y),
      z: dtr(transform.rotation.z)
    };

    if (modelBeingTransformed) {
      console.log('Not Supported');
    } else {
      tempModel.rotation.set(rotation.x, rotation.y, rotation.z);
    }
  }

  if (transform.scale) {
    let scale = transform.scale;
    if (modelBeingTransformed) {
      console.log('Not Supported');
    } else {
      tempModel.scale.set(scale.x, scale.y, scale.z);
    }
  }

  modelHasUpdated();
}

models.public.transformModel = function transformModel (uuid) {
  const tempModel = sceneObjectKeys[uuid];
  if (!tempModel) {
    return;
  }

  // Transform already exists. Remove it.
  if (modelTransformControls[tempModel.id]) {
    let existingTransform = modelTransformControls[tempModel.id];
    existingTransform.pivotHelper.remove(tempModel);

    tempModel.matrixWorld.decompose(tempModel.position, tempModel.quaternion, tempModel.scale);

    store.scene.add(tempModel);
    existingTransform.control.detach();

    store.scene.remove(existingTransform.pivotHelper);
    store.scene.remove(existingTransform.control);
    delete modelTransformControls[tempModel.id];

    // Removed last control. Remove event listener
    if (Object.keys(modelTransformControls).length === 0) {
      document.removeEventListener('keypress', handleControlsMode);
    }

		modelHasUpdated();
    return;
  }

  // Adding the first control. Add an event listener.
  if (Object.keys(modelTransformControls).length === 0) {
    document.addEventListener('keypress', handleControlsMode);
  }

  tempModel.updateMatrixWorld();
  tempModel.geometry.computeBoundingSphere();

  const center = new THREE.Vector3();
  center.copy(tempModel.geometry.boundingSphere.center);
  center.applyMatrix4(tempModel.matrixWorld);

  const pivotHelper = new THREE.Mesh(new THREE.SphereGeometry(0.1, 4, 4), new THREE.MeshBasicMaterial({ color: 0xFF0000 }));
  pivotHelper.position.copy(center);
  pivotHelper.add(tempModel);
  tempModel.position.sub(center);
  store.scene.add(pivotHelper);

  const control = new TransformControls(store.camera, store.webglRenderer.domElement);
  control.attach(pivotHelper);
  store.scene.add(control);

  control.addEventListener('dragging-changed', function (event) {
    store.controls.enabled = !event.value;
    store.requestRender();
  });

  control.addEventListener('change', (e) => {
    if (transformData) {
      tempModel.matrixWorld.decompose(transformData.decompPosition, transformData.decompoRotation, transformData.decompScale);
      modelTransformControls[tempModel.id] = transformData;
      modelHasUpdated();
    }
    store.requestRender();
  });

  const transformData = {
    pivotHelper,
    control,
    decompPosition: new THREE.Vector3(),
    decompoRotation: new THREE.Quaternion(),
    decompScale: new THREE.Vector3()
  };

  tempModel.matrixWorld.decompose(transformData.decompPosition, transformData.decompoRotation, transformData.decompScale);
  modelTransformControls[tempModel.id] = transformData;
  modelHasUpdated();
  store.requestRender();
};

models.public.setColor = function setColor (uuid, color) {
  const tempModel = sceneObjectKeys[uuid];
  if (!tempModel.highlighted) {
    tempModel.material.color.setStyle(color);
  } else {
    tempModel.oldMaterial.color.setStyle(color);
  }
  modelHasUpdated();
  store.setUnsavedScene();
};

models.public.setOpacity = function setOpacity (uuid, opacity) {
  const tempModel = sceneObjectKeys[uuid];
  tempModel.material.opacity = opacity;
  modelHasUpdated();
  store.setUnsavedScene();
};

models.public.toggleHighlight = function toggleHighlight (uuid, color) {
  const highlightColor = color || '#00FFDA';
  const tempModel = sceneObjectKeys[uuid];
  if (tempModel.highlighted) {
    tempModel.material = tempModel.oldMaterial;
  } else {
    tempModel.oldMaterial = tempModel.material.clone();
    models.public.setColor(uuid, highlightColor);
  }
  tempModel.highlighted = !tempModel.highlighted;
  modelHasUpdated();
};

models.public.toggleVisibility = function toggleVisibility (uuid) {
  if (store.followerMode) {
    return;
  }
  const tempModel = sceneObjectKeys[uuid];
  if (tempModel) {
    tempModel.visible = !tempModel.visible;
    modelHasUpdated();
  }
};

models.public.setGroupName = function setGroupName (uuid, newName) {
  const collection = modelCollectionsKeys[uuid];
  collection.name = newName;
  modelHasUpdated();
  store.setUnsavedScene();
};

// Stores the collection for the model to be loaded into
// This is because we are calling a loading the model in 2 stages.
// The first stage is from the sidebar UI where we have access to the groups UUID
// At this point we open the popup asset manager container where we lose access to the UUID
// To work around this we first call prepareLoadModel to register the groups UUID.
// Then when we call addModel we will just be sending the path
/* models.public.prepareAddModel = function prepareAddModel(uuid) {
  preparedGroup = modelCollectionsKeys[uuid];
} */

models.public.addModel = function addModel (uuid, path, name) {
  if (name === undefined) {
    name = 'Untitled Model';
  }

  const collection = modelCollectionsKeys[uuid];

  if (collection === null) {
    console.log('Collection Not Found');
    return;
  }

  loadModelDynamic(path, collection, name);
  store.setUnsavedScene();
};

models.public.deleteGroup = function deleteGroup (uuid) {
	//TODO: This really should call removeModel on each model instead of duplicating code.
  const collection = modelCollectionsKeys[uuid];
  collection.models.map((modelData) => {
		delete sceneObjectsOriginalID[modelData.id];
    store.scene.remove(sceneObjectKeys[modelData.uuid]);
  });
  delete modelCollectionsKeys[uuid];
  let indexFound = false;
  let index = -99;
  modelCollections.map((collection, i) => {
    if (collection.uuid === uuid) {
      indexFound = true;
      index = i;
    }
  });
  if (!indexFound) {
    return;
  }
  modelCollections.splice(index, 1);
  collectionsDeleted++;
  sceneStates.public.clean();// FIXME Ugly referencing global object
	clipping.clean();
  modelHasUpdated();
  store.setUnsavedScene();
};

models.public.createGroup = function createGroup () {
  var name = window.prompt('Group Name:', 'Untitled Group');
  if (name === null || name === '') {
    return;
  }

  const uuid = utility.generateUID();
  const shortID = modelCollections.length + collectionsDeleted;
  const emptyCollection = { name, models: [], uuid, shortID };

  modelCollections.push(emptyCollection);
  modelCollectionsKeys[uuid] = emptyCollection;
  modelHasUpdated();
  store.setUnsavedScene();
};

models.public.sortGroups = function sortGroups (data) {
  modelCollections = arrayMove(modelCollections, data.oldIndex, data.newIndex);
  store.setUnsavedScene();
};

models.public.sortModels = function sortModels (uuid, data) {
  let tModels = modelCollectionsKeys[uuid].models;
  tModels = arrayMove(tModels, data.oldIndex, data.newIndex);
  // Updating modelCollectionsKeys also updates modelCollections automatically (same object)
  modelCollectionsKeys[uuid].models = tModels;
  modelHasUpdated();
  store.setUnsavedScene();
};

models.public.toggleGroupVisibility = function toggleGroupVisibility (uuid) {
  if (store.followerMode) {
    return;
  }
  const collection = modelCollectionsKeys[uuid];
  let visibleCount = 0;
  let hiddenCount = 0;
  // Decide whether to hide or show objects based on total count of objects visible in the model collection
  collection.models.map((modelData, index) => {
    const uuid = modelData.uuid;
    const tempModel = sceneObjectKeys[uuid];
    if (!tempModel) {
      return;
    }
    if (tempModel.visible) {
      visibleCount++;
    } else {
      hiddenCount++;
    }
  });
  // Actually set the model to visible.
  let updateVisible = true;

  if (hiddenCount === 0) {
    updateVisible = false;
  } else if (visibleCount === 0) {
    updateVisible = true;
  } else {
    visibleCount >= hiddenCount ? updateVisible = true : updateVisible = false;
  }

  collection.models.map((modelData, index) => {
    const uuid = modelData.uuid;
    const tempModel = sceneObjectKeys[uuid];
    if (!tempModel) {
      return;
    }
    tempModel.visible = updateVisible;
  });
  modelHasUpdated();
};

models.public.getMaterial = function getMaterial (uuid) {
  if (!sceneObjectKeys[uuid]) { return; }
  if (!sceneObjectKeys[uuid].material) { return; }
  const tMaterial = sceneObjectKeys[uuid].material;
  const cleanData = {};// For sending to UI
  cleanData.type = tMaterial.type;
  cleanData.color = '#' + tMaterial.color.getHexString();
  cleanData.side = 'DoubleSide';

  switch (tMaterial.side) {
    case THREE.FrontSide:
      cleanData.side = 'FrontSide';
      break;
    case THREE.BackSide:
      cleanData.side = 'BackSide';
      break;
    case THREE.DoubleSide:
      cleanData.side = 'DoubleSide';
      break;
  }

  if (tMaterial.type !== 'MeshBasicMaterial') {
    cleanData.emissive = '#' + tMaterial.emissive.getHexString();
  }
  cleanData.opacity = tMaterial.opacity;
  cleanData.transparent = tMaterial.transparent;
  if (tMaterial.type === 'MeshStandardMaterial' || tMaterial.type === 'MeshPhysicalMaterial') {
    cleanData.roughness = tMaterial.roughness;
    cleanData.metalness = tMaterial.metalness;
  }
  cleanData.flatShading = tMaterial.flatShading;

  cleanData.wireframe = tMaterial.wireframe;

  if (tMaterial.map) {
    if (tMaterial.map.image) {
      cleanData.mapPath = tMaterial.map.image.currentSrc;
    }
  }
  return cleanData;
};

models.public.setMaterial = function setMaterial (uuid, data) {
  if (!sceneObjectKeys[uuid]) { return; }
  if (!sceneObjectKeys[uuid].material) { return; }
  if (!data) return;

  const tMaterial = sceneObjectKeys[uuid].material;

  if (data.hasOwnProperty('color')) {
    tMaterial.color.setStyle(data.color);
  }

  if (data.hasOwnProperty('opacity')) {
    tMaterial.opacity = data.opacity;
    tMaterial.transparent = tMaterial.opacity < 1;
  }

  if (data.hasOwnProperty('wireframe')) {
    tMaterial.wireframe = data.wireframe;
  }

  if (data.hasOwnProperty('side')) {
    switch (data.side) {
      case 'FrontSide':
        tMaterial.side = THREE.FrontSide;
        break;
      case 'BackSide':
        tMaterial.side = THREE.BackSide;
        break;
      case 'DoubleSide':
        tMaterial.side = THREE.DoubleSide;
        break;
    }
  }

  if (tMaterial.type === 'MeshStandardMaterial' || tMaterial.type === 'MeshPhysicalMaterial') {
    if (data.hasOwnProperty('roughness')) {
      tMaterial.roughness = data.roughness;
    }

    if (data.hasOwnProperty('metalness')) {
      tMaterial.metalness = data.metalness;
    }
  }

  if (tMaterial.type !== 'MeshBasicMaterial') {
    if (data.hasOwnProperty('emissive')) {
      tMaterial.emissive.setStyle(data.emissive);
    }
  }

  if (data.hasOwnProperty('flatShading')) {
    tMaterial.flatShading = data.flatShading;
  }
  modelHasUpdated();
  store.requestRender();
  store.setUnsavedScene();
};

/* if (tMaterial.map) {
  if (tMaterial.map.image) {
    if (texturePath !== tMaterial.map.image.currentSrc) {
      var tempTexture = new THREE.TextureLoader().load(texturePath);
      tMaterial.map = tempTexture;
    }
  }
} */

models.public.setTexture = function setTexture (uuid, texturePath) {
  if (!sceneObjectKeys[uuid]) { return; }
  if (!sceneObjectKeys[uuid].material) { return; }

  const tMaterial = sceneObjectKeys[uuid].material;

  if (texturePath !== undefined) {
    const onLoadComplete = modelHasUpdated;
    var tempTexture = new THREE.TextureLoader().load(texturePath, onLoadComplete);
    var maxAnisotropy = store.webglRenderer.capabilities.getMaxAnisotropy();
    tempTexture.anisotropy = maxAnisotropy;
    tempTexture.minFilter = THREE.LinearFilter;
    tMaterial.map = tempTexture;
    tMaterial.needsUpdate = true;
  }
  modelHasUpdated();
  store.setUnsavedScene();
};

models.public.clearTexture = function clearTexture (uuid) {
  if (!sceneObjectKeys[uuid]) { return; }
  if (!sceneObjectKeys[uuid].material) { return; }
  const tMaterial = sceneObjectKeys[uuid].material;
  tMaterial.map = null;
  tMaterial.needsUpdate = true;
  modelHasUpdated();
  store.setUnsavedScene();
};

models.public.updateEnvMap = function updateEnvMap () {
  var g = new THREE.SphereGeometry(20, 32, 32);
  var m = new THREE.MeshStandardMaterial({ envMap: store.envMap, color: 0xFFFFFF, roughness: 0, metalness: 1 });
  var sp = new THREE.Mesh(g, m);
  store.scene.add(sp);

  sceneObjects.map((model) => {
    const oldMat = model.material;
    if (oldMat.type === 'MeshStandardMaterial') {
      const newMaterial = new THREE.MeshStandardMaterial({ color: oldMat.color, roughness: oldMat.roughness, metalness: oldMat.metalness, envMap: store.envMap });
      model.material = newMaterial;
    }
  });
};

models.public.getModelsIDS = function getModelIDS () {
  return Object.keys(sceneObjectsOriginalID);
};

models.generateJSON = function generateJSON () {
  // Generate Collection structure in the correct order
  var processedCollections = [];
  // First loop through all modelCollections.
  // This is important to preserve the order in the sidebar
  for (var i = 0; i < modelCollections.length; i++) {
    var origCollection = modelCollections[i];
    var tempCollection = {
      name: origCollection.name || 'Untitled Collection',
      models: []
    };

    // Now we loop through all the models in the collection and copy out the values we need into it's
    // simple scene.json version
    for (var j = 0; j < origCollection.models.length; j++) {
      var origModel = origCollection.models[j]; // Original Object Props
      var currentMesh = sceneObjectsOriginalID[origModel.id]; // Current 3D Model

			///Here
			if (modelTransformControls[currentMesh.id]) {
				// FIXME. modelTransformControls indexs uses mesh.id
				// models.public.transformModel takes mesh.uuid as ID (As this is what Inventum UI historically uses as ID);
				// Inconsistent
				models.public.transformModel(currentMesh.uuid); // Disables the transform
			}

      var tempModel = {
        id: origModel.id,
        name: origModel.name,
        visibleStart: origModel.visibleStart,
        receiveShadow: origModel.receiveShadow,
        castShadow: origModel.castShadow,
        position: {
          x: currentMesh.position.x,
          y: currentMesh.position.y,
          z: currentMesh.position.z
        },
        rotation: {
          x: currentMesh.rotation.x,
          y: currentMesh.rotation.y,
          z: currentMesh.rotation.z
        },
        scale: {
          x: currentMesh.scale.x,
          y: currentMesh.scale.y,
          z: currentMesh.scale.z
        }
      };

      if (typeof (origModel.needsReview) === 'boolean') {
        tempModel.needsReview = origModel.needsReview;
      }

      if (typeof (origModel.downloadAutomatically) === 'boolean') {
        tempModel.downloadAutomatically = origModel.downloadAutomatically;
      }

      if (tempModel.receiveShadow === undefined) {
        tempModel.receiveShadow = false;
      }

      tempModel.path = origModel.path;

      // Some checks to determine what string should be set for side:
      let side = '';
      if (origModel.material.side === THREE.FrontSide) {
        side = 'FrontSide';
      } else if (origModel.material.side === THREE.BackSide) {
        side = 'BackSide';
      } else if (origModel.material.side === THREE.DoubleSide) {
        side = 'DoubleSide';
      }

      // Now we need to save the THREE Material structure into a json version

      const tempMaterial = {
        type: origModel.material.type,
        color: '#' + origModel.material.color.getHexString(),
        side: side,
        opacity: origModel.material.opacity,
        transparent: false,
        depthWrite: origModel.material.depthWrite,
        depthTest: origModel.material.depthTest,
        flatShading: origModel.material.flatShading
      };

      // Only MeshBasicMaterial does not have an emissive property so we can set it
      // if it isn't basic
      if (origModel.material.type !== 'MeshBasicMaterial') {
        tempMaterial.emissive = '#' + origModel.material.emissive.getHexString();
      }

      // Properties to set for PhongMaterials
      if (origModel.material.type === 'MeshPhongMaterial') {
        tempMaterial.specular = '#' + origModel.material.specular.getHexString();
        tempMaterial.shininess = origModel.material.shininess;
      }

      // Properties to set for StandardMaterials
      if (origModel.material.type === 'MeshStandardMaterial' || origModel.material.type === 'MeshPhysicalMaterial') {
        tempMaterial.roughness = origModel.material.roughness;
        tempMaterial.metalness = origModel.material.metalness;
      }

      // Set transparent boolean flag to true if the opacity is less than 1.0
      // Setting transparent = true when it *isn't* transparent causes a world of problems
      if (tempMaterial.opacity < 1.0) {
        tempMaterial.transparent = true;
      }

      if (origModel.material.map) {
        if (origModel.material.map.image) {
          tempMaterial.map = origModel.material.map.image.currentSrc;
        }
      }

      tempModel.material = tempMaterial;
      tempCollection.models.push(tempModel);
    }
    processedCollections.push(tempCollection);
  }
  return ({ groups: processedCollections });
};

models.modifyTransform = function modifyTransform (deltaTransform) {
  sceneObjects.map((object) => {
    object.position.x += deltaTransform.x;
    object.position.y += deltaTransform.y;
    object.position.z += deltaTransform.z;
  });
  store.setUnsavedScene();
};

models.modifyScale = function modifyScale (newScale) {
  sceneObjects.map((object) => {
    // Update the position depending on the scale.
    // Otherwise objects will get further apart when scaled smaller and closer when scaled big.
    const oldScale = store.globalscale;
    object.position.sub(store.inventumTransform); // Remove the Inventum Transform temp
    object.position.divideScalar(oldScale); // Calculate what the original 100% was
    object.position.multiplyScalar(newScale); // Calculate the new scale
    object.position.add(store.inventumTransform); // Add the Inventum Transform back

    object.scale.set(newScale, newScale, newScale);
  });
  store.setUnsavedScene();
};

models.public.fetchModel = function fetchModelPublic (propsID, onComplete) {
  // Abstraction
  // This function is called from the SideBar component when the user clicks the download model button
  // We don't want the user to be able to do this in followerMode so we have a separate internal function that gets called
  // If it is *not* in followerMode
  if (store.followerMode) {
    return;
  }
  models.fetchModel(propsID, onComplete);
};

// Mode fetching is currently not used and disabled
// Allows for delayed loading of models
models.fetchModel = function fetchModel (propsID, onComplete) {
  const modelProps = modelPropsByID[propsID];
  if (!modelProps) {
    return;
  }

  if (store.broadcastMode) {
    store.sync.private.broadcast({ action: 'MODEL_FETCH_DOWNLOAD', payload: propsID });
  }

  var path = modelProps.path;

  // if path is relative then add storagePath
  if (!path.includes('http')) {
    path = store.storagePath + path;
  }

  const callback = (geometry) => {
    processModel(geometry, modelProps, () => {
      modelHasUpdated();
      if (typeof (onComplete) === 'function') {
        onComplete();
      }
    }, { forceVisible: true });
  };

  const fileExtension = path.split('.').pop().toLowerCase();
  switch (fileExtension) {
    case 'js':
      console.log('JS Files are no longer supported');
      break;
    case 'ctm':
      CTMLoader(path, callback);
      break;
    case 'obj':
      console.log('OBJ is Unsupported');
      break;
    default:
      console.error('Unsupported file format' + fileExtension);
  }
};

function handleControlsMode (e) {
  switch (e.keyCode) {
    case 69:
    case 101:
      controlsMode = 'scale';
      break;
    case 82:
    case 114:
      controlsMode = 'rotate';
      break;
    case 87:
    case 119:
      controlsMode = 'translate';
      break;
  }

  for (var key in modelTransformControls) {
    if (!modelTransformControls.hasOwnProperty(key)) continue;
    modelTransformControls[key].control.setMode(controlsMode);
    if (controlsMode === 'rotate') {
      // control.showZ = false;
      modelTransformControls[key].control.setRotationSnap(THREE.Math.degToRad(5));
    }
  }

  store.requestRender();
}

models.public.resetScale = function resetScale (uuid) {
  const tempModel = sceneObjectKeys[uuid];
  if (!tempModel) return;
  tempModel.scale.set(store.globalscale, store.globalscale, store.globalscale);
  modelHasUpdated();
  store.requestRender();
}

models.public.resetRotation = function resetRotation (uuid) {
  const tempModel = sceneObjectKeys[uuid];
  if (!tempModel) return;
  tempModel.rotation.set(0, 0, 0);
  modelHasUpdated();
  store.requestRender();
}

models.public.resetPosition = function resetPosition (uuid) {
  const tempModel = sceneObjectKeys[uuid];
  if (!tempModel) return;
  tempModel.position.set(store.inventumTransform.x, store.inventumTransform.y, store.inventumTransform.z);
  modelHasUpdated();
  store.requestRender();
}


models.reset = function reset () {
  sceneObjects = [];
  sceneObjectKeys = {};
  sceneObjectsOriginalID = {};
  modelCollections = [];
  modelCollectionsKeys = {};
  onModelUpdateCallbacks = [];
  modelCount = 0;
  modelsLoaded = 0;
  modelsErrored = 0;
  clipboard = '';
  materialClipboard = '';
  modelStateClipboard = {};
	lastGeneratedModelID = null;
};

export { models };
