import * as THREE from 'three';
import { store } from '../core/store.js';
import { models } from '../models/models.js';
import { views } from '../camera/views.js';
var debug = { public: {} };

debug.public.getCameraPosition = function getCameraPosition () {
  console.log(JSON.stringify(views.public.getCurrentView(), null, 2));
};

debug.public.getCameraPositionOld = function getCameraPositionOld () {
  var curXPos = 'x:' + store.controls.target.x.toFixed(2) + ',';
  var curYPos = 'y:' + store.controls.target.y.toFixed(2) + ',';
  var curZPos = 'z:' + store.controls.target.z.toFixed(2) + ',';
  var curPhi = 'phi:' + store.controls.currentRotation.phi.toFixed(2) + ',';
  var curTheta = 'theta:' + store.controls.currentRotation.theta.toFixed(2) + ',';
  var curDistance = 'distance:' + store.controls.currentDistance.distance.toFixed(2) + ',';
  var curDelay = 'delay:0';
  console.log('==Position for Initial Camera Position==');
  console.log(store.camera.position);
  console.log('==Position for Initial Target Position==');
  console.log(store.controls.target);
  console.log('==Position for Animation Key==');
  var cameraTemp = curXPos + curYPos + curZPos + curPhi + curTheta + curDistance + curDelay;
  console.log(cameraTemp);
  return cameraTemp;
};

debug.public.createCameraView = function createCameraView (viewName) {
  views.public.createView(viewName);
  console.log('Camera View Created');
};

debug.public.convertOldCamera = function createOldCamrea (cameraObj) {
  const tempObject = {
    id: 1,
    name: 'Untitled View',
    position: {
      x: cameraObj.x,
      y: cameraObj.y,
      z: cameraObj.z
    },
    phi: cameraObj.phi,
    theta: cameraObj.theta,
    distance: cameraObj.distance,
    visibleMenu: false
  };
  console.log(JSON.stringify(tempObject, null, 2));
};

debug.public.getVisibleModels = function getVisibleModels () {
  const modelsVisible = models.getVisible();
  console.info('"models":' + JSON.stringify(modelsVisible));
};

debug.public.drawMarker = function drawMarker (position) {
  var geometry = new THREE.BoxGeometry(1, 1, 1);
  var material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
  var cube = new THREE.Mesh(geometry, material);
  cube.position.set(position.x, position.y, position.z);
  store.scene.add(cube);
};

export { debug };
