import React, { useState } from 'react';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import Draggable from 'react-draggable';
import { animations } from '../../../../engine/modules/camera/animations';

const DragHandle = SortableHandle(() => <div className="KeyframeDragStyle"><i className="material-icons notranslate">drag_indicator</i></div>);


function Keyframe(props) {
  let handleClick = () => {
    Inventum.animations.showKeyframe(props.data.id);
    //props.toggleGallery(false);
  }

  let handleDelete = () => {
    Inventum.animations.deleteKeyframe(props.data.id);
  }
  return (
    <div className={"KeyframePreview" + (props.data.enabled ? "" : " disabled") + (props.isActive ? " active" : "") + (props.editing ? " editing" : "")} onClick={handleClick}>
      {props.editing ? <DragHandle /> : null}
      <KeyframeThumbnail id={props.data.id} flightDuration={props.data.flightDuration} editing={props.editing} keyframeCount={props.keyframeCount} keyframeIndex={props.keyframeIndex} path={props.data.thumbnail} base64Thumb={props.data.thumbnailBase64} delay={props.data.delay}/>
      <div style={{width:"90%", marginRight:"20px"}}>
        <KeyframeDescription editing={props.editing} keyframeIndex={props.keyframeIndex} description={props.data.description} name={props.data.name}/>
      </div>
      {props.editing ? <KeyframeEditButtonPanel id={props.data.id} /> : null}
      {props.editing ? <div title="Delete Action" onClick={handleDelete} className="KeyframeDeleteButton"><i className="material-icons notranslate">delete</i></div> : null}
    </div>
  )
}

function KeyframeEditButtonPanel (props) {
  let handleCaption = () => {
    Inventum.animations.showKeyframe(props.id, {durationOveride:1});
    //We run the next bit of code in setTimeout to make sure the keyframe has been rendered correctly.
    setTimeout(()=>{
      Inventum.slides.createCaption();
      //Inventum.labels.createCaption();
      Inventum.animations.setScenestate();
    }, 10);
  }

  let handleClone = () => {
    Inventum.animations.cloneKeyframe(props.id);
  }


  return (
    <div className="KeyframeEditButtonPanel">
      <div className="EditActionButton" title="Create a new caption and records it" onClick={handleCaption}>Create Caption</div>
      <div className="EditActionButton" title="Clones Action" onClick={handleClone}>Clone Action</div>
    </div>
  );
}


function KeyframeThumbnail(props) {
  let path = "data/thumbnails/placeholder.png";
  if (props.base64Thumb) {
    path = props.base64Thumb;
  }else if (props.path) {
    path = props.path;
  }

  let style = {
    backgroundImage: "url(" + path + ")"
  }

  let handleDelayClick = (e) => {
    if (!props.editing) return;
    e.stopPropagation();
    Inventum.animations.changeDelay(props.keyframeIndex - 1);
  }

  let handleFlightTime = (e) => {
    Inventum.animations.setFlightDuration(props.id);
    e.stopPropagation();
  }

  return (
    <div className="KeyframeThumbnail" style={style}>
      <div className="KeyframeInfo">
        <div className="KeyframeCounter">{props.keyframeIndex} / {props.keyframeCount}</div>
        {props.editing ? <div style={{fontSize:"12px", paddingRight:"5px"}} title={props.editing ? "Click to change camera flight" : "Action duration"} className={"KeyframeDelay" + (props.editing ? ' editable' : '')} onClick={handleFlightTime}>FL: {props.flightDuration / 1000}.</div> : null}
        <div style={{fontSize:"12px"}} title={props.editing ? "Click to change action duration" : "Action duration"} className={"KeyframeDelay" + (props.editing ? ' editable' : '')} onClick={handleDelayClick}>{props.editing ? "DL" : "Delay"}: {props.delay / 1000}.</div>
      </div>
    </div>
  )
}

function CreateNewKeyframe(props) {
  let handleClick = (e) => {
    e.stopPropagation();
    Inventum.animations.createKeyframe();
  }
  return (
    <div className={"CreateKeyframe"} onClick={handleClick}>
      Create Action
    </div>
  )
}

function KeyframeDescription(props) {
  let handleNameClick = (e) => {
    if (!props.editing) return;
    e.stopPropagation();
    Inventum.animations.changeName(props.keyframeIndex - 1);
  }

  /*let handleDescriptionCLick = (e) => {
    e.stopPropagation();
    Inventum.animations.changeDescription(props.keyframeIndex - 1);
  }*/

  return (
    <div className="KeyframeDescription" style={{width:"100%"}}>
      <div title={props.editing ? "Click to change action title" : "Animation Title"} className={"KeyframeName" + (props.editing ? " editable" : "")} onClick={handleNameClick}>{props.name}</div>
      {/*<div title="Click to change action description" onClick={handleDescriptionCLick}>
        {props.description || "No Description Provided"}
      </div>*/}
    </div>
  )
}

const KeyframeContainer = SortableContainer((props) => {
  return (
    <div className="KeyframeContainer">
        {props.keyframes.map((keyData, index) => <KeyframeSortable disabled={!props.editing} editing={props.editing} data={keyData} key={index} index={index} keyframeCount={props.keyframes.length} keyframeIndex={index + 1} isActive={index === props.currentKeyframe-1} toggleGallery={props.toggleGallery} />)}
        {props.editing ? <CreateNewKeyframe /> : null}
    </div>
  )
});

const KeyframeSortable = SortableElement((props) =>
  <Keyframe {...props}/>
);


function GalleryHeader(props) {
  return (
    <div className="GalleryHeader">
      <GalleryTitle galleryMinimized={props.galleryMinimized} editing={props.editing} animationName={props.animationName} />
      <WindowControls galleryMinimized={props.galleryMinimized} toggleGalleryMin={props.toggleGalleryMin} toggleGallery={props.toggleGallery} />
    </div>
  )
}

function WindowControls(props) {
  return (
    <div className="windowControlsContainer">
      <GalleryMinimize galleryMinimized={props.galleryMinimized} toggleGalleryMin={props.toggleGalleryMin} />
      <GalleryClose toggleGallery={props.toggleGallery} />
    </div>
  )
}

function EditAnimationHeader(props) {
  return (
    <div className="EditHeader">
      {props.editing ? <EditAnimationControls {...props} /> : null}
    </div>
  )
}

function EditActionHeader(props) {
  return (
    <div className="EditHeader">
      {props.editing ? <EditActionControls {...props} /> : null}
    </div>
  )
}

function EditAnimationControls (props) {
  let delAnimClick = (e) => {
    e.stopPropagation();
    Inventum.animations.deleteAnimation();
  }

  let cloneAnimationClick = (e) => {
    e.stopPropagation();
    Inventum.animations.cloneAnimation();
  }

  let setTourClick = (e) => {
    if (props.isTour) {
      return
    }
    e.stopPropagation();
    Inventum.animations.setTour();
  }

  return (
    <div className="EditControls">
      <div title="Change Project Tour" className={"delbutton" + (props.isTour ? " disabled" : "")} onClick={setTourClick}>{props.isTour ? "Current Project Tour" : "Set Project Tour" }</div>
      <div title="Clone Animation" className="delbutton" onClick={cloneAnimationClick}>Clone Anim.</div>
      <div title="Deletes the animation. Warning can't be undone!" className="delbutton" onClick={delAnimClick}>Delete Anim.</div>
      <div title="Toggle camera lock.&#10;Unlocked means the camera won't move during the animation. Useful for scheduling.&#10;Defaults to locked." className="delbutton" onClick={props.toggleCameraLock}>Camera {props.cameraLocked ? 'Locked' : 'Unlocked'}</div>
    </div>
  )
}

function EditActionControls(props) {
  const [include3D, setInclude3D] = useState(true);

  let cameraClick = (e) => {
    e.stopPropagation();
    Inventum.animations.setCamera();
  }

  let objectsClick = (e) => {
    e.stopPropagation();
    Inventum.animations.setScenestate();
  }

  let createAnimation = (e) => {
    e.stopPropagation();
    Inventum.animations.createAnimation();
  }

  let updateAllClick = (e) => {
    e.stopPropagation();
    Inventum.animations.updateKeyframe();
  }

  let handleCopyView = (e) => {
    e.stopPropagation();
    Inventum.animations.storeCameraClipboard();
  }

  let handlePasteView = (e) => {
    e.stopPropagation();
    Inventum.animations.setCameraFromClipboard();
  }

  let handleCopySceneState = (e) => {
    e.stopPropagation();
    Inventum.animations.storeScenestateClipboard();
  }

  let handlePasteSceneState = (e) => {
    e.stopPropagation();
    Inventum.animations.setScenestateFromClipboard();
  }

  const toggleInclude3D = () => setInclude3D(!include3D);

  let handleFinishIn3DClick = (e) => {
    e.stopPropagation();
    toggleInclude3D();
    animations.finishIn3D = !animations.finishIn3D;
  };


  let buttonCollection = {
    display: "flex",
    border: "2px solid white",
    borderRadius: "4px",
    marginLeft:"5px"
  }

  return (
    <div className="EditControls" style={{ flexFlow: "wrap" }}>
      <div style={buttonCollection}>
        <div
          className="galleryEditButton"
          style={{ borderRight: "1px solid #FFF" }}
          title="Sets the current camera position for the active action (highlighted green)"
          onClick={cameraClick}>
          Set View
        </div>
        <div className="galleryEditButton" style={{ borderRight: "1px solid #FFF" }} title="Copies current view" onClick={handleCopyView}>
          Copy
        </div>
        <div className="galleryEditButton" title="Pastes previously copied view" onClick={handlePasteView}>
          Paste
        </div>
      </div>
      <div style={buttonCollection}>
        <div
          className="galleryEditButton"
          style={{ borderRight: "1px solid #FFF" }}
          title="Sets the objects in the scenestate for the active action (highlighted green)"
          onClick={objectsClick}>
          Set State
        </div>
        <div
          className="galleryEditButton"
          style={{ borderRight: "1px solid #FFF" }}
          title="Copies current scenestate"
          onClick={handleCopySceneState}>
          Copy
        </div>
        <div className="galleryEditButton" title="Pastes previously copied scenestate" onClick={handlePasteSceneState}>
          Paste
        </div>
      </div>
      <div style={buttonCollection}>
        <div title="Updates Both Camera and Scene State (highlighted red)" className="galleryEditButton" onClick={updateAllClick}>
          Set Both
        </div>
      </div>
      <div style={buttonCollection}>
        <div
          title="Whilst enabled, the Finish in 3D option will place you in the 3D world after your final animation tour slide."
          className={include3D ? "galleryEditButton Enabled" : "galleryEditButton Disabled"}
          onClick={handleFinishIn3DClick}>
          Finish 3D: {include3D ? "On" : "Off"}
        </div>
      </div>
    </div>
  );
}



function GalleryTitle(props) {
  let handleClick = () => {
    if (props.editing && !props.galleryMinimized) {
      Inventum.animations.changeAnimationName();
    }
  }
  return (
    <div className={"GalleryTitle" + (props.galleryMinimized ? " minimized" : "")} onClick={handleClick}>{props.animationName}</div>
  )
}

function GalleryClose(props) {
  const handleClose = () => {
    props.toggleGallery(false);
  };

  const handleTouchStart = () => {
    handleClose();
  };

  return (
    <div className="titlebarCloseButton" onClick={handleClose} onTouchStart={handleTouchStart}>
      <i className="material-icons notranslate">close</i>
    </div>
  );
}


function GalleryMinimize(props) {
  const handleClose = () => {
    props.toggleGalleryMin();
  }

  const handleTouchStart = () => {
    handleClose();
  };
  return (
    <div className="titlebarMinMaxButton" onClick={handleClose} onTouchStart={handleTouchStart}>
      <i className="material-icons notranslate">{props.galleryMinimized ? "crop_square" : "remove"}</i>
    </div>
  )
}



class GalleryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyframes: [],
    }
    this.updateAnimationState = this.updateAnimationState.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.registerHandlers = this.registerHandlers.bind(this);
  }

  handleSort(data) {
    Inventum.animations.reorderKeyframes(data.oldIndex, data.newIndex);
  }

  componentDidMount() {
    this.registerHandlers();
  }

  //NOTE: React Update (componentWillReceiveProps)
  componentDidUpdate(nextProps) {
    if (this.props.sceneKey !== nextProps.sceneKey) {
      this.registerHandlers();
    }
  }

  registerHandlers() {
    Inventum.animations.registerStateCallback(this.updateAnimationState);
    let x = Inventum.animations.getState();
    this.updateAnimationState(x);
  }

  updateAnimationState(animationState) {
    this.setState(animationState);
  }

  render() {
    let showEditor = false;
    if (this.props.siteAdmin && this.props.reviewMode && this.state.name !== "No Animation Selected") {
      showEditor = true;
    }

    if (this.props.galleryMinimized) {
      return (
        <Draggable handle=".GalleryHeader">
        <div className={"GalleryContainer" + (this.props.galleryVisible ? " visible" : "") + (this.props.galleryMinimized ? " minimized" : "")}>
          <GalleryHeader galleryMinimized={this.props.galleryMinimized} editing={showEditor} toggleGalleryMin={this.props.toggleGalleryMin} toggleGallery={this.props.toggleGallery} animationName={this.state.name} />
        </div>
        </Draggable>
      )
    }

		const toggleCameraLock = () => {
			Inventum.animations.toggleCameraLock();
			this.setState({ cameraLocked: !this.state.cameraLocked });
		};

    return (
      <Draggable handle=".GalleryHeader">
        <div
          className={
            "GalleryContainer" + (this.props.galleryVisible ? " visible" : "") + (this.props.galleryMinimized ? " minimized" : "")
          }>
          <GalleryHeader
            galleryMinimized={this.props.galleryMinimized}
            editing={showEditor}
            toggleGalleryMin={this.props.toggleGalleryMin}
            toggleGallery={this.props.toggleGallery}
            animationName={this.state.name}
          />
          {showEditor ? (
            <EditAnimationHeader
              toggleCameraLock={toggleCameraLock}
              cameraLocked={this.state.cameraLocked}
              isTour={this.state.isTour}
              reviewMode={this.props.reviewMode}
              editing={showEditor}
              toggleGallery={this.props.toggleGallery}
              animationName={this.state.name}
            />
          ) : null}
          {showEditor ? (
            <EditActionHeader
              editing={showEditor}
              toggleGallery={this.props.toggleGallery}
              animationName={this.state.name}
              keyframes={this.state.keyframes}
            />
          ) : null}
          <KeyframeContainer
            useDragHandle={true}
            lockAxis={"y"}
            onSortEnd={this.handleSort}
            distance={5}
            helperClass="sortableHelper"
            keyframes={this.state.keyframes}
            editing={showEditor}
            toggleGallery={this.props.toggleGallery}
            currentKeyframe={this.state.currentKeyframe}
          />
        </div>
      </Draggable>
    );
  }

}

export default GalleryContainer;
