import {
  SET_TITLE,
  SET_SUBTITLE,
  SET_WEBSITE,
  SET_LOGO,
  RESET_TITLES,
  SET_LAYER_CONTENT_MODE,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  TOGGLE_SIDEBAR
} from '../actions/sidebarUI.js';

const DEFAULT_TITLE = "";
const DEFAULT_SUBTITLE = "";

function sidebarUI(state = {title:DEFAULT_TITLE,subtitle:DEFAULT_SUBTITLE,mode:"3D",visible:false,hasOpened:false}, action) {
  switch (action.type) {
    case SET_TITLE:
      return Object.assign({},state,{title:action.data});
    case SET_SUBTITLE:
      if (!action.data) {
        return Object.assign({},state,{subtitle:DEFAULT_SUBTITLE});
      }
      return Object.assign({},state,{subtitle:action.data});
    case SET_WEBSITE:
      return Object.assign({},state,{website:action.data});
    case SET_LOGO:
      return Object.assign({},state,{logo:action.data});
    case SET_LAYER_CONTENT_MODE:
      if (action.data !== "2D" && action.data !== "3D"){return state};
      return Object.assign({},state,{mode:action.data});
    case RESET_TITLES:
      return Object.assign({},state,{title:DEFAULT_TITLE,subtitle:DEFAULT_SUBTITLE,logo:null});
    case OPEN_SIDEBAR:
      return Object.assign({},state,{visible:true,hasOpened:true});
    case CLOSE_SIDEBAR:
      return Object.assign({},state,{visible:false});
    case TOGGLE_SIDEBAR:
      if (typeof(action.data) === "boolean") {
        return Object.assign({},state,{visible:action.data,hasOpened:true});
      }
      return Object.assign({},state,{visible:!state.visible,hasOpened:true});
    default:
      return state
  }
}

export default sidebarUI;
