import {
  LOAD_COMPANY_MAP,
  CLEAR_COMPANY_MAP
} from '../actions/companyMapDefaultState.js';

function companyMapDefaultState(state = {}, action) {
  switch (action.type) {
    case LOAD_COMPANY_MAP:
      return Object.assign({},state,action.data)
    case CLEAR_COMPANY_MAP:
      return {}
    default:
      return state
  }
}

export default companyMapDefaultState;
