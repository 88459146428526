import * as THREE from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { store } from '../core/store.js';

// Lights in Scene
let lights = [];

// Editor Objects
let activeShadowHelper = {};
let activeLight = {};
let controls = [];
let lightHelpers = [];

class LightManager {
  reset () {
    lights = [];
    activeShadowHelper = {};
    activeLight = {};
    controls = [];
    lightHelpers = [];
  }

  // Helper function for creating ambient lighting for the world. Optional
  createAmbient () {
    var hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.5);
    hemiLight.name = 'Ambient Light';
    hemiLight.color.setHSL(0.6, 1, 0.6);

    hemiLight.groundColor.setHSL(0.095, 1, 0.75);

    hemiLight.position.set(0, 0, 0);
    store.scene.add(hemiLight);

    var bouncedLight = new THREE.DirectionalLight(0xffffff, 0.2);
    bouncedLight.name = 'Bounced Light';
    bouncedLight.position.set(0, -50, 0);
    bouncedLight.target.position.set(0, 0, 0);
    store.scene.add(bouncedLight.target);
    store.scene.add(bouncedLight);

    lights.push(bouncedLight);
    lights.push(hemiLight);
    store.requestRender();
    store.setUnsavedScene();
  }

  modifyTransform (deltaTransform) {
    lights.map((light) => {
      if (light.type !== 'HemisphereLight') {
        light.position.add(deltaTransform);
        if (light.target) {
          light.target.position.add(deltaTransform);
        }
      }
    });
    store.setUnsavedScene();
  }

  modifyScale (scale) {
    const oldScale = store.globalscale;
    lights.map((light) => {
      if (light.type !== 'HemisphereLight') {
        light.position.sub(store.inventumTransform);
        light.position.divideScalar(oldScale);
        light.position.multiplyScalar(scale);
        light.position.add(store.inventumTransform);

        if (light.target) {
          light.target.position.sub(store.inventumTransform);
          light.target.position.divideScalar(oldScale);
          light.target.position.multiplyScalar(scale);
          light.target.position.add(store.inventumTransform);
        }

        if (Object.prototype.hasOwnProperty.call(light, 'castShadow')) {
          light.shadow.camera.left /= oldScale;
          light.shadow.camera.right /= oldScale;
          light.shadow.camera.top /= oldScale;
          light.shadow.camera.bottom /= oldScale;

          light.shadow.camera.left *= scale;
          light.shadow.camera.right *= scale;
          light.shadow.camera.top *= scale;
          light.shadow.camera.bottom *= scale;
        }
      }
    });
    store.setUnsavedScene();
  }

  create (lightData) {
    let tempLight = {};
    switch (lightData.type) {
      case 'DirectionalLight':
        tempLight = new THREE.DirectionalLight();
        break;
      case 'SpotLight':
        tempLight = new THREE.SpotLight();
        break;
      case 'HemisphereLight':
        tempLight = new THREE.HemisphereLight();
    }

    if (tempLight === undefined) {
      console.log('Invalid Light type');
      return;
    }

    tempLight.name = lightData.name || 'Light';

    tempLight.intensity = lightData.intensity;
    tempLight.color.setStyle(lightData.color);
    if (lightData.type === 'HemisphereLight') {
      if (lightData.groundColor !== undefined) {
        tempLight.groundColor.setStyle(lightData.groundColor);
      }
    }

    tempLight.position.set(parseFloat(lightData.position.x), parseFloat(lightData.position.y), parseFloat(lightData.position.z));
    if (lightData.target !== undefined) {
      tempLight.target.position.set(parseFloat(lightData.target.x), parseFloat(lightData.target.y), parseFloat(lightData.target.z));
    }

    if (lightData.castShadow !== undefined) {
      tempLight.castShadow = lightData.castShadow;
    }

    if (tempLight.castShadow !== undefined && Object.prototype.hasOwnProperty.call(tempLight, 'shadow')) {
      tempLight.shadow.mapSize.width = 2048;
      tempLight.shadow.mapSize.height = 2048;
      tempLight.shadow.bias = isNaN(lightData.bias) ? -0.0001 : lightData.bias; // 0.0001; -0.001;
      lightData.cameraSize = isNaN(lightData.cameraSize) ? 20 : lightData.cameraSize;
      tempLight.shadow.camera.left = -lightData.cameraSize;
      tempLight.shadow.camera.right = lightData.cameraSize;
      tempLight.shadow.camera.top = lightData.cameraSize;
      tempLight.shadow.camera.bottom = -lightData.cameraSize;
      if (lightData.cameraNear) { tempLight.shadow.camera.near = lightData.cameraNear; } else { tempLight.shadow.camera.near = 0.5; }// Preserve legacy versions default value
      if (lightData.cameraFar) {
        tempLight.shadow.camera.far = lightData.cameraFar;
      }
    }
    store.scene.add(tempLight);
    if (tempLight.target) { store.scene.add(tempLight.target); }
    lights.push(tempLight);
    store.requestRender();
  }

  getAll () {
    const lightData = [];
    lights.map((light) => {
      let isActive = false;
      if (activeLight !== undefined) {
        if (light.id === activeLight.id) {
          isActive = true;
        }
      }
      const tempData = {
        name: light.name,
        intensity: light.intensity,
        type: light.type,
        castShadow: light.castShadow,
        id: light.id,
        active: isActive,
        cameraSize: null
      };
      if (light.shadow !== undefined) {
        const cameraSize = Math.abs(light.shadow.camera.right);
        tempData.cameraSize = cameraSize;
        tempData.bias = light.shadow.bias;
        tempData.cameraFar = light.shadow.camera.far;
      }
      lightData.push(tempData);
    });
    return lightData;
  }

  generateJSON () {
    const jsonCollection = [];
    let jsonObj = {};

    lights.map((light) => {
      jsonObj.type = light.type;
      jsonObj.name = light.name;
      jsonObj.color = '#' + light.color.getHexString();

      if (light.groundColor) {
        jsonObj.groundColor = '#' + light.groundColor.getHexString();
      }

      jsonObj.intensity = light.intensity;
      jsonObj.position = {
        x: Math.round(light.position.x * 1e2) / 1e2,
        y: Math.round(light.position.y * 1e2) / 1e2,
        z: Math.round(light.position.z * 1e2) / 1e2
      };

      if (light.target) {
        jsonObj.target = {
          x: Math.round(light.target.position.x * 1e2) / 1e2,
          y: Math.round(light.target.position.y * 1e2) / 1e2,
          z: Math.round(light.target.position.z * 1e2) / 1e2
        };
      }

      if (light.type !== 'HemisphereLight') {
        jsonObj.castShadow = light.castShadow;
      }

      if (light.castShadow) {
        jsonObj.cameraSize = Math.abs(light.shadow.camera.left);
        jsonObj.cameraFar = light.shadow.camera.far;
        jsonObj.cameraNear = light.shadow.camera.near;
        jsonObj.bias = light.shadow.bias;
      }
      jsonCollection.push(jsonObj);
      jsonObj = {};
    });
    return { lights: jsonCollection };
  }

  setActive (lightID) {
    let lightFound = false;
    if (activeLight.id === lightID) {
      activeLight = {};
      removeLightEditor();
      removeHelpers();
      return;
    }

    lights.map((light) => {
      if (light.id === lightID) {
        if (Object.keys(activeLight).length === 0) {
          generateHelpers();
        }
        activeLight = light;
        lightFound = true;
      }
    });

    if (lightFound) {
      removeLightEditor();
      generateLightEditor();
    }
  }

  setIntensity (lightID, value) {
    if (isNaN(value)) return;
    let lightIndex = -1;
    lights.map((light, index) => {
      if (light.id === lightID) {
        lightIndex = index;
      }
    });
    // Light wasn't found.
    if (lightIndex === -1) { return; }
    lights[lightIndex].intensity = parseFloat(value);
    store.requestRender();
    store.setUnsavedScene();
  }

  toggleShadow (lightID) {
    let tIndex = -1;
    lights.map((light, index) => {
      if (light.id === lightID) {
        if (Object.prototype.hasOwnProperty.call(light, 'castShadow')) {
          tIndex = index;
          light.castShadow = !light.castShadow;
        }
      }
    });

    if (tIndex !== -1) {
      if (activeLight.id === lights[tIndex].id) {
        removeLightEditor();
        generateLightEditor();
      }
    }
    store.requestRender();
    store.setUnsavedScene();
  }

  setCameraSize (lightID, value) {
    if (isNaN(value)) return;
    let lightIndex = -1;
    var tSize = 0;
    lights.map((light, index) => {
      if (light.id === lightID) {
        if (Object.prototype.hasOwnProperty.call(light, 'castShadow')) {
          lightIndex = index;
          tSize = Math.abs(light.shadow.camera.left);
        }
      }
    });
    // Not Found
    if (lightIndex === -1) {
      return;
    }

    lights[lightIndex].shadow.camera.left = -parseFloat(value);
    lights[lightIndex].shadow.camera.right = parseFloat(value);
    lights[lightIndex].shadow.camera.top = parseFloat(value);
    lights[lightIndex].shadow.camera.bottom = -parseFloat(value);

    if (activeLight.id === lights[lightIndex].id) {
      removeLightEditor();
      generateLightEditor();
    }

    store.requestRender();
    store.setUnsavedScene();
  }

  setFar (lightID, value) {
    if (isNaN(value)) return;
    let lightIndex = -1;
    lights.map((light, index) => {
      if (light.id === lightID) {
        if (Object.prototype.hasOwnProperty.call(light, 'castShadow')) {
          lightIndex = index;
        }
      }
    });
    // Not Found
    if (lightIndex === -1) {
      return;
    }

    const light = lights[lightIndex];

    light.shadow.camera.far = parseFloat(value);

    if (activeLight.id === lights[lightIndex].id) {
      removeLightEditor();
      generateLightEditor();
    }
    store.requestRender();
    store.setUnsavedScene();
  }

  setBias (lightID, value) {
    if (isNaN(value));
    let lightIndex = -1;
    lights.map((light, index) => {
      if (light.id === lightID) {
        if (Object.prototype.hasOwnProperty.call(light, 'castShadow')) {
          lightIndex = index;
        }
      }
    });
    // Not Found
    if (lightIndex === -1) {
      return;
    }

    lights[lightIndex].shadow.bias = parseFloat(value);
    store.requestRender();
    store.setUnsavedScene();
  }

  setName (lightID, text) {
    let lightIndex = -1;
    lights.map((light, index) => {
      if (light.id === lightID) {
        lightIndex = index;
      }
    });
    // Not Found
    if (lightIndex === -1) {
      return;
    }

		if (text === undefined) {
			text = window.prompt('Light Name:', lights[lightIndex].name);
			if (text === null || text === '') {
				return;
			}
		}

    lights[lightIndex].name = text;
    store.setUnsavedScene();
  }

  delete (lightID) {
    const confirm = window.confirm('Delete Light?');
    if (!confirm) return;

    if (activeLight.id) {
      removeLightEditor();
      removeHelpers();
    }

    let lightIndex = -1;
    lights.map((light, index) => {
      if (light.id === lightID) {
        lightIndex = index;
        store.scene.remove(light);
        if (light.target) {
          store.scene.remove(light.target);
        }
      }
    });

    if (lightIndex === -1) {
      return;
    }
    lights.splice(lightIndex, 1);
    store.requestRender();
    store.setUnsavedScene();
  }

  getInfo () {
    return activeLight;
  }
}

// Generates all the helpers for the light currently being edited/selected
function generateLightEditor () {
  generateShadowHelper(activeLight);
  generateControls(activeLight);
  generateControls(activeLight.target);
}

// Removes the helpers for the editor light
function removeLightEditor () {
  controls.map((control) => {
    control.detach();
    store.scene.remove(control);
  });
  controls = [];
  store.scene.remove(activeShadowHelper);
  store.requestRender();
}

// Generates the light helper model
function generateLightHelper (light) {
  let tLightHelper = {};
  switch (light.type) {
    case 'DirectionalLight':
      tLightHelper = new THREE.DirectionalLightHelper(light, 5);
      break;
    case 'SpotLight':
      tLightHelper = new THREE.SpotLightHelper(light);
      break;
    case 'HemisphereLight':
      tLightHelper = new THREE.HemisphereLightHelper(light);
      break;
  }
  return tLightHelper;
}

function removeHelpers () {
  lightHelpers.map((helper) => {
    store.scene.remove(helper);
  });
}

function generateHelpers () {
  lights.map((light) => {
    var tHelper = generateLightHelper(light);
    store.scene.add(tHelper);
    lightHelpers.push(tHelper);
  });
}

// generates the shadow helper model
function generateShadowHelper (light) {
  if (light.castShadow) {
    activeShadowHelper = new THREE.CameraHelper(light.shadow.camera);
    store.scene.add(activeShadowHelper);
  }
}

// generates the controls model
function generateControls (light) {
  const control = new TransformControls(store.camera, store.webglRenderer.domElement);
  control.addEventListener('change', (e) => {
    lightHelpers.map((lh) => { lh.update(); store.requestRender(); });
  });
  control.addEventListener('dragging-changed', function (event) {
    store.controls.enabled = !event.value;
    store.requestRender();
  });

  control.attach(light);
  store.scene.add(control);
  controls.push(control);
  store.requestRender();
}

const LM = new LightManager();

export { LM as lights };
