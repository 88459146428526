import React from 'react';

class MeetMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meetingCode:"",
    };
  }

  render() {
    const handleInput = (e) => {
      let val = e.target.value;
      if (val.length > 11) {
        return;
      }
      if (val.length == 0) {
        this.setState({meetingCode:""})
        return
      }
      let num = val.replace(/-/g,"");
      let isnum = /^\d+$/.test(num);
      if (!isnum && num.length > 0) {
        console.log('Contains Letters');
        return;
      }
      this.setState({meetingCode:val.match(/\d{3}(?=\d{1,3})|\d+/g).join("-")})
    }

    const handleSubmit = () => {
      if (this.state.meetingCode.length !== 11) return;
      let code = this.state.meetingCode.replace(/-/g,"");
      window.location.href = `https://inventum3d.com/meet/${code}`;
    }

    return (
      <div className="MeetingViewerContainer connecting">
        <div className="ConnectionUIBox">
          <div className="MeetingPasswordTitle">Join an Inventum3D Meeting</div>
          <div className="MeetingPasswordInfo">
          Meetings are a way to live share an Inventum3D project.<br/><br/>
          Enter a meeting code to connect
          </div>
          <div className="MeetingPasswordInputContainer">
            <span>Meeting Code (9 digits)</span>
            <input placeholder="123-456-789" type="text" value={this.state.meetingCode} onChange={handleInput} />
          </div>
          <div className="PasswordSubmitButtonsContainer">
            <div onClick={()=>{window.location.href="/"}}className="MeetingPasswordButton PasswordCancelButton">Cancel</div>
            <div onClick={handleSubmit} className={"MeetingPasswordButton" + (this.state.meetingCode.length == 11 ? "" : " MeetingButtonDisabled")}>Join</div>
          </div>
        </div>
      </div>
    )
  }
}

export default MeetMenu
