import axios from 'axios';
import store from './Redux/store.js';

var axiosInstance = axios.create({
  baseURL:'https://api.inventum3d.com'
})

/*function setAuthTokenHeader() {
  axiosInstance.defaults.headers.common['Authorization'] = store.getState().user.token;
}

const unsubscribe = store.subscribe(setAuthTokenHeader);*/

axiosInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axiosInstance.interceptors.request.use(config => {
  let token = localStorage.getItem("inventumToken")
  if (token !== null) {
    config.headers = Object.assign({
      Authorization: token
    }, config.headers)
  }
  return config
});


export default axiosInstance;
