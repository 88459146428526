import { store } from '../core/store.js';
import { sceneStates } from '../tools/sceneStates.js';
import { Animation } from './AnimationObject.js';
import { screenshot } from '../tools/screenshot.js';
import { views } from './views.js';
import { slides } from '../slides/slides.js';
import { notifications } from '../ui/notifications.js';
import { world } from '../world/world.js';

var controller = { public: {} };
var animationsByID = {};
var onKeyframeUpdate = [];
var scenestateClipboard = null;
var cameraViewClipboard = null;

// An object that contains the animations current state.
// A modified version of this gets sent to interested parties via getState(); which strips out internal information

var state = {
  animation: null,
  keyframes: [],
  animationPosition: null,
  loop: false,
  controlsVisible: false,
  playing: false,
  currentDelayTimeout: null,
  hasStarted: false,
  updateInProcess: false
};

// Used Internally for generating the Animation Object
controller.createAnimation = function (options) {
  if (options === undefined) {
    options = {};
  }
  if (options.id === undefined) {
    console.log('Missing Animation ID. Generating one...');
    let idFound = false;
    let idCounter = 1;
    while (idFound === false) {
      if (animationsByID[idCounter] === undefined) {
        idFound = true;
        options.id = idCounter;
        console.log('Generated Animation ID is:' + options.id);
      } else {
        idCounter++;
      }
    }
  }

  if (options.isTour === undefined) {
    options.isTour = false;
  }

  if (options.name === undefined) {
    options.name = 'Untitled Animation';
  }

  const tempAnimation = new Animation(options);

  animationsByID[options.id] = tempAnimation;
  return tempAnimation;
};

controller.getAnimationByID = function getAnimationByID (id) {
  return animationsByID[id];
};

function stateHasUpdated () {
  onKeyframeUpdate.map((callback, index) => {
    callback(controller.public.getState());
  });
  store.requestRender();
}

controller.public.getState = function getState () {
  let name = 'No Animation Selected';
  let isTour = false;
	let cameraLocked = false;
  if (state.animation !== null) {
    name = state.animation.name;
    isTour = state.animation.isTour;
		cameraLocked = state.animation.cameraLocked;
  }

  const tempKeyArray = [];
  let tempKeyData = {};
  state.keyframes.map((keyData, iterator) => {
    if (keyData !== undefined) {
      tempKeyData = {
        id: iterator,
        name: keyData.name,
        description: keyData.description,
        thumbnail: keyData.thumbnail,
        thumbnailBase64: keyData.thumbnailBase64,
        delay: keyData.delay,
        enabled: keyData.enabled,
        flightDuration: views.getViewInfo(keyData.camera).duration
      };
      tempKeyArray.push(tempKeyData);
    }
  });
  return {
    name,
    playing: state.playing,
    currentKeyframe: state.animationPosition + 1,
    keyframeCount: state.keyframes.length,
    controlsVisible: state.controlsVisible,
    keyframes: tempKeyArray,
    willLoop: state.loop,
    isTour: isTour,
		cameraLocked: cameraLocked
  };
};

function showControls (controlsVisible) {
  state.controlsVisible = controlsVisible;
  stateHasUpdated();
}

controller.finishIn3D = true;

controller.public.showControls = showControls;

controller.public.toggleLoop = function toggleLoop () {
  state.loop = !state.loop;
  stateHasUpdated();
};

function handleUpdate () {
  if (state.animationPosition < 0 || state.animationPosition >= state.keyframes.length) {
    // console.log('Keyframe out of range.');
    if (!state.loop && state.playing) {
      // console.log('Reached end of Presentation. Stopping.');
      state.animationPosition = 0;
      state.playing = false;
      if (controller.finishIn3D) {
        world.public.showVisibleStart();
        views.public.showStartView();
      } else;
      notifications.add({ content: 'Animation Has Finished' });
      notifications.add({ content: 'You Have Control', icon: '3d_rotation', displayTime: 3000 });
      stateHasUpdated();
      return;
    } else {
      // console.log('Reached end of Presentation. Looping.');
      state.animationPosition = 0;
    }
  }
  stateHasUpdated();
  const keydata = state.keyframes[state.animationPosition];
  renderKeyframe(keydata);
}

function renderKeyframe (keydata, options) {
  if (options === undefined) {
    options = {};
  }
  state.updateInProcess = true;
  // Activate Scene State
  sceneStates.public.activateSceneState(keydata.scenestate);

  // Callback function to run after the camera movement is complete.
  const onCameraComplete = () => {
    const onDelayComplete = () => {
      state.currentDelayTimeout = null;
      state.animationPosition++;
      state.updateInProcess = false;
      handleUpdate();
    };

    if (state.playing) {
      state.currentDelayTimeout = setTimeout(onDelayComplete, keydata.delay);
    } else {
      state.updateInProcess = false;
    }
  };

  const viewOptions = { ignoreSceneState: true };

  if (options.durationOveride !== undefined) {
    viewOptions.durationOveride = options.durationOveride;
  }

  if (state.animation.cameraLocked) {
    views.public.showView(keydata.camera, onCameraComplete, viewOptions);
  } else {
    onCameraComplete();
  }
}

// PUBLIC
controller.public.registerStateCallback = function registerStateCallback (callback) {
  onKeyframeUpdate.push(callback);
  callback(controller.public.getState());
};

controller.public.getAnimations = function getAnimations () {
  const tempAnimations = [];
  for (const id in animationsByID) {
    if (Object.prototype.hasOwnProperty.call(animationsByID, id)) {
      tempAnimations.push({
        name: animationsByID[id].name,
        id: animationsByID[id].id
      });
    }
  }
  return tempAnimations;
};

controller.public.selectAnimation = function selectAnimation (animationID, loadCompleteCallback) {
  const animation = animationsByID[animationID];
  if (animation === undefined) {
    console.log('Animation not found');
    return;
  }
  state.animation = animation;
  state.animationPosition = 0;
  state.keyframes = [];
  state.hasStarted = false;

  animation.keyframes.map((keyframe) => {
    state.keyframes.push(keyframe);
  });
  // Generate Thumbs
  controller.public.generateThumbnails(loadCompleteCallback);
  showControls(true);
};

controller.public.play = function startAnimation () {
  if (!inputAllowed()) {
    return;
  }

  if (!state.playing) {
    state.playing = true;
    state.hasStarted = true;
    // console.log('Starting Animation');
    if (state.animationPosition === null) { state.animationPosition = 0; }
    handleUpdate();
  } else {
    // console.log('Animation is already playing');
  }
};

controller.public.stop = function stop () {
  if (state.animation === null) {
    // console.log('Load an animation before trying to stop it');
    return;
  }
  if (state.playing) {
    // console.log('Stopping Animation');
    state.playing = false;
    state.updateInProcess = false;
    views.public.cancelMovement();
    if (state.currentDelayTimeout !== null) {
      clearTimeout(state.currentDelayTimeout);
      state.currentDelayTimeout = null;
    }
    stateHasUpdated();
  } else {
    console.log('Animation is already stopped');
  }
};

controller.public.next = function next () {
  if (!inputAllowed()) {
    return;
  }

  if (state.hasStarted) {
    state.animationPosition++;
  } else {
    state.animationPosition = 0;
    state.hasStarted = true;
  }
  handleUpdate();
};

controller.public.playTourAnimation = function playTourAnimation () {
  if (Object.keys(animationsByID).length === 0) {
    console.log('No Animations Found');
    return;
  }

  let tourID = findTourAnimation();
  if (tourID === null) {
    tourID = animationsByID[Object.keys(animationsByID)[0]].id;
    if (tourID === null) {
      console.log('No Animations Found');
      return;
    }
  }

  const onLoadComplete = () => {
    controller.public.play();
  };

  controller.public.selectAnimation(tourID, onLoadComplete);
};

controller.public.previous = function previous () {
  if (!inputAllowed()) {
    return;
  }
  //  if (state.hasStarted) {

  if (state.animationPosition !== null) {
    state.animationPosition--;
  } else {
    state.animationPosition = 0;
    state.hasStarted = true;
  }
  handleUpdate();
};

function findTourAnimation () {
  let tourID = null;
  for (var key in animationsByID) {
    if (Object.prototype.hasOwnProperty.call(animationsByID, key)) {
      if (animationsByID[key].isTour) {
        tourID = animationsByID[key].id;
        break;
      }
    }
  }
  return tourID;
}

function inputAllowed () {
  if (state.animation === null) {
    // console.log('Please load an animation');
    return false;
  }

  if (state.updateInProcess) {
    // console.log('Please wait for the current update to complete');
    return false;
  }

  return true;
}

/**
 * showKeyframe() takes a keyframeID and optionally takes a duration and an option to disable the delay
 * options = {durationOveride:500,disableDelay:true}
 */

controller.public.showKeyframe = function showKeyframe (keyframeID, options) {
  if (!inputAllowed()) {
    return;
  }
  if (keyframeID === undefined) {
    console.log('Missing KeyframeID');
    return;
  }
  const keyframeData = state.keyframes[keyframeID];
  if (keyframeData === undefined) {
    console.log('Invalid keyframe ID');
    return;
  }

  state.animationPosition = keyframeID;
  state.hasStarted = true;
  renderKeyframe(keyframeData, options);
  stateHasUpdated();
};

controller.public.restart = function restart () {
  state.hasStarted = false;
  state.animationPosition = 0;
  slides.hideAll();
  controller.public.showKeyframe(state.animationPosition, { durationOveride: 10 });
  stateHasUpdated();
};

controller.public.resetAction = function resetAction () {
  if (!isNaN(state.animationPosition)) {
    controller.public.showKeyframe(state.animationPosition, { durationOveride: 10 });
  }
};

// EDIT MODE

controller.public.setCamera = function setCamera () {
  const newID = views.public.createView();
  state.keyframes[state.animationPosition].camera = newID;
  generateThumbnail(state.keyframes[state.animationPosition]);
  notifications.add({ content: `Action ${state.animationPosition + 1} Camera Updated` });
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.storeCameraClipboard = function storeCameraClipboard () {
  cameraViewClipboard = views.public.createView(); // Stores the ID of the new view we created on the clipboard.
  notifications.add({ content: 'Copied view to Clipboard' });
};

controller.public.setCameraFromClipboard = function setCameraFromClipboard () {
  if (isNaN(cameraViewClipboard)) {
    notifications.add({ content: 'Error: Clipboard is empty!' });
    return;
  }
  const onViewChange = () => {
    generateThumbnail(state.keyframes[state.animationPosition]);
    notifications.add({ content: `Action ${state.animationPosition + 1} Camera Updated` });
    stateHasUpdated();
  };

  const newViewID = views.clone(cameraViewClipboard);
  state.keyframes[state.animationPosition].camera = newViewID;
  views.public.showView(newViewID, onViewChange, { durationOveride: 1 });
  store.setUnsavedScene();
};

controller.public.storeScenestateClipboard = function storeScenestateClipboard () {
  scenestateClipboard = sceneStates.createFromCurrentState();
  notifications.add({ content: 'Copied Scenestate to Clipboard' });
};

controller.public.setScenestateFromClipboard = function setScenestateFromClipboard () {
  if (isNaN(scenestateClipboard)) {
    notifications.add({ content: 'Error: Clipboard is empty!' });
    return;
  }
  const newScenestateID = sceneStates.clone(scenestateClipboard);
  sceneStates.public.activateSceneState(newScenestateID);
  state.keyframes[state.animationPosition].scenestate = newScenestateID;
  generateThumbnail(state.keyframes[state.animationPosition]);
  notifications.add({ content: `Action ${state.animationPosition + 1} SceneState Updated` });
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.setCameraFromID = function setCameraFromID (id) {
  // TODO Should check with views that ID exists.
  // TODO Should check if animation is active and has keyframes etc
  if (isNaN(id)) return;
  state.keyframes[state.animationPosition].camera = id;
  generateThumbnail(state.keyframes[state.animationPosition]);
  notifications.add({ content: `Action ${state.animationPosition + 1} Camera Updated` });
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.setFlightDuration = function setFlightDuration (id) {
  if (!state.keyframes[id]) {
    return;
  }
  views.setFlightDuration(state.keyframes[id].camera);
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.setScenestate = function setScenestate () {
  const newID = sceneStates.createFromCurrentState();
  state.keyframes[state.animationPosition].scenestate = newID;
  generateThumbnail(state.keyframes[state.animationPosition]);
  notifications.add({ content: `Action ${state.animationPosition + 1} SceneState Updated` });
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.setScenestateFromID = function setScenestateFromID (id) {
  if (isNaN(id)) return;
  state.keyframes[state.animationPosition].scenestate = id;
  generateThumbnail(state.keyframes[state.animationPosition]);
  notifications.add({ content: `Action ${state.animationPosition + 1} SceneState Updated` });
  stateHasUpdated();
  store.setUnsavedScene();
};

function generateKeyframeData (name) {
  const data = {
    name: name,
    description: 'New Keyframe',
    thumbnail: null,
    thumbnailBase64: null,
    camera: views.public.createView(),
    scenestate: sceneStates.createFromCurrentState(),
    delay: 3000,
    enabled: true
  };
  generateThumbnail(data);
  return (data);
}

// Create a base64 image thumbnail. Needs to be a callback due to the async method of the screenshot creation tool.
function generateThumbnail (keyframe) {
  screenshot.createThumbnail((base64Image) => {
    keyframe.thumbnailBase64 = base64Image;
    stateHasUpdated();
  });
}

controller.public.createKeyframe = function createKeyframe () {
  const keyframeName = window.prompt('Name of Action:', 'Untitled Action');
  if (keyframeName === null || keyframeName === '') {
    notifications.add({ content: 'New Action was Not Created' });
    return;
  }
  // Create the empty new Keyframe object
  const newKeyframe = generateKeyframeData(keyframeName);

  state.keyframes.push(newKeyframe);
  notifications.add({ content: 'New Keyframe Created' });
  state.animationPosition = state.keyframes.length - 1;
  state.animation.keyframes = state.keyframes;
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.deleteKeyframe = function deleteKeyframe (id) {
  state.keyframes.splice(id, 1);
  state.animation.keyframes = state.keyframes;
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.cloneKeyframe = function cloneKeyframe (id) {
  const newKeyframe = Object.assign({}, state.keyframes[id]);
  newKeyframe.name += ' (Copy)';
  state.keyframes.splice(id + 1, 0, newKeyframe);
  state.animation.keyframes = state.keyframes;
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.deleteAnimation = function deleteAnimation () {
  const userConfirmed = window.prompt("Are you sure? Type 'YES' to delete:", '');
  if (userConfirmed.toLowerCase() !== 'yes') {
    notifications.add({ content: 'Animation was Not Deleted' });
    return;
  }

  const tID = state.animation.id;
  controller.unloadAnimation();
  delete animationsByID[tID];
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.createAnimation = function createAnimation () {
  const animationName = window.prompt('Name of Animation:', 'Untitled Animation');
  if (animationName === null || animationName === '') {
    notifications.add({ content: 'New Animation was Not Created' });
    return;
  }

  const tempAnimation = controller.createAnimation({ name: animationName });
  controller.public.selectAnimation(tempAnimation.id);
  notifications.add({ content: 'New Animation Created' });
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.cloneAnimation = function cloneAnimation () {
  const clonedName = state.animation.name + ' (Copy)';
  const tAnimation = controller.createAnimation({ name: clonedName });

  const tKeyframes = [];
  state.keyframes.map((keyframe) => {
    const cKeyframe = Object.assign({}, keyframe);
    cKeyframe.scenestate = sceneStates.clone(cKeyframe.scenestate);
    cKeyframe.camera = views.clone(cKeyframe.camera);
    tKeyframes.push(cKeyframe);
  });
  tAnimation.keyframes = tKeyframes;
  notifications.add({ content: 'Animation Cloned' });
  controller.public.selectAnimation(tAnimation.id);
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.updateKeyframe = function updateKeyframe () {
  state.keyframes[state.animationPosition].scenestate = sceneStates.createFromCurrentState();
  state.keyframes[state.animationPosition].camera = views.public.createView();
  generateThumbnail(state.keyframes[state.animationPosition]);
  notifications.add({ content: 'Keyframe Updated' });
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.changeDelay = function changeDelay (actionID) {
  let actionToUpdate = state.animationPosition;
  if (Number.isInteger(actionID)) { actionToUpdate = actionID; }

  const newDelay = window.prompt('Delay (Seconds):', state.keyframes[actionToUpdate].delay / 1000);
  if (newDelay === null || newDelay === '') {
    notifications.add({ content: 'Delay was Not Updated' });
    return;
  }

  state.keyframes[actionToUpdate].delay = parseFloat(newDelay) * 1000; // Convert seconds to Milliseconds
  notifications.add({ content: 'Keyframe Updated' });
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.setTour = function setTour () {
  if (!state.animation) {
    return;
  }
  Object.keys(animationsByID).map(key => {
    animationsByID[key].isTour = false;
  });
  state.animation.isTour = true;

  notifications.add({ content: 'Project Tour Updated' });
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.changeName = function changeName (actionID) {
  let actionToUpdate = state.animationPosition;
  if (Number.isInteger(actionID)) { actionToUpdate = actionID; }

  const newName = window.prompt('Action Name:', state.keyframes[actionToUpdate].name);
  if (newName === null || newName === '') {
    notifications.add({ content: 'Name was Not Updated' });
    return;
  }

  state.keyframes[actionToUpdate].name = newName;
  notifications.add({ content: 'Keyframe Updated' });
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.changeAnimationName = function changeAnimationName () {
  if (!state.animation) {
    return;
  }

  const newName = window.prompt('Animation Name:', state.animation.name);
  if (newName === null || newName === '') {
    notifications.add({ content: 'Name was Not Updated' });
    return;
  }

  state.animation.name = newName;
  notifications.add({ content: 'Animation Updated' });
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.toggleCameraLock = function toggleCameraLock () {
  if (!state.animation) {
    return;
  }

  state.animation.cameraLocked = !state.animation.cameraLocked;
  if (state.animation.cameraLocked) {
    notifications.add({ content: 'Animation Camera Locked' });
  } else {
    notifications.add({ content: 'Animation Camera Unlocked' });
  }
  store.setUnsavedScene();
};

controller.public.changeDescription = function changeDescription (actionID) {
  let actionToUpdate = state.animationPosition;
  if (Number.isInteger(actionID)) { actionToUpdate = actionID; }

  const newDescription = window.prompt('Action Name:', state.keyframes[actionToUpdate].description);
  if (newDescription === null || newDescription === '') {
    notifications.add({ content: 'Description was Not Updated' });
    return;
  }

  state.keyframes[actionToUpdate].description = newDescription;
  notifications.add({ content: 'Keyframe Updated' });
  stateHasUpdated();
  store.setUnsavedScene();
};

controller.public.reorderKeyframes = function reorderKeyframes (oldIndex, newIndex) {
  state.keyframes.splice(newIndex, 0, state.keyframes.splice(oldIndex, 1)[0]);
  state.animation.keyframes = state.keyframes;
  stateHasUpdated();
  store.setUnsavedScene();
};

// Returns an indexed array with true where the camera ID is being used by the animations
controller.public.getCameras = function getCameras () {
  const camerasList = [];
  for (const id in animationsByID) {
    if (Object.prototype.hasOwnProperty.call(animationsByID, id)) {
      const tAnimation = animationsByID[id];
      tAnimation.keyframes.map((keyframe) => {
        camerasList[keyframe.camera] = true;
      });
    }
  }
  return camerasList;
};

// Returns an indexed array with true where the scenestate ID is being used by the animations
controller.public.getScenestates = function getScenestates () {
  const scenestatesList = [];
  for (const id in animationsByID) {
    if (Object.prototype.hasOwnProperty.call(animationsByID, id)) {
      const tAnimation = animationsByID[id];
      tAnimation.keyframes.map((keyframe) => {
        scenestatesList[keyframe.scenestate] = true;
      });
    }
  }
  return scenestatesList;
};

controller.generateJSON = function generateJSON () {
  const tAnimations = [];
  // The array that is returned need to be filled from 0 -> the length of the animations
  // animationsByID array uses the ID of the animation as the index.
  // e.g for 2 animations with the ID 2 and 4
  // animationsByID=[null,null,animation,null,animation]
  // We need to make it like this
  // tAnimations=[animation,animation]

  // We need to copy the keyframes to remove the thumbnailBase64 property as we don't want to store a bunch of jpgs in the database
  Object.keys(animationsByID).map(function (key, index) {
    var animation = animationsByID[key];
    var tKeyframes = [];
    animation.keyframes.map((keyframe) => {
      var tframe = Object.assign({}, keyframe);
      delete keyframe.id;
      delete tframe.thumbnailBase64;
      // DELETE
      // Removing relative paths only keeping absolute
      /* if (tframe.thumbnail) {
        tframe.thumbnail = tframe.thumbnail.replace(store.storagePath,'');//Changes the path from an Absolute Path to a Relative Path. The inverse of this happens in the sceneLoader.
      } */
      tKeyframes.push(tframe);
    });
    var tAnim = Object.assign({}, animation);
    tAnim.keyframes = tKeyframes;
    tAnimations.push(tAnim);
  });
  return { animations: tAnimations };
};

controller.public.generateThumbnails = function generateThumbnails (loadCompleteCallback) {
  /// / NOTE: This would be a good place to do a loader for the Animation in the future. i.e preload images and slides.
  if (state.animation === null) {
    return;
  }

  if (state.animation.keyframes.length === 0) {
    return;
  }

  if (state.animation.keyframes[0].thumbnailBase64) {
    if (typeof (loadCompleteCallback) === 'function') {
      loadCompleteCallback();
    }
    return;
  }

  if (typeof (state.animation.keyframes[0].thumbnail) === 'string') {
    if (state.animation.keyframes[0].thumbnail.startsWith('http')) {
      if (typeof (loadCompleteCallback) === 'function') {
        loadCompleteCallback();
      }
      return;
    }
  }

  // Backup the original settings
  const originalStateID = sceneStates.createFromCurrentState();
  const originalViewID = views.public.createView();
  let currentKeyframe = 0;
  const keyframesLength = state.animation.keyframes.length;

  const loadingElement = document.createElement('div');
  loadingElement.setAttribute('id', 'ThumbnailLoadingScreen');
  loadingElement.innerHTML = 'Generating Preview Thumbnails...';

  document.body.appendChild(loadingElement);
  // console.log(document.getElementsByClassName("ViewerPage"));

  // Set the render size to the thumbnail size
  const renderer = store.webglRenderer;

  // ToDo. Display some sort of loading screen while the thumbnail generation is happening

  // This is called at the end to restore everything back to defaults
  const cleanup = () => {
    const onViewReset = () => {
      loadingElement.parentNode.removeChild(loadingElement);
      stateHasUpdated();
      if (typeof (loadCompleteCallback) === 'function') {
        loadCompleteCallback();
      }
    };
    renderer.setSize(window.innerWidth, window.innerHeight);
    sceneStates.public.activateSceneState(originalStateID);
    views.public.showView(originalViewID, onViewReset, { durationOveride: 1 });
  };

  // This function is recusrively called every time a keyframe thumbnail is processed.
  const process = () => {
    const keydata = state.animation.keyframes[currentKeyframe];

    sceneStates.public.activateSceneState(keydata.scenestate, { excludeSlides: true }); // Exclude showing slides as it's useless and not captured in screenshot

    // Final function that receives the screenshot.
    const onScreenshotCallback = (base64Image) => {
      currentKeyframe++;
      keydata.thumbnailBase64 = base64Image;

      if (currentKeyframe < keyframesLength) {
        process();
      } else {
        cleanup();
      }
    };

    // Run onScreenshotCallback when the view has been updated.
    views.public.showView(keydata.camera, () => { screenshot.createAnimationThumbnail(onScreenshotCallback); }, { durationOveride: 1 });
  };

  // Renders the current image to the background as a placeholder.
  /* screenshot.renderLoadingScreen((blob)=>{
    loadingElement.style.backgroundImage = `url('${blob}')`;
    renderer.setSize(320,180);
    process();
  }) */

  // Starts the process
  renderer.setSize(320, 180);
  process();
};

controller.unloadAnimation = function unloadAnimation () {
  state = {
    animation: null,
    keyframes: [],
    animationPosition: null,
    loop: false,
    controlsVisible: false,
    playing: false,
    currentDelayTimeout: null,
    hasStarted: false,
    updateInProcess: false
  };
};

controller.reset = function reset () {
  state = {
    animation: null,
    keyframes: [],
    animationPosition: null,
    loop: false,
    controlsVisible: false,
    playing: false,
    currentDelayTimeout: null,
    hasStarted: false,
    updateInProcess: false
  };
  animationsByID = {};
  onKeyframeUpdate = [];
  scenestateClipboard = null;
  cameraViewClipboard = null;
};

export { controller as animations };
