import React from 'react';
import {connect} from 'react-redux';
import {closeModal} from '../../../Redux/actions/ui.js';

class Settings3D extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fov: 55,
      controlsInverted: false,
      videoExport: {},
      screenshotExport: {},
    };
    this.getCameraSettings = this.getCameraSettings.bind(this);
    this.setFOV = this.setFOV.bind(this);
    this.toggleControlsInversion = this.toggleControlsInversion.bind(this);
    this.resetFOV = this.resetFOV.bind(this);
  }

  componentDidMount() {
    this.getCameraSettings();
  }

  getCameraSettings() {
    this.setState({
      fov: Inventum.camera.getFOV(),
      sceneFOV: Inventum.camera.getSceneFOV(),
      controlsInverted: Inventum.camera.getControlsInvertedStatus(),
      videoExport: Inventum.video.getSettings(),
      screenshotExport: Inventum.screenshot.getSettings(),
    });
  }

  setFOV(value) {
    this.setState({ fov: value });
    Inventum.camera.setFOV(value);
  }

  resetFOV() {
    Inventum.camera.resetFOV();
    this.getCameraSettings();
  }

  toggleControlsInversion() {
    Inventum.camera.invertControls();
    this.setState({ controlsInverted: !this.state.controlsInverted });
  }

  render() {
    return (
      <div className="WorldEditorDrawer" style={{ alignItems: "unset", minWidth: "200px" }}>
        <div>Per-Session Camera Settings</div>
        <div style={{ marginTop: "10px" }}>
          <div style={{ fontSize: "14px" }}>Field of View</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="range"
              min="20"
              max="100"
              onChange={(e) => {
                e.stopPropagation();
                this.setFOV(parseInt(e.target.value));
              }}
              value={this.state.fov}
            />
            <div style={{ marginLeft: "10px" }}>{this.state.fov}</div>
            {this.state.fov !== this.state.sceneFOV ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  this.resetFOV();
                }}
                title="Reset Camera FOV to Scene"
                className="FovResetButton">
                Reset
              </div>
            ) : null}
          </div>
          <div style={{ marginTop: "10px" }}>
            <div style={{ fontSize: "14px" }}>Controls Inversion</div>
            <div
              onClick={this.toggleControlsInversion}
              style={{ display: "inline-block" }}
              className={"WorldEditorButton" + (this.state.controlsInverted ? "" : " Disabled")}>
              Inversion {this.state.controlsInverted ? "On" : "Off"}
            </div>
          </div>
          <VideoControls
            settings={this.state.videoExport}
            update={() => {
              this.setState({ videoExport: Inventum.video.getSettings() });
            }}
          />
          <ScreenshotControls
            settings={this.state.screenshotExport}
            update={() => {
              this.setState({ screenshotExport: Inventum.screenshot.getSettings() });
            }}
          />
        </div>
      </div>
    );
  }
}

function VideoControls (props) {
	if (Object.keys(props.settings).length === 0) return null;
	const resolutionOptions = [720, 1080, 2160];
	const framerateOptions = [25, 30, 50, 60];
	const bitrateOptions = [25, 50, 75, 100];
	const wrapperStyle = {marginTop:'5px',paddingTop:'5px'};
	return (
		<div style={{marginTop:'10px', padding:'5px', backgroundColor:'rgb(56, 56, 56)'}}>
			<div>Video Export Settings</div>
			<div>
				<div style={wrapperStyle}>
					<div>Resolution</div>
						<div style={{display:'flex'}}>
							{resolutionOptions.map((value, index) => <div onClick={() => {Inventum.video.setResolution(value); props.update()}} key={index} className={'VideoSettingPill' + (value === props.settings.videoHeight ? ' active' : '')}>{value}</div>)}
						</div>
				</div>
				<div style={wrapperStyle}>
					<div>FrameRate</div>
						<div style={{display:'flex'}}>
							{framerateOptions.map((value, index) => <div onClick={() => {Inventum.video.setFramerate(value); props.update()}} key={index} className={'VideoSettingPill' + (value === props.settings.framerate ? ' active' : '')}>{value}</div>)}
						</div>
				</div>
				<div style={wrapperStyle}>
					<div>Bitrate (Mbps)</div>
					<div style={{display:'flex'}}>
						{bitrateOptions.map((value, index) => <div onClick={() => {Inventum.video.setBitrate(value); props.update()}} key={index} className={'VideoSettingPill' + (value === props.settings.bitrate ? ' active' : '')}>{value}</div>)}
					</div>
				</div>
			</div>
		</div>
	)
}

function ScreenshotControls (props) {
	if (Object.keys(props.settings).length === 0) return null;
	const resolutionOptions = [0, 1080, 2160];
	const wrapperStyle = {marginTop:'5px',paddingTop:'5px'};
	return (
		<div style={{marginTop:'10px', padding:'5px', backgroundColor:'rgb(56, 56, 56)'}}>
			<div>Screenshot Capture Settings</div>
			<div>
				<div style={wrapperStyle}>
					<div>Resolution (0 = Unconstrained)</div>
						<div style={{display:'flex'}}>
							{resolutionOptions.map((value, index) => <div onClick={() => {Inventum.screenshot.setResolution(value); props.update()}} key={index} className={'VideoSettingPill' + (value === props.settings.resolution ? ' active' : '')}>{value}</div>)}
						</div>
				</div>
			</div>
		</div>
	)
}


function Settings2D () {
	return (
		<div style={{padding:'5px'}}>
			<h3 style={{marginTop:'0px'}}>No Available Settings.</h3>
		</div>
	)
}

function SettingsEditor (props) {
	return (
		<div>
			{props.viewerMode === '2D' ? <Settings2D /> : <Settings3D />}
		</div>
	)
}

let mapDispatchToProps = dispatch => ({
  closeModal:() => {
    dispatch(closeModal());
  }
})

let mapStateToProps = (state) => ({
	viewerMode: state.sidebarUI.mode,
})

const SettingsEditorContainer = connect(mapStateToProps,mapDispatchToProps)(SettingsEditor)

export default SettingsEditorContainer
