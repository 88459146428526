import {PlaneGeometry, MeshBasicMaterial, Mesh, DoubleSide, SpriteMaterial, Sprite, SphereGeometry, TextureLoader} from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { store } from '../core/store.js';
import { resolutionManager } from '../core/resolution.js';

const controller = { public: {} };

class StaticLabel {
  constructor () {
    const material = new SpriteMaterial({ color: 0xFFFFFF });
    this.type = 'STATIC';
    this.sprite = new Sprite(material);
    this.sprite.visible = false; // Default not visible.
		this.visibleStart = false; // Default to off at start
    this.sprite.position.set(0, 0, 1);
    this.imageRatio = { width: 1, height: 1 }; // Normalized between 0 - 1
    this.screenPosition = { x: 0, y: 0 }; // Normalized between 0 - 1
    this.scaleFactor = 0.5; // Normalized between 0 - 1
    store.sceneHUD.add(this.sprite);
		this.update = this.update.bind(this);
		resolutionManager.addListener(this.update);
    // window.addEventListener('resize', this.update, false);
    this.update();
  }

  setImage (texture) {
    if (!texture) return;
    const imgWidth = texture.image.naturalWidth || texture.image.width;
    const imgHeight = texture.image.naturalHeight || texture.image.height;
    this.imageRatio = imgWidth >= imgHeight ? { width: 1, height: imgHeight / imgWidth} : { width: imgWidth / imgHeight, height:1 };
    this.sprite.scale.set(this.imageRatio.width * this.scaleFactor, this.imageRatio.height * this.scaleFactor, 1);
    this.sprite.material.map = texture;
    this.sprite.material.needsUpdate = true;
    this.update();
    store.requestRender();
  }

  remove () {
    store.sceneHUD.remove(this.sprite);
  }

  update () {
    this.setSize();
    this.setPosition();
  }

  setSize (scaleFactor) {
    if (!this.imageRatio) return;
    if (!isNaN(scaleFactor)) {
      this.scaleFactor = scaleFactor;
    }
    let safeFrameRes = calcResFromAspect(1920, 1080);
    // Need to multiply the scaleFactor by the actual safeFrame  resolution to get a consistent size
    const screenScaleFactor = this.scaleFactor * safeFrameRes.width;
    this.sprite.scale.x = this.imageRatio.width * screenScaleFactor;
    this.sprite.scale.y = this.imageRatio.height * screenScaleFactor;
    this.setPosition();
  }

  getSize () {
    let safeFrameRes = calcResFromAspect(1920, 1080);
    return { x: this.sprite.scale.x / safeFrameRes.width, y: this.sprite.scale.y / safeFrameRes.height }
  }

  getPosition () {
    return { x:this.screenPosition.x, y: this.screenPosition.y }
  }

  setPosition (x, y) {
    if (isNaN(x)) {
      x = this.screenPosition.x;
    }

    if (isNaN(y)) {
      y = this.screenPosition.y;
    }

    this.screenPosition.x = x;
    this.screenPosition.y = y;

    const convertCoords = (x, y) => {
      let safeFrameRes = calcResFromAspect(1920, 1080);
      const halfWidth = safeFrameRes.width * 0.5;
      const halfHeight = safeFrameRes.height * 0.5;
      const screenX = mapRange(x, 0, 1, -halfWidth, halfWidth);
      const screenY = mapRange(y, 0, 1, halfHeight, -halfHeight);
      return { x: screenX, y: screenY };
    }

    let screenCoords = convertCoords(this.screenPosition.x, this.screenPosition.y);
    let halfSpriteWidth = this.sprite.scale.x * 0.5;
    let halfSpriteHeight = this.sprite.scale.y * 0.5;
    this.sprite.position.set(screenCoords.x + halfSpriteWidth , screenCoords.y - halfSpriteHeight, 1);
    store.requestRender();
  }

  centerPosition () {
    const size = this.getSize();
    this.setPosition(0.5 - (size.x * 0.5), 0.5 - (size.y * 0.5));
  }

  setVisible (visibleState) {
    this.sprite.visible = visibleState;
    store.requestRender();
  }

	isVisible () {
		return this.sprite.visible;
	}
}

function calcResFromAspect (targetWidth, targetHeight) {
	const canvasRes = resolutionManager.getResolution();

  const forceHeight = { width: canvasRes.width, height: (targetHeight / targetWidth) * canvasRes.width };
  const forceWidth = { width: (targetWidth / targetHeight) * canvasRes.height, height: canvasRes.height };

	let updatedResolution = null;
  if (forceHeight.height < canvasRes.height) {
    // console.log('Aspect Ratio forcing height');
    updatedResolution = forceHeight;
  } else if (forceWidth.width < canvasRes.width) {
    // console.log('Aspect Ratio Forcing Width');
    updatedResolution = forceWidth;
  } else {
    return { width: canvasRes.width, height: canvasRes.height };
  }

	updatedResolution.width = Math.floor(updatedResolution.width);
  updatedResolution.height = Math.floor(updatedResolution.height);

  if (updatedResolution.width === 0) {
    updatedResolution.width = 1;
  }
  if (updatedResolution.height === 0) {
    updatedResolution.height = 1;
  }
  // console.log(`New Resolution is:${updatedResolution.width}x${updatedResolution.height}`);
  return updatedResolution;
}

function mapRange(value, fromMin, fromMax, toMin, toMax) {
  return (((value - fromMin) * (toMax - toMin)) / (fromMax - fromMin)) + toMin
}

export { StaticLabel }
