import React, { useState } from 'react';
import { EditTextPill } from '../HeaderCommon.js';

function LightEntryHeader (props) {
  const deleteLight = (e) => {
    e.stopPropagation();
    Inventum.lights.delete(props.light.id);
    props.getLights();
  }

  const transformLight = (e) => {
    e.stopPropagation();
    Inventum.lights.setActive(props.light.id);
    props.getLights();
  }

  const handleNameChange = (text) => {
    Inventum.lights.setName(props.light.id, text);
    props.getLights();
  }
	// style={{color: props.light.active ? "#0F0" : "#FFF"}}
  return (
    <div className='LabelHeader' onClick={props.toggleOpen}>
      <span className='PanelOpenStatus'>{props.isOpen ? '▾' : '▸'}</span>
      <span className='LabelHeaderBtn delete' title='Delete Light' onClick={deleteLight}><i className="material-icons notranslate">clear</i></span>
      <span className={'LabelHeaderBtn' + (props.light.active ? ' active' : '')} title='Move Light' onClick={transformLight}><i className="material-icons notranslate">open_with</i></span>
			<EditTextPill setText={(text) => {handleNameChange(text)}} value={props.light.name} label={props.light.name} />
    </div>
  )
}

function LightEntryDrawer (props) {
  const style = {
    'marginLeft': '5px',
    'background': '#383838',
    'padding': '5px',
    'borderRadius': '5px',
    'boxSizing': 'border-box'
  };

  if (props.light.type === 'HemisphereLight') {
    return (
      <div style={style}>
        <div>{props.light.type}</div>
        <SpinnerValue name='Intensity' value={props.light.intensity} min={0} step={0.1} handleUpdate={(value) => { Inventum.lights.setIntensity(props.light.id, Number(value)) }} />
      </div>
    )
  } else if (props.light.type === 'DirectionalLight') {
    return (
      <div style={style}>
        <div>{props.light.type}</div>
        <SpinnerValue name='Intensity' value={props.light.intensity} min={0} step={0.1} handleUpdate={(value) => { Inventum.lights.setIntensity(props.light.id, Number(value)) }} />
        <CheckboxValue name='Enable Shadow' value={props.light.castShadow} handleUpdate={(value) => { Inventum.lights.toggleShadow(props.light.id) }} />
        <SpinnerValue name='Shadow Size' value={props.light.cameraSize} min={1} step={10} handleUpdate={(value) => { Inventum.lights.setCameraSize(props.light.id, Number(value)) }} />
        <SpinnerValue name='Shadow Far' value={props.light.cameraFar} min={1} step={10} handleUpdate={(value) => { Inventum.lights.setFar(props.light.id, Number(value)) }} />
        <SpinnerValue name='Shadow Bias' value={props.light.bias} min={-0.001} max={0.001} step={0.0001} handleUpdate={(value) => { Inventum.lights.setBias(props.light.id, Number(value)) }} />
      </div>
    )
  }
  return null;
}

function SpinnerValue (props) {
  const [value, setValue] = useState(props.value);
  const onChange = (e) => {
    setValue(e.target.value);
    if (typeof (props.handleUpdate) === 'function') {
      props.handleUpdate(e.target.value);
    }
  }

  const inputStyle = {
    width: '75px',
    borderRadius: '2px',
    outline: 'none',
    border: 'none',
    marginLeft: '10px'
  };

  return (
    <div style={{ display:'flex', fontSize: '14px', marginBottom: '10px', alignItems: 'center', marginTop: '10px' }}>
      <div style={{ width: '100px' }} title={props.name}>{props.name}</div>
      <input style={inputStyle} type='number' min={props.min} max={props.max} step={props.step} value={value} onChange={onChange} />
    </div>
  )
}

function CheckboxValue (props) {
  const [checked, setValue] = useState(props.value);
  const onChange = (e) => {
    setValue(e.target.checked);
    if (typeof (props.handleUpdate) === 'function') {
      props.handleUpdate(e.target.checked);
    }
  }

  const inputStyle = {
    outline: 'none',
    border: 'none',
    marginLeft: '10px'
  };

  return (
    <div style={{ display:'flex', fontSize: '14px', marginBottom: '10px', alignItems: 'center', marginTop: '10px' }}>
      <div style={{ width: '100px' }} title={props.name}>{props.name}</div>
      <input style={inputStyle} type='checkbox' checked={checked} onChange={onChange} />
    </div>
  )
}

class LightEntry extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render () {
    return (
      <div>
        <LightEntryHeader getLights={this.props.getLights} toggleOpen={() => { this.props.getLights(); this.setState({ isOpen: !this.state.isOpen }); }} isOpen={this.state.isOpen} light={this.props.light} />
        { this.state.isOpen ? <LightEntryDrawer getLights={this.props.getLights} light={this.props.light} /> : null }
      </div>
    )
  }
}

function LightsCollection (props) {
  const createDirectional = (e) => {
    Inventum.lights.create({
      type: 'DirectionalLight',
      intensity: 1,
      position:{ x: 20, y: 20,z: 20 },
      target:{ x: 0,y: 0, z: 0 },
      color:'#FFFFFF'
    });
    props.getLights();
  };

  const createHemi = (e) => {
    Inventum.lights.createAmbient();
    props.getLights();
  };

  return (
    <div style={{ marginLeft: '5px'}}>
      <div style={{padding:'5px'}}>
        <span onClick={createDirectional} className='CreateLightsButton' title='Create Directional Light'><i className="material-icons notranslate">add</i> Directional</span>
        <span onClick={createHemi} className='CreateLightsButton' title='Create Ambient Light'><i className="material-icons notranslate">add</i> Ambient</span>
      </div>
      <div>
        {props.lights.map((light, index) => <LightEntry getLights={props.getLights} key={index} light={{...light}} />)}
      </div>
    </div>
  )
}

class InventumLightsEditor extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      open:true,
      lights:[]
    };
    this.getLights = this.getLights.bind(this);
  }

  componentDidMount () {
    this.getLights();
  }

  getLights () {
    const lights = Inventum.lights.getAll();
    this.setState({ lights: lights });
  }


  render () {
    const toggleOpen = () => {this.setState({ open: !this.state.open })};

		const handleHelp = (e) => {
			e.stopPropagation();
			window.open('https://docs.inventum3d.com/lights/', '_blank');
		};

    return (
      <div className='LabelsPanel'>
				<div onClick={toggleOpen} className="EditorPanelHeader">
          <span className='PanelOpenStatus'>{this.state.open ? '▾' : '▸'}</span>
          <span>Lights</span>
					<span title='Opens the Inventum Docs' className="EditorPanelHelpButton" onClick={handleHelp}><i className="material-icons notranslate">help</i></span>
        </div>
        {this.state.open ? <LightsCollection getLights={this.getLights} lights={this.state.lights} /> : null}
      </div>
    )
  }
}

export default InventumLightsEditor
