import React from 'react';
import {withRouter, matchPath} from 'react-router-dom';
import {connect} from 'react-redux';
import {openModal} from '../../../Redux/actions/ui.js';
import axios from '../../../axios-config.js';

class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword:"",
      confirmedPassword:"",
      token:props.token,
      tokenValid:true,
      checkingToken:true,
      errors:[],
      changeSuccess:false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    let match = matchPath(this.props.history.location.pathname, {
      path: '/resetpassword/:resetToken',
      exact: true,
      strict: false
    })
    //Check if token is valid
    axios.post('https://api.inventum3D.com/resetpassword',{
      token:match.params.resetToken
    })
    .then((response) => {
      this.setState({token:match.params.resetToken,checkingToken:false})
    })
    .catch((error) => {
      this.setState({tokenValid:false,checkingToken:false})
    })
  }

  handleSubmit() {
    let newErrors = [];
    if (this.state.newPassword !== this.state.confirmedPassword) {
      newErrors.push("Passwords Do Not Match");
      this.setState({errors:newErrors});
      return;
    }

    if (this.state.newPassword.length < 10 ) {
      newErrors.push(`Password must contain at least 10 characters`)
      newErrors.push(`Your entered password contains ${this.state.newPassword.length} characters`)
      this.setState({errors:newErrors});
      return
    }

    axios.post('https://api.inventum3D.com/resetpassword',{
      token:this.state.token,
      password:this.state.newPassword
    })
    .then((response) => {
      this.setState({newPassword:'',confirmedPassword:'',changeSuccess:true})
    })
    .catch((error) => {
      newErrors.push(error.response.data.message);
      this.setState({errors:newErrors});
    })
  }

  render () {
    let handleNewPassword = (e) => {
      this.setState({newPassword:e.target.value});
    }

    let handleConfirmedPassword = (e) => {
      this.setState({confirmedPassword:e.target.value});
    }

    if (this.state.checkingToken) {
      return (
        <div className="passwordForm">
        <div style={{textAlign:"center"}}>Checking Token Validity...</div>
        </div>
      )
    }


    if (!this.state.tokenValid) {
      return (
        <div className="passwordForm">
        <div className="tokenExpiredWarning">Reset Token is Invalid or has Expired</div>
        <div className="passwordFormSubmit" onClick={()=>{window.location = '/'}}>Close</div>
        </div>
      )
    }

    if (this.state.changeSuccess) {
      return (
        <div className="passwordForm">
        <div style={{textAlign:"center"}}>Password Changed Successfully</div>
        <input type="submit" value="Close" onClick={()=>{window.location = '/'}} />
        </div>
      )
    }

    return (
      <div className="passwordForm">
        <div className="passwordFormTitle">Change Password</div>
        <input onChange={handleNewPassword} placeholder="New Password" type="password"/>
        <input onChange={handleConfirmedPassword} placeholder="Confirm New Password" type="password"/>
        {this.state.errors.length !== 0 ? <div className="errorsBoxPassword">{this.state.errors.map((error,index) => {return (<div key={index}>{error}</div>)})}</div> : null }
        <div className="passwordFormSubmit" onClick={this.handleSubmit}>Submit</div>
      </div>
    )
  }
}

let mapStateToProps = (state) => ({})


let mapDispatchToProps = dispatch => ({
  closeModal:() => {
    dispatch(closeModal());
  }
})


const PasswordResetContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetForm));


export default PasswordResetContainer
