import React from 'react';
import {connect} from 'react-redux';
import axios from '../../../../axios-config.js';

class SceneFileEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			scene: '',
			jsonValid: false,
			locked: true
		};
		this.getScene = this.getScene.bind(this);
		this.saveScene = this.saveScene.bind(this);
	}

	componentDidMount () {
		this.getScene();
	}

	getScene () {
		this.setState({scene: JSON.stringify(Inventum.generateSceneJSON(), null, 2), jsonValid: true })
	}

	saveScene () {
		if (!this.state.jsonValid) return;
		if (this.state.locked) return;
		let apiURL = `https://api.inventum3D.com/project/${this.props.projectID}/scene/update`;
		axios.post(apiURL, {name:"New Scene", data: JSON.parse(this.state.scene)})
		.then((result) => {
			Inventum.notifications.addNotification({content:"Updated Review Version", displayTime:3000});
			Inventum.scene.clearUnsaved();
		})
		.catch((error) => {
			Inventum.notifications.addNotification({content:"Something went wrong. Check the console.", displayTime:7000});
			console.log('Error Posting New Scene');
			console.log(error);
		})
	}

	render () {
		const isValidJSON = (str) => {
			if (typeof(str) !== 'string') return false;
			if (str.length === 0) return false;

			try {
				JSON.parse(str);
			} catch (e) {
				return false;
			}

			return true;
		}

		const handleChange = (e) => {
			this.setState({scene: e.target.value, jsonValid: isValidJSON(e.target.value)});
		}
		const saveBtnStyle = {
			width: "50px",
	    textAlign: "center",
	    padding: "5px",
	    margin: "2px"
		};
		const warningStyle = {background: '#F00', padding: '5px', fontWeight: 'bold', margin: '5px 0px 5px 0px'};
		return (
			<div style={{width:'500px', height:'500px', display:'flex', flexDirection: 'column'}}>
				<div style={{fontSize:'20px'}}>Advanced Scene Editor</div>
				<div style={warningStyle}>Warning: Improper Use Will Break Scene</div>
				{(this.state.jsonValid && !this.state.locked) ? <div style={saveBtnStyle} className='CreateLightsButton' onClick={this.saveScene}>Save</div> : null}
				{this.state.locked ? <div style={saveBtnStyle} className='CreateLightsButton' onClick={()=>{this.setState({locked:false})}}>Unlock</div> : null}
				{this.state.jsonValid ? null : <div style={warningStyle}>Invalid JSON. Saving Disabled</div>}
				<textarea readOnly={this.state.locked} style={{opacity: this.state.locked ? "0.4" : "1.0", height:'400px'}} type='area' value={this.state.scene} onChange={handleChange} />
			</div>
		)
	}
}

let mapStateToProps = (state) => ({
  projectID: state.activePage.projectID
})

let mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SceneFileEditor)
