import {
  SET_FILTER,
  REMOVE_FILTER,
  REMOVE_ALL_FILTERS,
  SET_FILTERED_RESULTS,
  CLEAR_FILTERED_RESULTS
} from '../actions/projectsFilters.js';

function projectsFilters(state = {active:{}, results:[]}, action) {
  switch (action.type) {
    case SET_FILTER:
      var active = Object.assign({}, state.active);
      active[action.data.type] = action.data;
      return Object.assign({}, state, {active})
    case REMOVE_FILTER:
      var active = Object.assign({}, state.active);
      delete active[action.data.type]
      return Object.assign({}, state, {active})
    case REMOVE_ALL_FILTERS:
      return Object.assign({}, state, {active:{}})
    case SET_FILTERED_RESULTS:
      return Object.assign({}, state, {results:[...action.data]});
    case CLEAR_FILTERED_RESULTS:
      return Object.assign({}, state, {results:[]});
    default:
      return state
  }
}

export default projectsFilters;
