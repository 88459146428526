import * as fixWebmDuration from '../libs/fix-webm-duration.js';
import { resolutionManager } from '../core/resolution.js';
const CHUNK_SIZE = 1000;
var framerate = 30;
var videoWidth = 1280;
var videoHeight = 720;
var bitrate = 25;
var chunks = [];
var timeStart = null;
var timeStop = null;
var mediaRecorder = null;
var videoPrefix = 'video';

function startRecording () {
	resolutionManager.lockResolution(videoWidth, videoHeight);
	let canvas = document.getElementById('Inventum3D');
	let stream = canvas.captureStream(framerate);
	chunks = [];
	mediaRecorder = new MediaRecorder(stream, {
		mimeType: 'video/webm;codecs=vp9',
		videoBitsPerSecond: bitrate * 1000000
	});
	mediaRecorder.ondataavailable = (e) => {
		if (e.data.size > 0) {
			chunks.push(e.data);
		}
	}

	mediaRecorder.onstop = () => {
		resolutionManager.unlockResolution();
		var blob = new Blob(chunks, {
			type: 'video/webm'
		});

		let duration = timeStop - timeStart;

		fixWebmDuration(blob, duration, (fixedBlob) => {
			var url = URL.createObjectURL(fixedBlob);
			var a = document.createElement('a');
			document.body.appendChild(a);
			a.style = 'display:none';
			a.href = url;
			let t = new Date();
			a.download = `${videoPrefix}_${t.toISOString()}.webm`;
			a.click();
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		});
	}

	timeStart = Date.now();
	mediaRecorder.start(CHUNK_SIZE);
}

function stopRecording (filename) {
	if (filename) {
		videoPrefix = filename;
	}
	if (mediaRecorder) {
		timeStop = Date.now();
		mediaRecorder.stop();
	}
}

function setResolution (height) {
	if (isNaN(height)) return;
	if (height === 720) {
		videoWidth = 1280
	} else if (height === 1080) {
		videoWidth = 1920
	} else if (height === 2160) {
		videoWidth = 3840
	}else {
		return
	}
	videoHeight = height;
}

function setFramerate (fr) {
	if (isNaN(fr)) return;
	framerate = fr;
}

function setBitrate (br) {
	if (isNaN(br)) return;
	bitrate = br;
}

function getSettings () {
	return {
		bitrate: bitrate,
		framerate: framerate,
		videoHeight: videoHeight
	}
}

const video = {
	public: {
		startRecording,
		stopRecording,
		setResolution,
		setBitrate,
		setFramerate,
		getSettings
	}
}

export {
	video
}
