import React from 'react';
import axios from '../../../axios-config.js';
import Draggable from 'react-draggable';
import {connect} from 'react-redux';
import {closeModal} from '../../../Redux/actions/ui.js';
import {loadGeojson, clearGeojson, toggleMarkers, showMarkers, toggleTenementsLayer, hideTenementsLayer, setNextBounds} from '../../../Redux/actions/mapData.js';

import DatePicker from 'react-datepicker';

//https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function capitalizeFirstLetter(string) {
    return string.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
}

function alphaSort(a, b) {
  if (a < b) {
    return -1;
  }
  if (b < a) {
    return 1;
  }
  return 0;
}

function calculateFeatureBounds(feature) {
  let minLat = Infinity;
  let minLon = Infinity;
  let maxLat = -Infinity;
  let maxLon = -Infinity;
  feature.geometry.coordinates.map(coordinateSet => {
    coordinateSet.map(point => {
      if (point[0] < minLon) {minLon = point[0]}
      if (point[0] > maxLon) {maxLon = point[0]}
      if (point[1] < minLat) {minLat = point[1]}
      if (point[1] > maxLat) {maxLat = point[1]}
    })
  })
  return [[minLat,minLon],[maxLat,maxLon]]
}

function calculateFeatureCollectionBounds(featureCollection) {
  let minLat = Infinity;
  let minLon = Infinity;
  let maxLat = -Infinity;
  let maxLon = -Infinity;
  featureCollection.features.map(feature => {
    feature.geometry.coordinates.map(coordinateSet => {
      coordinateSet.map(coordinateSubset => {
        coordinateSubset.map(point => {
          if (point[0] < minLon) {minLon = point[0]}
          if (point[0] > maxLon) {maxLon = point[0]}
          if (point[1] < minLat) {minLat = point[1]}
          if (point[1] > maxLat) {maxLat = point[1]}
        })
      })
    })
  })
  return [[minLat,minLon],[maxLat,maxLon]]
}



let CloseButton = (props) => {
  return (
    <div onClick={props.handleClick} className="titlebarCloseButton"><i className="material-icons notranslate">close</i></div>
  )
}

let MinimizeButton = (props) => {
  return (
    <div className="titlebarMinMaxButton" onClick={props.toggleMinimize}>
      <i className="material-icons notranslate">{props.minimized ? "crop_square" : "remove"}</i>
    </div>
  )
}

function WindowControls(props) {
  return (
    <div className="windowControlsContainer">
      <MinimizeButton minimized={props.minimized} toggleMinimize={props.toggleMinimize} />
      <CloseButton handleClick={props.handleClose} />
    </div>
  )
}

function MapControls(props) {
  return (
    <div style={{display:"flex",flexDirection:"column",position:"absolute",right:"0px"}}>
      <div className="MapControlsButton" onClick={props.toggleMarkers}>{props.markersVisible ? "Hide" : "Show"} Project Markers</div>
      <div className="MapControlsButton" onClick={props.toggleTenementsLayer}>{props.tenementsLayerVisible ? "Hide" : "Show"} Todays Tenements</div>
    </div>
  )
}

function ControlButton(props) {
  return (
    <i style={{"paddingRight":"10px"}} title={props.title} className="material-icons notranslate" onClick={props.onClick}>{props.icon}</i>
  )
}


class TenementDirectory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title:"Tenements Directory",
      minimized:false
    }
    this.setTitle = this.setTitle.bind(this);
    this.toggleMinimize = this.toggleMinimize.bind(this);
  }

  toggleMinimize(force) {
    if (typeof(force) === "boolean") {
      this.setState({minimized:force});
    } else {
      this.setState({minimized:!this.state.minimized});
    }
  }

  setTitle(newTitle) {
    this.setState({title:newTitle})
  }

  componentWillUnmount() {
    this.props.showMarkers();
    this.props.hideTenementsLayer();
  }

  componentDidMount() {
    this.props.toggleTenementsLayer();
  }

  render () {
    return (
      <Draggable handle=".popupDragHandle">
        <div className={"WorldEditorContainer" + (this.state.minimized ? " minimized" : "")}>
          <div className="popupDragHandle"><div style={{paddingLeft:"5px"}}>{this.state.title}</div><WindowControls minimized={this.state.minimized} toggleMinimize={this.toggleMinimize} handleClose={this.props.closeModal} /></div>
          <TenementsWizard setNextBounds={this.props.setNextBounds} loadGeojson={this.props.loadGeojson} clearGeojson={this.props.clearGeojson} />
          <MapControls markersVisible={this.props.markersVisible} tenementsLayerVisible={this.props.tenementsLayerVisible} toggleMarkers={this.props.toggleMarkers} toggleTenementsLayer={this.props.toggleTenementsLayer} />
        </div>
      </Draggable>
    )
  }
}

class TenementSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      holders:[],
      searchResults:[],
      selectedTenement:{},
      selectedHolder:""
    };
    this.searchHolders = this.searchHolders.bind(this);
    this.getHolderFeature = this.getHolderFeature.bind(this);
  }

  componentDidMount() {
    let tUrl = "https://api.inventum3d.com/geoserver/inventum-public/wfs?SERVICE=wfs&VERSION=1.1.1&REQUEST=GetFeature&TYPENAME=inventum-public:au-wa-live-pending&propertyname=HOLDER1&outputformat=csv";
    axios.get(tUrl, {transformRequest:[(data, headers) => {delete headers.Authorization; return data}]})
    .then(response => {
      let allHolders = {};
      response.data.split('\n').map(line => {
        let holder = line.slice(line.indexOf(',') + 1);
        holder = holder.replace(/"/g,"");
        if (!allHolders.hasOwnProperty(holder) && holder.length > 0) {
          allHolders[holder] = true;
        }
      })
      let sortedHolders = Object.keys(allHolders).sort(alphaSort);
      this.setState({searchResults:sortedHolders,holders:sortedHolders});
    })
  }

  searchHolders(value) {
    let searchResults = [];
    this.state.holders.map(holder => {
      if (holder.toLowerCase().includes(value.toLowerCase())) {
        searchResults.push(holder);
      }
    })
    this.setState({searchResults});
  }

  getHolderFeature(value) {
    let tUrl = `https://api.inventum3d.com/geoserver/inventum-public/wfs?
      SERVICE=wfs
      &VERSION=1.1.1
      &REQUEST=GetFeature
      &TYPENAME=inventum-public:au-wa-live-pending
      &CQL_FILTER=HOLDER1='${value}'
      &outputformat=application/json
    `
    axios.get(tUrl, {transformRequest:[(data, headers) => {delete headers.Authorization; return data}]})
    .then(response => {
      this.props.clearGeojson();
      this.props.loadGeojson(response.data);
      let bounds = calculateFeatureCollectionBounds(response.data);
      this.props.setNextBounds(bounds);
      this.setState({selectedHolder:value});
    })
  }

  render() {
    return (
      <div className="TenementHolderSearchContainer">
        <div>Search for a Tenement Holder</div>
        <div style={{display:"flex"}}>
          <input style={{width:"200px"}} onChange={(e)=>{this.searchHolders(e.target.value)}}/>
          <div className="TenementsHolderCancelBtn" onClick={this.props.cancel}>Cancel</div>
        </div>
        <div className="TenementHolderSearchResultContainer">
          {this.state.searchResults.map((value, index) => <div onClick={()=>{this.getHolderFeature(value)}} className={"TenementHolderSearchResult" + (this.state.selectedHolder == value ? " selected" : "")} key={index}>{value}</div>)}
        </div>
      </div>
    )
  }
}


class TenementsWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tenements:[],
      selectedTenement:"",
      dataDates:[],
      date1:null,
      date2:null,
      geoJSON:{},
      droppedData:[],
      addedData:[],
      showSubmitRequest:false,
      tenementSearch:false
    };
    this.getTenements = this.getTenements.bind(this);
    this.getTenementDataDates = this.getTenementDataDates.bind(this);
    this.fetchGeoJSON = this.fetchGeoJSON.bind(this);
    this.filterHolder = this.filterHolder.bind(this);
    this.updateMap = this.updateMap.bind(this);
    this.submitRequest = this.submitRequest.bind(this);
    this.getComparison = this.getComparison.bind(this);
    this.mergeTenementsGeoJSON = this.mergeTenementsGeoJSON.bind(this);
  }
  //NOTE: React Update
  componentDidMount() {
    this.getTenements();
  }

  componentWillUnmount() {
    this.props.clearGeojson();
  }

  //Tenements is an array with just the name and ID. In the future will add support for groups.
  getTenements() {
    axios.get('https://api.inventum3d.com/tenements/')
    .then(response => {
      this.setState({tenements:response.data});
    })
    .catch(error => {
      console.log(error);
    })
  }

  //Gets all the dates that data is available for that tenement.
  getTenementDataDates() {
    if (!this.state.selectedTenement) return
    axios.get(`https://api.inventum3d.com/tenements/${this.state.selectedTenement}`)
    .then(response => {
      this.setState({dataDates:response.data.dataAvailable.map(str => {return new Date(str)})});
    })
    .catch(error => {
      console.log(error);
    })
  }

  getAvailableExtracts() {
    axios.get(`https://api.inventum3d.com/tenements/${this.state.selectedTenement}/compare?compareDate=${formatDate(this.state.compareDate)}`)
    .then(response => {
      let existingExtractDates = response.data.extracts.map(str => {return new Date(str.date)});
      let extractsUrls = {};
      response.data.extracts.map(extract => {
        let tFormat = formatDate(extract.date);
        extractsUrls[tFormat] = extract.url;
      })
      this.setState({extractsUrls:extractsUrls, existingExtractsDates:existingExtractDates});
    })
    .catch(error => {
      console.log(error);
    })
  }

  getComparison() {
    axios.get(`https://api.inventum3d.com/tenements/${this.state.selectedTenement}/comparev2?date1=${formatDate(this.state.date1)}&date2=${formatDate(this.state.date2)}`)
      .then(response => {
        this.fetchGeoJSON({
          dropped:response.data.dropped,
          added:response.data.added
        });
      })
      .catch(error => {
        if (error.response.status == 404) {
          this.setState({showSubmitRequest:true})
        }
      })
  }

  fetchGeoJSON(urlObject) {
    if (!urlObject) {
      return;
    }

    let dGeoJSON = {};
    let aGeoJSON = {};

    axios.get(urlObject.dropped, {transformRequest:[(data, headers) => {delete headers.Authorization; return data}]})
    .then(tenementsDroppedResponse => {
      dGeoJSON = tenementsDroppedResponse.data;
      axios.get(urlObject.added, {transformRequest:[(data, headers) => {delete headers.Authorization; return data}]})
      .then(tenementsAddedResponse => {
        aGeoJSON = tenementsAddedResponse.data;
        this.mergeTenementsGeoJSON(dGeoJSON, aGeoJSON);
      })
    })
    .catch(error => {
      console.log(error);
    })
  }

  mergeTenementsGeoJSON(dGeoJSON, aGeoJSON) {
    let mergedGeoJSON = {
      type:"FeatureCollection",
      name:"TenementDifferences",
      crs:{
        type:"name",
        properties:{
          name:"urn:ogc:def:crs:EPSG::4283"
        }
      },
      features:[]
    };
    dGeoJSON.features.map(feature => {
      feature.properties._INVENTUM_GROUP = "DROPPED";
    })

    aGeoJSON.features.map(feature => {
      feature.properties._INVENTUM_GROUP = "ADDED";
    })

    mergedGeoJSON.features = mergedGeoJSON.features.concat(dGeoJSON.features,aGeoJSON.features);
    this.setState({geoJSON:mergedGeoJSON},() => {
      this.props.loadGeojson(mergedGeoJSON);
      this.processHolders();

    })
  }

  processHolders() {
    let droppedData = [];
    let addedData = [];

    let tempholders = {};
    this.state.geoJSON.features.map(feature => {
      let tProps = {
        id:feature.properties.FMT_TENID,
        holder:feature.properties.HOLDER1,
        type:feature.properties.TYPE,
        survey_status:feature.properties.SURVSTATUS,
        tenement_status:feature.properties.TENSTATUS,
        end_date:feature.properties.ENDDATE,
        bounds:calculateFeatureBounds(feature)
      };

      if (feature.properties._INVENTUM_GROUP === "DROPPED") {
        droppedData.push(tProps);
      }else {
        addedData.push(tProps);
      }
    })
    let sortFunc = (a, b) => {
      if (a.holder < b.holder) { return -1;}
      if (a.holder > b.holder) { return 1;}
      return 0;
    }
    droppedData.sort(sortFunc);
    addedData.sort(sortFunc);
    this.setState({droppedData:droppedData,addedData:addedData});
  }

  filterHolder(name) {
    let holdersFiltered = this.state.holdersFiltered;
    let geoJSONFiltered = {};

    let index = holdersFiltered.indexOf(name);
    if (index == -1) {
      holdersFiltered.push(name)
    }else {
      holdersFiltered.splice(index, 1);
    }

    if (holdersFiltered.length > 0) {
      geoJSONFiltered = Object.assign({}, this.state.geoJSON);
      geoJSONFiltered.features = geoJSONFiltered.features.filter(feature => {
        return holdersFiltered.includes(feature.properties.HOLDER1)
      })
    }else {
      geoJSONFiltered = {};
    }

    this.setState({geoJSONFiltered:geoJSONFiltered,holdersFiltered:holdersFiltered},this.updateMap)
  }

  updateMap() {
    if (Object.keys(this.state.geoJSONDropped).length > 0) {
      this.props.loadGeojson(this.state.geoJSONDropped);
    }

    if (Object.keys(this.state.geoJSONAdded).length > 0) {
      this.props.loadGeojson(this.state.geoJSONAdded);
    }
  }

  submitRequest() {
    let onSubmit = response => {
      if (response.data.message == "Request Underway") {
        alert('Job Submitted. Check back in 5-10 minutes');
      }else if (response.status == 202) {
        alert('Job is already being processed. Please wait several minutes');
      }else {
        alert('Job has been processed. Please reload the tenement viewer to load it.')
      }
      console.log(response);
    }

    let handleError = error => {
      console.log(error);
    }

    //Submit request for Dropped Tenements
    axios.post(`https://api.inventum3d.com/tenements/${this.state.selectedTenement}/compare`,{compareDate:formatDate(this.state.date1),extractDate:formatDate(this.state.date2)})
    .then(onSubmit)
    .catch(handleError)

    //Submit request for Added Tenements (Reverse Dates)
    axios.post(`https://api.inventum3d.com/tenements/${this.state.selectedTenement}/compare`,{compareDate:formatDate(this.state.date2),extractDate:formatDate(this.state.date1)})
    .then(onSubmit)
    .catch(handleError)

  }

  render() {
    let handleTenementSelect = (id) => {
      this.setState({selectedTenement:id},() => {
        this.getTenementDataDates();
      })
    }

    let handleDate1Select = (date) => {
      this.setState({date1:date})
    }

    let handleDate2Select = (date) => {
      this.setState({date2:date,geoJSON:{},addedData:[],droppedData:[],showSubmitRequest:false},() => {
        this.props.clearGeojson();
        this.getComparison();
      });
    }

    if (this.state.tenementSearch) {
      return (
        <div className="TenementsDirectoryContainer">
          <TenementSearch cancel={()=>{this.setState({tenementSearch:false})}} setNextBounds={this.props.setNextBounds} clearGeojson={this.props.clearGeojson} loadGeojson={this.props.loadGeojson}/>
        </div>
      )
    }

    return (
      <div className="TenementsDirectoryContainer">
        <OptionsForm {...this.state}
        handleTenementSelect={handleTenementSelect}
        handleDate1Select={handleDate1Select}
        handleDate2Select={handleDate2Select}
        enableTenementSearch={()=>{this.setState({tenementSearch:true})}}
        />
        {this.state.showSubmitRequest ? <SubmitRequest handleSubmit={this.submitRequest} /> : null}
        <ResultsTable setNextBounds={this.props.setNextBounds} extractDate={this.state.extractDate} compareDate={this.state.compareDate} holdersFiltered={this.state.holdersFiltered} handleFilter={this.filterHolder} droppedData={this.state.droppedData} addedData={this.state.addedData} />
      </div>
    )
  }
}

function OptionsForm(props) {
  let titleStyle={
    marginTop: "5px",
    marginBottom: "5px",
  }

  let dateSelectorContainerStyle = {
    display: "inline-block",
    borderRadius: "3px",
    marginTop: "5px",
    marginBottom: "5px",
    background: "#222",
    padding: "5px"
  }

  return (
    <div>
      <TenementSelect selected={props.selectedTenement} tenements={props.tenements} handleSelect={props.handleTenementSelect} />
      <div>
        <div className="TenementHolderSearchButton" onClick={props.enableTenementSearch}>Search for a Tenement Holder</div>
      </div>
      <div style={dateSelectorContainerStyle}>
        <div style={titleStyle}>Calculate Tenement differences between 2 dates</div>
          <div style={{display:"flex"}}>
            <DateSelector
            selectedDate={props.date1}
            dataAvailable={props.dataDates}
            disabled={props.selectedTenement.length == 0 ? true : false}
            handleSelect={props.handleDate1Select}
            guide="Older Date"
            placeholder="Older Date"
            />
            <DateSelector
            highlightDates={props.existingExtractsDates}
            excludeDates={[props.compareDate]}
            selectedDate={props.date2}
            dataAvailable={props.dataDates}
            disabled={props.date1 === null ? true : false}
            handleSelect={props.handleDate2Select}
            guide="Recent Date"
            placeholder="Recent Date"
            />
        </div>
      </div>
    </div>
  )
}

let SubmitRequest = (props) => {
  let containerStyle = {
    fontSize: "18px",
    display: "flex",
    marginBottom: "5px",
    background: "#222222",
    padding: "5px",
    paddingLeft: "10px",
    borderRadius: "5px",
    color: "#FF0000",
    fontWeight: "bold"
  }

  let submitStyle = {
    marginLeft: "10px",
    color: "#FFFFFF",
    paddingLeft: "5px",
    fontWeight: "normal",
    paddingRight: "5px",
    background: "#00AA00",
    borderRadius: "2px"
  }

  return (
    <div style={containerStyle}>The comparison you selected doesn't exist. <div onClick={props.handleSubmit} style={submitStyle}>Submit Request</div></div>
  )
}

let TenementSelect = (props) => {
  return (
    <div>
      <div>Tenement</div>
      <select value={props.selected} onChange={(e)=>{props.handleSelect(e.target.value)}}>
        <option value={""} disabled>Select a Tenement</option>
        {props.tenements.map(tenement => <option key={tenement.id} value={tenement.id}>{tenement.name}</option>)}
      </select>
    </div>
  )
}

let DateSelector = (props) => {
  return (
    <div className="TenementDateSelector">
      <div style={{fontSize:"15px"}}>{props.guide}</div>
      <DatePicker
      disabled={props.disabled}
      onChange={props.handleSelect}
      includeDates={props.dataAvailable}
      excludeDates={props.excludeDates}
      selected={props.selectedDate}
      highlightDates={props.highlightDates}
      showMonthDropdown
      placeholderText={props.placeholder}
      dateFormat="dd/MM/yyyy"
      />
     </div>
  )
}


class ResultsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {category:"DROPPED"};
  }

  toggleCategory(category) {
    this.setState({category})
  }

  render() {
    let buttonInactiveStyle = {
      background: "#444444",
      borderRadius: "5px",
      padding: "4px",
      margin: "5px",
      fontSize: "14px",
    }
    let buttonDroppedActiveStyle = Object.assign({}, buttonInactiveStyle);
    buttonDroppedActiveStyle.background = "#AAAAAA";

    let buttonAddedActiveStyle = Object.assign({}, buttonInactiveStyle);
    buttonAddedActiveStyle.background = "#AAAAAA";

    return (
      <div className="TenementsWizardResultsContainer">
        <div style={{display:"flex"}}>
          <div onClick={() => {this.toggleCategory("DROPPED")}} style={this.state.category === "DROPPED" ? buttonDroppedActiveStyle : buttonInactiveStyle}>Dropped</div>
          <div onClick={() => {this.toggleCategory("ADDED")}} style={this.state.category === "ADDED" ? buttonAddedActiveStyle : buttonInactiveStyle}>Added</div>
        </div>
        <div className="HoldersList">
          <table className="LightsEditorContainer">
            <thead>
              <tr className="LightTableSetting" style={{backgroundColor:this.state.category === "DROPPED" ? "#00AA00" : "#AA0000"}}>
                <th>Holder</th>
                <th>ID</th>
                <th>Type</th>
                <th>Survey Status</th>
                <th>Tenement Status</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody style={{maxHeight:"160px"}}>
              {this.state.category === "ADDED" ? this.props.addedData.map((data, index) => <Result setNextBounds={this.props.setNextBounds} key={index} {...data}/>) : null}
              {this.state.category === "DROPPED" ? this.props.droppedData.map((data, index) => <Result setNextBounds={this.props.setNextBounds} key={index} {...data}/>) : null}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

function Result(props) {
  let handleClick = () => {
    props.setNextBounds(props.bounds);
  }
  return (
    <tr className="LightTableSetting">
      <td><div style={{textDecoration:"underline"}} onClick={handleClick}>{props.holder}</div></td>
      <td><div style={{textDecoration:"underline"}} onClick={handleClick}>{props.id}</div></td>
      <td>{props.type}</td>
      <td>{props.survey_status}</td>
      <td>{props.tenement_status}</td>
      <td>{props.end_date}</td>
    </tr>
  )
}

//      {props.holders.map((holder, index) => <div className={"TenementHolderResult" + (props.holdersFiltered.length > 0 && !props.holdersFiltered.includes(holder.name) ? " Filtered" : "")} onClick={() => {props.handleFilter(holder.name)}} key={index}>{capitalizeFirstLetter(holder.name.toLowerCase())}</div>)}

let mapDispatchToProps = dispatch => ({
  closeModal:() => {
    dispatch(closeModal());
  },
  loadGeojson:(data) => {
    dispatch(loadGeojson(data));
  },
  clearGeojson:() => {
    dispatch(clearGeojson());
  },
  toggleMarkers:() => {
    dispatch(toggleMarkers());
  },
  showMarkers:() => {
    dispatch(showMarkers());
  },
  toggleTenementsLayer:() => {
    dispatch(toggleTenementsLayer());
  },
  hideTenementsLayer:() => {
    dispatch(hideTenementsLayer());
  },
  setNextBounds:(data) => {
    dispatch(setNextBounds(data));
  }
})

let mapStateToProps = (state) => ({
  markersVisible:state.mapData.markersVisible,
  tenementsLayerVisible:state.mapData.tenementsLayerVisible
})

const TenementDirectoryContainer = connect(mapStateToProps,mapDispatchToProps)(TenementDirectory)

export default TenementDirectoryContainer
