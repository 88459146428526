import React from 'react';
import {connect} from 'react-redux';
import {store} from '../../../../engine/modules/core/store.js';
import {models} from '../../../../engine/modules/models/models.js';
import {openContextMenu,closeContextMenu} from '../../../Redux/actions/ui.js';
import ModelProperties from './modules/ModelProperties/ModelProperties.js';
import {SortableContainer, SortableHandle, SortableElement} from 'react-sortable-hoc';
import { IconButton } from '@mui/material';
import CropFreeIcon from "@mui/icons-material/CropFree";
import Inventum from '../../../../engine/Inventum';

const DragHandle = SortableHandle(() => <div style={{ cursor: 'grab' }}><i className="material-icons notranslate">drag_indicator</i></div>);

class ModelTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {name:props.name};
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleChange(e) {
    this.setState({name:e.target.value});
  }

  handleSave() {
    Inventum.models.setModelName(this.props.id,this.state.name);
    this.props.cancelEditing();
  }

  componentDidUpdate(prevProps) {
    if (this.props.editing === true && prevProps.editing === false) {
      this.inputBox.focus();
      this.inputBox.select();
    }
  }

  render() {
    let handleKey = (e) => {
      if (e.key === "Enter") {
        this.handleSave();
      }
    }

    if (this.props.editing) {
      return (
        <div className="ModelTitle editing" onClick={(e)=>{e.stopPropagation()}}>
          <input onKeyDown={handleKey} ref={(input)=>{this.inputBox=input}}  value={this.state.name} onChange={this.handleChange} />
          <div className="PanelTitleEditButton" onClick={this.handleSave}>Save</div>
          <div className="PanelTitleEditButton" onClick={()=>{this.setState({name:this.props.name});this.props.cancelEditing()}}>Cancel</div>
        </div>
      )
    }

    let needsReviewStyle = {fontSize:"16px",paddingRight:"5px",color:"#ffd24d"};
    return (
      <div className="ModelTitle" title={this.props.name}>
      {(this.props.needsReview && this.props.editMode) ? <i className="material-icons notranslate" style={needsReviewStyle} title="New Model">new_releases</i> : null}
      {this.props.name}
      </div>
    )
  }
}

function ModelColorPicker(props) {
  let boxColor = {"backgroundColor":props.color};
  let handleClick = (e) => {

  }
  return (
    <div className="ModelColorPicker" onClick={handleClick}>
      <div className="ColorPreview " style={boxColor} />
    </div>
  )
}

function ModelWireframe(props) {
  let click = () => {
    Inventum.models.toggleWireframe(props.id);
  }
  return (
    <div className="ModelWireframeToggle" onClick={click}>
      <i className="material-icons model-controls">{props.wireframe ? "check_box" : "check_box_outline_blank"}</i>
    </div>
  )
}

function ModelOpacity(props) {
  return (
    <div className="ModelWireframeToggle" onClick={props.handleClick}>
      <i className="material-icons model-controls">opacity</i>
    </div>
  )
}


function ModelZoomToggle(props) {
  const handleClick = (e) => {
    e.stopPropagation();
    Inventum.camera.zoomToObject(props.id);
  }
  return (
    <IconButton className="ModelZoomToggle" onClick={handleClick} aria-label="zoom" style={{ padding: "0px" }}>
      <CropFreeIcon color="info" />
    </IconButton>
  );
}


function ModelLabelToggle(props) {
  let click = () => {
    Inventum.models.toggleLabels(props.id);
  }
  var style = props.visible ? {} :{'color':'#222222'};

  if(!props.hasLabels) {
    return (
      <div className="ModelLabelToggle">
        <i className="material-icons model-controls"></i>
      </div>
    )
  }

  return (
    <div className="ModelLabelToggle" onClick={click}>
      <i className="material-icons model-controls" style={style}>label</i>
    </div>
  )
}

function ModelVisibilityToggle(props) {
  let click = (e) => {
    e.stopPropagation();
    if (props.id !== undefined) {
      Inventum.models.toggleVisibility(props.id);
    }
  }
  var style = props.visible ? {} :{'color':'#222222'};
  return (
    <div className="ModelVisibilityToggle" onClick={click}>
      <i className="material-icons model-controls" style={style}>{props.visible ? 'check_box' : 'check_box_outline_blank'}</i>
    </div>
  )
}

function ModelEntryHeader(props) {
  return (
    <div className="ModelEntryHeader">
      <div>Colour</div>
      <div>Opacity</div>
      <div>Zoom</div>
    </div>
  )
}

function ModelEntryContextMenu (props) {
  let handleRename = (e) => {
    if (!props.siteAdmin) {
      return;
    }
    e.stopPropagation();
    props.closeMenu();
    props.setEditing();
  }

  let handleDelete = (e) => {
    if (!props.siteAdmin) {
      return;
    }
    e.stopPropagation();
    if (window.confirm("Delete Model?")) {
      Inventum.models.removeModel(props.model.id);
    }
    props.closeMenu();
  }

  let handleCopyName = (e) => {
    e.stopPropagation();
    if (!props.siteAdmin) {
      return;
    }
    navigator.clipboard
      .writeText(props.model.name)
      .then(() => {
        console.log("Model name copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy model name: ", err);
      });
    props.closeMenu();
  };

  let handlePasteName = (e) => {
    e.stopPropagation();
    if (!props.siteAdmin) {
      return;
    }
    navigator.clipboard
      .readText()
      .then((text) => {
        Inventum.models.setModelName(props.model.id, text);
        console.log("Model name pasted from clipboard");
      })
      .catch((err) => {
        console.error("Failed to paste model name: ", err);
      });
    props.closeMenu();
  };


  let handleVisibleStart = (e) => {
    if (!props.siteAdmin) {
      return;
    }
    e.stopPropagation();
    props.closeMenu();
    Inventum.models.toggleVisibleStart(props.model.id);
  }

  let handleCastShadow = (e) => {
    if (!props.siteAdmin) {
      return;
    }
    e.stopPropagation();
    props.closeMenu();
    Inventum.models.toggleCastShadow(props.model.id);
  }

  let handleReceiveShadow = (e) => {
    if (!props.siteAdmin) {
      return;
    }
    e.stopPropagation();
    props.closeMenu();
    Inventum.models.toggleReceiveShadow(props.model.id);
  }



  let handleCut = (e) => {
    if (!props.siteAdmin) {
      return;
    }
    e.stopPropagation();
    props.closeMenu();
    Inventum.models.cutModel(props.model.id);
  }

  let handleCopyMaterial = (e) => {
    if (!props.siteAdmin) {
      return;
    }
    e.stopPropagation();
    props.closeMenu();
    Inventum.models.copyMaterial(props.model.id);
  }

  let handlePasteMaterial = (e) => {
    if (!props.siteAdmin) {
      return;
    }
    e.stopPropagation();
    Inventum.models.pasteMaterial(props.model.id);
    props.closeMenu();
    Inventum.models.setTexture(props.model.id, Inventum.models.getMaterial(props.model.id).mapPath);
    Inventum.models.setMaterial(props.model.id, Inventum.models.getMaterial(props.model.id));
    Inventum.models.getModelData();
    // let onPasteTimeout = () => {
    //   Inventum.models.getModelData();
    // };
    // setTimeout(onPasteTimeout, 100);
  };


  let handleCopySceneStates = (e) => {
    e.stopPropagation();
    Inventum.models.copySceneStates(props.model.id);
    props.closeMenu();
  }

  let handlePasteSceneStates = (e) => {
    e.stopPropagation();
    Inventum.models.pasteSceneStates(props.model.id);
    props.closeMenu();
  }

  const CopyMaterialAndSceneStates = (e) => {
    handleCopyMaterial(e);
    handleCopySceneStates(e);
  };

  const PasteMaterialAndSceneStates = (e) => {
    handlePasteMaterial(e);
    handlePasteSceneStates(e);
  };

  let handleNeedsReview = (e) => {
    e.stopPropagation();
    Inventum.models.toggleNeedsReview(props.model.id);
    props.closeMenu();
  }

  let handleDownloadAutomatically = (e) => {
    e.stopPropagation();
    Inventum.models.toggleDownloadAutomatically(props.model.shortID);
    props.closeMenu();
  }

  let handleTransformModel = (e) => {
    e.stopPropagation();
    Inventum.models.transformModel(props.model.id);
    props.closeMenu();
  }

  if (props.pageMode !== "3D") {
    return null
  }
  if (!props.siteAdmin || !props.editMode) {
    return (
      <div className="SideBarPanelContextMenu" />
    )
  }
  return (
    <div className="SideBarPanelContextMenu">
      <div className="SideBarContextMenuEntry" onClick={handleRename}>
        Rename Model
      </div>
      <div className="SideBarContextMenuEntry" onClick={handleCopyName}>
        Copy Model Name
      </div>
      <div className="SideBarContextMenuEntry" onClick={handlePasteName}>
        Paste Model Name
      </div>
      <div className="SideBarContextMenuEntry MenuEntryWarning" onClick={handleDelete}>
        Delete Model
      </div>
      <div className="SideBarContextMenuEntry" onClick={handleCut}>
        Cut Model
      </div>
      <div className="SideBarContextMenuEntry" onClick={handleCopyMaterial}>
        Copy Material
      </div>
      <div className="SideBarContextMenuEntry" onClick={handlePasteMaterial}>
        Paste Material
      </div>
      <div className="SideBarContextMenuEntry" onClick={handleCopySceneStates}>
        Copy Scene States
      </div>
      <div className="SideBarContextMenuEntry" onClick={handlePasteSceneStates}>
        Paste Scene States
      </div>
      <div className="SideBarContextMenuEntry" onClick={CopyMaterialAndSceneStates}>
        Copy Material And Scene States
      </div>
      <div className="SideBarContextMenuEntry" onClick={PasteMaterialAndSceneStates}>
        Paste Material And Scene States
      </div>
      <div className="SideBarContextMenuEntry" onClick={handleTransformModel}>
        <input readOnly name="Transform Active" type="checkbox" checked={props.model.transformActive} />
        Transform Model
      </div>
      <div className="SideBarContextMenuEntry" onClick={handleVisibleStart}>
        <input readOnly name="Visible Start" type="checkbox" checked={props.model.visibleStart} />
        Visible Start
      </div>
      <div className="SideBarContextMenuEntry" onClick={handleCastShadow}>
        <input readOnly name="Cast Shadow" type="checkbox" checked={props.model.castShadow} />
        Cast Shadow
      </div>
      <div className="SideBarContextMenuEntry" onClick={handleReceiveShadow}>
        <input readOnly name="Receive Shadow" type="checkbox" checked={props.model.receiveShadow} />
        Receive Shadow
      </div>
      <div className="SideBarContextMenuEntry" onClick={handleNeedsReview}>
        <input readOnly name="Needs Review" type="checkbox" checked={props.model.needsReview} />
        Needs Review
      </div>
    </div>
  );
  //       <div className="SideBarContextMenuEntry" onClick={handleDownloadAutomatically}>Download Automatically <input readOnly name="Download Automatically" type="checkbox" checked={props.model.downloadAutomatically}/></div>
}

class ModelEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelPropertiesVisible:false,
      editingName:false
    };
    this.toggleModelProperties = this.toggleModelProperties.bind(this);
    this.handleContext = this.handleContext.bind(this);
    this.handleHeaderClick = this.handleHeaderClick.bind(this);
  }

  handleContext(force) {
    if (this.props.pageMode === "3D" && !this.props.editMode) {
      return
    }
    if (typeof(force) === "boolean") {
      if (force) {
        this.props.openContextMenu(this.props.model.id);
      }else {
        this.props.closeContextMenu();
      }
      return;
    }
    if (this.props.model.id === this.props.activeContextMenu) {
      this.props.closeContextMenu();
    }else {
      this.props.openContextMenu(this.props.model.id);
    }
  }

  toggleModelProperties() {
    this.setState({modelPropertiesVisible:!this.state.modelPropertiesVisible}, () => {
      if (this.state.modelPropertiesVisible) {
        Inventum.models.getModelData();
      }
    });
  }

  handleHeaderClick() {
    this.handleContext(false);
    this.toggleModelProperties();
  }

  render() {
    //Remove this.props.model.color from parent at some point. Replaced by this.state.material which is fetched frequently
    let contextMenuOpen = this.props.model.id === this.props.activeContextMenu;
    const editMode = this.props.editMode;

    if (!this.props.model.isLoaded) {
      return (
        <div style={{ justifyContent: "flex-start" }} className={"ModelEntry"}>
          {editMode && <DragHandle />}
          <span style={{ fontSize: "12px", paddingLeft: "40px", minWidth: "155px" }}>{this.props.model.name}</span>
          <div
            onClick={() => {
              Inventum.models.fetchModel(this.props.model.id);
            }}
            className="LoadModelButton"
            style={{ fontSize: "12px" }}>
            Click to Load
          </div>
        </div>
      );
    }

    const handleRightClickHeader = (e) => {
      // Development Help. Disables right click menu if url param is set
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('options');
      if (myParam === 'disableSidebarContext') {
        return;
      }
      e.preventDefault();
      this.handleContext();
    }
    return (
      <div className={"ModelEntry" + (contextMenuOpen ? " active" : "")} onClick={this.handleHeaderClick}>
        <div className='ModelEntryHeaderWrapper' onContextMenu={handleRightClickHeader}>
          {editMode && <DragHandle />}
          {contextMenuOpen ? <ModelEntryContextMenu model={this.props.model} pageMode={this.props.pageMode} siteAdmin={this.props.siteAdmin} editMode={this.props.editMode} closeMenu={()=>{this.handleContext(false)}} setEditing={()=>{this.setState({editingName:true})}} /> : null}
          <ModelVisibilityToggle id={this.props.model.id} visible={this.props.model.visible}/>
          <div><i className="material-icons notranslate">{this.state.modelPropertiesVisible ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</i></div>
          <ModelTitle editMode={this.props.editMode} name={this.props.model.name} id={this.props.model.id} needsReview={this.props.model.needsReview} editing={this.state.editingName} cancelEditing={()=>{this.setState({editingName:false})}}/>
          {!this.state.editingName ? <ModelColorPicker id={this.props.model.id} color={this.props.model.color} /> : null}
          {!this.state.editingName ? <ModelOpacity id={this.props.model.id}/> : null}
          {!this.state.editingName ? <ModelZoomToggle id={this.props.model.id}/> : null }
        </div>
        {this.state.modelPropertiesVisible ? <ModelProperties activeProjectID={this.props.activeProjectID} modelProps={this.props.model} id={this.props.model.id} editMode={this.props.editMode} siteAdmin={this.props.siteAdmin} /> : null}
      </div>
    )
  }
}


let ModelEntrySortable = SortableElement((props) =>
  <ModelEntry {...props} />
);

let mapStateToProps = (state) => ({
  siteAdmin:(state.user.siteAdmin) ? true : false,
  editMode:state.ui.editMode,
  pageMode:state.sidebarUI.mode,
  activeContextMenu:state.ui.activeContextMenu,
  activeProjectID:state.activePage.projectID
})

let mapDispatchToProps = dispatch => ({
  openContextMenu:(panelID) => {
    dispatch(openContextMenu(panelID));
  },
  closeContextMenu:() => {
    dispatch(closeContextMenu());
  }
})

let ModelEntryWrapper = connect(mapStateToProps,mapDispatchToProps)(ModelEntrySortable);



const ModelCollection = SortableContainer((props) => {
  //Disable sorting if only one item in list
  return (
    <div className="ModelCollection">
      <ModelEntryHeader />
      {props.items.map((model, index) => {
        return <ModelEntryWrapper itemCount={props.items.length} disabled={props.items.length === 1 ? true : false} index={index} key={model.shortID} model={model}/>
      })}
    </div>
  )
});

export default ModelCollection;