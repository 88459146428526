import * as THREE from 'three';
import { store } from '../core/store.js';
import { resolutionManager } from '../core/resolution.js';
import { views } from '../camera/views.js';
import { models } from '../models/models.js';
import { markers } from '../ui/screenMarker.js';
import { labels } from '../ui/labels.js';

var selectTool = {};

const clickDistanceTolerance = 50;
const clickTimerTolerance = 250;
var lastClickedTime = 1000;
var lastCoordinates = {};
var lastBroadcastMouseTime = 0;

selectTool.handleTouch = function handleTouch (event) {
  if (event.touches.length === 1) {
    handleInput({ offsetX: event.touches[0].offsetX, offsetY: event.touches[0].offsetY });
  }
};

selectTool.handleClick = function handleClick (event) {
  if (event.which !== 1) {
    return;
  }
  handleInput({ offsetX: event.offsetX, offsetY: event.offsetY });
};

function handleInput (coordinates) {
  const haveClickedMarker = markers.checkSelection(coordinates.offsetX, coordinates.offsetY, 'click');
  const haveOpenedLabelURL = labels.checkSelection(coordinates.offsetX, coordinates.offsetY, 'click');

  if (haveClickedMarker || haveOpenedLabelURL) {
    // Don't process any further events if a marker has been clicked.
    return;
  }
  // Check if mouse double click happened within timer tolerance
  if ((Date.now() - lastClickedTime) <= clickTimerTolerance) {
    // Check if mouse click is within distance tolerance
    if (checkClickDistance(coordinates, lastCoordinates)) {
      handleDoubleClick(coordinates);
    }
  } else {
    lastClickedTime = Date.now();
    lastCoordinates = Object.assign({}, coordinates);
  }
}

function handleDoubleClick (coordinates) {
  var intersected = fetchWorldCoord(coordinates);
  if (!intersected) {
    return;
  }
  if (intersected.model) {
    // UGLY HACK 2021-05-19
    // If controls.enabled is set to false before the mouse click is released, the camera orbit will get stuck down
    // To prevent this we wait 200 milliseconds before starting the orbit.
    // The alternative fix is to not disable the controls in the view.animateCamera function. But this allows the user to fight the camera animation
    // Which isn't ideal.
    const safeMouseUpWindow = 200;
    setTimeout(() => { views.public.zoomToObject(intersected.model.uuid); }, safeMouseUpWindow);
  }
}

function checkClickDistance (currentClick, lastClick) {
  const tempX = currentClick.offsetX - lastClick.offsetX;
  const tempY = currentClick.offsetY - lastClick.offsetY;

  const distance = Math.sqrt(tempX * tempX + tempY * tempY);
  // Returns boolean
  return (Math.abs(distance) < clickDistanceTolerance);
}

selectTool.handleMove = function handleMove (e) {
  if (store.broadcastMode) {
    if (Date.now() - lastBroadcastMouseTime > 100) {
      const mouseX = parseInt(e.offsetX) / parseInt(store.webglRenderer.domElement.width);
      const mouseY = parseInt(e.offsetY) / parseInt(store.webglRenderer.domElement.height);
      store.sync.private.broadcast({ eventOverride: 'inventum-annotation-mouse-move', x: mouseX, y: mouseY });
      lastBroadcastMouseTime = Date.now();
    }
  }

  markers.checkSelection(e.offsetX, e.offsetY, 'hover');
};

function fetchWorldCoord (e) {
  var raycaster = new THREE.Raycaster();
  var mouse = new THREE.Vector2();
  var validObjectFound = false;

	const resolution = resolutionManager.getResolution();
  mouse.x = (e.offsetX / resolution.width) * 2 - 1;
  mouse.y = -(e.offsetY / resolution.height) * 2 + 1;
  raycaster.setFromCamera(mouse, store.camera);
  // console.log(raycaster.ray);
  // showRay(raycaster.ray)
  var intersects = raycaster.intersectObjects(models.getSceneObjects());
  for (var i = 0; i < intersects.length; i++) {
    if (validObjectFound) {
      break;
    }
    const intersect = intersects[i];
    if (intersect.object.visible === true) {
      validObjectFound = true;
      return { model: intersect.object, position: intersect.point };
    }
  }
}

export { selectTool };
