const controller = { public: {} };

var receivers = [];

controller.add = function addNotification (notificationData) {
  receivers.map((reciever) => {
    reciever(notificationData);
  });
};

controller.public.addNotification = controller.add;

controller.public.registerReciever = function registerReciever (cb) {
  if (typeof (cb) !== 'function') {
    console.log('Notification Receiver should be a function');
    return;
  }
  receivers.push(cb);
};

controller.reset = () => {
  receivers = [];
};

export { controller as notifications };
