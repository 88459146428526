import {
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_CONTEXT_MENU,
  CLOSE_CONTEXT_MENU,
  SET_EDIT_MODE,
  SET_ACTIVE_PROJECT,
  SET_MODAL_OPTIONS,
  SET_HAS_VISITED_HOME,
  TOGGLE_UI_HIDE_ALL,
  TOGGLE_UI_HIDE_ANIMATION,
	TOGGLE_DRAWING_PANEL
} from '../actions/ui.js';

function ui(state = {activeModal:'', modalOptions:{}, sideBarVisible:false, activeContextMenu:"", editMode:false, activeProject:"", hasVisitedHome:false, hideMode:'', drawingPanelVisible: false}, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return Object.assign({}, state, { activeModal: action.data });
    case CLOSE_MODAL:
      return Object.assign({},state,{activeModal:"",modalOptions:{}});
    case SET_MODAL_OPTIONS:
      return Object.assign({},state,{modalOptions:action.data});
    case OPEN_CONTEXT_MENU:
      return Object.assign({},state,{activeContextMenu:action.data});
    case CLOSE_CONTEXT_MENU:
      return Object.assign({},state,{activeContextMenu:""});
    case SET_EDIT_MODE:
      return Object.assign({},state,{editMode:action.data});
    case SET_ACTIVE_PROJECT:
      return Object.assign({},state,{activeProject:action.data});
    case SET_HAS_VISITED_HOME:
      return Object.assign({},state,{hasVisitedHome:true});
    case TOGGLE_UI_HIDE_ALL:
      return Object.assign({}, state, {hideMode: state.hideMode === 'ALL' ? '' : 'ALL'});
    case TOGGLE_UI_HIDE_ANIMATION:
      return Object.assign({}, state, {hideMode: state.hideMode === 'ANIMATION' ? '' : 'ANIMATION'});
		case TOGGLE_DRAWING_PANEL:
			return Object.assign({}, state, {drawingPanelVisible: !state.drawingPanelVisible});
    default:
      return state
  }
}

export default ui;
