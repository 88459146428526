import React from 'react';
import {connect} from 'react-redux';

class NotFound extends React.Component {

	constructor (props) {
		super(props);

	}

	componentDidUpdate(prevProps, prevState) {
			if (prevProps.userID !== this.props.userID) {
				// Reload the page if the user logs in.
				window.location.reload();
			}
	}

	render () {
		let style = {
			"width": "100%",
			"height": "100%",
			"background":"linear-gradient(45deg, #0075a6 0%, #4affe5 100%)",
			"position": "absolute",
			"fontFamily": 'Sans-Serif',
			"fontSize": "50px",
			"textAlign": "center",
			"color": "#FFFFFF",
			"display":"flex",
			"alignItems":"center",
			"justifyContent":"center",
			"flexDirection":"column"
		}

		let noNetwork = {
			"fontSize":"20px",
			"background":"#FF0000",
			"padding":"5px"
		}

		if (!navigator.onLine) {
			return (
				<div style={style}>
					<div>Network Connection Unavailable.</div>
					<div style={{fontSize:"20px"}}>The page you requested has not been cached.</div>
					<div style={{fontSize:"20px"}}>You will need to connect to a network to see this page.</div>
				</div>
			)
		}

		return (
			<div style={style}>
				<div>404 Not Found.</div>
			</div>
		)
	}
}

let mapStateToProps = (state) => ({
  userID: state.user.id
})

export default connect(mapStateToProps)(NotFound);
