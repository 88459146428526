import React from "react";
import { ImagePanel } from './Image.js';
import { StaticPanel } from './Static.js';

function StaticImageLabelHeader (props) {
    const handleClone = (e) => {
      e.stopPropagation();
      Inventum.labels.duplicate(props.id);
      props.getLabels();
    }

    const handleDelete = (e) => {
      e.stopPropagation();
      Inventum.labels.delete(props.id);
      props.getLabels();
    };

    const handleVisible = (e) => {
      e.stopPropagation();
      Inventum.labels.toggleVisible(props.id);
      props.getLabels();
    };

		const handleToggleVisibleStart = (e) => {
			e.stopPropagation();
			Inventum.labels.toggleVisibleStart(props.id);
			props.getLabels();
		};

    const handleNameChange = (e) => {
      props.setLabelName(e.target.value);
    };

    const handleNameBlur = () => {
      props.setIsEditing(false);
    };
    
    const handleClickEnter = (e) => {
      if (e.key === 'Enter') {
        props.setIsEditing(false);
      }
    }

    const handleEditClick = (e) => {
      e.stopPropagation();
      props.setIsEditing(true);
    };

    return (
      <div onClick={props.toggleOpen} className='LabelHeader'>
        <span className='PanelOpenStatus'>{props.isOpen ? '▾' : '▸'}</span>
        <span className='LabelHeaderBtn delete' onClick={handleDelete} title='Delete Label'><i className="material-icons notranslate">clear</i></span>
        <span className='LabelHeaderBtn' onClick={handleClone} title='Clone Label'><i className="material-icons notranslate">copy_all</i></span>
				<span className='LabelHeaderBtn' style={{opacity: props.visibleStart ? 1.0 : 0.4}} onClick={handleToggleVisibleStart} title={props.visibleStart ? 'Label will appear when Scene Loads (Visible Start = True)' : "Label won't appear when scene loads (Visible Start = False)"}><i className="material-icons notranslate">{ props.visibleStart ? "tour" : "not_interested"}</i></span>
				<span className={'LabelHeaderBtn' + (props.visible ? ' active' : '')} onClick={handleVisible} title='Toggle Visible'><i className="material-icons notranslate">{ props.visible ? 'check_box' : 'check_box_outline_blank' }</i></span>
        <span className='LabelHeaderBtn' onClick={handleEditClick} title='Change Label Name'><i className="material-icons notranslate">edit</i></span>
        {props.isEditing ? (
          <input
            type="text"
            value={props.labelName}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
            onKeyDown={handleClickEnter}
            autoFocus
          />
        ) : (
          <span>{props.labelName}</span>
        )}
      </div>
    )
}

function StaticImageLabelDrawer (props) {
  const onImageLoad = (url) => {
    Inventum.labels.setStaticImage(props.id, url, () => {
      props.getLabels();
    });
  };
  return (
    <div style={{ padding: '5px' }}>
      <ImagePanel url={props.imageURL} onImageLoad={onImageLoad} {...props} />
      <StaticPanel {...props} />
    </div>
  )
}

class StaticImageLabel extends React.Component {
  constructor(props) {
    super(props);
    const currentLabelName = Inventum.labels.getLabelName(this.props.id) || "Default Label Name";
    this.state = {
      open: false,
      labelName: currentLabelName,
      isEditing: false,
    };

    // Bind methods to ensure 'this' context
    this.setLabelName = this.setLabelName.bind(this);
    this.setIsEditing = this.setIsEditing.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  setLabelName = (name) => {
    this.setState({ labelName: name });
    Inventum.labels.updateLabelName(this.props.id, name);
  };

  setIsEditing = (isEditing) => {
    this.setState({ isEditing });
  };

  toggleOpen = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  render() {
    const toggleOpen = () => {
        this.setState((prevState) => ({ open: !prevState.open }));
    };
    return (
      <div>
        <StaticImageLabelHeader
          toggleOpen={toggleOpen}
          labelName={this.state.labelName}
          isEditing={this.state.isEditing}
          setLabelName={this.setLabelName}
          setIsEditing={this.setIsEditing}
          isOpen={this.state.open}
          {...this.props}
        />
        <div style={{ marginLeft: "5px", backgroundColor: "#303030" }}>
          {this.state.open ? <StaticImageLabelDrawer {...this.props} /> : null}
        </div>
      </div>
    );
  }
}

export { StaticImageLabel }
