import {
  LOAD_COMPANY,
  UPDATE_COMPANY,
  CLEAR_COMPANY
} from '../actions/company.js';

function company(state = {}, action) {
  switch (action.type) {
    case LOAD_COMPANY:
      return Object.assign({},state,action.data)
    case UPDATE_COMPANY:
      return Object.assign({},state,action.data)
    case CLEAR_COMPANY:
      return {}
    default:
      return state
  }
}

export default company;
