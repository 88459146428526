export const EDIT_COMPANY_MAP = 'EDIT_COMPANY_MAP';
export const CLEAR_EDITED_COMPANY_MAP = 'CLEAR_EDITED_COMPANY_MAP';

export function clearEditedCompanyMap() {
  return {type:CLEAR_EDITED_COMPANY_MAP}
}

export function editCompanyMap(data) {
  return {type:EDIT_COMPANY_MAP,data}
}
