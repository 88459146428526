import React from 'react';
import SideBarContainer from './SideBar/SideBarContainer.js';
import ViewerButtons from './ViewerButtons.js';
import PopupContainer from './PopupContainer.js';
import {withRouter} from 'react-router-dom';
import InfoBox from './InfoBox.js';



class SiteControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }


  render() {
    let state = this.state;
    return (
      <div onKeyPress={this.handleKeyPress}>
        <SideBarContainer panelContent={[]} />
        <ViewerButtons loginProps={{handleLogin:this.handleLogin,handleLogout:this.handleLogout,}} loginStatus={state.user} openRegisterForm={()=>{this.setPopupVisibility("registerForm",true)}}/>
        <PopupContainer />
        <InfoBox />
        {this.props.children}
      </div>
    )
  }
}

let SiteControlsContainer = withRouter(SiteControls);

export default SiteControlsContainer;
