import React, {useState, memo} from 'react';
import axios from '../../../axios-config.js';
import {connect} from 'react-redux';
import {closeModal} from '../../../Redux/actions/ui.js';
import {BarChart, Bar, Label, XAxis, YAxis,Tooltip, Legend, Brush} from 'recharts';
import {ComposableMap, Geographies, Geography, Marker} from "react-simple-maps";
import {scaleQuantile} from "d3-scale";

import ReactTooltip from 'react-tooltip';
import DatePicker from 'react-datepicker';

const MONTH_NAMES = ["January","February","March","April","May","June","July","August","September","October","November","December"];
const WEEKDAY_NAMES = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]; //Google returns this Sun - Sat (0-6);
const colorArr = [
  "#003f5c",
  "#ffa600",
  "#2f4b7c",
  "#ff7c43",
  "#665191",
  "#f95d6a",
  "#a05195",
  "#d45087"
]

function sortArrayValue(a, b) {
  if (a.value > b.value) {
    return -1
  }else if(b.value > a.value) {
    return 1;
  }
  return 0;
}

function formatISODateWords(isoString) {
  let date = new Date(isoString);
  return `${date.getDate()} ${MONTH_NAMES[date.getMonth()]} ${date.getFullYear()}`
}

function legendFormatter(value, entry) {
  return <span style={{color:"#FFFFFF"}}>{value}</span>
}

class AnalyticsDirectory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render () {
    return (
      <div className="AnalyticsDirectoryContainer">
        <AnalyticsDataViewer closeModal={this.props.closeModal} activeCompanyID={this.props.activeCompanyID} />
      </div>
    )
  }
}

function LoadingIcon() {
  return (
    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
  )
}

class AnalyticsDataViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      report: {},
      selectedProject: "",
      selectedCategory: "pageViews",
      hasLoaded: false,
      maxMonthValue: 10,
      noProjectsError: false,
      loadingError: false,
      fromDate: null,
      toDate: null,
      refetchingData: false,
    };
    this.switchProject = this.switchProject.bind(this);
    this.calculateMaxMonthValue = this.calculateMaxMonthValue.bind(this);
    this.calculateCombinedProjects = this.calculateCombinedProjects.bind(this);
    this.processReport = this.processReport.bind(this);
    this.updateDates = this.updateDates.bind(this);
    this.getDynamicReport = this.getDynamicReport.bind(this);
  }

  componentDidMount() {
    //let timeoutID = setTimeout(function(),3000)
    axios
      .get(`https://api.inventum3d.com/stats?companyID=${this.props.activeCompanyID}`)
      .then((response) => {
        if (Object.keys(response.data.report.projects).length == 0) {
          this.setState({ hasLoaded: true, noProjectsError: true });
          return;
        }

        let report = this.processReport(response.data.report);
        let activeProject = Object.keys(report.projects)[0];
        let maxMonthValue = this.calculateMaxMonthValue(report, activeProject);

        report = this.calculateCombinedProjects(report);

        this.setState({
          report: report,
          selectedProject: activeProject,
          maxMonthValue: maxMonthValue,
          hasLoaded: true,
          fromDate: report.queryInfo.startDate,
          toDate: report.queryInfo.endDate,
        });
      })
      .catch((error) => {
        this.setState({ hasLoaded: true, loadingError: true });
        console.log(error);
      });
  }

  updateDates(fromDate, toDate) {
    this.setState({ fromDate, toDate, refetchingData: true }, () => {
      this.getDynamicReport();
    });
  }

  getDynamicReport() {
    axios
      .get(
        `https://api.inventum3d.com/stats?companyID=${this.props.activeCompanyID}&startDate=${this.state.fromDate}&endDate=${this.state.toDate}`
      )
      .then((response) => {
        //Clean Report
        let cleanedReport = this.processReport(response.data.report);

        //Merge Reports
        let existingReport = this.state.report;

        //Iterate over new projects and check they exist in the existing report (they always should)
        Object.keys(cleanedReport.projects).map((projectKey) => {
          if (existingReport.projects.hasOwnProperty(projectKey)) {
            let projectMetrics = cleanedReport.projects[projectKey].pageViews;
            //Set that metric to the project and overwrite the existing ones
            Object.keys(projectMetrics).map((metricKey) => {
              existingReport.projects[projectKey].pageViews[metricKey] = projectMetrics[metricKey];
            });
          }
        });

        existingReport = this.calculateCombinedProjects(existingReport);

        this.setState({ report: existingReport, refetchingData: false });
      });
  }

  processReport(report) {
    if (!report.hasOwnProperty("projects")) {
      this.setState({ hasLoaded: true, noProjectsError: true });
      return;
    }

    if (Object.keys(report.projects).length == 0) {
      this.setState({ hasLoaded: true, noProjectsError: true });
      return;
    }

    Object.keys(report.projects).map((projectName) => {
      let project = report.projects[projectName];

      if (project.pageViews.hasOwnProperty("dates")) {
        project.pageViews.dates.map((dateRecord) => {
          //Keep only the last part of the date (e.g 2019-01-05) we only want 05
          //dateRecord.date = dateRecord.date.split('-').pop();
          //We make a new array to store the project name. This is because the bar chart component will stack bars together if there is more than one project
          let t = new Date(dateRecord.date);
          dateRecord.projects = [projectName];
          //Store the value under the value_ProjectName key so the chart library can find the value for the dynamic bar
          //Note we can't delete the existing dateRecord.value key as the "All Projects Summing" function uses it
          dateRecord["value_" + projectName] = dateRecord.value;
        });
      }

      if (project.pageViews.hasOwnProperty("cities")) {
        project.pageViews.cities.sort(sortArrayValue);
      }

      if (project.pageViews.hasOwnProperty("geo")) {
        project.pageViews.geo = project.pageViews.geo.filter((point) => {
          if (point.lat !== 0 && point.lon !== 0) {
            console.log(point);
            return point;
          }
        });
      }

      if (project.pageViews.hasOwnProperty("sources")) {
        project.pageViews.sources.sort(sortArrayValue);
      }

      if (project.pageViews.hasOwnProperty("previousYearMonths")) {
        project.pageViews.previousYearMonths.map((record) => {
          //Store the first 3 characters of the month as a short name
          record.name = `${MONTH_NAMES[+record.month - 1].slice(0, 3)}`;
          //See notes above
          record.projects = [projectName];
          record["value_" + projectName] = record.value;
        });
      }

      if (project.pageViews.hasOwnProperty("previousYearDayOfWeek")) {
        project.pageViews.previousYearDayOfWeek.map((record) => {
          //Store the first 3 letters of the week day
          record.name = `${WEEKDAY_NAMES[+record.day].slice(0, 3)}`;
          //See notes above
          record.projects = [projectName];
          record["value_" + projectName] = record.value;
        });
      }

      if (project.pageViews.hasOwnProperty("currentYearMonths")) {
        project.pageViews.currentYearMonths.map((record) => {
          //Store the first 3 characters of the Month as a short name
          record.name = `${MONTH_NAMES[+record.month - 1].slice(0, 3)}`;
          //See notes above
          record.projects = [projectName];
          record["value_" + projectName] = record.value;
        });
      }
    });

    return report;
  }

  switchProject(project) {
    let maxMonthValue = this.calculateMaxMonthValue(this.state.report, project);
    this.setState({ selectedProject: project, maxMonthValue: maxMonthValue });
  }

  calculateMaxMonthValue(report, projectName) {
    let maxValue = 0;
    let pageViews = report.projects[projectName].pageViews;
    if (pageViews.hasOwnProperty("previousYearMonths")) {
      pageViews.previousYearMonths.map((record) => {
        if (record.value > maxValue) {
          maxValue = record.value;
        }
      });
    }

    if (pageViews.hasOwnProperty("currentYearMonths")) {
      pageViews.currentYearMonths.map((record) => {
        if (record.value > maxValue) {
          maxValue = record.value;
        }
      });
    }

    return maxValue;
  }

  calculateCombinedProjects(report) {
    if (Object.keys(report.projects).length < 2) {
      return report;
    }

    let summedProjects = {
      pageViews: {
        total: 0,
        currentYearMonths: [],
        previousYearMonths: [],
        dates: [],
        cities: [],
        sources: [],
      },
    };

    let currentYearMonthsHashTable = {};
    let previousYearMonthsHashTable = {};
    let citiesHashTable = {};
    let sourcesHashTable = {};
    let datesHashTable = {};
    let dowHashTable = {};
    let countryHashTable = {};
    let geoHashTable = {};

    Object.keys(report.projects).map((projectName) => {
      let project = report.projects[projectName];

      //For each project add all the totals together to get a grand total
      if (project.pageViews.hasOwnProperty("total")) {
        summedProjects.pageViews.total += project.pageViews.total;
      }

      //For each project merge all the cities together.
      //If a city already exists then add the values to get a total value
      if (project.pageViews.hasOwnProperty("cities")) {
        project.pageViews.cities.map((cityRecord) => {
          if (citiesHashTable.hasOwnProperty(cityRecord.city)) {
            citiesHashTable[cityRecord.city].value += cityRecord.value;
          } else {
            citiesHashTable[cityRecord.city] = Object.assign({}, cityRecord);
          }
        });
      }

      //For each project merge all the sources together.
      //If a source already exists then add the values to get a total value
      if (project.pageViews.hasOwnProperty("sources")) {
        project.pageViews.sources.map((sourceRecord) => {
          if (sourcesHashTable.hasOwnProperty(sourceRecord.source)) {
            sourcesHashTable[sourceRecord.source].value += sourceRecord.value;
          } else {
            sourcesHashTable[sourceRecord.source] = Object.assign({}, sourceRecord);
          }
        });
      }

      //For each project create a month key if it doesn't exist. Then push the project into the projects array on that object for that project
      //Then create a new key called "value_ProjectName" e.g value_Karlawinda. This is used by the charting library to get the data for the dynamic bar charts
      if (project.pageViews.hasOwnProperty("currentYearMonths")) {
        project.pageViews.currentYearMonths.map((monthRecord) => {
          if (currentYearMonthsHashTable.hasOwnProperty(monthRecord.month)) {
            currentYearMonthsHashTable[monthRecord.month].projects.push(projectName);
            currentYearMonthsHashTable[monthRecord.month]["value_" + projectName] = monthRecord.value;
          } else {
            currentYearMonthsHashTable[monthRecord.month] = { month: monthRecord.month, name: monthRecord.name, projects: [projectName] };
            currentYearMonthsHashTable[monthRecord.month]["value_" + projectName] = monthRecord.value;
          }
        });
      }

      //See notes above
      if (project.pageViews.hasOwnProperty("previousYearMonths")) {
        project.pageViews.previousYearMonths.map((monthRecord) => {
          if (previousYearMonthsHashTable.hasOwnProperty(monthRecord.month)) {
            previousYearMonthsHashTable[monthRecord.month].projects.push(projectName);
            previousYearMonthsHashTable[monthRecord.month]["value_" + projectName] = monthRecord.value;
          } else {
            previousYearMonthsHashTable[monthRecord.month] = { month: monthRecord.month, name: monthRecord.name, projects: [projectName] };
            previousYearMonthsHashTable[monthRecord.month]["value_" + projectName] = monthRecord.value;
          }
        });
      }

      //See notes above
      if (project.pageViews.hasOwnProperty("dates")) {
        project.pageViews.dates.map((datesRecord) => {
          if (datesHashTable.hasOwnProperty(datesRecord.date)) {
            datesHashTable[datesRecord.date].projects.push(projectName);
            datesHashTable[datesRecord.date]["value_" + projectName] = datesRecord.value;
          } else {
            datesHashTable[datesRecord.date] = { date: datesRecord.date, projects: [projectName] };
            datesHashTable[datesRecord.date]["value_" + projectName] = datesRecord.value;
          }
        });
      }

      //See notes above
      if (project.pageViews.hasOwnProperty("previousYearDayOfWeek")) {
        project.pageViews.previousYearDayOfWeek.map((dowRecord) => {
          if (dowHashTable.hasOwnProperty(dowRecord.day)) {
            dowHashTable[dowRecord.day].projects.push(projectName);
            dowHashTable[dowRecord.day]["value_" + projectName] = dowRecord.value;
          } else {
            dowHashTable[dowRecord.day] = { day: dowRecord.day, name: dowRecord.name, projects: [projectName] };
            dowHashTable[dowRecord.day]["value_" + projectName] = dowRecord.value;
          }
        });
      }

      if (project.pageViews.hasOwnProperty("country")) {
        project.pageViews.country.map((countryRecord) => {
          if (countryHashTable.hasOwnProperty(countryRecord.country)) {
            countryHashTable[countryRecord.country].value += countryRecord.value;
          } else {
            countryHashTable[countryRecord.country] = { country: countryRecord.country, value: countryRecord.value };
          }
        });
      }

      if (project.pageViews.hasOwnProperty("geo")) {
        project.pageViews.geo.map((geoRecord) => {
          if (geoHashTable.hasOwnProperty(`lat:${geoRecord.lat}_long:${geoRecord.long}`)) {
            geoHashTable[`lat:${geoRecord.lat}_long:${geoRecord.long}`].value += geoRecord.value;
          } else {
            geoHashTable[`lat:${geoRecord.lat}_long:${geoRecord.long}`] = {
              lat: geoRecord.lat,
              long: geoRecord.long,
              value: geoRecord.value,
            };
          }
        });
      }
    });

    let sortArrayMonths = (a, b) => {
      if (Number(a.month) < Number(b.month)) {
        return -1;
      } else if (Number(b.month) < Number(a.month)) {
        return 1;
      }
      return 0;
    };

    let sortArrayDates = (a, b) => {
      if (Number(a.date) < Number(b.date)) {
        return -1;
      } else if (Number(b.date) < Number(a.date)) {
        return 1;
      }
      return 0;
    };

    let sortArrayDow = (a, b) => {
      if (Number(a.day) < Number(b.day)) {
        return -1;
      } else if (Number(b.day) < Number(a.day)) {
        return 1;
      }
      return 0;
    };

    summedProjects.pageViews.currentYearMonths = Object.keys(currentYearMonthsHashTable)
      .map((key) => {
        return currentYearMonthsHashTable[key];
      })
      .sort(sortArrayMonths);
    summedProjects.pageViews.previousYearMonths = Object.keys(previousYearMonthsHashTable)
      .map((key) => {
        return previousYearMonthsHashTable[key];
      })
      .sort(sortArrayMonths);

    summedProjects.pageViews.cities = Object.keys(citiesHashTable)
      .map((key) => {
        return citiesHashTable[key];
      })
      .sort(sortArrayValue);
    summedProjects.pageViews.country = Object.keys(countryHashTable).map((key) => {
      return countryHashTable[key];
    });
    summedProjects.pageViews.geo = Object.keys(geoHashTable).map((key) => {
      return geoHashTable[key];
    });
    summedProjects.pageViews.sources = Object.keys(sourcesHashTable)
      .map((key) => {
        return sourcesHashTable[key];
      })
      .sort(sortArrayValue);

    summedProjects.pageViews.dates = Object.keys(datesHashTable)
      .map((key) => {
        return datesHashTable[key];
      })
      .sort(sortArrayDates);
    summedProjects.pageViews.previousYearDayOfWeek = Object.keys(dowHashTable)
      .map((key) => {
        return dowHashTable[key];
      })
      .sort(sortArrayDow);

    report.projects.All = summedProjects;
    return report;
  }

  render() {
    if (!this.state.hasLoaded) {
      return (
        <div className="AnalyticsLoadingContainer">
          <div className="AnalyticsLoadingBox">
            <LoadingIcon /> Fetching Report...
          </div>
        </div>
      );
    }

    if (this.state.loadingError) {
      return (
        <div className="AnalyticsLoadingContainer">
          <div className="AnalyticsLoadingBox">
            <CloseButton closeModal={this.props.closeModal} />
            Unable to fetch reports
          </div>
        </div>
      );
    }

    if (this.state.noProjectsError) {
      return (
        <div className="AnalyticsLoadingContainer">
          <div className="AnalyticsLoadingBox">
            <CloseButton closeModal={this.props.closeModal} />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>There are no valid projects to fetch reports for</div>
              <div>This could be because:</div>
              <ul>
                <li>The projects are in the legacy system </li>
                <li>No data exists yet for the projects</li>
              </ul>
              <div>If you think this is in error please contact support</div>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.refetchingData) {
      return (
        <div className="AnalyticsLoadingContainer">
          <div className="AnalyticsLoadingBox">
            <LoadingIcon />
            Fetching Data For New Report
          </div>
        </div>
      );
    }

    var activeData = {};
    if (this.state.report.projects) {
      var activeData = this.state.report.projects[this.state.selectedProject][this.state.selectedCategory];
    }

    let currentDate = new Date();
    let generatedDate = new Date(this.state.report.generated);
    let generatedMinutesAgo = ((currentDate - generatedDate) / 1000 / 60).toFixed(0);

    let scrollContainer = {
      display: "block",
      height: "100%",
      overflow: "auto",
    };

    return (
      <div className="AnalyticsDataViewerContainer analyticsReportPrintable">
        <div className="AnalyticsDataViewerContainerPanel">
          <AnalyticsHeader
            updateDates={this.updateDates}
            fromDate={this.state.fromDate}
            toDate={this.state.toDate}
            generatedMinutesAgo={generatedMinutesAgo}
            total={activeData.total}
            options={Object.keys(this.state.report.projects)}
            selected={this.state.selectedProject}
            switchProject={this.switchProject}
            closeModal={this.props.closeModal}
          />
        </div>
        <div style={scrollContainer} className="hide-scrollbar">
          <div className="AnalyticsDataViewerContainerPanel">
            <YearBarChartStacked
              tempActiveProject={this.state.selectedProject}
              yDomain={[0, this.state.maxMonthValue]}
              name={"Monthly Page Views"}
              year={`${currentDate.getFullYear() - 1}`}
              data={activeData.previousYearMonths}
            />
            <YearBarChartStacked
              tempActiveProject={this.state.selectedProject}
              yDomain={[0, this.state.maxMonthValue]}
              name={"Monthly Page Views"}
              year={`${currentDate.getFullYear()}`}
              data={activeData.currentYearMonths}
            />
          </div>
          <div className="AnalyticsDataViewerContainerPanel">
            <DayOfWeekStackedBarChart
              tempActiveProject={this.state.selectedProject}
              name={"Week Day Page Views"}
              dateRange={`[${formatISODateWords(this.state.fromDate)} - ${formatISODateWords(this.state.toDate)}]`}
              data={activeData.previousYearDayOfWeek}
            />
            <MonthStackedBarChart
              tempActiveProject={this.state.selectedProject}
              name={"Page Views"}
              dateRange={`[${formatISODateWords(this.state.fromDate)} - ${formatISODateWords(this.state.toDate)}]`}
              data={activeData.dates}
            />
          </div>
          <div className="AnalyticsDataViewerContainerPanel">
            <MapOfWorld name={`Top Countries by Page View`} markerData={activeData.geo} countryData={activeData.country} />
          </div>
          <div className="AnalyticsDataViewerContainerPanel">
            <ListValues name="Page Views by City" nameKey={"city"} valueKey={"value"} data={activeData.cities} />
            <ListValues name="Page Views by Source" nameKey={"source"} valueKey={"value"} data={activeData.sources} />
          </div>
        </div>
      </div>
    );
  }
}


var MapOfWorld = memo(function MapOfWorld(props) {
  if (!props.countryData && !props.markerData) {
    return (
      <div className="YearLineChart">
      <NoDataAvailable name={props.name} />
      </div>
    )
  }

  let geoUrl = "/static/data/world-110m.json"
  let countryData = props.countryData || [];
  let markerData = props.markerData || [];
  // console.log(markerData)

  var colorScale = scaleQuantile()
  .domain(countryData.map(cd => cd.value))
  .range([
    "#f7fbff",
    "#deebf7",
    "#c6dbef",
    "#9dcae1",
    "#6aaed6",
    "#4191c6",
    "#2070b4",
    "#08509b",
    "#08306b"]);

  const [content, setContent] = useState("");


  return (
    <div className="MonthLineChart" style={{ background: "#EEEEEE" }}>
      <div
        style={{
          color: "#000",
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "bold",
          textShadow: "2px 2px 4px #D0CCCC",
          paddingBottom: "10px",
        }}>
        <u>{props.name}</u>
      </div>
      <ReactTooltip>{content}</ReactTooltip>
      <ComposableMap data-tip="" projection="geoMercator" projectionConfig={{ center: [0, 30], scale: 50 }} width={400} height={200}>
        <Geographies geography={geoUrl} fill="#DDD" stroke="#FFF">
          {({ geographies }) =>
            geographies.map((geo) => {
              let cData = countryData.find((c) => {
                if (c.country == geo.properties.NAME_LONG || c.country == geo.properties.NAME) {
                  return c;
                }
              });
              let countryFillColor = "#CCC";
              let pageViews = 0;
              if (cData) {
                pageViews = cData.value;
                countryFillColor = colorScale(pageViews);
              }
              let countryName = geo.properties.NAME;
              return (
                <Geography
                  onMouseLeave={() => {
                    setContent("");
                  }}
                  onMouseEnter={() => {
                    setContent(`${countryName} - ${pageViews} Page Views`);
                  }}
                  style={{ default: { outline: "none" }, hover: { fill: "#FF0000", outline: "none" }, pressed: { outline: "none" } }}
                  fill={countryFillColor}
                  strokeWidth="0"
                  key={geo.rsmKey}
                  geography={geo}
                />
              );
            })
          }
        </Geographies>
        {markerData.map((point, index) => (
          <MapMarker value={point.value} key={index} lat={Number(point.lat)} long={Number(point.long)} />
        ))}
      </ComposableMap>
    </div>
  );
});

function MapMarker(props) {
  let min = (a,b) => {
    return a < b ? a : b
  }
  return (
    <Marker coordinates={[props.long, props.lat]}>
      <circle r={min(3, (0.4 + (0.1 * props.value)))} fill="#ff7a00" />
    </Marker>
  )
}

function NoDataAvailable(props) {
  return (
    <div style={{position:"relative", width:"500px",display:"flex",alignItems:"center",justifyContent:"center",color:"#FFFFFF",height:"100%",fontSize:"15px"}}>
      <div>{props.name} - <span style={{color:"#FF0000"}}>No Data Available</span></div>
    </div>
  )
}

function TooltipYearSummary(props) {
  let summedTotal = 0;
  if (props.payload.length > 1) {
    props.payload.map(entry => {
      summedTotal += entry.value;
    })
  }
  return (
    <div style={{background:"#FFFFFF", padding:"10px"}}>
      <div>{props.label}</div>
      {props.payload.map((entry, index) => <div key={index} style={{color:entry.fill}}>{entry.name} : {entry.value}</div>)}
      {props.payload.length > 1 ? <div>Total : {summedTotal}</div> : null}
    </div>
  )
}

function YearBarChartStacked(props) {
  if (!props.data) {
    return (
      <div className="YearLineChart">
        <NoDataAvailable name={props.name} />
      </div>
    )
  }
  return (
    <div className="YearLineChart">
      <div
        style={{
          color: "#FFF",
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "bold",
          paddingBottom: "15px",
          textShadow: "2px 2px 4px #000000",
        }}>
        {props.name}
        <div style={{ fontSize: "20px" }}>{props.year}</div>
      </div>
      <BarChart
        width={500}
        height={250}
        data={props.data}
        margin={{
          top: 30,
          right: 0,
          left: 30,
          bottom: 20,
        }}
        barGap={0}
        barCategoryGap={0}>
        {props.data[0].projects.length > 1 ? (
          <Legend wrapperStyle={{ top: "5px" }} formatter={legendFormatter} verticalAlign="top" />
        ) : null}
        <XAxis dataKey="name">
          <Label value="Month" offset={0} position="bottom" style={{ fontWeight: "bold", fill: "rgba(255, 255, 255, 1)" }} />
        </XAxis>
        <YAxis domain={props.yDomain}>
          <Label value="Views" offset={-20} position="left" style={{ fontWeight: "bold", fill: "rgba(255, 255, 255, 1)" }} />
        </YAxis>
        <Tooltip content={<TooltipYearSummary />} cursor={{ fill: "#444444" }} />
        {props.data[0].projects.map((project, index) => (
          <Bar
            key={index}
            name={project}
            dataKey={`value_${project}`}
            fill={props.data[0].projects.length > 1 ? colorArr[index] : "#25b9a4"}
            stackId="x"
          />
        ))}
      </BarChart>
    </div>
  );
}

function TooltipDatesSummary(props) {
  let tDate = new Date(props.label)
  let labelValue = `${props.label} (${WEEKDAY_NAMES[tDate.getDay()]})`

  let summedTotal = 0;
  if (props.payload.length > 1) {
    props.payload.map(entry => {
      summedTotal += entry.value;
    })
  }

  return (
    <div style={{background:"#FFFFFF", padding:"10px"}}>
      <div>{labelValue}</div>
      {props.payload.map((entry, index) => <div key={index} style={{color:entry.fill}}>{entry.name} : {entry.value}</div>)}
      {props.payload.length > 1 ? <div>Total : {summedTotal}</div> : null}
    </div>
  )
}


function MonthStackedBarChart(props) {
  if (!props.data) {
    return (
      <div className="YearLineChart">
      <NoDataAvailable name={props.name} />
      </div>
    )
  }
  return (
    <div className="MonthLineChart">
      <div
        style={{
          color: "#FFF",
          textAlign: "center",
          fontWeight: "bold",
          textShadow: "2px 2px 4px #000000",
        }}>
        <div style={{ fontSize: "20px" }}>{props.name}</div>
        <div style={{ fontSize: "15px" }}>{props.dateRange}</div>
      </div>
      <BarChart
        width={500}
        height={250}
        data={props.data}
        margin={{
          top: 30,
          right: 0,
          left: 30,
          bottom: 20,
        }}
        barGap={0}
        barCategoryGap={0}>
        {props.data[0].projects.length > 1 ? (
          <Legend wrapperStyle={{ top: "5px" }} formatter={legendFormatter} verticalAlign="top" />
        ) : null}
        <XAxis dataKey="date" hide={true} />
        <YAxis>
          <Label value="Views" offset={-20} position="left" style={{ fontWeight: "bold", fill: "rgba(255, 255, 255, 1)" }} />
        </YAxis>
        <Tooltip content={<TooltipDatesSummary />} cursor={{ fill: "#444444" }} />
        {props.data[0].projects.map((project, index) => (
          <Bar
            key={index}
            name={project}
            dataKey={`value_${project}`}
            fill={props.data[0].projects.length > 1 ? colorArr[index] : "#25b9a4"}
            stackId="x"
          />
        ))}
        <Brush dataKey="date" height={30} stroke="#8884d8" />
      </BarChart>
    </div>
  );
}
/*
<Label value="Day" offset={0} position="bottom" style={{ fill: 'rgba(255, 255, 255, 1)' }}/>
*/
function DayOfWeekStackedBarChart(props) {
  if (!props.data) {
    return (
      <div className="YearLineChart">
      <NoDataAvailable name={props.name} />
      </div>
    )
  }
  return (
    <div className="MonthLineChart">
      <div
        style={{
          color: "#FFF",
          textAlign: "center",
          fontWeight: "bold",
          textShadow: "2px 2px 4px #000000",
        }}>
        <div style={{ fontSize: "20px" }}>{props.name}</div>
        <div style={{ fontSize: "15px" }}>{props.dateRange}</div>
      </div>
      <BarChart
        width={500}
        height={250}
        data={props.data}
        margin={{
          top: 30,
          right: 0,
          left: 30,
          bottom: 20,
        }}
        barGap={0}
        barCategoryGap={0}>
        {props.data[0].projects.length > 1 ? (
          <Legend wrapperStyle={{ top: "5px" }} formatter={legendFormatter} verticalAlign="top" />
        ) : null}
        <XAxis dataKey="name">
          <Label value="Day" offset={0} position="bottom" style={{ fontWeight: "bold", fill: "rgba(255, 255, 255, 1)" }} />
        </XAxis>
        <YAxis>
          <Label value="Views" offset={-20} position="left" style={{ fontWeight: "bold", fill: "rgba(255, 255, 255, 1)" }} />
        </YAxis>
        <Tooltip content={<TooltipYearSummary />} cursor={{ fill: "#444444" }} />
        {props.data[0].projects.map((project, index) => (
          <Bar
            key={index}
            name={project}
            dataKey={`value_${project}`}
            fill={props.data[0].projects.length > 1 ? colorArr[index] : "#25b9a4"}
            stackId="x"
          />
        ))}
      </BarChart>
    </div>
  );
}



function ListValues(props) {
  if (!props.data) {
    return (
      <div className="YearLineChart">
      <NoDataAvailable name={props.name} />
      </div>
    )
  }
  return (
    <div className="AnalyticsListValues">
      <div
        style={{
          color: "#FFF",
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "bold",
          padding: "10px",
          textShadow: "2px 2px 4px #000000",
        }}>
        {props.name}
      </div>
      <div style={{ height: "100%", overflowY: "auto" }} className="hide-scrollbar">
        <table style={{ width: "100%" }}>
          <thead className="AnalyticsTableStickyHeader">
            <tr className="AnalyticsTableStickyHeader">
              <th className="AnalyticsTableStickyHeader" style={{ textAlign: "left", paddingBottom: "4px" }}>
                City
              </th>
              <th className="AnalyticsTableStickyHeader" style={{ textAlign: "left", paddingBottom: "4px" }}>
                Page Views
              </th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((record, index) => (
              <tr className="AnalyticsListEntry" key={index}>
                <td>{record[props.nameKey]}</td>
                <td>{record[props.valueKey]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}


class AnalyticsHeader extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      projectSelectorMenuOpen:false,
      dateSelectorMenuOpen:false
    };
  }

  render() {
    let arrowStyle = {
      lineHeight:"0px",
      position:"absolute",
      top:"12px"
    }
    return (
      <div
        className="AnalyticsHeader"
        onClick={() => {
          if (this.state.projectSelectorMenuOpen) {
            this.setState({ projectSelectorMenuOpen: false });
          }
        }}>
        <div>
          <span
            style={{
              color: "#FFF",
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "bold",
              padding: "10px",
              textShadow: "2px 2px 4px #000000",
            }}>
            Analytics Report for
          </span>
          <span
            style={{ position: "relative", width: "auto" }}
            onClick={() => {
              this.setState({ projectSelectorMenuOpen: !this.state.projectSelectorMenuOpen });
            }}
            className="AnalyticsHeaderSpan">
            {this.props.selected}
            <i style={arrowStyle} className="material-icons notranslate">
              expand_more
            </i>
            {this.state.projectSelectorMenuOpen ? (
              <SelectorMenu selected={this.props.selected} handleSelect={this.props.switchProject} options={this.props.options} />
            ) : null}
          </span>
        </div>

        <div style={{ width: "400px" }}>
          <span
            style={{
              color: "#FFF",
              textAlign: "center",
              fontSize: "15px",
              fontWeight: "bold",
              padding: "10px",
              textShadow: "2px 2px 4px #000000",
            }}>
            Date Range:
          </span>
          <span
            style={{ position: "relative", width: "auto" }}
            onClick={() => {
              this.state.dateSelectorMenuOpen ? null : this.setState({ dateSelectorMenuOpen: true });
            }}
            className="AnalyticsHeaderSpan">
            {formatISODateWords(this.props.fromDate)} - {formatISODateWords(this.props.toDate)}{" "}
            <i style={arrowStyle} className="material-icons notranslate">
              expand_more
            </i>
            {this.state.dateSelectorMenuOpen ? (
              <DateSelectorMenu
                updateDates={this.props.updateDates}
                closeMenu={() => {
                  this.setState({ dateSelectorMenuOpen: false });
                }}
                fromDate={this.props.fromDate}
                toDate={this.props.toDate}
              />
            ) : null}
          </span>
        </div>

        <div style={{ paddingRight: "20px" }}>
          <span
            style={{
              color: "#FFF",
              textAlign: "center",
              fontSize: "15px",
              fontWeight: "bold",
              padding: "10px",
              textShadow: "2px 2px 4px #000000",
            }}>
            Page Views:
          </span>
          <span style={{ paddingLeft: "5px", color: "#25b9a4", fontSize: "18px" }}>{this.props.total || 0}</span>
        </div>

        <CloseButton closeModal={this.props.closeModal} />

        <div className="ReportGeneratedBox">
          Report Generated:
          {this.props.generatedMinutesAgo > 60
            ? ` ${(this.props.generatedMinutesAgo / 60).toFixed(0)} hour(s) ago`
            : ` ${this.props.generatedMinutesAgo} minute(s) ago`}
        </div>
      </div>
    );
  }
}

function SelectorMenu(props) {
  let options = props.options;
  //If "All" Option exists move it to the top of array for convinience in menu
  if (options.indexOf('All') !== -1 && options.indexOf('All') !== 0) {
    options.splice(options.indexOf('All'),1);
    options.unshift('All');
  }
  return (
    <div className="AnalyticsSelectorMenu">
      {options.map((option, index) => <div className={"AnalyticeSelectorEntry" + (props.selected == option ? " selected" : "")} key={index} onClick={()=>{props.handleSelect(option)}}>{option}</div>)}
    </div>
  )
}

class DateSelectorMenu extends React.Component {
  constructor(props) {
    super(props);
    let fromDate = new Date(props.fromDate);
    let toDate = new Date(props.toDate);

    this.state = {
      fromDate:fromDate,
      toDate:toDate
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  handleSelect(update) {
    let tState = this.state;
    tState[update.key] = update.value;
    this.setState(tState);
  }

  handleUpdate() {
    let fromDateString = this.state.fromDate.toISOString().split('T')[0];
    let toDateString = this.state.toDate.toISOString().split('T')[0];

    this.props.updateDates(fromDateString, toDateString);
    this.props.closeMenu();
  }

  render() {
    return (
      <div className="AnalyticsDateSelectorMenu">
        <div style={{textAlign:"center",paddingTop:"10px"}}>Select Date Range</div>
        <div style={{display:"flex"}}>

          <div style={{padding:"10px"}}>
            <div style={{textAlign:"center"}}>From</div>
            <DatePicker
            onChange={(e) => {this.handleSelect({key:"fromDate",value:e})}}
            selected={this.state.fromDate}
            dateFormat="dd/MM/yyyy"
            inline
            />
          </div>

          <div style={{padding:"10px"}}>
            <div style={{textAlign:"center"}}>To</div>
            <DatePicker
            onChange={(e) => {this.handleSelect({key:"toDate",value:e})}}
            selected={this.state.toDate}
            dateFormat="dd/MM/yyyy"
            inline
            />
          </div>

        </div>
        <div style={{display:"flex",flexDirection:"row-reverse"}}>
          <div onClick={this.handleUpdate} className="UpdateDatesButton">Update</div>
          <div onClick={this.props.closeMenu} className="UpdateDatesButton">Cancel</div>
        </div>
      </div>
    )
  }
}


function CloseButton(props) {
  return (
    <div onClick={()=>{props.closeModal()}} className="AnalyticsCloseButton"><i className="material-icons notranslate">close</i></div>
  )
}

let mapDispatchToProps = dispatch => ({
  closeModal:() => {
    dispatch(closeModal());
  }
})

let mapStateToProps = (state) => ({
  activeCompanyID:state.activePage.companyID,
})

const AnalyticsDirectoryContainer = connect(mapStateToProps,mapDispatchToProps)(AnalyticsDirectory)

export default AnalyticsDirectoryContainer
