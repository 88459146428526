class MarkerCanvas {
  constructor (canvasColor) {
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
    this.ctx.canvas.width = 128;
    this.ctx.canvas.height = 128;
    this.canvasColor = canvasColor;
    this.render();
  }

  loadImage (path) {
    /* var img = new Image();
    img.onload = function () {
        ctx.drawImage(img, 0, 0);
    };
    img.src = path; */
  }

  setStyle (updatedColor) {
    this.canvasColor = updatedColor;
    this.render();
  }

  getCanvas () {
    return this.canvas;
  }

  render () {
    this.ctx.clearRect(0, 0, 128, 128);
    this.ctx.arc(64, 64, 50, 0, 2 * Math.PI, false);
    this.ctx.fillStyle = this.canvasColor;
    this.ctx.fill();
    return this.canvas;
  }
}

export { MarkerCanvas };
