import { Engine } from './modules/core/engine.js';
const Inventum = {};

window.Inventum = Inventum;

Inventum.load = function loadScene (sceneJSON) {
  Engine.setup(this);
  Engine.load(sceneJSON);
};

Inventum.generateSceneJSON = function generateSceneJSON () {
  return Engine.generateSceneJSON();
};

Inventum.onLoadComplete = function onLoadComplete (callback) {
  Engine.registerLoadCallback(callback);
};

Inventum.unload = function unload () {
  Engine.cleanup();
};

export default Inventum;
