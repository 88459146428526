import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {toggleSidebar} from '../../../Redux/actions/sidebarUI.js';

function OpenSideBar(props) {
  return (
    <div className="sidebarToggleButton" onClick={props.toggleSidebar}>
      <i className="material-icons notranslate">menu</i>
      {props.hasOpened ? null : <div className="pulsating-circle"></div>}
    </div>
  )
}

function InventumIcon(props) {
  let style = {
    "paddingRight":"5px",
    "paddingTop":"3px"
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 256 256" style={style}>
      <path d="M139.5 9c-10-1.6-61 2.7-79.1 41 -18.8 39.7 63.5 7 92.6 6.2 21.1-0.6 68.3-6.5 83.6 21.6 11.8 21.7 12.6 41.3 12.7 45 -2.3-17.5-18.6-58.6-114.1-32 -95.5 26.6-102.5 58-119.3 31.4C1.2 98.8 41.6-6.2 139.5 9" className="inventumLogo"/>
      <path d="M116 247c10 1.6 61-2.7 79.1-41 18.8-39.7-63.5-7-92.6-6.2 -21.1 0.6-68.3 6.5-83.6-21.6C7 156.5 6.2 137 6.1 133.2c2.3 17.5 18.7 58.6 114.1 32 95.5-26.6 102.5-58 119.3-31.4C254.2 157.2 213.8 262.2 116 247" className="inventumLogo"/>
    </svg>
  )
}

function LogoBox(props) {
  if (!props.logo) {
    return null;
  }
  let logoPath = `url('https://mapability-website.s3.amazonaws.com/company-logos/${props.logo}')`;
  let style = {width:"70px",height:"70px",backgroundImage:logoPath,backgroundSize:'cover',marginRight:"10px"}
  return (
    <div style={style}></div>
  )
}

function PageTitle(props) {
  let defaultStyle = {
    "backgroundImage": "url(/static/img/logo/inventum_logo_filled.png)",
    "backgroundSize": "contain",
    "backgroundRepeat": "no-repeat",
    "width": "280px",
    "height": "58px"
  }
  if (!props.title) {
    if (props.sideBarVisible) { return null }; //Don't show logo if sidebar is open
    return(
      <div style={defaultStyle} className="projectHeading"></div>
    )
  };
  let handleClick = () => {
    if (props.website) {
      window.location.href = props.website;
    }
  }
  return (
    <div className="projectHeading" onClick={handleClick}>{props.title}</div>
  )
}

function PageSubtitle(props) {
  if (!props.subtitle) {return null};
  return (
    <div className="companyHeading"><a>{props.subtitle}</a></div>
  )
}

function LinkBox(props) {
  if (!props.website || props.mode === "2D") {return null};

  let handleInventumClick = () => {
  }

  let handleWebClick = () => {
    window.open(props.website,"_blank");
    //window.location.href = props.website;
  }

  return (
  <div className="linkBox">
    <div className="companyLink" onClick={handleWebClick}>
      <i className="material-icons notranslate">language</i>
      Company Website
    </div>
    <div className="inventumLink" onClick={handleInventumClick}>
      <InventumIcon />
      <Link to= './'>Company Projects Map</Link>
    </div>
  </div>
  )
}

function TitleBox(props) {
  //      <ProjectInfo {...props} />

  return (
    <div className={"menu-controls-info " + (props.sideBarVisible ? "moved" :"")}>
      {props.sideBarVisible ? null : <OpenSideBar hasOpened={props.hasOpened} toggleSidebar={props.toggleSidebar} />}
    </div>
  )
}

let mapDispatchToProps = dispatch => ({
  toggleSidebar: () => {
    dispatch(toggleSidebar());
  }
})

let mapStateToProps = (state) => ({
  title:state.sidebarUI.title,
  subtitle:state.sidebarUI.subtitle,
  mode:state.sidebarUI.mode,
  website:state.sidebarUI.website,
  logo:state.sidebarUI.logo,
  sideBarVisible:state.sidebarUI.visible,
  hasOpened:state.sidebarUI.hasOpened
})

const TitleBoxContainer = connect(mapStateToProps,mapDispatchToProps)(TitleBox)

export default TitleBoxContainer;
