import React from 'react';
import axios from '../../../../axios-config.js';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';


const InputField = (props) => {
  return (
    <div className="CreateCompanyInput">
      <div>{props.title}</div>
      <input value={props.value} onChange={(e)=>{props.onChange(e.target.value)}} placeholder={props.placeholder} />
    </div>
  )
}

const Submit = (props) => {
  return (
    <div onClick={props.onClick} className="CreateCompanySubmit">Submit</div>
  )
}


class CompanyCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name:"",
      website:"",
      slug:""
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    let state = this.state;
    if (state.name.length === 0) {
      window.alert(`Name can't be blank`);
      return;
    }
    axios.post('https://api.inventum3D.com/company',{name:state.name,website:state.website,slug:state.slug})
    .then((response) => {
      this.props.back();
    })
    .catch((error) => {
      window.alert('something went wrong. Check the console');
      console.log(error);
    })
  }

  render() {
    return (
      <div>
        <div style={{"textAlign":"center"}}>Create Company</div>
        <div className="CreateCompanyForm">
          <InputField title={"Name"} placeholder={"XYZ Limited"} value={this.state.name} onChange={(name) => {this.setState({name})}} />
          <InputField title={"Website"} placeholder={"https://example.com"} value={this.state.website} onChange={(website) => {this.setState({website})}} />
          <InputField title={"Slug"} placeholder={"example"} value={this.state.slug} onChange={(slug) => {this.setState({slug})}} />
          <Submit onClick={this.handleSubmit} />
        </div>
      </div>
    )
  }
}

export default CompanyCreator
