export const LOAD_INVENTUM_SCENES = 'LOAD_INVENTUM_SCENES';
export const CLEAR_INVENTUM_SCENES = 'CLEAR_INVENTUM_SCENES';

export function loadInventumScenes(data) {
  return { type:LOAD_INVENTUM_SCENES, data}
}

export function clearInventumScenes() {
  return { type:CLEAR_INVENTUM_SCENES }
}
