import React from 'react';
import { Remarkable } from 'remarkable';

var md = new Remarkable();

function CloseButton(props) {
  let handleClick = () => {
    let id = props.slideID;
    Inventum.slides.setVisible(id,false);
  }
  return (
    <div className="SlideCloseButton" onClick={handleClick}>
      <i className="material-icons notranslate">close</i>
    </div>
  )
}

function SlideElementText(props) {
  let style = {};
  if (props.style) {
    style = Object.assign({},props.style);
  }
  return (
    <div className="SlideElementText" style={style}>
      {props.content}
    </div>
  )
}

function SlideElementMarkdown(props) {
  let innerHtml = {__html:md.render(props.content)};

  let style = {};
  if (props.style) {
    style = Object.assign({},props.style);
  }
  style['p'] = {'margin':'0px'}; //WHAT IS THIS?
  return (
    <div className="SlideElementText" style={style} dangerouslySetInnerHTML={innerHtml}></div>
  )
}


function SlideElementImage(props) {
  let style = {};
  if (props.style) {
    style = Object.assign({},props.style);
  }
  style.backgroundImage = "url('" + props.content + "')";
  return (
    <div className="SlideElementImage" style={style}></div>
  )
}

class SlideElementYouTubeVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayWarning: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({displayWarning:true});
    },10000);
  }

  componentWillUnmount() {
    this.setState({displayWarning:false});
  }

  render() {
    let style = {};
    if (this.props.style) {
      style = Object.assign({},this.props.style);
    }

    return (
      <div className="SlideElementVideo" style={style}>
        <LoadingYouTube displayWarning={this.state.displayWarning} />
        <iframe width="100%" height="100%" src={"https://www.youtube.com/embed/" + this.props.content + "?autoplay=1&mute=1&rel=0&controls=0&modestbranding=1&showinfo=0"} frameBorder="0" allowFullScreen></iframe>
      </div>
    )
  }
}

function LoadingYouTube(props) {
  if (props.displayWarning) {
    return (
      <div>
        <div className="LoadingYoutube">YouTube may be blocked on your network</div>
        <div className="LoadingYoutube">Please contact your Administrator</div>
      </div>

    )
  }
  return (
      <div className="LoadingYoutube">Loading YouTube Video...</div>
    )
}

function SlideElement(props) {
  switch(props.type) {
    case "TEXT":
      return <SlideElementText content={props.content} style={props.rStyle} />
      break;
    case "IMAGE":
      return <SlideElementImage content={props.content} style={props.rStyle} />
      break;
    case "YOUTUBE_VIDEO":
      return <SlideElementYouTubeVideo content={props.content} style={props.rStyle} />
    case "MARKDOWN":
      return <SlideElementMarkdown content={props.content} style={props.rStyle} />
    default:
      console.log('Unsupported Slide Element Type');
      return null
  }
  return null
}

class Slide extends React.Component {
  constructor(props)
  {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    let slideID = this.props.id;
    if (this.props.clickEnabled) {
      Inventum.slides.nextSlideKeyframe(slideID);
    }else {
      console.log('Slide clicked but it is disabled');
    }
  }

  render() {
    let slideID = this.props.id;
    let elements = this.props.elements;
    return (
      <div className="Slide" style={this.props.style} onClick={this.handleClick}>
      {this.props.canClose ? <CloseButton slideID={slideID} /> : null}
      {elements.map((element, index) => <SlideElement key={index} rStyle={element.style} content={element.content} type={element.type} />)}
      </div>
    )
  }
}

export default Slide;
