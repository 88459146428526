import * as THREE from 'three';
import { CTM } from '../libs/ctm.js';

function CTMLoader (url, callback, onError) {
  window.fetch(url)
    .then(response => {
      if (response.ok) {
        return response.arrayBuffer();
      } else {
        throw new Error('Network Error');
      }
    })
    .then(buffer => {
      const arr = new Uint8Array(buffer);
      var stream = new CTM.Stream(arr);
      var file = new CTM.File(stream);
      loadGeometry(file, callback);
    })
    .catch((error) => {
      console.log(error);
      if (typeof(onError) === 'function') {
        onError(error);
      }
    });
}

function loadGeometry (file, callback) {
  var geometry = new THREE.BufferGeometry();

  var indices = file.body.indices;
  const verts = file.body.vertices;
  const normals = file.body.normals;

  const uvMaps = file.body.uvMaps;
  const attrMaps = file.body.attrMaps;
  let uvs = null;
  let colors = null;

  if (uvMaps && uvMaps.length > 0) {
    uvs = file.body.uvMaps[0].uv;
  }

  if (attrMaps && attrMaps.length > 0 && attrMaps[0].name === 'Color') {
    colors = attrMaps[0].attr;
  }

  geometry.setIndex(new THREE.BufferAttribute(indices, 1));
  geometry.setAttribute('position', new THREE.BufferAttribute(verts, 3));

  if (normals) {
    geometry.setAttribute('normal', new THREE.BufferAttribute(normals, 3));
  } else {
    geometry.computeVertexNormals();
  }

  if (uvs) {
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
  }

  if (colors) {
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 4));
  }

  callback(geometry);
}

export { CTMLoader };
