import * as THREE from 'three';
import { store } from './store.js';
import { sceneStates } from '../tools/sceneStates.js';
import { slides } from '../slides/slides.js';
import { models } from '../models/models.js';
import { views } from '../camera/views.js';
import { animations } from '../camera/animations.js';
import { labels } from '../ui/labels.js';
import { lights } from '../world/lights.js';
import { clipping } from '../tools/clipping.js';
import { sceneGrid } from '../world/grid.js';
import { world } from '../world/world.js';

let currentScene = null;
let unsavedChanges = false;

var sceneLoader = {
  public: {}
};

sceneLoader.load = function load (sceneJSON, onCompleteCallback) {
  currentScene = sceneJSON;
  window.sceneJSON = sceneJSON; // For debug copying scene;
  processFile(sceneJSON, onCompleteCallback);
};

sceneLoader.public.clearUnsaved = function confirmSave () {
  unsavedChanges = false;
};

sceneLoader.public.setUnsaved = function setUnsaved () {
  unsavedChanges = true;
};

store.setUnsavedScene = sceneLoader.public.setUnsaved;

sceneLoader.public.isUnsaved = function getUnsaved () {
  return unsavedChanges;
};

sceneLoader.generateSceneJSON = function generateSceneJSON () {
  const updatedCameraJSON = {
    camera: Object.assign(
      {},
      currentScene.camera,
      views.generateJSON(),
      animations.generateJSON(),
			{ sceneFOV: store.sceneFOV }
    )
  };

	if (Object.prototype.hasOwnProperty.call(updatedCameraJSON.camera, 'fov')) {
		delete updatedCameraJSON.camera.fov;
		console.log('Removed legacy FOV from Camera. Replaced with sceneFOV');
	}

  const tInventumTransform = {
    x: store.inventumTransform.x,
    y: store.inventumTransform.y,
    z: store.inventumTransform.z
  };

  const updatedTransforms = { transforms: { inventum: tInventumTransform, regional: store.regionalTransform, custom: [] } };

  const updatedProject = {
    project: Object.assign(
      {},
      currentScene.project,
      { storagePath: store.storagePath }
    )
  };

  // Note. At the moment the world properties are scattered in different places
  // Lights have their own manager.
  // Scene Transforms are stored in the global store
  // Grid and Sky settings are stored under the world.js module. It'd be nice to eventually unify everything
  // For now we can just merge gridSkySerialized  into the updatedWorld object
  // gridSkySerialized only provides the grid and sky

  const gridSkySerialized = world.serialize(); // {grid:{},sky:{}}
  const scaleSerial = { scale: currentScene.world.scale };
  scaleSerial.scale.global = store.globalscale;

  const updatedWorld = {
    world: Object.assign(
      {},
      currentScene.world,
      lights.generateJSON(),
      updatedTransforms,
      gridSkySerialized,
      scaleSerial
    )
  };

  const newScene = Object.assign(
    {},
    currentScene,
    sceneStates.generateJSON(),
    models.generateJSON(),
    slides.generateJSON(),
    labels.generateJSON(),
    clipping.generateJSON(),
    updatedProject,
    updatedCameraJSON,
    updatedWorld
  );

  // Bug Cleanup!
  // 2019-02-27.
  // For awhile the loader incorrectly saved with the key sceneStates instead of scenestates
  // This line of code cleans that up if we find it and fixes the problem. Can remove in the future. Maybe after August 2019.
  if (Object.prototype.hasOwnProperty.call(newScene, 'sceneStates')) {
    console.warn('Bug Detected: sceneStates instead of scenestates. Fixing');
    delete newScene.sceneStates;
  }

  if (Object.prototype.hasOwnProperty.call(newScene, 'project')) {
    delete newScene.project.company;
    delete newScene.project.name;
    delete newScene.project.logo;
    delete newScene.project.website;
  }

  if (Object.prototype.hasOwnProperty.call(newScene, 'meta')) {
    console.log('Removing old meta property from Scene State. Now handled by DB');
    delete newScene.meta;
  }

  if (Object.prototype.hasOwnProperty.call(newScene, 'pointclouds')) {
    console.log('Removing old pointclouds from Scene State. Not currently supported');
    delete newScene.pointclouds;
  }
  return newScene;
};

function processFile (data, onCompleteCallback) {
  if (data.project) {
    processProject(data.project);
  }
  processWorld(data.world);
  processScenestates(data.scenestates);
  processSlides(data.slides);
  processLabels(data.labels);
  processEngine(data.engine);
  processCamera(data.camera);
  processGroups(data.groups);
  processClipping(data.clipping);
  onCompleteCallback();
}

function processClipping (clippingData) {
  if (!clippingData) {
    return;
  }
  clippingData.map(data => {
    clipping.loadPlane(data);
  });
}

function processGroups (groupData) {
  let remotePath = '';
  if (store.storagePath !== undefined) {
    remotePath = store.storagePath;
  }
  const modelIDsUsed = [];
  const needsID = [];
  groupData.map((group) => {
    const modelList = [];
    group.models.map((modelData) => {
      if (modelIDsUsed[modelData.id] === undefined) {
        modelIDsUsed[modelData.id] = true;
      } else {
        //console.log(`Duplicate ID: ${modelData.id}. For model ${modelData.name}`);
        needsID.push(modelData);
      }

      // Makes an empty material if none exists.
      const material = processMaterial(modelData.material, remotePath);

      const model = {
        id: modelData.id,
        name: modelData.name,
        path: modelData.path,
        material: material,
        receiveShadow: modelData.receiveShadow,
        castShadow: modelData.castShadow,
        visibleStart: modelData.visibleStart,
        needsReview: typeof (modelData.needsReview) === 'boolean' ? modelData.needsReview : false,
        isLoaded: false,
        downloadAutomatically: typeof (modelData.downloadAutomatically) === 'boolean' ? modelData.downloadAutomatically : true
      };

      if (modelData.position) {
        model.position = modelData.position;
      }

      if (modelData.rotation) {
        model.rotation = modelData.rotation;
      }

      if (modelData.scale) {
        model.scale = modelData.scale;
      }

      // Handles case where path isn't an absolute path;
      if (!modelData.path.toLowerCase().startsWith('http')) {
        model.path = remotePath + modelData.path;
      }

      modelList.push(model);
    });

    const collection = { name: group.name, models: modelList };
    models.addCollection(collection);
  });

  // Fix duplicate IDs by assigning earliest available ID.
  for (var i = 0; i < needsID.length; i++) {
    const model = needsID[i];
    model.id = (() => {
      let id = 1;
      while (1) {
        if (!modelIDsUsed[id]) {
          return id;
        }
        id++;
      }
    })();
  }
}

function processMaterial (materialData, remotePath) {
  if (!materialData) {
    return THREE.MeshStandardMaterial();
  }

  let tempMaterial = {};
  switch (materialData.type) {
    case 'MeshLambertMaterial':
      tempMaterial = new THREE.MeshLambertMaterial();
      if (Object.prototype.hasOwnProperty.call(materialData, 'emissive')) {
        tempMaterial.emissive.setStyle(materialData.emissive);
      }
      break;
    case 'MeshPhongMaterial':
      tempMaterial = new THREE.MeshPhongMaterial();
      tempMaterial.shininess = materialData.shininess;
      tempMaterial.emissive.setStyle(materialData.emissive);
      tempMaterial.specular.setStyle(materialData.specular);
      break;
    case 'MeshBasicMaterial':
      tempMaterial = new THREE.MeshBasicMaterial();
      break;
    case 'MeshStandardMaterial':
      tempMaterial = new THREE.MeshStandardMaterial();
      tempMaterial.roughness = !isNaN(materialData.roughness) ? materialData.roughness : 0.5;
      tempMaterial.metalness = !isNaN(materialData.metalness) ? materialData.metalness : 0.5;
      if (Object.prototype.hasOwnProperty.call(materialData, 'emissive')) {
        tempMaterial.emissive.setStyle(materialData.emissive);
      }
      break;
    case 'MeshPhysicalMaterial':
      tempMaterial = new THREE.MeshPhysicalMaterial();
      tempMaterial.roughness = !isNaN(materialData.roughness) ? materialData.roughness : 0.5;
      tempMaterial.metalness = !isNaN(materialData.metalness) ? materialData.metalness : 0.5;
      tempMaterial.clearCoat = !isNaN(materialData.clearCoat) ? materialData.clearCoat : 0.0;
      tempMaterial.clearCoatRoughness = !isNaN(materialData.clearCoatRoughness) ? materialData.clearCoatRoughness : 0.0;
      if (Object.prototype.hasOwnProperty.call(materialData, 'emissive')) {
        tempMaterial.emissive.setStyle(materialData.emissive);
      }
      break;
    default:
      console.log('Invalid Material Type ' + materialData.type + ' Defaulting to MeshBasicMaterial');
      tempMaterial = new THREE.MeshBasicMaterial();
  }

  if (materialData.map) {
    var tempTexture = {};

    if (materialData.map.toLowerCase().startsWith('http')) {
      // If the path starts with http treat it as an absolute path
      tempTexture = new THREE.TextureLoader().load(materialData.map, store.requestRender);
    } else {
      // Otherwise treat it as a relative path.
      tempTexture = new THREE.TextureLoader().load(remotePath + materialData.map, store.requestRender);
    }

    var maxAnisotropy = store.webglRenderer.capabilities.getMaxAnisotropy();
    tempTexture.anisotropy = maxAnisotropy;
    tempTexture.minFilter = THREE.LinearFilter;
    const wrapS = materialData.wrapS;
    const wrapT = materialData.wrapT;
    if (wrapS === 'RepeatWrapping') {
      tempTexture.wrapS = THREE.RepeatWrapping;
    } else if (wrapS === 'ClampToEdgeWrapping') {
      tempTexture.wrapS = THREE.ClampToEdgeWrapping;
    } else if (wrapS === 'MirroredRepeatWrapping') {
      tempTexture.wrapS = THREE.MirroredRepeatWrapping;
    }

    if (wrapT === 'RepeatWrapping') {
      tempTexture.wrapT = THREE.RepeatWrapping;
    } else if (wrapT === 'ClampToEdgeWrapping') {
      tempTexture.wrapT = THREE.ClampToEdgeWrapping;
    } else if (wrapT === 'MirroredRepeatWrapping') {
      tempTexture.wrapT = THREE.MirroredRepeatWrapping;
    }

    tempMaterial.map = tempTexture;
  }

  tempMaterial.color.setStyle(materialData.color);
  tempMaterial.transparent = materialData.transparent;
  tempMaterial.opacity = materialData.opacity;
  tempMaterial.depthWrite = materialData.depthWrite;
  if (materialData.depthTest === true || materialData.depthTest === false) {
    tempMaterial.depthTest = materialData.depthTest;
  }

  // Legacy shading reader.
  switch (materialData.shading) {
    case 'FlatShading':
      // FIXME Using FlatShading causes WebGL Shader Errors.
      // console.warn('FlatShading is currently broken. This should not cause an issue');
      // tempMaterial.shading = THREE.FlatShading;
      // console.log(THREE.FlatShading);
      // break;
      tempMaterial.flatShading = true;
      // console.warn('Shading property is deprecated. Use flatShading boolean instead')
      break;
    case 'SmoothShading':
      // console.warn('Shading property is deprecated. Use flatShading boolean instead')
      tempMaterial.flatShading = false;
      break;
  }

  // Supports new flatShading boolean;
  if (Object.prototype.hasOwnProperty.call(materialData, 'flatShading')) {
    if (typeof (materialData.flatShading) === 'boolean') {
      tempMaterial.flatShading = materialData.flatShading;
    }
  }

  switch (materialData.side) {
    case 'FrontSide':
      tempMaterial.side = THREE.FrontSide;
      break;
    case 'BackSide':
      tempMaterial.side = THREE.BackSide;
      break;
    case 'DoubleSide':
      tempMaterial.side = THREE.DoubleSide;
      break;
  }
  return tempMaterial;
}

function processCamera (cameraData) {
  let remotePath = '';
  if (store.storagePath !== undefined) {
    remotePath = store.storagePath;
  }

  let camera = store.camera;
  if (cameraData.type === 'OrthographicCamera') {
    console.log('Currently there is only support for PerspectiveCamera');
    store.camera = store.orthographicCamera;
    store.camera.zoom = cameraData.zoom;
  } else {
    camera = store.perspectiveCamera;
  }

	if (!cameraData.sceneFOV || isNaN(cameraData.sceneFOV)) {
		camera.fov = 75; // Legacy Default
	}else {
		camera.fov = cameraData.sceneFOV;
	}

	store.sceneFOV = camera.fov;

	camera.updateProjectionMatrix();

  const tPosition = cameraData.start.position;
  const tTarget = cameraData.start.target;
  camera.position.set(tPosition.x, tPosition.y, tPosition.z);
  store.controls.target.set(tTarget.x, tTarget.y, tTarget.z);

  // We store the Initial Camera Position and Target position in the Views file so we can retreive it later
  views.storeInitialPosition(tPosition, tTarget);

  processCameraViews(cameraData.views);
  processAnimations(cameraData.animations, remotePath);
}

function processCameraViews (viewData) {
  viewData.map((view, index) => {
    let duration = 3000;
    if (view.duration !== undefined) {
      duration = view.duration;
    }
    const tempView = {
      x: view.position.x,
      y: view.position.y,
      z: view.position.z,
      phi: view.phi,
      theta: view.theta,
      distance: view.distance,
      duration: duration,
      name: view.name,
      visibleMenu: view.visibleMenu
    };

    if (view.id !== undefined) {
      tempView.id = view.id;
    }

    if (view.scenestate !== undefined) {
      tempView.scenestate = view.scenestate;
    }
    views.loadView(tempView);
  });
}

function processAnimations (animationData, remotePath) {
  const tourFound = false;
  animationData.map((animation) => {
    // TODO Low Priority.
    // This default value checking should be done in the animation creation not in the loader!
    let isTour = false;
    if (animation.isTour && !tourFound) {
      isTour = true;
    } else if (animation.isTour && tourFound) {
      console.warn('You have already provided an animation as a tour. Only one tour is currently supported.');
    }

    let cameraLocked = true;
    if (Object.prototype.hasOwnProperty.call(animation, 'cameraLocked')) {
      cameraLocked = animation.cameraLocked;
    }

    const tempAnimation = animations.createAnimation({ id: animation.id, name: animation.name, isTour, cameraLocked });
    animation.keyframes.map((keyframeData) => {
      // For case where scene.json is using old relative paths;
      if (keyframeData.thumbnail !== null && keyframeData.thumbnail !== undefined) {
        if (!keyframeData.thumbnail.toLowerCase().startsWith('http')) {
          keyframeData.thumbnail = remotePath + keyframeData.thumbnail;
        }
      }
      tempAnimation.addKeyframe(keyframeData);
    });
  });
}

function processEngine (data) {
  store.canvasBackgroundColor = data.canvas.backgroundColor || '#FFFFFF';
}

function processLabels (data) {
  data.map((labelData, index) => {
    if (labelData.type === undefined) {
      console.warn('Inventum_sceneLoader:Label type is missing. Your labels will need to be updated');
    } else {
      labels.createLabel(labelData);
    }
  });
}

function processProject (data) {
  if (data === undefined) {
    return;
  }
  if (data.storagePath) {
    store.storagePath = data.storagePath;
  }
}

function processScenestates (data) {
  // FIXME Needs better error handling. Needs to check for duplicate object ID's and state ID's.
  data.map((state, index) => {
    const tempSceneState = sceneStates.create({ id: state.id });

    if (state.models !== undefined) {
      tempSceneState.addModels(state.models);
    }

    if (state.labels !== undefined) {
      tempSceneState.addLabels(state.labels);
    }

    if (state.slides !== undefined) {
      tempSceneState.addSlides(state.slides);
    }

    if (state.clippingPlanes !== undefined) {
      tempSceneState.addClippingPlanes(state.clippingPlanes);
    }
  });
}

function processSlides (slideData) {
  let remotePath = '';
  if (store.storagePath !== undefined) {
    remotePath = store.storagePath;
  }

  if (slideData === undefined) {
    return;
  }

  if (Object.prototype.hasOwnProperty.call(slideData, 'elements')) {
    processSlideElements(slideData.elements, remotePath);
  }

  if (Object.prototype.hasOwnProperty.call(slideData, 'containers')) {
    slideData.containers.map((container, index) => {
      const tempSlide = slides.createSlide(container);
      tempSlide.setElements(container.elements);
    });
  }
}

function processSlideElements (elementData, remotePath) {
  elementData.map((element, index) => {
    if (element.type === 'IMAGE') {
      if (!element.content.toLowerCase().startsWith('http')) {
        element.content = remotePath + element.content;
      }
    }
    slides.createElement(element);
  });
}

function processWorld (data) {
  store.inventumTransform = new THREE.Vector3(0, 0, 0);
  store.inventumTransform.set(Number(data.transforms.inventum.x), Number(data.transforms.inventum.y), Number(data.transforms.inventum.z));
  store.regionalTransform = data.transforms.regional;

  store.interceptsScale = data.scale.labels;
  store.globalscale = data.scale.global;
  processGrid(data.grid);
  processSky(data.sky);
  processLights(data.lights);
}

function processSky (skyUsrOptions) {
  const options = {
    type: 'static',
    colorTop: '#1155a5',
    colorBottom: '#d2e8f6',
    startInclination: 0.2,
    animated: false,
    staticScale: 1.0
  };

  if (typeof (skyUsrOptions.type) === 'string') {
    options.type = skyUsrOptions.type;
  }

  if (typeof (skyUsrOptions.top) === 'string') {
    options.colorTop = skyUsrOptions.top;
  }

  if (typeof (skyUsrOptions.bottom) === 'string') {
    options.colorBottom = skyUsrOptions.bottom;
  }

  if (typeof (skyUsrOptions.startInclination) === 'number') {
    options.startInclination = skyUsrOptions.startInclination;
  }

  if (typeof (skyUsrOptions.animated) === 'boolean') {
    options.animated = skyUsrOptions.animated;
  }

  if (typeof (skyUsrOptions.staticScale) === 'number') {
    options.staticScale = skyUsrOptions.staticScale;
  }

  world.drawSky(options);
}

function processGrid (gridUsrOpts) {
  const options = { enabled: true };
  // The grid is enabled by default so we disable it if we need to

  if (Object.prototype.hasOwnProperty.call(gridUsrOpts, 'enabled')) {
    options.enabled = gridUsrOpts.enabled;
  }

  if (!options.enabled) {
    sceneGrid.disable();
  }

  if (typeof (gridUsrOpts.image) === 'string') { // deprecated
    // Some legacy jobs will have image instead of mapPath
    options.mapPath = gridUsrOpts.image;
  }

  if (typeof (gridUsrOpts.mapPath) === 'string') {
    options.mapPath = gridUsrOpts.mapPath;
  }

  if (typeof (gridUsrOpts.tiling) === 'number') {
    options.tiling = gridUsrOpts.tiling;
  }

  if (typeof (gridUsrOpts.offsetX) === 'number') {
    options.offsetX = gridUsrOpts.offsetX;
  }

  if (typeof (gridUsrOpts.offsetY) === 'number') {
    options.offsetY = gridUsrOpts.offsetY;
  }

  sceneGrid.updateTexture(options);

  if (typeof (gridUsrOpts.scale) === 'number') {
    sceneGrid.scale(gridUsrOpts.scale);
  }
}

function processLights (data) {
  if (data === undefined) {
    return;
  }

  data.map((lightData) => {
    lights.create(lightData);
  });
}

export { sceneLoader };
