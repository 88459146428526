import React from 'react';
import Draggable from 'react-draggable';
import axios from '../../../axios-config.js';
import {connect} from 'react-redux';
import {closeModal} from '../../../Redux/actions/ui.js';

let CloseButton = (props) => {
  return (
    <div onClick={props.handleClick} className="titlebarCloseButton"><i className="material-icons notranslate">close</i></div>
  )
}

let MinimizeButton = (props) => {
  return (
    <div className="titlebarMinMaxButton" onClick={props.toggleMinimize}>
      <i className="material-icons notranslate">{props.minimized ? "crop_square" : "remove"}</i>
    </div>
  )
}

let AssetForm = (props) => {
  let stringDate = new Date(props.created_at).toLocaleString();

  let hfDate = "";

  let createdAt = new Date(props.created_at);
  let currentTime = new Date();
  let unit = "day";

  let timeDiff = Math.floor((currentTime - createdAt) / 86400000);

  if (timeDiff == 0) {
    unit = "hour";
    timeDiff = Math.floor((currentTime - createdAt) / 3600000)
    if (timeDiff == 0) {
      unit = "minute"
      timeDiff = Math.floor((currentTime - createdAt) / 60000)
      if (timeDiff == 0) {
        unit = "second"
        timeDiff = Math.floor((currentTime - createdAt) / 1000)
      }
    }
  }

  if (timeDiff > 1) {
    unit = unit + 's';
  }

  if (unit === "days" && timeDiff > 31) {
    hfDate = createdAt.toLocaleDateString();
  }else {
    hfDate = `${timeDiff} ${unit} ago`;
  }


  let style = {};

  if (props.reviewID === props.id) {
    style.backgroundColor = "#ff7b00";
  }else if (props.publicID === props.id) {
    style.backgroundColor = "#108710";
  }

  let handleSet = () => {
    if (window.confirm('This will change the current Review Version to the selected one. Are you sure?')) {
      axios.post(`https://api.inventum3d.com/project/${props.activeProject}/scene/set`,{sceneID:props.id})
      .then((result) => {
        Inventum.notifications.addNotification({content:"Changed Review Version. Please Reload",displayTime:10000})
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }

  let author = "";
  if (props.first_name)  {
    author = props.first_name;
  }else {
    author = 'Unknown'
  }

  return (
    <tr style={style}>
      <td>{props.id}</td>
      <td style={{width:"100px"}} title={stringDate}>{hfDate}</td>
      <td>{props.parent_id}</td>
      <td style={{width:"100px"}}>{author}</td>
      <td className="WorldEditorButton" onClick={handleSet}>Set</td>
    </tr>
  )
}

class AssetsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scenes:[]
    };
    this.getScenes = this.getScenes.bind(this);
  }

  componentDidMount() {
    this.getScenes();
  }

  getScenes() {
    axios.get(`https://api.inventum3d.com/project/${this.props.activeProject}/scene/list`)
    .then((result) => {
      console.log(result);
      this.setState({scenes:result.data.scenes,publicID:result.data.publicID,reviewID:result.data.reviewID})
    })
    .catch((error) => {
      console.log(error);
    })
  }

  render() {
    return (
      <div className="WorldEditorDrawer">
        <table className="LightsEditorContainer">
          <thead>
            <tr>
              <th>ID</th>
              <th style={{width:"100px"}}>Created At</th>
              <th>Parent ID</th>
              <th style={{width:"100px"}}>Author</th>
              <th>Set as Review</th>
            </tr>
          </thead>
          <tbody>
            {this.state.scenes.map((scene,index) => <AssetForm activeProject={this.props.activeProject} reviewID={this.state.reviewID} publicID={this.state.publicID} key={index} {...scene}/>)}
          </tbody>
        </table>
      </div>
    )
  }
}

function WindowControls(props) {
  return (
    <div className="windowControlsContainer">
      <MinimizeButton minimized={props.minimized} toggleMinimize={props.toggleMinimize} />
      <CloseButton handleClick={props.handleClose} />
    </div>
  )
}

class ProjectHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {title:"Project History"}
    this.setTitle = this.setTitle.bind(this);
    this.toggleMinimize = this.toggleMinimize.bind(this);
  }

  toggleMinimize(force) {
    if (typeof(force) === "boolean") {
      this.setState({minimized:force});
    } else {
      this.setState({minimized:!this.state.minimized});
    }
  }

  setTitle(newTitle) {
    this.setState({title:newTitle})
  }

  render () {
    return (
      <Draggable handle=".popupDragHandle">
      <div className={"WorldEditorContainer" + (this.state.minimized ? " minimized" : "")}>
        <div className="popupDragHandle"><div style={{paddingLeft:"5px"}}>{this.state.title}</div><WindowControls minimized={this.state.minimized} toggleMinimize={this.toggleMinimize} handleClose={this.props.closeModal} /></div>
          {this.state.minimized ? null : <AssetsList closeModal={this.props.closeModal} activeProject={this.props.activeProject} modalOptions={this.props.modalOptions} />}
        </div>
      </Draggable>
    )
  }
}


let mapDispatchToProps = dispatch => ({
  closeModal:() => {
    dispatch(closeModal());
  }
})

let mapStateToProps = (state) => ({
  activeProject:state.ui.activeProject,
  modalOptions:state.ui.modalOptions
})

const InventumProjectHistoryContainer = connect(mapStateToProps,mapDispatchToProps)(ProjectHistory)

export default InventumProjectHistoryContainer
