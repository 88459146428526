import React from 'react';
import axios from 'axios';
import GA from '../../../analytics.js';
import {connect} from 'react-redux';
import {loadMapTileLayers, toggleTileLayerActive, clearActiveLayers} from '../../../Redux/actions/mapTileLayers.js';
import {toggleSidebarItem, updateSidebarItem, clearSidebarItems} from '../../../Redux/actions/sidebarItems.js';

let Layer = (props) => {
  let handleClick = () => {
    props.handleSelect(props.id);
    GA.event({category:'Map-Layers',action:`Toggled Layer ${props.name}`});
  }
  return (
    <div className={"MapLayerChoice" + (props.isActive ? " active" : "")} onClick={handleClick}>
      <i className="material-icons notranslate">{props.isActive ? "check_box" : "check_box_outline_blank"}</i>
      <span>{props.name}</span>
    </div>
  )
}

class LayerGroup extends React.Component {
  constructor(props) {
    super(props);
    let open = false;
    if (props.forceOpen) {
      open = true;
    }
    this.state = {
      open
    }
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  toggleDrawer() {
    this.setState({open:!this.state.open});
  }

  render() {
    if (this.props.availableLayers == 0) {
      return null;
    }
    let LayersDrawer = (props) => {
      return (
        <div style={{marginLeft:'5px'}}>
        {this.props.availableLayers.map((layer) => {
          let isActive = false;
          this.props.loadedLayers.map((loadedLayer) => {
            if (layer.id === loadedLayer.id) {
              isActive = true;
            }
          });
          return (
            <Layer key={layer.id} id={layer.id} name={layer.name} handleSelect={this.props.handleSelect} isActive={isActive} />
          )
        })}
        </div>
      )
    }
    return (
      <div>
        <div onClick={this.toggleDrawer} className={"MapDrawerHeader" + (this.state.open ? " open" : "")}><i className="material-icons notranslate">{this.state.open ? "keyboard_arrow_down" : "keyboard_arrow_right"}</i>{this.props.name}</div>
        {this.state.open ? <LayersDrawer /> : null}
      </div>
    )
  }
}

class MapLayerSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible:true,
      onLine:navigator.onLine
    };
    this.getMapLayers = this.getMapLayers.bind(this);
    this.handleLayerSelect = this.handleLayerSelect.bind(this);
    this.handleOnlineStatus = this.handleOnlineStatus.bind(this);
  }

  //NOTE: React Update
  componentDidMount() {
    this.getMapLayers();
    window.addEventListener('online',  this.handleOnlineStatus);
    window.addEventListener('offline', this.handleOnlineStatus);
  }

  getMapLayers() {
    axios.get('https://api.inventum3d.com/mapdata')
    .then((result) => {
      this.props.loadMapTileLayers(result.data);
      if (navigator.onLine) {
        this.handleLayerSelect('Ydz2d'); //Open Street Map layer
      }
    })
  }

  handleOnlineStatus () {
    this.setState({onLine:navigator.onLine});
    if (!navigator.onLine) {
      console.log('Clear');
      this.props.clearActiveLayers();
      this.props.clearSidebarItems();
    }
    //console.log(navigator.onLine);
  }

  handleLayerSelect(id) {
    let name = this.props.mapTileLayersAvailable[id].name;
    let visibilityHandler = () => {
      this.props.sidebarItems.map((item) => {
        if (item.id === id) {
          if (item.visibility == "all") {
            item.visibility = "mixed"
            item.opacity = 0.5;
          }else if (item.visibility == "mixed") {
            if (item.opacity > 0.5) {
              item.visibility = "all"
              item.opacity = 1.0;
            }else {
              item.visibility = "none"
              item.opacity = 0;
            }
          }else {
            item.visibility = "all"
            item.opacity = 1.0;
          }
        }
        this.props.updateSidebarItem(item);
      })
    }

    let opacityHandler = (value) => {
      this.props.sidebarItems.map((item) => {
        if (item.id === id) {
          item.opacity = value
          if (item.opacity === 1.0) {item.visibility = "all"}
          else if (item.opacity === 0) {item.visibility = "none"}
          else {item.visibility = "mixed"}
          this.props.updateSidebarItem(item);
        }
      })
    }


    this.props.toggleSidebarItem({type:"MAP_LAYER", id, name, visibility:"all", visibilityHandler, opacity:1.0, opacityHandler:opacityHandler});
    this.props.toggleTileLayerActive(id);
  }

  render() {
    //let selectedLayers = {};
    let availableLayers = this.props.mapTileLayersAvailable;
    let loadedLayers = this.props.mapTileLayersLoaded;
    let visibility = this.props.visibility;
    if (this.state.visible === false) {
      return null;
    }

    //NOTE
    //This code takes the available layers which is an object arranged by layerID as it's keys
    //This object is sorted into different arrays based on the coverage area. i.e WA First then Australia then World
    //This is pretty messy. In the future we need to think about a nice way to manage this and have a dynamic order based on how the user wants to sort the layers
    let availableLayersSorted = [];
    let auLayers = [];
    let waLayers = [];
    let wdLayers = [];
    let dfLayers = [];
    for (var tKey in availableLayers) {
      if (availableLayers.hasOwnProperty(tKey)) {
        let tLayer = availableLayers[tKey];
        switch (tLayer.coverage) {
          case "Western Australia":
            waLayers.push(tLayer);
            break;
          case "Australia":
            auLayers.push(tLayer);
            break;
          case "World":
            wdLayers.push(tLayer);
            break
          default:
            dfLayers.push(tLayer);
        }
      }
    }
    let sortPopularity = (a,b) =>{
      if (a.popularity < b.popularity)
        return -1
      else (a.popularity > b.popularity)
        return 1
      return 0
    }
    waLayers.sort(sortPopularity);
    auLayers.sort(sortPopularity);
    wdLayers.sort(sortPopularity);
    dfLayers.sort(sortPopularity);
    let noLayers = false;
    if (waLayers.length === 0 && auLayers.length === 0 && wdLayers.length === 0) {
      noLayers = true;
    }

    if (!this.state.onLine) {
      return (
        <div className="MapLayerSelector">
          <div style={{textAlign:"center",color:"#25b9a4",fontSize:"20px"}}>No Network Connection</div>
        </div>
      )
    }


    return (
      <div className="MapLayerSelector">
        <div className='MapLayerHint'>{noLayers ? "Network Connection Unavailable" : "Available Map Layers"}</div>
        <LayerGroup name={"Western Australia"} availableLayers={waLayers} loadedLayers={loadedLayers} handleSelect={this.handleLayerSelect} />
        <LayerGroup name={"Australia"} availableLayers={auLayers} loadedLayers={loadedLayers} handleSelect={this.handleLayerSelect} />
        <LayerGroup forceOpen={true} name={"World"} availableLayers={wdLayers} loadedLayers={loadedLayers} handleSelect={this.handleLayerSelect} />
        <LayerGroup name={"Other"} availableLayers={dfLayers} loadedLayers={loadedLayers} handleSelect={this.handleLayerSelect} />
      </div>
    );
  }
  //        <div onClick={()=>{this.setState({visible:false})}} className="PopupCloseButton">X</div>
}

let mapStateToProps = (state) => ({
  mapTileLayersAvailable:state.mapTileLayers.available,
  mapTileLayersLoaded:state.mapTileLayers.loaded,
  sidebarItems:state.sidebarItems
})


let mapDispatchToProps = dispatch => ({
  loadMapTileLayers:(layers) => {
    dispatch(loadMapTileLayers(layers));
  },
  toggleTileLayerActive:(id) => {
    dispatch(toggleTileLayerActive(id));
  },
  toggleSidebarItem:(item) => {
    dispatch(toggleSidebarItem(item));
  },
  updateSidebarItem:(item) => {
    dispatch(updateSidebarItem(item));
  },
  clearActiveLayers:() => {
    dispatch(clearActiveLayers());
  },
  clearSidebarItems:() => {
    dispatch(clearSidebarItems());
  }
})

const MapLayerSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MapLayerSelector);


export default MapLayerSelectorContainer
