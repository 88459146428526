import React from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';
import {connect} from 'react-redux';
import { openModal, closeModal } from '../../Redux/actions/ui.js';
import SubmitDeposit from './Popups/SubmitDeposit.js';
import ProjectDirectory from './Popups/ProjectDirectory.js';
import TenementDirectory from './Popups/TenementDirectory.js';
import HelpInfo from './Popups/HelpInfo.js';
import RegisterInterest from './Popups/RegisterInterest.js';
import RegisterAccount from './Popups/RegisterAccount.js';
import InventumSlideEditor from './Popups/InventumSlideEditor.js';

import InventumLabelEditor from './Popups/editor_v2/labels/Labels.js';
import InventumLightsEditor from './Popups/editor_v2/lights/Lights.js';
import InventumClippingEditor from './Popups/editor_v2/clipping/Clipping.js';
import InventumWorldEditor from './Popups/editor_v2/world/World.js';

import InventumAssetManager from './Popups/InventumAssetManager.js';
import InventumProjectHistory from './Popups/InventumProjectHistory.js';
import AnalyticsDirectory from './Popups/AnalyticsDirectory.js';
import PasswordReset from './Popups/PasswordReset.js';
import MeetingCreator from './Popups/MeetingCreator.js';
import SettingsEditor from './Popups/SettingsEditor.js';
import InventumSceneFileEditor from './Popups/editor_v2/SceneFileEditor.js';

import SignIn from './Popups/SignIn.js';
import ReAuthorize from './Popups/ReAuthorize.js';

function copyStyles (source, target) {
  Array.from(source.styleSheets).forEach(styleSheet => {
    if (styleSheet.href) {
      const newLinkEl = source.createElement('link');
      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = styleSheet.href;
      target.head.appendChild(newLinkEl);
    } else if (styleSheet.cssRules && styleSheet.cssRules.length > 0) {
      const newStyleEl = source.createElement('style');
      Array.from(styleSheet.cssRules).forEach(rule => {
        newStyleEl.appendChild(source.createTextNode(rule.cssText));
      });
      target.head.appendChild(newStyleEl);
    }
  })
}


class TutorialBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {showTutorial:false,popped:false}
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidMount() {
    if (!window.localStorage.getItem('Tutorial_1_Completed')) {
      this.setState({showTutorial:true})
      setTimeout(()=>{this.setState({popped:true})},2500)
    }
  }

  handleDismiss() {
    window.localStorage.setItem('Tutorial_1_Completed',true);
    this.setState({popped:false});
    setTimeout(()=>{this.setState({showTutorial:false})},1500)
  }


  render() {
    if (!this.state.showTutorial || this.props.pageMode === "3D") {
      return null;
    }

    return (
      <div className={"BarNotification" + (this.state.popped ? " visible" : "")}>Click on a Label <i className="material-icons notranslate">pin_drop</i> to view the Project in 3D <div onClick={this.handleDismiss} className="DismissButton">Got It</div></div>
    )
  }
}


class EditorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popupWindow: null
    };
    this.MODALS = [
			{ name: 'HelpInfo', title: 'Help', el: HelpInfo, icon: 'help', adminOnly: false },
			{ name: 'SettingsEditor', title: 'Settings Editor', el: SettingsEditor, icon: 'settings', adminOnly: false },
	/*  { name: 'InventumAnimationEditor', title: 'Animation Editor', el: InventumLabelEditor, icon: 'apps', adminOnly: false, reviewOnly: true }, */
      { name: 'InventumLabelEditor', title: 'Label Editor', el: InventumLabelEditor, icon: 'label', adminOnly: true, reviewOnly: true },
      { name: 'InventumLightsEditor', title: 'Lights Editor', el: InventumLightsEditor, icon: 'tungsten', adminOnly: true, reviewOnly: true },
      { name: 'InventumClippingEditor', title: 'Clipping Editor', el: InventumClippingEditor, icon: 'compare', adminOnly: true, reviewOnly: true },
      { name: 'InventumWorldEditor', title: 'World Editor', el: InventumWorldEditor, icon: 'public', adminOnly: true, reviewOnly: true },
      { name: 'InventumSlideEditor', title: 'Legacy Slide Editor', el: InventumSlideEditor, icon: 'picture_in_picture', adminOnly: true, reviewOnly: true},
			{ name: 'InventumSceneFileEditor', title: 'Advanced Scene Editor', el: InventumSceneFileEditor, icon: 'code', adminOnly: true, reviewOnly: true}
    ]

		this.openWindow = this.openWindow.bind(this);
		this.handleUnload = this.handleUnload.bind(this);
    this.popupEl = null;

		window.addEventListener('beforeunload', this.handleUnload);
  }

	componentWillUnmount () {
		window.removeEventListener('beforeunload', this.handleUnload);
	}


	handleUnload () {
		if (this.state.popupWindow) {
			this.props.closeModal();
			this.state.popupWindow.close();
			this.setState({ popupWindow: null });
		}
	}

  openWindow () {
    this.popupEl = document.createElement('div');
    this.popupEl.style.width = '100%';
    this.popupEl.style.height = '100%';

    const popupwin = window.open('', 'Settings', 'width=600,height=400,left=200,top=200');
    popupwin.document.title = "Inventum3D Editor";
    const faviconLink = popupwin.document.createElement("link");
    faviconLink.rel = "icon";
    faviconLink.href = "/favicon-32x32.png";
    faviconLink.type = "image/png";

    popupwin.document.head.appendChild(faviconLink);
    popupwin.onbeforeunload = () => {
      this.setState({ popupWindow: null });
      this.props.closeModal();
    }
    copyStyles(document, popupwin.document);
    popupwin.document.body.appendChild(this.popupEl);
    this.setState({ popupWindow: popupwin });
  }

  render () {
    if (!this.MODALS.find(modal => modal.name === this.props.activeModal)) {
      return null;
    }

    const PopOutBtn = () => {
      return (
        <div title='Open in new window' className='EditorTabIcon' onClick={this.openWindow}><i className="material-icons notranslate">open_in_new</i></div>
      )
    }

    const CloseBtn = () => {
      return (
        <div title='Close Editor' className='EditorTabIcon' onClick={this.props.closeModal}><i className="material-icons notranslate">close</i></div>
      )
    }

    const ControlsMenu = () => {
      return (
        <div style={{display:'flex', position:'absolute', right:'0px'}} >
          <PopOutBtn />
          <CloseBtn />
        </div>
      )
    }

    const EditorEl = () => {
      return (
        <div style={{ display:'flex', pointerEvents:'all', width:'100%', height:'100%', position:'relative' }}>
          {this.state.popupWindow ? null : <ControlsMenu />}
          <div className='EditorModeSelectPanel' style={{cursor: 'grab', backgroundColor:'#232323'}}>
            {this.MODALS.map((modal, index) => {
							if ((modal.adminOnly === undefined && !this.props.siteAdmin) || (modal.adminOnly && !this.props.siteAdmin ) || (modal.reviewOnly && !this.props.editMode)) {
								// If adminOnly is not set then default to assuming it's an admin only panel
								// Don't render if not logged in as Admin.
								return null;
							}

							return (
                <div onClick={() => {
                  if (this.props.activeModal === modal.name) return;
                  this.props.openModal(modal.name)
                }}
                title={modal.title}
                key={index}
                className={'EditorTabIcon' + (this.props.activeModal === modal.name ? ' active' : '')}>
                  <i className="material-icons notranslate">{modal.icon}</i>
                </div>)
             })
            }
          </div>
          <div className='EditorPanel' style={{ resize: this.state.popupWindow ? 'none' : 'both'}}>
            {this.MODALS.map((modal, index) => {
              let Element = modal.el;
              if (this.props.activeModal === modal.name) return <Element key={index} />;
              return null;
            })}
          </div>
        </div>
      )
    }

		if (this.state.popupWindow) {
      return (
        ReactDOM.createPortal(<EditorEl />, this.popupEl)
      );
    }
//style={{ width:'500px', height:'500px' }}
    return (
			<Draggable handle='.EditorModeSelectPanel'>
				<div>
	        <EditorEl />
	      </div>
			</Draggable>
    )
  }
}

const PopupContainer = (props) => {
  let style = {
    "display":"flex",
    "justifyContent":"center",
    "alignItems":"center",
    "position":"absolute",
    "width":"100%",
    "height":"100%"
  }
  return (
    <div className="PopupContainer" style={style}>
      {props.activeModal === "SubmitDeposit" ? <SubmitDeposit /> : null}
      {props.activeModal === "AnalyticsDirectory" ? <AnalyticsDirectory /> : null}
      {props.activeModal === "ProjectDirectory" ? <ProjectDirectory /> : null}
      {props.activeModal === "TenementDirectory" ? <TenementDirectory /> : null}
      {props.activeModal === "RegisterInterest" ? <RegisterInterest /> : null}
      {props.activeModal === "RegisterAccount" ? <RegisterAccount /> : null}
      {props.activeModal === "InventumAssetManager" ? <InventumAssetManager /> : null}
      {props.activeModal === "PasswordReset" ? <PasswordReset /> : null}
      {props.activeModal === "SignIn" ? <SignIn /> : null}
      {props.activeModal === "ReAuthorize" ? <ReAuthorize /> : null}
      {props.activeModal === "InventumProjectHistory" ? <InventumProjectHistory /> : null}
      {props.activeModal === "MeetingCreator" ? <MeetingCreator /> : null}
      <EditorContainer editMode={props.editMode} siteAdmin={props.siteAdmin} openModal={props.openModal} closeModal={props.closeModal} activeModal={props.activeModal} />
      <TutorialBar pageMode={props.pageMode} />
    </div>
  )
}

let mapStateToProps = (state) => ({
  activeModal: state.ui.activeModal,
  pageMode: state.sidebarUI.mode,
	editMode: state.ui.editMode,
	siteAdmin: (state.user.siteAdmin) ? true : false,
})

let mapDispatchToProps = dispatch => ({
  openModal: (value) => {
    dispatch(openModal(value));
  },
  closeModal: () => {
    dispatch(closeModal());
  }
})


const PopupContainerWrapper = connect(mapStateToProps,mapDispatchToProps)(PopupContainer)

export default PopupContainerWrapper;
