import { store } from './store.js';
import { models } from '../models/models.js';
import { views } from '../camera/views.js';
import { slides } from '../slides/slides.js';
import { labels } from '../ui/labels.js';
import { resolutionManager } from './resolution.js';

var callbacks = [];
var lastCameraMoveTime = 0;

function setControlsOptions () {
  if (store.broadcastMode || store.followerMode) {
    store.controls.rotateSpeed = 0.2;
    store.controls.panSpeed = 0.8;
    store.controls.enableDamping = false;
  } else {
    store.controls.rotateSpeed = 0.06;
    store.controls.panSpeed = 0.2;
    store.controls.enableDamping = true;
  }
}

function broadcastAspectRatio () {
	let tRes = resolutionManager.getResolution()
  sync.private.broadcast({ action: 'SET_RESOLUTION', payload: { width: window.innerWidth, height: window.innerHeight } });
}

const sync = {
  public: {
    setState: function setState (data) {
      // action = {instruction:'UPDATE_CAMERA', payload:{}};
      switch (data.action) {
        case 'CAMERA_MOVE': {
          if (!data.payload.duration) {
            data.payload.duration = 1;
          }
          const el = data.payload;
          views.animateCamera({ x: el.x, y: el.y, z: el.z, phi: el.phi, theta: el.theta, radius: el.radius }, el.duration);
          break;
        }
        case 'CAMERA_SHOW_VIEW':
          if (data.payload.options) {
            views.public.showView(data.payload.viewID, null, data.payload.options);
          } else {
            views.public.showView(data.payload.viewID);
          }
          break;
        case 'MODEL_VISIBLE_SCENE_STATE':
          models.arrayVisibleStateOriginalID(data.payload);
          break;
        case 'MODEL_FETCH_DOWNLOAD':
          models.fetchModel(data.payload);
          break;
        case 'SLIDES_HIDE_ALL':
          slides.hideAll();
          break;
        case 'SLIDES_BATCH_SET_VISIBLE':
          slides.batchSetVisible(data.payload);
          break;
        case 'LABELS_HIDE_ALL':
          labels.hideAll();
          break;
        case 'LABELS_SET_VISIBLE':
          labels.batchSetVisible(data.payload.labels, data.payload.isVisible);
          break;
        case 'SET_RESOLUTION':
          resolutionManager.setTargetAspectRatio(data.payload.width, data.payload.height);
          break;
        default:
          console.log('Unsupported Action');
          console.log(data.action);
          break;
      }
    },
    getCurrentState: function getCurrentState () {
      broadcastAspectRatio();
      views.broadcastCamera();
      models.broadcastModels();
      slides.broadcastSlides();
    },
    registerCallback: function registerCallback (cb) {
      if (typeof (cb) === 'function') {
        callbacks.push(cb);
      }
    },
    removeCallbacks: function removeCallbacks () {
      callbacks = [];
    },
    setFollowerMode: function enableFollowerMode (bool) {
      store.followerMode = bool;
      setControlsOptions();
      store.controls.enabled = !bool; // Disable controls if enabled
    },
    setBroadcastMode: function enableBroadcastMode (bool) {
      store.broadcastMode = bool;
      setControlsOptions();
      // Always Enable
      if (!store.controls.enabled && bool) {
        store.controls.enabled = true;
      }
    }
  },
  private: {
    broadcast: function broadcast (data) {
      // Rate Limit CAMERA_MOVEMENT.
      if (data.action === 'CAMERA_MOVE') {
        if (Date.now() - lastCameraMoveTime > 25) { // 16
          lastCameraMoveTime = Date.now();
        } else {
          return;
        }
      }
      callbacks.map(cb => {
        if (typeof (cb) === 'function') {
          cb(data);
        }
      });
    },
    reset: function reset () {
      callbacks = [];
    }
  }
};

store.sync = sync;

export { sync };
