import { createStore, applyMiddleware } from 'redux'
import inventumApp from './reducers/index.js'

const store = createStore(
  inventumApp,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

/*const unsubscribe = store.subscribe(() =>
  console.log(store.getState())
)*/

window.getReduxState = () => {
  console.log(store.getState());
}

export default store;
