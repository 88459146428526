import { sceneStates } from '../tools/sceneStates.js';

class Animation {
  constructor (options) {
    this.name = options.name;
    this.keyframes = [];
    this.id = options.id;
    this.isTour = options.isTour;
    this.cameraLocked = true;
    if (Object.prototype.hasOwnProperty.call(options, 'cameraLocked')) {
      this.cameraLocked = options.cameraLocked;
    }
  }

  addKeyframe (keyData) {
    if (keyData === undefined) {
      console.log('Must have keyframe data');
      return;
    }

    if (Object.prototype.hasOwnProperty.call(keyData, 'id')) {
      // console.warn('IDs have been removed from animation keys');
      delete keyData.id;
    }

    if (keyData.scenestate === undefined) {
      console.log('Keyframe needs a valid Scenestate ID');
      return;
    } else if (!sceneStates.idIsValid(keyData.scenestate)) {
      console.log('Scene State Does not exist');
      return;
    }

    if (keyData.camera === undefined) {
      console.log('Keyframe needs a valid Camera Position');
      return;
    }
    // Check this if problems with delay
    if (keyData.delay === undefined) {
      keyData.delay = 0;
    }
    this.keyframes.push(keyData);
  }
}

export { Animation };
