export const SET_FILTER = 'SET_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const REMOVE_ALL_FILTERS = 'REMOVE_ALL_FILTERS';
export const SET_FILTERED_RESULTS = 'SET_FILTERED_RESULTS'
export const CLEAR_FILTERED_RESULTS = 'CLEAR_FILTERED_RESULTS';

export function setFilter(data) {
  return { type:SET_FILTER, data}
}

export function removeFilter(data) {
  return { type:REMOVE_FILTER, data }
}

export function removeAllFilters() {
  return { type:REMOVE_ALL_FILTERS }
}

export function setFilteredResults(data) {
  return { type:SET_FILTERED_RESULTS, data }
}

export function clearFilteredResults() {
  return { type:CLEAR_FILTERED_RESULTS}
}
