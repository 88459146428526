export const SET_TITLE = 'SET_TITLE';
export const SET_SUBTITLE = 'SET_SUBTITLE';
export const SET_WEBSITE = 'SET_WEBSITE';
export const SET_LOGO = 'SET_LOGO';
export const RESET_TITLES = 'RESET_TITLES';
export const SET_LAYER_CONTENT_MODE = 'SET_LAYER_CONTENT_MODE';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export function setTitle(data) {
  return { type:SET_TITLE, data}
}

export function setSubtitle(data) {
  return { type:SET_SUBTITLE,data }
}

export function setWebsite(data) {
  return {type:SET_WEBSITE,data}
}

export function setLogo(data) {
  return {type:SET_LOGO,data}
}

export function resetTitles() {
  return { type:RESET_TITLES }
}

export function setLayerContentMode(data) {
  return { type:SET_LAYER_CONTENT_MODE,data }
}

export function openSidebar() {
  return { type:OPEN_SIDEBAR}
}

export function closeSidebar() {
  return { type:CLOSE_SIDEBAR}
}

export function toggleSidebar(data) {
  return { type:TOGGLE_SIDEBAR,data}
}
